import React, {useEffect, useState} from "react";
import NavBar from "../../components/NavBar";
import { Button, Col, Container, Modal, ModalBody, Row } from "reactstrap";
import { ROUTE_PRIVATE_BADGE_DETAIL, ROUTE_PRIVATE_CART, } from "../../services/routes/Routes";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from '@fortawesome/free-solid-svg-icons'
import Footer from "../../components/Footer";
import { addToCart } from "../../services/stores/actions/CartActions";
import {useDispatch, useSelector} from "react-redux";
import app from "../../services/Firebase";
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { checkSponsorisedBadge } from "../../services/stores/nft/NftSlice";
import BadgeSell from "../../components/BadgeSell";
import CartComponent from "../../components/CartComponent";
import ModalAskCodePin from "../../components/ModalAskCodePin";
import ModalReferralCode from "../../components/ModalReferralCode";
import {formatDateDMYFrench} from "../../services/Constant";
const db = getFirestore(app);
const badgesCollection = collection(db, 'badge-collection');

function Marketplace() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isOpenCheckBadgeDialog, setIsOpenCheckBadgeDialog] = useState(false)
    const [badgesData, setBadgesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [indexSelected, setIndexSelected] = useState(null);
    const [loadAddCart, setLoadAddCart] = useState(false);
    const [isOpenCodePin, setIsOpenCodePin] = useState(false);
    const [isReferralCodeModalOpen, setIsReferralCodeModalOpen] = useState(false);
    const [userReferralCode, setUserReferralCode] = useState('');
    const [isValidReferral, setIsValidReferral] = useState(true);
    const [discountUsed, setDiscountUsed] = useState(false)
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;

    useEffect(() => {
        document.body.style.overscrollBehaviorY = 'auto';
    }, [])

    useEffect(() => {
        var element = document.getElementById("button-nav-close");
        element.classList.remove("toggled");
        fetchBadges();
    }, []);

    useEffect(() => {
        fetchUserReferralCode();
    }, [userReferralCode])

    const fetchBadges = async () => {
        const currentDate = formatDateDMYFrench(new Date);
        const q = query(badgesCollection, where("dateEnd", ">", currentDate));
        const badgesCollectionDocs = await getDocs(q);

        if (badgesCollectionDocs.docs.length > 0) {
            var collectionBadges = [];
            badgesCollectionDocs.docs
                .map((badge, index) => {
                    const isReferrable = badge.data().isreferrable;

                    if (!badge.data().isBadgePrivate || hasMatchingUserPrivelege(badge)) {
                        collectionBadges[index] = {
                            key: badge.id,
                            name: badge.data().name,
                            collectionName: badge.data().collectionName,
                            amount: badge.data().amount,
                            isListed: badge.data().listed,
                            contractAddress: badge.data().address,
                            imageUrl: badge.data().imageUrl,
                            dateStart: badge.data().dateStart,
                            dateEnd: badge.data().dateEnd,
                            advantage: badge.data().advantages,
                            category: badge.data().category,
                            details: badge.data().details,
                            collectionImg: badge.data().collectionImg,
                            advantages: badge.data().advantages,
                            badgeSerial: badge.data().badgeSerial,
                            discount: badge.data().referralDiscount,
                            ownerEmail: badge.data().ownerEmail,
                            isreferrable: isReferrable,
                            isBadgePrivate: badge.data().isBadgePrivate,
                            privilegedUsersList: badge.data().privilegedUsersList,
                            Mint: "N/A"
                        };
                    } else {
                        collectionBadges[index] = null; 
                    }
    

                    return collectionBadges;
                });
                
            collectionBadges = collectionBadges.filter(badge => badge !== null);

            setBadgesData(collectionBadges)
        }
        setLoading(false);
    }

    function hasMatchingUserPrivelege(badge) {
        const badgeData = badge.data();

         if (badgeData.privilegedUsersList) {

        for (const key in badgeData.privilegedUsersList) {
            const privilegedUser = badgeData.privilegedUsersList[key];

            if (privilegedUser.number === user.phoneNumber) {
                return true;
            }
        }
    }
        return false;
    }
    
    const fetchUserReferralCode = async () => {
        const usersCollection = collection(db, 'users');
        const userDocRef = doc(usersCollection, user.phoneNumber);

        const userDocSnapshot = await getDoc(userDocRef)
        const userData = userDocSnapshot.data();

        setUserReferralCode(userData.referralCode || '');
    }

    const handleAddToCart = async (item, index) => {
        setIndexSelected(index);
        setLoadAddCart(true);
        await checkSponsorisedBadge(item.badgeSerial).then(async (result) => {

            if (result === true) {
                if (isValidReferral) {
                    dispatch(addToCart(item));
                    setIsOpenDialog(true);
                }
            } else if (result === false) {
                setIsOpenCheckBadgeDialog(true);
            } else if (!result) {
                if (isValidReferral) {
                    dispatch(addToCart(item));
                    setIsOpenDialog(true);
                }
            }

            setLoadAddCart(false);
        })
    };

    const handleDialogOK = () => {
        setIsOpenDialog(false);
    };

    const handlerShowBadgeInformation = async (badge) => {
        return navigate(ROUTE_PRIVATE_BADGE_DETAIL, { state: badge });
    }

    const validateReferralCode = async (referralCode) => {
        const referralIDCollectionRef = collection(db, 'referralid_collection');
        const q = query(referralIDCollectionRef, where('referralID', '==', referralCode));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size === 0) {
            console.log("Aucun code de référence correspondant n'a été trouvé");
            return null
        }

        return querySnapshot.docs[0];
    };

    const handleReferralCodeSubmit = async (referralCode, badge, indexSelected) => {
        const isValid = await validateReferralCode(referralCode);

        if (isValid && !discountUsed && referralCode !== userReferralCode) {
            const referralData = isValid.data()
            setIsValidReferral(true);
            setIsReferralCodeModalOpen(false);

            badge.isReferralUsed = true
            badge.referrerID = referralData.userID;
            badge.referralID = referralData.referralID;

            const discountPercentage = badge.discount;
            const originalPrice = badge.amount;
            const discountAmount = (discountPercentage / 100) * originalPrice;
            badge.amount = originalPrice - discountAmount;
            setBadgesData(prevState => ([...prevState]));
            setDiscountUsed(true);
        } else if(referralCode === "") {
            handleAddToCart(badge, indexSelected);
        }

        handleAddToCart(badge, indexSelected);
    };
    
    const handleNoCode = () => {
        if (indexSelected !== null) {
            const selectedItem = badgesData[indexSelected];
            handleAddToCart(selectedItem, indexSelected);
        }
        
        setIsReferralCodeModalOpen(false);
    };

    return (
        <div className="marketplace-page">
            <NavBar />
            <Modal className="modal-badge dark" funk={true} isOpen={isOpenDialog} toggle={() => setIsOpenDialog(!isOpenDialog)} size="xl">
                <ModalBody className="dark">
                    <div className="modal-badge-close">
                        <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={() => setIsOpenDialog(!isOpenDialog)} />
                    </div>
                    <div className="modal-badge-button text-center">
                        <h3 className="primary-text-color">Badge ajouté avec succès</h3>
                        <br />
                        <h5>Vous pouvez procéder au payement ou ajouter un autre badge</h5>
                        <div className="container-validate">
                            <Button block className="btn-round btn-korix-orange" size="md" onClick={() => navigate(ROUTE_PRIVATE_CART)}>
                                aller au panier
                            </Button>
                            <Button block className="btn-round return" size="md" onClick={handleDialogOK}>
                                Ajouter un autre article
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal className="modal-badge dark" funk={true} isOpen={isOpenCheckBadgeDialog} toggle={() => setIsOpenCheckBadgeDialog(!isOpenCheckBadgeDialog)} size="xl">
                <ModalBody className="dark">
                    <div className="modal-badge-close">
                        <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={() => setIsOpenCheckBadgeDialog(!isOpenCheckBadgeDialog)} />
                    </div>
                    <div className="modal-badge-button text-center">
                        <h3 className="modal-title">Badge Sponsorisé</h3>
                        <br />
                        <h5>L'offre n'est plus disponible pour ce badge </h5>
                        <div className="container-validate">
                            <Button block className="btn-round btn-korix-orange" size="md" onClick={() => setIsOpenCheckBadgeDialog(false)}>
                                OK
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Container>
                <CartComponent className={null}/>
                <div className="sell-marketplace-page">
                    <h4>Badges en ventes</h4>
                    <Row className="pt-3">
                        {
                            badgesData.map((badge, index) => {
                                return (
                                    <Col xs={6} className="pb-3">
                                        <BadgeSell
                                            index={index}
                                            loading={loading}
                                            badge={badge}
                                            onEventImage={
                                                () => {
                                                    setIndexSelected(index);
                                                    if (badge.isreferrable && !discountUsed) {
                                                        setIsReferralCodeModalOpen(true);
                                                    } else {
                                                        handleAddToCart(badge, index);
                                                    }
                                                }
                                            }
                                            loadingSelect={loadAddCart}
                                            indexSelect={indexSelected}
                                            onEvent={handlerShowBadgeInformation}
                                            className={"sell"}
                                            sell={true}
                                        />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
            </Container>
            <Footer />
            <ModalAskCodePin isOpen={isOpenCodePin} toggle={() => setIsOpenCodePin(!isOpenCodePin)} route={ROUTE_PRIVATE_CART} state={null} />
            <ModalReferralCode
                isOpen={isReferralCodeModalOpen}
                toggleModal={() => setIsReferralCodeModalOpen(false)}
                onReferralCodeSubmit={(referralCode) => handleReferralCodeSubmit(referralCode, badgesData[indexSelected], indexSelected)}
                validateReferralCode={validateReferralCode}
                onNoCode={handleNoCode }
            />
        </div>
    )
}

export default Marketplace;