import React, {useEffect, useRef, useState} from "react";
import ModalBadgeDetail from "./ModalBadgeDetail";
import {collection, getCountFromServer, getFirestore, query, where} from "firebase/firestore";
import app from "../services/Firebase";
import {formatDateDMYFrench} from "../services/Constant";
const db = getFirestore(app);
const badgeCollection = collection(db, 'badge-collection');

const BadgePartnerComponent = ({badge, onEvent, className}) => {
    const [isOpenBadgeDetail, setIsOpenBadgeDetail] = useState(false);
    const [count, setCount] = useState(0);
    const intervalClick = useRef();

    useEffect(() => {
        fetchCountBadge();
    }, [])

    const fetchCountBadge = async () => {
        const currentDate = formatDateDMYFrench(new Date);
        const q = await query(badgeCollection, where("collectionName", "==", badge.name), where("dateEnd", ">", currentDate));
        const snapshot = await getCountFromServer(q);

        setCount(snapshot.data().count)
    }

    const onMouseDown = () => {
        intervalClick.current = setTimeout(() => {
            setIsOpenBadgeDetail(true);
        }, 400);
    }

    const onMouseUp = (event: MouseEvent) => {
        clearTimeout(intervalClick.current);
    }

    return (
        <div className="badge-partner-content text-center" onClick={onEvent ? () => onEvent(badge) : null}>
            <div className="badge-partner-image"
                 style={{background: "url(" + badge.imageUrl + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}
                 onTouchStart={onMouseDown}
                 onTouchEnd={onMouseUp}
            />
            <div className="badge-partner-body">
                <h6>{badge.category}</h6>
                <span>{count} {count > 1 ? "Badges" : "Badge"}</span>
            </div>
            <ModalBadgeDetail isOpen={isOpenBadgeDetail} toggle={() => setIsOpenBadgeDetail(!isOpenBadgeDetail)} badge={badge}/>
        </div>
    );
}

export default BadgePartnerComponent;