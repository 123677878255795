import {Link} from "react-router-dom";
import {ROUTE_LEGAL_NOTICE, ROUTE_TERM_AND_CONDITION} from "../services/routes/Routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-solid-svg-icons";


function FooterLight() {
    return (
        <div className="footer register-footer-white text-center">
            <div className="footer-information  ml-auto mr-auto">
                <div className="text-center pb-3">
                    <Link to={ROUTE_TERM_AND_CONDITION} >Termes & Conditions</Link> <br/> 
                    <Link to={ROUTE_LEGAL_NOTICE} >Mentions légales</Link>
                </div>
                <div className="pb-4">
                    <span className="copyright">
                        © {new Date().getFullYear()}<br/>
                    Designed With {" "}<FontAwesomeIcon icon={faHeart} className="icon-fa"/> by Korix
                    </span>
                </div>
            </div>
        </div>
    )
}

export default FooterLight;