import React from "react";
import {Col, Modal, ModalBody, Row} from "reactstrap";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {formatDate, formatPrice} from "../services/Constant";
import BadgeImage from "./BadgeImage";


function ModalBadgeDetail({isOpen, toggle, badge}) {

    return (
        <Modal className="modal-badge" funk={true} isOpen={isOpen} toggle={toggle} size="xl">
            <ModalBody>
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle}/>
                </div>
                <div className="modal-badge-button text-center">
                    <Row className="modal-description-container-light">
                        <Col xs={3}>
                            <BadgeImage
                                badge={badge}
                                loading={false}
                                onEvent={null}
                            />
                        </Col>
                        <Col xs={9} className="modal-description-container-text">
                            {
                                badge.amount && (
                                    <>
                                        <span className="price"><strong>{formatPrice(badge.amount)} <small>FCFA</small></strong></span> <br/>
                                        <span><strong>Fin de validité le {formatDate(badge.dateEnd)}</strong></span>
                                    </>
                                )
                            }
                        </Col>
                    </Row>
                    <h4 className="title-uppercase font-weight-bold">{badge.name}</h4>
                    <div className="pb-3">
                        <p>{badge.details}</p>
                    </div>
                    {
                        undefined !== badge["advantages"] && (
                            <>
                                <div className="title-privilege-badge-page">
                                    <span><strong>Privilèges Exclusifs</strong></span>
                                </div>
                                <div>
                                    <div className="privilege-container-badge-page">
                                        {
                                            Object.keys(badge["advantages"]).map((key) => {
                                                return (
                                                    <div key={key}>
                                                        <Row>
                                                            <Col className="col-md-12">
                                                                <span>{badge["advantages"][key]["advantage"]}</span>
                                                            </Col>
                                                        </Row>
                                                        <hr/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalBadgeDetail;

ModalBadgeDetail.defaultProps = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    badge: PropTypes.object,
};

ModalBadgeDetail.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};