import { useEffect, useState } from 'react';
import { getFirestore, collection,getDocs, query, where,getDoc } from "firebase/firestore";
import { selectBadgeList, selectPhoneNumber, badgeListget } from '../../../../services/stores/nft/NftSlice';
import { useDispatch, useSelector } from 'react-redux';
import app from '../../../../services/Firebase';
import PropTypes from "prop-types";
import { Button, Container, Col, Card, Row, CardBody, CardImg, CardTitle, CardText } from 'reactstrap';
import { ROLES_ADMIN, ROLES_OWNER } from '../../../../services/Constant';

const db = getFirestore(app);
const nftMintCollection = collection(db, 'badges-mint');
const badgeCollection = collection(db, 'badge-collection');

function MintedBadgeScreen({ adminType, thisOwner, setThisOwner }) {

    const dispatch = useDispatch();
    const userTel = useSelector(selectPhoneNumber);
    const badgeList = useSelector(selectBadgeList);
    const [badgeCount, setBadgeCount] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null);
    const [totalBadge, setTotalBadge] = useState(null);

    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;

    // Get badge size useEffect
    useEffect(() => {
        const getnftMinted = async (props) => {

            const Name = await Promise.all(badgeList.result.map(async count => {
                const badgeCount = count;
                const addressByIndexID = query(nftMintCollection, where("badgeSerial", "==", badgeCount.badgeSerial))
                const docSnap = await getDocs(addressByIndexID);

                return {
                    badge: badgeCount.name,
                    image: badgeCount.imageUrl,
                    prix: badgeCount.amount,
                    end: badgeCount.dateEnd,
                    start: badgeCount.dateStart,
                    badgeOwner: badgeCount.badgeOwner,
                    contractAddress: badgeCount.address,
                    nombreBadge: docSnap.size
                };
            }));
            setBadgeCount(Name)
        }
        getnftMinted();
    }, [badgeList.result]);

    useEffect(() => {
        const fetchTotalBadge = async () => {
            const badgeCollectionDocs = await getDocs(badgeCollection);
            var badgesCollection = [];
            badgeCollectionDocs.docs.map((result) => {
                badgesCollection[result.id] = result.data();
            })

          try {
            const snapshot = await getDocs(nftMintCollection);
            const totalBadgeData = [];
            snapshot.docs.map((doc) => {
                totalBadgeData.push(
                    {
                        id: doc.id,
                        ...doc.data(),
                        ...badgesCollection[doc.data().badgeSerial]
                    }
                )
            });

        const groupedBadge = totalBadgeData.reduce((acc, badge) => {
            if (acc[badge.name]) {
              acc[badge.name].documents.push(badge);
            } else {
              acc[badge.name] = {
                ...badge,
                documents: [badge],
              };
            }
            return acc;
          }, {});

        const groupedBadgessArray = Object.values(groupedBadge);

            setTotalBadge(groupedBadgessArray);
            setLoading(false);
          } catch (error) {
            console.log(error);
            setError(error);
            setLoading(false);
          }
        };
        fetchTotalBadge();
      }, []);
    
      

    useEffect(() => {
        if (user && badgeCount) {
            const ownerBadges = Array.isArray(badgeCount) ? badgeCount : [];
            const filteredCollection = ownerBadges.map((r) => {
                const ownerCol = r.contractAddress;
                if (ownerCol === user.address) {
                    return r;
                }
                return null;
            })
                .filter((r) => r !== null);
            setThisOwner(filteredCollection);
        }
    }, [badgeCount, setThisOwner,user]);


    useEffect(() => {
        dispatch(badgeListget());
    }, [dispatch])

    const ShowToggle = (e, id) => {
        var elm = document.getElementById(id);
        if (e.target.textContent === "Tout afficher") {
            e.target.textContent = "Réduire";
            elm.classList.add("showMore");
        }
        else {
            e.target.textContent = "Tout afficher"
            elm.classList.remove("showMore");
        }
    }


    return (
        <Container className="ml-auto mr-auto">
            {loading ? (
                    <div></div>

            ) : user.role ===  ROLES_ADMIN ? (
                <div>
                    <div className="marketplace-title">
                        <h3>Badges total achetés</h3>
                    </div>
                    <Row xs="1" sm="2" md="3" lg="4"> 
                        {totalBadge.length === 0 ? (
                            <Col>
                                <div>Aucun Badge en cours</div>
                            </Col>
                        ) : (
                            totalBadge.map((row) => (
                                <Col key={row.name} className="mb-4" xs="6">
                                    <Card style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                                        {row.imageUrl && (
                                            <CardImg
                                                top
                                                src={row.imageUrl}
                                                alt={row.name}
                                                className="img-fluid h-100" 
                                                style={{  objectFit: 'cover' }}
                                            />
                                        )}
                                        <CardBody >
                                            <CardTitle>{row.name}</CardTitle>
                                            <CardTitle >Total : {row.documents.length}</CardTitle>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))
                        )}
                    </Row>
                </div>
            ) : thisOwner ? (
                <div>
                    <div className="marketplace-title">
                        <h5>Badges total achetés</h5>

                    </div>
                    <Row xs="1" sm="2" md="3" lg="4"> 
                    {thisOwner.length === 0 ? (
                        <div>
                            <div>Vous ne disposez pas encore de badge veuillez contacter l'équipe Korix</div>
                        </div>
                    ) : (
                        thisOwner.map((row, index) => (
                            <Col key={index} className="mb-4" xs="6">
                                <Card style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                                    {row.image && (
                                        <CardImg
                                            top
                                            src={row.image}
                                            alt={row.badge}
                                            className="img-fluid h-100"
                                            style={{ objectFit: 'cover' }}
                                        />
                                    )}
                                    <CardBody >
                                        <CardTitle>{row.badge}</CardTitle>
                                        <CardTitle >Total : {row.nombreBadge}</CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))
                    )}
                    </Row>
                </div>
            ) : null}
        </Container>
    );
};

export default MintedBadgeScreen;

MintedBadgeScreen.defaultProps = {
    //badgeCount: PropTypes.array,
    adminType: PropTypes.any,
    thisOwner: PropTypes.any,
    setThisOwner: PropTypes.any,
};

MintedBadgeScreen.propTypes = {
    adminType: PropTypes.any.isRequired,
    thisOwner: PropTypes.any.isRequired,
    setThisOwner: PropTypes.any.isRequired,
}