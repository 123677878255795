import React from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';

function AddRewardLevelForm({
    newRewardName,
    setNewRewardName,
    newRewardDescription,
    setNewRewardDescription,
    newRewardReferralCount,
    setNewRewardReferralCount,
    newReward,
    setNewReward,
    onAddLevel,
}) {
    const handleAddLevelClick = () => {
        if (newRewardName && newRewardDescription && newRewardReferralCount && newReward) {
            onAddLevel({
                name: newRewardName,
                description: newRewardDescription,
                referralCountRequired: newRewardReferralCount,
                reward: newReward,
            });

            setNewRewardName('');
            setNewRewardDescription('');
            setNewRewardReferralCount(0);
            setNewReward('');
        }
    };

    return (
        <div>
            <FormGroup>
                <Label>Niveau de Récompense</Label>
                <Input
                    type="text"
                    value={newRewardName}
                    onChange={(e) => setNewRewardName(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label>Description du Niveau</Label>
                <Input
                    type="text"
                    value={newRewardDescription}
                    onChange={(e) => setNewRewardDescription(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label>Nombre de Références Requises</Label>
                <Input
                    type="number"
                    value={newRewardReferralCount}
                    onChange={(e) => setNewRewardReferralCount(parseInt(e.target.value))}
                />
            </FormGroup>
            <FormGroup>
                <Label>Récompense</Label>
                <Input
                    type="text"
                    value={newReward}
                    onChange={(e) => setNewReward(e.target.value)}
                />
            </FormGroup>
            <Button onClick={handleAddLevelClick} color="primary">
                Ajouter Niveau Temporaire
            </Button>
        </div>
    );
}

export default AddRewardLevelForm;