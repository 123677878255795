import React from "react";
import {Modal, ModalBody, Card, Button, Row, Col} from "reactstrap";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

export function ModalDetailBadge({isOpen, toggle, data}) {

    return (
        <Modal className="modal-badge" funk={true} isOpen={isOpen} toggle={toggle} size="xl">
            <ModalBody>
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle}/>
                </div>
                <div className="modal-badge-button">
                    <Card className="modal-badge-card ml-auto mr-auto text-center">
                        <div className="modal-badge-card-button text-center">
                            <Button className="modal-badge-card-title text-uppercase btn-round" size="lg">
                                <div className="modal-badge-card-fa">
                                    <div className="text" style={{ color: "gray", fontSize: "1.2em" }}>Mes badges</div>
                                </div>
                            </Button>
                        </div>
                        <div className="modal-badge-box">
                            <img src={data.imageUrl} alt={data.name} />
                        </div>
                    </Card>
                </div>
                <div className="modal-badge-body">
                    <Card className="modal-badge-body-div ml-auto mr-auto text-center">
                        <Row>
                            <Col className="col-md-4">
                                <label>Token ID</label>
                            </Col>
                            <Col clasOLOsName="col-md-8">
                                <span>{data.tokenID}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-4">
                                <label>Propriétaire</label>
                            </Col>
                            <Col clasOLOsName="col-md-4">
                                <span>{data.phone}</span>
                            </Col>
                        </Row>
                    </Card>
                    <Card className="modal-badge-body-div ml-auto mr-auto text-center">
                        <Row>
                            <Col className="col-md-4">
                                <label>Créer le</label>
                            </Col>
                            <Col clasOLOsName="col-md-8">
                                <span>{data.dateStart}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-4">
                                <label>Expire le</label>
                            </Col>
                            <Col clasOLOsName="col-md-4">
                                <span>{data.dateEnd}</span>
                            </Col>
                        </Row>
                    </Card>
                    <Card className="modal-badge-body-div ml-auto mr-auto text-center">
                        <h5 className="text-uppercase">Privilège</h5> <br/>
                        <div>
                            {
                                undefined !== data.advantages &&
                                Object.keys(data.advantages).map((index) => {
                                    return (
                                        <div key={index}>
                                            <span className="text-uppercase">{data.advantages[index].advantage}</span>
                                            <hr/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Card>
                </div>


            </ModalBody>
        </Modal>
    )
}

ModalDetailBadge.defaultProps = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    data: PropTypes.object,
};

ModalDetailBadge.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};