import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useNavigate} from "react-router-dom";


function AboutUs () {

    const navigate = useNavigate();

    return (
        <div className="page-about-us">
            <Container>
                <div className="container-header-page-about-us">
                    <div className="icon-container-page-about-us">
                        <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="icon"/>
                    </div>
                    <div className="logo-container-page-about-us text-center">
                        <img src={require("../assets/img/Brand/ACCESS_LOGO_WHITE_nobg.png")} alt={"logo-korix"} width={"75%"}/>
                    </div>
                </div>
                <Row className="mini-logo-container-page-about-us">
                    <Col mx={10} className="text">
                        <span>Une Application</span>
                    </Col>
                    <Col mx={2} className="img">
                        <img src={require("../assets/img/Brand/KORIX_LOGO_b_nobg.png")} alt={"logo-korix"} width={"70%"}/>
                    </Col>
                </Row>
                <div className="card-page-about-us ml-auto mr-auto text-center">
                    <h3 className="font-weight-bold">A propos</h3>
                    <div className="card-body-page-about-us">
                        <p>
                            Korix est une startup tech malienne spécialisée dans le développement de solutions basées sur la blockchain.
                            <br/><br/>
                            Nous avons pour mission de développer le potentiel créatif en Afrique subsaharienne autour d'un écosystème bâti sur le Web-3.
                            <br/><br/>
                            Nos solutions digitales vont révolutionner les interactions entre les célébrités, les artistes, les sportifs et leurs fans.
                            <br/><br/>
                            Cette innovation permettra à la jeunesse d'Afrique francophone d'acquérir des NFT exclusifs par paiement Mobile Money et de les collectionner dans leur portefeuille acXes.
                            <br/><br/>
                            À travers des partenariats gagnant-gagnant conclus avec des partenaires locaux, nous créons des badges digitaux (NFT) qui permettront à leurs acquéreurs d'accéder à des avantages exclusifs et tangibles.
                            <br/><br/>
                            Ces avantages peuvent inclure des rencontres avec leurs idoles, du contenu exclusif de ces derniers, ou encore des accès privilégiés à leurs événements.
                            <br/><br/>
                            Nos partenaires bénéficieront non seulement de campagnes marketing taillées sur mesure autour des badges confectionnés à leur image de marque, mais également d'une nouvelle source de revenus générée par la vente des badges, sans compter les nouveaux liens créés entre les fans et les célébrités.
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default AboutUs;