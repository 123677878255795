import Dexie from "dexie";
import {Badge} from "../models/Badge";
import {saveBadge, saveReferralCount} from "../queries/Badge";
import {collection, doc, getFirestore, setDoc} from "firebase/firestore";
import app from "../Firebase";
const dbFirebase = getFirestore(app);
const PersistDataFailPaymentSuccessCollection = collection(dbFirebase, 'persist_data_fail_payment_success');
let db = new Dexie('app_cache_acxes');

const initDb = () => {
    db.version(2).stores({
        cartUser: "++id, &key, value",
    });

    db.open().catch(function (e) {
        console.error("Open failed: " + e.stack);
    })
}

initDb();

export const addBadgesToCache = async (badges, userPublicKey, operatorValue, phoneNumber, paymentOrderId, paymentTxiId) => {
    try {
        const data = {
            badges: badges,
            userPublicKey: userPublicKey,
            operatorValue: operatorValue,
            phoneNumber: phoneNumber,
            paymentOrderId: paymentOrderId,
            paymentTxiId: paymentTxiId
        }

        return await db.cartUser.add({key: `badges_cache`, value: JSON.stringify(data)});
    } catch (e) {
        console.warn(e);

        return false;
    }
}

const handelBuyBadge = (badge, userPublicKey, operatorValue, phoneNumber, paymentOrderId, paymentTxiId) => {
    return new Promise(async (resolve) => {
        try {
            const referralID = badge.referralID ? badge.referralID : null;
            const referrerID = badge.referrerID ? badge.referrerID : null;
            const badgeModel = new Badge(badge.name, 'Badges', phoneNumber, "userPublicKey", "ORANGE", badge.amount, badge.contractAddress, badge.dateStart,
                badge.dateEnd, badge.imageUrl, badge.collectionImg, badge.collectionName, badge.advantage, badge.category, badge.badgeSerial,
                "SUCCEEDED", referralID,  referrerID, paymentOrderId, paymentTxiId);
            await saveBadge(badgeModel);

            if(badge.isReferralUsed){
                await saveReferralCount(badgeModel);
            }

            resolve (true);
        } catch (error) {
            resolve(false);
            console.log(error)
        }
    });
}

/**
 * @returns {Promise<*>}
 */
export const saveBadgesToCache = async () => {
    try {
        let entry = await db.cartUser.get({key: `badges_cache`});
        if(!entry) {
            return false;
        }

        const data = JSON.parse(entry.value);
        let collectionPromiseBuyBadge = [];
        let badgesInformation = [];
        let tryToPersist = true;
        let count = 0;

        data.badges.map((badge) => {
            badgesInformation.push(
                {
                    badgeSerial: badge.badgeSerial,
                    referralID: badge.referralID ? badge.referralID : null
                });
            collectionPromiseBuyBadge.push(handelBuyBadge(badge, data.userPublicKey, data.operatorValue, data.phoneNumber, data.paymentOrderId, data.paymentTxiId));

            return collectionPromiseBuyBadge;
        });

        do {
            const responsesPromise = await Promise.all(collectionPromiseBuyBadge);

            let response = true;
            for (let i = 0; i < responsesPromise.length; i++) {
                if (responsesPromise[i] === false) {
                    response = false;
                    break;
                }
            }

            if(response) {
                await db.cartUser.clear();
                tryToPersist = false;
            } else {
                count++;
            }

            if(!tryToPersist && count === 3) {
                const PersistDataFailPaymentSuccessId = doc(PersistDataFailPaymentSuccessCollection, data.paymentTxiId);
                await setDoc(PersistDataFailPaymentSuccessId, {
                    date: new Date().toString(),
                    collectionBadgeInformation: badgesInformation,
                    payment: data.operatorValue,
                    paymentOrderId: data.paymentOrderId,
                    paymentTxiId: data.paymentTxiId,
                    publicID: data.phoneNumber,
                });
                tryToPersist = false;
            }
        } while (tryToPersist);

        return true;
    } catch (e) {
        console.warn(e);

        return false;
    }
}