import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaPaperPlane, FaTimes } from 'react-icons/fa';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { doc, updateDoc, collection, addDoc, serverTimestamp, getFirestore, getDocs, query, where } from 'firebase/firestore';
import app from '../services/Firebase';
import { useSelector } from 'react-redux';

const db = getFirestore(app);
const reelsCommentLikesCollection = collection(db, 'reels_Comments_likes');
const reelsCommentsCollection = collection(db, 'reels_comments');

const ReelCommentToast = ({ comments, onLikeComment, onAddComment, isVisible, onSendComment, onReplayComment, onClose }) => {
    const [animationClass, setAnimationClass] = useState('');
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;
    const userPhone = user.phoneNumber;
    const [newCommentText, setNewCommentText] = useState('');
    const [commentList, setCommentList] = useState([]);
    const [commentLikesCount, setcommentLikesCount] = useState(0);
    const [hasCommentLiked, setHasCommentLiked] = useState(false);

    useEffect(() => {
        setAnimationClass(isVisible ? 'show' : '');
        setCommentList(comments);

        document.body.classList.toggle('body-scroll-lock', isVisible);

        return () => {
            document.body.classList.remove('body-scroll-lock');
          };

    }, [isVisible, comments]);

    const handleLikeComment = async (comment) => {
        if (!hasCommentLiked) {
            setcommentLikesCount((prevCount) => {
                const newCount = comment.commentLikes + 1;
    
                const commentDocRef = doc(reelsCommentsCollection, comment.id);
                updateDoc(commentDocRef, { commentLikes: newCount });
    
                console.log('Like ajouté avec succès!');
                return newCount;
            });
    
            setHasCommentLiked(true);

            const likeData = {
                reelCommentId:comment.id,
                userId: userPhone,
              };

            await addDoc(reelsCommentLikesCollection, likeData);

            console.log('Like ajouté avec succès!');
        } else {
            console.log('L\'utilisateur a déjà aimé ce comentaire.');
        }
    };


    return (
        <div className={`comment-toast-container ${animationClass}`}>
            <div className="toast-header">
                <div className="close-button" onClick={onClose}>
                    <FaTimes />
                </div>
            </div>
            <div className="comments">
                {commentList.map((comment, index) => (
                    <div key={index}>
                        <div key={index} className="comment-box" >{comment.reelComment} </div>
                        
                        <div className="like-button">
                        <FontAwesomeIcon icon={faHeart} onClick={() => handleLikeComment(comment)} className="fa-like" />
                        <p> {comment.commentLikes}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="new-comment-container">
              <Input
                type="textarea"
                placeholder="Ajouter un commentaire..."
                value={newCommentText}
                onChange={(e) => {setNewCommentText(e.target.value); onAddComment(e.target.value)}}
              />
              <span className="send-button" onClick={() =>{ onSendComment(); setNewCommentText("")} }>
                <FaPaperPlane size="16px" className="fa-paper-plane" />
              </span>
            </div>
        </div>
    );
};

ReelCommentToast.propTypes = {
    comments: PropTypes.array.isRequired,
    onLikeComment: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    onReplayComment: PropTypes.func.isRequired,
    onAddComment: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ReelCommentToast;
