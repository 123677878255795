import React, { useState, useEffect, useRef } from 'react';
import { ZegoExpressEngine } from 'zego-express-engine-webrtc';
import { Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon, Modal, ModalBody, UncontrolledDropdown } from 'reactstrap';
import { FaEye, FaHeart, FaPaperPlane, FaPlay } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDollarToSlot, faClose, faHeart, faLaugh, faSmile, faThumbsDown, faThumbsUp, faWarning } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ROUTE_PRIVATE_HOME } from '../../services/routes/Routes';
import { useNavigate } from 'react-router-dom';
import SignalBars from '../../components/LiveSignalBars';

const PlayStream = () => {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [likes, setLikes] = useState("0");
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [userCount, setUserCount] = useState('');
  const roomid = useSelector((state) => state.playerRoomReducer);
  const { roomID, streamID, badgeImg } = roomid;
  const [token, setToken] = useState('');
  const [zg, setZg] = useState(null); // État pour le ZegoExpressEngine
  const userReducers = useSelector((state) => state.userReducers);
  const { user } = userReducers;
  const userUID = user.uuid;
  const firstName = user.firstName;
  const lastName = user.lastName;
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const commentsContainerRef = useRef(null);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [networkQuality, setNetworkQuality] = useState(0);
  let touchStartY = 0;
  let touchEndY = 0;

  const appID = 1936111096;
  const server = 'wss://webliveroom1936111096-api-bak.coolzcloud.com/ws';
  const [isRoomStreamReady, setIsRoomStreamReady] = useState(false);


  useEffect(() => {
    if (roomID && streamID) {
      const newZg = new ZegoExpressEngine(appID, server);
      setZg(newZg);
      setIsRoomStreamReady(true);
    }
  }, [roomID, streamID, appID, server]);

  useEffect(() => {
    if (isRoomStreamReady) {
      handleGenerateToken();
    }
  }, [isRoomStreamReady]);

  useEffect(() => {
    if (token !== "") {
      playLiveStream()
    }
  }, [zg, token, isRoomStreamReady]);

  useEffect(() => {
    if (zg) {
      zg.on("roomOnlineUserCountUpdate", (roomID, count) => {
        setUserCount(count);
      });

      zg.on('roomUserUpdate', (roomID, updateType, userList) => {
        console.log(`roomUserUpdate: room ${roomID}, user ${updateType === 'ADD' ? 'added' : 'left'}`, JSON.stringify(userList));
      });

      zg.on('roomStateUpdate', (roomID, state, errorCode, extendedData) => {
        console.log("++++++++++roomID", roomID, "++++++++++extendedData", extendedData);
      });

      zg.on("roomOnlineUserCountUpdate", (roomID, count) => {
        setUserCount(count);
        console.log("++++++++++rCOUNT++++++++++extendedData", count);
      });

      zg.on("networkQuality", (userUID, upstreamQuality,downstreamQuality) => {

        if(upstreamQuality && -1 !== upstreamQuality) {
          setNetworkQuality(upstreamQuality);
          //window.alert(upstreamQuality)
        } else if(downstreamQuality && -1 !== downstreamQuality) {
          setNetworkQuality(downstreamQuality);
          //window.alert(downstreamQuality) 
        }
      })
      
    }
  }, [zg]);

  useEffect(() => {
    if (isRoomStreamReady) {
      zg.on("IMRecvBarrageMessage", (roomID, chatData) => {
        const messageContent = chatData[0].message;

        if (messageContent.startsWith("#LIKE#")) {
          // It's a like
          const likeCount = parseInt(messageContent.replace("#LIKE#", ""), 10);
          setLikes(likeCount.toString());
        } else if (messageContent.startsWith("#EMOJI#")) {
          // It's an emoji
          const emoji = messageContent.replace("#EMOJI#", "");
          setSelectedEmoji(emoji);
        }  else {
          // It's a comment
          const updatedComments = [...comments, messageContent];
          setComments(updatedComments);

          if (scrollPosition < updatedComments.length - 5) {
            setScrollPosition(updatedComments.length - 5);
          }
        }
      });
    }

  }, [likes, comments,zg, roomID, streamID, appID, server]);

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
  }, [comments]);

  const payloadObject = {
    room_id: 'demo',
    privilege: {
      1: 1,   // loginRoom: 1 pass, 0 not pass
      2: 0    // publishStream: 1 pass, 0 not pass
    },
    stream_id_list: null
  };

  const handleGenerateToken = () => {
    const zegoFunctionUrl = 'https://us-central1-korixdev.cloudfunctions.net/zegoTokenGeneration';

    const requestData = {
      appId: 1936111096,
      userId: userUID,
      secret: "a43c58c4a5d244025315c2adfe88e5fe",
      effectiveTimeInSeconds: 3600,
      payload: ""
    };

    // Effectuez la requête HTTP vers la fonction Firebase.
    axios.post(zegoFunctionUrl, requestData)
      .then(response => {
        // La réponse contient le token généré.
        setToken(response.data.token);
      })
      .catch(error => {
        console.error('Erreur lors de l\'appel à la fonction Firebase :', error);
      });
  };


  const playLiveStream = async () => {
    try {
      if (isRoomStreamReady) {
        const userID = userUID;
        const userName = "Xn" + Math.floor(Math.random() * 100000000).toString();
        const result = await zg.loginRoom(roomID, token, { userID, userName }, { userUpdate: true });

        if (result) {
          const remoteStream = await zg.startPlayingStream(streamID);
          setIsPlaying(true);
          setIsLoaded(false);
          const liveVideo = document.getElementById('streamVideo');
          if (liveVideo) {
            liveVideo.srcObject = remoteStream;
          }
        } else {
          console.error('Échec de la connexion à la salle');
        }
      } else {
        console.log('En attente de roomID et streamID...');
      }
    } catch (error) {
      console.error('Erreur lors du démarrage du streaming en direct :', error);
    }
  };

  const handleLike = async () => {
    const integer = parseInt(likes, 10);
    const updatedLikes = integer + 1;
    const likeString = `#LIKE#${updatedLikes}`;

    setLikes(parseInt(likeString.replace("#LIKE#", ""), 10));

    try {
      const isSent = await zg.sendBarrageMessage(roomID, likeString);
    } catch (error) {
      console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++", error);
    };
  };

  const handleAddComment = async () => {
    const updatedComments = [...comments, firstName + lastName + ":" + newComment];
    setComments(updatedComments);
    setNewComment('');

    if (scrollPosition < updatedComments.length - 5) {
      setScrollPosition(updatedComments.length - 5);
    }

    try {
      await zg.sendBarrageMessage(roomID,  firstName + lastName + ":" + newComment);
    } catch (error) {
      console.log("Erreur lors de l'envoi du commentaire :", error);
    }
  };

  const handleEmojiClick = async  (emoji) => {
    setSelectedEmoji(emoji);
    const message = `#EMOJI#${emoji}`;
    await zg.sendBarrageMessage(roomID, message);
  };


  const scrollUp = () => {
    if (scrollPosition > 0) {
      setScrollPosition(scrollPosition - 1);
    }
  };

  const scrollDown = () => {
    if (scrollPosition > 0) {
      setScrollPosition(scrollPosition - 1);
    }
  };

  const handleTouchStart = (e) => {
    touchStartY = e.changedTouches[0].clientY;
  };

  const handleTouchEnd = (e) => {
    touchEndY = e.changedTouches[0].clientY;
    if (touchStartY - touchEndY > 40) {
      // Scrolling up
      scrollUp();
    } else if (touchEndY - touchStartY > 40) {
      // Scrolling down
      scrollDown();
    }
  };

  const exitLiveStream = async () => {
    try {
      if (zg) {
        zg.logoutRoom(roomID);
      }
      navigate(ROUTE_PRIVATE_HOME);
      window.location.reload()
    } catch (error) {
      console.error("Erreur lors de la deconnexion ", error);
    }
  };

  return (
    <>
    {
      isLoaded ? (
          <div className="uil-reload-css-reload-small">
              <div></div>
          </div>
      ) : null
  }
    <div className="component-playStream" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      <div className={`video-container ${isPlaying ? 'streaming' : ''}`} >
      <video id="streamVideo" autoPlay playsInline style={{ width: '100%', height: '100vh', objectFit: 'cover', }}></video>
        {isRoomStreamReady && isPlaying && (
          <div>
            <img src={require("../../assets/img/Brand/ACXES_LOGO_b_nobg.png")} alt={"logo-korix"} className="logo" />
              <div className="badge-button">
                <Button className="button">
                  <div className="badge-content">
                    <img src={badgeImg} alt="logo-korix" className="badge-img" />
                    <div className="text-lines">
                      <p>{firstName} {lastName}</p>
                      <p>{likes} j'aime</p>
                    </div>
                  </div>
                </Button>
              </div>
            <div className="user-count">
              <Button className="button">
                <FaEye /> {userCount}
              </Button>
            </div>
            <SignalBars signalLevel={networkQuality}  />

            <div className="comments"  ref={commentsContainerRef}>
            {comments.slice(scrollPosition, scrollPosition + 5).map((comment, index) => (
                <div key={index} className="comment-box">
                  {comment}
                </div>
              ))}
            </div>
            <div className="emoji-container">
              <FontAwesomeIcon
                icon={faSmile}
                className={`fa-smile ${selectedEmoji === 'smile' ? 'selected selected-emoji-animation' : ''}`}
                onClick={() => handleEmojiClick('smile')}
              />
              <FontAwesomeIcon
                icon={faHeart}
                className={`fa-heart ${selectedEmoji === 'heart' ? 'selected selected-emoji-animation' : ''}`}
                onClick={() => handleEmojiClick('heart')}
              />
              <FontAwesomeIcon
                icon={faLaugh}
                className={`fa-laugh ${selectedEmoji === 'laugh' ? 'selected selected-emoji-animation' : ''}`}
                onClick={() => handleEmojiClick('laugh')}
              />
              <FontAwesomeIcon
                icon={faThumbsUp}
                className={`fa-thumbs-up ${selectedEmoji === 'thumbs-up' ? 'selected selected-emoji-animation' : ''}`}
                onClick={() => handleEmojiClick('thumbs-up')}
              />
              <FontAwesomeIcon
                icon={faThumbsDown}
                className={`fa-thumbs-down ${selectedEmoji === 'thumbs-down' ? 'selected selected-emoji-animation' : ''}`}
                onClick={() => handleEmojiClick('thumbs-down')}
              />
            </div>
            <div className="new-comment">
              <Input
                type="textarea"
                placeholder="Ajouter un commentaire"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
              <span className="send-button" onClick={handleAddComment}>
                <FaPaperPlane size="16px" className="fa-paper-plane" />
              </span>
            </div>
            <ButtonGroup className="play-button-group">
              <UncontrolledDropdown direction="left" className="play-dollar-dropdown" >
                <DropdownToggle caret color="primary" className="play-dropdown-toggle">
                  <FontAwesomeIcon icon={faCircleDollarToSlot} />
                </DropdownToggle>
                <DropdownMenu className="play-dropdown-menu">
                  <DropdownItem className="play-dropdown-item"> 10 000 Fcfa </DropdownItem>
                  <DropdownItem className="play-dropdown-item"> 5 000 Fcfa</DropdownItem>
                  <DropdownItem className="play-dropdown-item"> 1000 Fcfa </DropdownItem>
                  <DropdownItem className="play-dropdown-item">250 Fcfa</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Button onClick={handleLike} color="link" className="play-like-button">
                <FaHeart color='red' size="32px" /> {likes}
              </Button>
            </ButtonGroup>
          </div>
        )}
      </div>
      <div className="go-live-button">
        {!isPlaying && !isLoaded  ? (
          <Button onClick={playLiveStream} color="green" className="follow-button">
            Suivre
            <FaPlay />
          </Button>
        ) : isPlaying && !isLoaded && (
          <div className="close-button">
            <FontAwesomeIcon icon={faClose} className="close-icon" onClick={() => setIsOpenDialog(true)} />
          </div>
        )}
      </div>
      <Modal className="modal-badge dark" funk={true} isOpen={isOpenDialog} toggle={() => setIsOpenDialog(false)} size="xl">
        <ModalBody className="dark">
          <div className="modal-badge-close">
            <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={() => setIsOpenDialog(false)} />
          </div>
          <div className="modal-badge-button text-center">
            <div align="center" className="pt-0 pb-0">
              <FontAwesomeIcon icon={faWarning} className="icon-fa" color="white" size={"6x"} />
            </div>
            <br />
            <h5>Voulez-vous vraiment fermer le live </h5>
            <div className="container-validate">
              <Button block className="btn-round btn-korix-orange" size="md" onClick={exitLiveStream}>
                Oui
              </Button>
              <Button block className="btn-round btn-korix-orange" size="md" onClick={() => setIsOpenDialog(false)}>
                Non
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
    </>
  );
};

export default PlayStream
