import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "../audience.css";
import { useNavigate } from "react-router-dom";
import { ROUTE_PRIVATE_LIVE_STREAM } from "../services/routes/Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { addDoc, collection, doc, getDocs, getFirestore, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import app from "../services/Firebase";
import { useSelector, useDispatch } from "react-redux";
import { setBadgeImg, setRoomID, setStreamID } from "../services/stores/actions/StreamActions";

const db = getFirestore(app);

const ModalReelAudience = ({ isOpen, toggle, badges, handleSaveSelectedBadges, selectedImages, handleImageSelect }) => {

    



    return (
        <Modal className="modal-badge" isOpen={isOpen} toggle={toggle} size="xl">
            <ModalBody className="modal-body">
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle} />
                </div>
                <div className="badge-content text-center">
                    <div className="d-flex flex-wrap justify-content-center">
                        {badges.map((image, index) => (
                            <div key={index} className="image-checkbox-container">
                                <div className="image-container">
                                    <img className="img-responsive" src={image.imageUrl} alt={image.alt} />
                                </div>
                                <label className="checkbox-label">
                                    <input
                                        type="checkbox"
                                        className="image-checkbox"
                                        checked={selectedImages.includes(index)}
                                        onChange={() => handleImageSelect(index)}
                                    />
                                </label>
                            </div>
                        ))}
                    </div>

                    <div className="pt-3">
                        <p>Veuillez choisir une audience à travers les badges présent ici</p>
                    </div>
                    <div className="container-validate">
                        <Button block className="btn-round btn-korix-orange" size="md" onClick={ handleSaveSelectedBadges }>
                            Confirmer
                        </Button>
                        <Button block className="btn-round btn-korix-black" onClick={toggle}>
                            Annuler
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalReelAudience;