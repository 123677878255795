import {getFirestore, collection, setDoc, deleteDoc, query, orderBy, getDocs, doc, getDoc} from "firebase/firestore";
import {User} from "../models/Users";
import app from "../../services/Firebase";
import {giveBadgeForANewUser} from "./Badge";
const db = getFirestore(app);
const userCollection = collection(db, 'users');
const collectionCELO = collection(db, 'address-CELO');

export async function saveUser(user: User, userPublicKey: String) {
    return new Promise(async (resolve, reject) => {
        const addressByIndexID = query(collectionCELO, orderBy('indexID', 'asc'))
        const addressDocSnap = await getDocs(addressByIndexID);
        const lastDoc = (addressDocSnap.docs[0].data());
        const deleteLastDoc = doc(collectionCELO, addressDocSnap.docs[0].id);

        const userDocID = doc(userCollection, user.getPhoneNumber());
        const userAddressCollection = collection(userDocID, 'address-CELO');
        const thisDoc = doc(userAddressCollection, lastDoc.publicKey);

        setDoc(userDocID, {
            firstName: user.getFirstName(),
            lastName: user.getLastName(),
            phoneNumber: user.getPhoneNumber(),
            role: user.getRole(),
            createdAt: user.getCreatedAt(),
            badge: user.getBadge(),
            address: user.getAddress(),
            status: user.getStatus(),
        }).then( async () => {
            await giveBadgeForANewUser("KRX002", user, lastDoc.publicKey).then(() => {
                setDoc(thisDoc, {
                    publicKey: lastDoc.publicKey,
                    publicID: user.getPhoneNumber(),
                    isUsed: true,
                    indexID: lastDoc.indexID,
                    chainID: 'CELO'
                }).then(() => {
                    deleteDoc(deleteLastDoc);
                    resolve(true);
                })
                .catch((error) => {
                    console.log(error);
                    resolve(false);
                })
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            })
        }).catch((error) => {
            console.log(error);
            resolve(false);
        });
    });
}

export function getUser(phoneNumber) {
    return new Promise(async (resolve) => {
        const userDocRef = doc(userCollection, phoneNumber);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            if(!data.status) {
                resolve(false);
            } else {
                resolve({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phoneNumber: data.phoneNumber,
                    role: data.role,
                    status: data.status,
                    codePin: data.codePin,
                    badge: data.badge,
                    address: data.address,
                });
            }
        }

        resolve(false)
    })
}