import PropTypes from "prop-types";
import {Button, Card, Col, FormGroup, Input, Label, Modal, ModalBody, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";


function ModalNewAdvantage({isOpen, toggle, badgeAdvantage, setBadgeAdvantage}) {

    //const [badgesAdvantage, setBadgesAdvantage] = useState([{ actor: "", advantage: "" }]);

    // handel remove AVANTAGES Option
    const handelRemoveAdvantage = (index) => {
        const list = [...badgeAdvantage];
        list.splice(index, 1);
        setBadgeAdvantage(list);
    }

    // handel inputing value of name and type of AVANTAGES Option
    const handelAddAdvantage = (e) => {
        const list = badgeAdvantage.concat({ actor: "", advantage: "" });
        setBadgeAdvantage(list);
    }

    const handlerChangeField = (e, index) => {
        const { name, value } = e.target;
        const list = [...badgeAdvantage];
        list[index][name] = value;
        setBadgeAdvantage(list);
    }

    return (
        <>
        <Modal className="modal-badge" funk={true} isOpen={isOpen} toggle={toggle} size="xl">
            <ModalBody>
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle}/>
                </div>
                <div className="modal-badge-button">
                    <Card className="modal-badge-card ml-auto mr-auto">
                        <Button className="modal-badge-button-title detail text-uppercase btn-round" size="lg">
                            <div className="text">Avantages</div>
                        </Button>
                        <div className="modal-badge-box">
                            <span className="modal-list-text">Vous pouvez ajouter autant d'avantages que necessaire</span>
                            <div className="pt-3">
                                {
                                    badgeAdvantage.map((advantage, index) => {
                                        return (
                                            <div key={index}>
                                                {
                                                    0 !== index && (
                                                        <div className="modal-badge-collection-close">
                                                            <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={(e) => handelRemoveAdvantage(index)}/>
                                                        </div>
                                                    )
                                                }
                                                <Row>
                                                    <Col className="col-md-6">
                                                        <FormGroup>
                                                            <Label for="actor" className="modal-list-text">Acteur</Label>
                                                            <Input type="text" name="actor" id="actor" placeholder="Acteur" onChange={(e) => handlerChangeField(e, index)}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="col-md-6">
                                                        <FormGroup>
                                                            <Label for="advantage" className="modal-list-text">Avantage</Label>
                                                            <Input type="text" name="advantage" id="advantage" placeholder="Avanatage" onChange={(e) => handlerChangeField(e, index)}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <Button className="btn-round" color="primary" onClick={handelAddAdvantage}><FontAwesomeIcon icon={faPlus}/> Nouvel avantage</Button>
                        </div>
                        <div className="right-side">
                            <Button className="btn-round" color="primary" onClick={toggle}>Valider</Button> {" "}
                            <Button className="btn-round" color="default" onClick={toggle}>Annuler</Button>
                        </div>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
        <div className='row'>
        {badgeAdvantage.map((row, index) =>
            row.advantage !== "" &&
            <div className='col-sm-2 ProOpt' key={index}>
                <div>{row.actor}</div>
                <div>{row.advantage}</div>
            </div>
        )}
    </div>
     </>
    )
}

export default ModalNewAdvantage;

ModalNewAdvantage.defaultProps = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    badgeAdvantage: PropTypes.array,
    setBadgeAdvantage: PropTypes.func,
};

ModalNewAdvantage.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    badgeAdvantage: PropTypes.array.isRequired,
    setBadgeAdvantage: PropTypes.func.isRequired,
};