import {Button, Container} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {ROUTE_ABOUT_US, ROUTE_LOGIN, ROUTE_PRIVATE_HOME} from "../services/routes/Routes";
import React from "react";
import AddToScreenToast from "../components/Toast";
import FooterLight from "../components/FooterLight";
import {useSelector} from "react-redux";


function Welcome() {

    const userReducers = useSelector((state) => state.userReducers);
    const { isAuthenticated } = userReducers;
    const navigate = useNavigate();

    const handlerRedirect = () => {

        if(!isAuthenticated) {
            navigate(ROUTE_LOGIN);
            return;
        }

        navigate(ROUTE_PRIVATE_HOME);
    }

    return (
        <div className="page-welcome">
            <AddToScreenToast/>
            <div className="page-header-page-welcome">
                <div className="block-page-welcome">
                    <Container>
                        <div className="information-page-welcome">
                            <div className="logo-container text-center">
                                <img src={require("../assets/img/Brand/ACCESS_LOGO_WHITE_nobg.png")} alt={"logo-korix"} width={"75%"}/>
                            </div>
                            <div className="title-page-welcome">
                                <span>La magie des badges</span>
                            </div>
                            <div className="button-border-page-welcome ml-auto mr-auto">
                                <div className="button-border-content-page-welcome"/>
                            </div>
                            <div className="block-information-page-welcome ml-auto mr-auto">
                                <span>Acheter nos badges et profiter de nos accès exclusifs</span>
                                <hr className="hr-page-welcome"/>
                                <div className="about-us-page-welcome">
                                    <Button block className="btn-round" size="lg" onClick={() => navigate(ROUTE_ABOUT_US)}>
                                        A propos
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="buy-badge-container-page-welcome">
                            <Button block className="btn-round btn-korix-black" size="md" onClick={handlerRedirect}>
                                se connecter
                            </Button>
                        </div>
                        <FooterLight />
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Welcome;