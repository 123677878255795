import React, {useEffect, useState} from "react";
import {Button, Col, Container, Row} from "reactstrap";
import {ROUTE_500, ROUTE_PRIVATE_CART} from "../../services/routes/Routes";
import {useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {collection, doc, getDoc, getDocs, getFirestore, query, where} from "firebase/firestore";
import app from "../../services/Firebase";
import {formatDate, formatPrice} from "../../services/Constant";
import {useDispatch, useSelector} from "react-redux";
import {checkSponsorisedBadge} from "../../services/stores/nft/NftSlice";
import {addToCart} from "../../services/stores/actions/CartActions";
import ModalReferralCode from "../../components/ModalReferralCode";
const db = getFirestore(app);
const badgesCollection = collection(db, 'badge-collection');

function Badge() {

    const {state} = useLocation();
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isValidReferral, setIsValidReferral] = useState(true);
    const [loadAddCart, setLoadAddCart] = useState(false);
    const [isReferralCodeModalOpen, setIsReferralCodeModalOpen] = useState(false);
    const [discountUsed, setDiscountUsed] = useState(false)
    const [userReferralCode, setUserReferralCode] = useState('');
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;

    useEffect(() => {
        if(!state) {
            navigate(ROUTE_500)
        }

    }, [state])

    useEffect(() => {
        fetchInformation();
    }, [])

    useEffect(() => {
        fetchUserReferralCode();
    }, [userReferralCode]);

    const fetchUserReferralCode = async () => {
        const usersCollection = collection(db, 'users');
        const userDocRef = doc(usersCollection, user.phoneNumber);

        const userDocSnapshot = await getDoc(userDocRef)
        const userData = userDocSnapshot.data();

        setUserReferralCode(userData.referralCode || '');
    }

    const fetchInformation = async () => {
        const badgeDocRef = await doc(badgesCollection, state.key);
        const badgeInfo = await getDoc(badgeDocRef);
        if(badgeInfo.exists()) {
            const isReferrable = badgeInfo.data().isreferrable;
            setData({
                key: badgeInfo.id,
                name: badgeInfo.data().name,
                collectionName: badgeInfo.data().collectionName,
                amount: badgeInfo.data().amount,
                isListed: badgeInfo.data().listed,
                contractAddress: badgeInfo.data().address,
                imageUrl: badgeInfo.data().imageUrl,
                dateStart: badgeInfo.data().dateStart,
                dateEnd: badgeInfo.data().dateEnd,
                advantage: badgeInfo.data().advantages,
                category: badgeInfo.data().category,
                details: badgeInfo.data().details,
                collectionImg: badgeInfo.data().collectionImg,
                advantages: badgeInfo.data().advantages,
                badgeSerial: badgeInfo.data().badgeSerial,
                discount:badgeInfo.data().referralDiscount,
                ownerEmail:badgeInfo.data().ownerEmail,
                isreferrable:isReferrable,
                Mint: "N/A"
            });
        }
    }

    const validateReferralCode = async (referralCode) => {
        const referralIDCollectionRef = collection(db, 'referralid_collection');
        const q = query(referralIDCollectionRef, where('referralID', '==', referralCode));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size === 0) {
            console.log("Aucun code de référence correspondant n'a été trouvé");
            return null
        }

        return querySnapshot.docs[0];
    };

    const handleAddToCart = async (badge) => {
        setLoadAddCart(true);
        await checkSponsorisedBadge(badge.key).then(async (result) => {
            if (result === true) {
                if (isValidReferral) {
                    dispatch(addToCart(badge));
                }
            } else if (!result) {
                if (isValidReferral) {
                    dispatch(addToCart(badge));
                }
            }

            setLoadAddCart(false);
            navigate(ROUTE_PRIVATE_CART);
        })
    };

    const handleReferralCodeSubmit = (badge, referralCode) => {
        const isValid = validateReferralCode(referralCode);

        if (isValid  && !discountUsed && referralCode !== userReferralCode && referralCode !== "") {
            setIsValidReferral(true);
            setIsReferralCodeModalOpen(false);
            const discountPercentage = badge.referralDiscount;
            const originalPrice = badge.amount;
            const discountAmount = (discountPercentage / 100) * originalPrice;
            badge.amount = originalPrice - discountAmount;
            setDiscountUsed(true);
        }

        handleAddToCart(badge);
    }

    const handleNoCode = (badge) => {
        handleAddToCart(badge);
        setIsReferralCodeModalOpen(false);
    };

    return (
        <div className="badge-page">
            <Container>
                <div className="container-header-badge-page">
                    <div className="icon-container-badge-page">
                        <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="icon"/>
                    </div>
                </div>

                <div className="img-container-badge-page">
                    <img src={state.imageUrl} alt={state.key}/>
                </div>
                <div className="card-page-badge-page text-center">
                    <div className="card-page-block-price-badge-page">
                        <span className="price"><strong>{formatPrice(data.amount)} <small>FCFA</small></strong></span> <br/>
                        <span><strong>Fin de validité le {formatDate(data.dateEnd)}</strong></span>
                    </div>
                    <h4 className="title-uppercase font-weight-bold">{data.name}</h4>
                    <div className="pb-3">
                        <p>{data.details}</p>
                    </div>
                    <div className="title-privilege-badge-page">
                        <span><strong>Privilèges Exclusifs</strong></span>
                    </div>
                    <div>
                        <div className="privilege-container-badge-page">
                            {
                                undefined !== data["advantages"] ?
                                    Object.keys(data["advantages"]).map((key) => {
                                        return (
                                            <div key={key}>
                                                <Row>
                                                    <Col className="col-md-12">
                                                        <span>{data["advantages"][key]["advantage"]}</span>
                                                    </Col>
                                                </Row>
                                                <hr/>
                                            </div>
                                        )
                                    }) : null
                            }
                        </div>

                        <div className={"container-validate-badge-page"}>
                            <Button className="btn-round btn-korix-black" color="primary" size="lg" onClick={
                                () => {
                                    if (data.isreferrable) {
                                        setIsReferralCodeModalOpen(true);
                                    } else {
                                        handleAddToCart(data);
                                    }
                                }
                            }>
                                {
                                    loadAddCart ?
                                        <div className="uil-reload-css-reload-xxs">
                                            <div></div>
                                        </div> :
                                        "Acheter un badge"
                                }
                            </Button>
                        </div>

                    </div>
                </div>
            </Container>
            <ModalReferralCode
                isOpen={isReferralCodeModalOpen}
                toggleModal={() => setIsReferralCodeModalOpen(false)}
                onReferralCodeSubmit={(referralCode) => handleReferralCodeSubmit(data, referralCode)}
                validateReferralCode={validateReferralCode}
                onNoCode={() => handleNoCode(data) }
            />
        </div>
    )
}

export default Badge;