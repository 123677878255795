import React from "react";
import classnames from "classnames";
import {
    ROUTE_ABOUT_US,
    ROUTE_LOGIN, ROUTE_PRIVATE_HOME,
    ROUTE_PRIVATE_USER_PROFILE,
    ROUTE_TERM_AND_CONDITION,
    routes
} from "../services/routes/Routes";
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Button,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronRight, faPhone} from '@fortawesome/free-solid-svg-icons'
import {Link, useLocation, useNavigate} from 'react-router-dom';
import app from '../services/Firebase';
import {useDispatch, useSelector} from "react-redux";
import ScanQrcode from "./ScanQrcode";
import {addDoc, collection, getDocs, getFirestore, query, where} from "firebase/firestore";
import CartComponent from "./CartComponent";
import {logOutUser} from "../services/stores/actions/UserActions";
import {codeScanError, ROLES_ADMIN, ROLES_AGENT, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_OWNER, isDateExpire} from "../services/Constant";
import ShowStatusScanQrcode from "./ShowStatusScanQrcode";
import ModalAudience from "./liveAudienceSelect";
const db = getFirestore(app);
const eventsUsersCollection = collection(db, 'events_users');

function NavBar() {
    let scan = false;
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;
    const [isOpen, setIsOpen] = React.useState(false);
    const [navbarCollapse, setNavbarCollapse] = React.useState(false);
    const [badgeIsOk, setBadgeIsOk] = React.useState(null);
    const [badgeInformation, setBadgeInformation] = React.useState(null);
    const [popupToggle, setPopupToggle] = React.useState(false);
    const [openAudienceModal, setOpenAudienceModal] =  React.useState(false);

    const toggleNavbarCollapse = () => {
        setNavbarCollapse(!navbarCollapse);
        document.documentElement.classList.toggle("nav-open");
    };

    const handlerDisconnect = () => {
        dispatch(logOutUser());
        return navigate(ROUTE_LOGIN);
    }

    function isExpireQr (expireDate) {
        return isDateExpire(expireDate);
    }

    function userCanScan(address) {
        return user.role === ROLES_AGENT && (user.address === null || !user.address.includes(address));
    }

    const onNewScanResult = async (decodedResult) => {
        if(true === scan) {
            return;
        }

        scan = true;
        const qrCodeResponse = JSON.parse(decodedResult);

        if(userCanScan(qrCodeResponse.contractAddress)) {
            setBadgeIsOk(codeScanError.scanUnauthorised);
            setIsOpen(true);
            setPopupToggle(false);
            return;
        }

        if(isExpireQr(qrCodeResponse.expireDate)) {
            setBadgeIsOk(codeScanError.scanInvalid);
            setBadgeInformation({expireDate: qrCodeResponse.expireDate});
            setIsOpen(true);
            setPopupToggle(false);
            return;
        }

        const queryEvents = await query(eventsUsersCollection,
            where("tokenId", "==", qrCodeResponse.tokenId),
            where("userTel", "==", qrCodeResponse.userTel),
            where("eventId", "==", qrCodeResponse.eventId)
        );

        const event = await getDocs(queryEvents);
        if(true === event.empty) {
            const user = {
                address: qrCodeResponse.contractAddress,
                userTel: qrCodeResponse.userTel,
                userName: qrCodeResponse.userName,
                eventName: qrCodeResponse.name,
                scanAt: new Date().toString(),
                eventId: qrCodeResponse.eventId,
                tokenId: qrCodeResponse.tokenId
            }

            await addDoc(eventsUsersCollection, user);
            setBadgeIsOk(codeScanError.scanValid);
            setBadgeInformation({userName: qrCodeResponse.userName, name: qrCodeResponse.name, userTel: qrCodeResponse.userTel});
        } else {
            setBadgeIsOk(codeScanError.scanIsAlreadyOk);
            setBadgeInformation({scanAt: event.docs[0].data().scanAt});
        }

        setIsOpen(true);
        setPopupToggle(false);
    }

    const handlerChange = (route) => {
        document.documentElement.classList.remove("nav-open");
        var element = document.getElementById("button-nav-close");
        element.classList.remove("toggled");
        navigate(route);
    }

    const handlerRedirectUserProfile = () => {
        document.documentElement.classList.remove("nav-open");
        var element = document.getElementById("button-nav-close");
        element.classList.remove("toggled");
        navigate(ROUTE_PRIVATE_USER_PROFILE, {
            state: {
                whereIComeFrom: location.pathname,
            }
        });
    }

    return (
        <Navbar className={classnames("fixed-top")} expand="lg">
            <Container>
                <div className="navbar-translate">
                    <NavbarBrand
                        data-placement="bottom"
                        title="Korix"
                        to={ROUTE_PRIVATE_HOME}
                        tag={Link}
                    >
                        <img src={require("../assets/img/Brand/ACCESS_LOGO_WHITE_nobg.png")} alt={"logo-korix"}/>
                    </NavbarBrand>
                    <div className="comment-icon">
                        {
                            [ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_OWNER].includes(user.role) && (
                                <img src={require("../assets/img/icone-live1_1.png")} 
                                className="go-live" 
                                alt={"golive"}
                                onClick={() =>  setOpenAudienceModal(true)}
                                />
                            )
                        }
                        {
                            [ROLES_ADMIN, ROLES_AGENT].includes(user.role) && (
                                <div className="qrcode">
                                    <ScanQrcode
                                        popupToggle={popupToggle}
                                        setPopupToggle={setPopupToggle}
                                        qrCodeSuccessCallback={onNewScanResult}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <button
                        id="button-nav-close"
                        aria-expanded={navbarCollapse}
                        className={classnames("navbar-toggler navbar-toggler close", {
                            toggled: navbarCollapse
                        })}
                        onClick={toggleNavbarCollapse}
                    >
                        <span className="navbar-toggler-bar bar1" />
                        <span className="navbar-toggler-bar bar2" />
                        <span className="navbar-toggler-bar bar3" />
                    </button>
                </div>
                <Collapse
                    className="justify-content-end"
                    navbar
                    isOpen={navbarCollapse}
                >
                    <Nav navbar>
                        <div className="nav-header" onClick={handlerRedirectUserProfile}>
                            <div className="block-image">
                                <img src={require("../assets/img/user-icon.png")} alt={user.firstName + " " + user.lastName}/>
                            </div>
                            <div className="block-user-name">
                                <span className="user-name">{user.firstName} {user.lastName}</span> <br/>
                                <span>{user.phoneNumber}</span>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faChevronRight} className="icon"/>
                            </div>
                        </div>

                        {
                            routes.map(route => {
                                if(route.show && route.roles) {
                                    if(route.roles.includes(user.role)) {
                                        return (
                                            <>
                                                {
                                                    route.collapse ?
                                                        <>
                                                            <UncontrolledDropdown nav inNavbar>
                                                                <DropdownToggle nav caret>
                                                                    Dashboard
                                                                </DropdownToggle>
                                                                <DropdownMenu right>
                                                                    {
                                                                        route.child.map((child, index) => {
                                                                            return (
                                                                                <DropdownItem className="nav-transparent" title={child.key} onClick={() => handlerChange(child.path)}>{child.name}</DropdownItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </> :
                                                        <NavItem key={route.key} className={route.path === location.pathname ? "active" : ""}>
                                                            <NavLink
                                                                onClick={() => handlerChange(route.path)}
                                                                title={route.key}
                                                            >
                                                                <p>{route.name}</p>
                                                            </NavLink>
                                                        </NavItem>
                                                }
                                            </>
                                        )
                                    }
                                }

                                return null;
                            })
                        }
                        <CartComponent className={"in-nav"}/>

                        <NavItem>
                            <NavLink
                                onClick={() => handlerChange(ROUTE_ABOUT_US)}
                                title={"aboutus"}
                            >
                                <p>À propos</p>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                onClick={() => handlerChange(ROUTE_TERM_AND_CONDITION)}
                                title={"Terme et condition"}
                            >
                                <p>Termes et conditions</p>
                            </NavLink>
                        </NavItem>

                        <div className="nav-footer">
                            <div className="span-center">
                                <FontAwesomeIcon icon={faPhone} className="icon"/> <br/>
                                <a href="tel:+22390501050"> Obtenir de l'aide ! </a>
                            </div>
                            <Button
                                className="btn-round btn-korix-black"
                                onClick={handlerDisconnect}
                            >
                                Se déconnecter
                            </Button>
                        </div>
                    </Nav>
                </Collapse>
            </Container>
            <ShowStatusScanQrcode
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                codeScanStatus={badgeIsOk}
                badgeInformation={badgeInformation}
            />
            <ModalAudience isOpen={openAudienceModal} toggle={() => setOpenAudienceModal(!openAudienceModal)} />
        </Navbar>
    );
}

export default NavBar;
