import React, {useEffect, useState} from "react";
import {Modal, ModalBody, Button} from "reactstrap";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {ROUTE_PRIVATE_CART} from "../services/routes/Routes";
import BadgeImage from "./BadgeImage";
import {useDispatch, useSelector} from "react-redux";
import {checkSponsorisedBadge} from "../services/stores/nft/NftSlice";
import {addToCart} from "../services/stores/actions/CartActions";
import {collection, doc, getDoc, getDocs, getFirestore, query, where} from "firebase/firestore";
import app from "../services/Firebase";
import InputComponent from "./input/InputComponent";
const db = getFirestore(app);

function ModalAskBadge({isOpen, toggle, loading, badge}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isValidReferral, setIsValidReferral] = useState(true);
    const [loadAddCart, setLoadAddCart] = useState(false);
    const [isReferralCodeModalOpen, setIsReferralCodeModalOpen] = useState(false);
    const [referralCode, setReferralCode] = useState('');
    const [userReferralCode, setUserReferralCode] = useState('');
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;

    useEffect(() => {
        if (badge && badge.isreferrable) {
            setIsReferralCodeModalOpen(true);
        }
    }, [badge])

    useEffect(() => {
        fetchUserReferralCode();
    }, [userReferralCode]);

    const fetchUserReferralCode = async () => {
        const usersCollection = collection(db, 'users');
        const userDocRef = doc(usersCollection, user.phoneNumber);

        const userDocSnapshot = await getDoc(userDocRef)
        const userData = userDocSnapshot.data();

        setUserReferralCode(userData.referralCode || '');
    }

    const validateReferralCode = async (referralCode) => {
        const referralIDCollectionRef = collection(db, 'referralid_collection');
        const q = query(referralIDCollectionRef, where('referralID', '==', referralCode));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size === 0) {
            console.log("Aucun code de référence correspondant n'a été trouvé");
            return null
        }

        return querySnapshot.docs[0];
    };

    const handleAddToCart = async (item) => {
        setLoadAddCart(true);
        await checkSponsorisedBadge(item.badgeSerial).then(async (result) => {
            if (result === true) {
                if (isValidReferral) {
                    dispatch(addToCart(item));
                }
            } else if (!result) {
                if (isValidReferral) {
                    dispatch(addToCart(item));
                }
            }

            setLoadAddCart(false);
            navigate(ROUTE_PRIVATE_CART);
        })
    };

    const handlerOnClick = (badge) => {
        if(isReferralCodeModalOpen && referralCode) {
            const isValid = validateReferralCode(referralCode);

            if (isValid) {
                const discountPercentage = badge.discount;
                const originalPrice = badge.amount;
                const discountAmount = (discountPercentage / 100) * originalPrice;
                badge.amount = originalPrice - discountAmount;
            } else {
                setIsValidReferral(true);
            }
        }

        handleAddToCart(badge);
    }

    return (
        <Modal className="modal-badge" funk={true} isOpen={isOpen} toggle={toggle} size="xl">
            <ModalBody>
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle}/>
                </div>
                <div className="modal-badge-button text-center">
                    <BadgeImage
                        badge={badge}
                        loading={loading}
                        onEvent={null}
                        className={"big"}
                        conserveContainer={true}
                    />
                    <div className="pt-3">
                        <p>Achetez le badge "{null !== badge ? badge.name : ""}" et profitez du contenu en direct</p>
                    </div>
                    {
                        isReferralCodeModalOpen && (
                            <div className="content-sponsorship pt-2">
                                <h6>Renseignez un code de référence si vous en détenez un.</h6>
                                <InputComponent
                                    type="text"
                                    defaultValue={referralCode}
                                    name="firstName"
                                    placeholder="Entrez le code de référence"
                                    className={"container-phone-page-register not-phone"}
                                    onChange={(value) => setReferralCode(value)}
                                    disabled={false}
                                    required={false}
                                />
                                {!isValidReferral && <p style={{ color: 'red' }}>Le code de référence est invalide.</p>}
                            </div>
                        )
                    }
                    <div className="container-validate">
                        <Button block className="btn-round btn-korix-black" size="md" onClick={() => handlerOnClick(badge)}>
                            {
                                loadAddCart ?
                                    <div className="uil-reload-css-reload-xxs">
                                        <div></div>
                                    </div> :
                                    "Acheter un badge"
                            }
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalAskBadge;

ModalAskBadge.defaultProps = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    loading: PropTypes.bool,
    badge: PropTypes.object,
};

ModalAskBadge.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};