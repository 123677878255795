import React from 'react';
import { FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

function EditLevelForm({
    rewardLevel,
    isLevelModalOpen,
    toggleLevelModal,
    onEditLevelChange,
    onCancelEditLevel,
    onSaveEditLevel,
}) {
    const handleSaveClick = () => {
        onSaveEditLevel(rewardLevel);
    };

    return (
        <Modal isOpen={isLevelModalOpen} toggle={toggleLevelModal}>
            <ModalHeader toggle={toggleLevelModal}>Modifier Niveau de Récompense</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Nom du Niveau</Label>
                    <Input
                        type="text"
                        value={rewardLevel?.name || ''}
                        onChange={(e) => {
                            const updatedName = e.target.value;
                            onEditLevelChange({ ...rewardLevel, name: updatedName });
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Description du Niveau</Label>
                    <Input
                        type="text"
                        value={rewardLevel?.description || ''}
                        onChange={(e) => {
                            const updatedDescription = e.target.value;
                            onEditLevelChange({ ...rewardLevel, description: updatedDescription });
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Nombre de Références Requises</Label>
                    <Input
                        type="number"
                        value={rewardLevel?.referralCountRequired || 0}
                        onChange={(e) => {
                            const updatedReferralCountRequired = parseInt(e.target.value);
                            onEditLevelChange({
                                ...rewardLevel,
                                referralCountRequired: updatedReferralCountRequired,
                            });
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Récompense</Label>
                    <Input
                        type="text"
                        value={rewardLevel?.reward || ''}
                        onChange={(e) => {
                            const updatedReward = e.target.value;
                            onEditLevelChange({ ...rewardLevel, reward: updatedReward });
                        }}
                    />
                </FormGroup>
                <Button onClick={onCancelEditLevel} color="secondary">
                    Annuler
                </Button>
                <Button onClick={handleSaveClick} color="primary">
                    Enregistrer
                </Button>
            </ModalBody>
        </Modal>
    );
}

export default EditLevelForm;