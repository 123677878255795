import {Button} from "reactstrap";
import React, {useEffect, useState} from "react";
import app from "../../services/Firebase";
import PropTypes from "prop-types";
import {collection, doc, getFirestore, onSnapshot} from "firebase/firestore";
const db = getFirestore(app);
const usersCollection = collection(db, 'users');

function IframeWave ({userPhoneNumber, eventCancel}) {
    const [isLoaded, setIsLoaded] = useState(true);
    const [paymentURL, setPaymentURL] = useState();

    useEffect(() => {
        fetchWaveUrl();
    }, [])

    const fetchWaveUrl = () => {
        const userDoc = doc(usersCollection, userPhoneNumber);
        const userPayment = collection(userDoc, "payments");
        const unsubscribe = onSnapshot(userPayment, snapshot => {
            snapshot.docChanges().forEach(async change => {
                if (change.type === 'added' || change.type === 'modified') {
                    try {
                        const url = await change.doc.data().data.payments[0]["nextAction"]["data"].url
                        console.log(url);

                        setPaymentURL(url);
                        unsubscribe();
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }
            });
        });
    };

    return (
        <>
            <p>Paiement Mobile</p>
            {
                isLoaded ? (
                    <div className="uil-reload-css-reload-small">
                        <div></div>
                    </div>
                ) : null
            }

            {
                paymentURL && (
                    <iframe
                        src={paymentURL}
                        title={"KORIX"}
                        style={{ width: '100%', height: '560px', border: 'none', overflow: 'hidden', margin: 0, padding: 0 }}
                        onLoad={() => {
                            setIsLoaded(false);
                        }}
                    />
                )
            }
            <div className="container-validate">
                <Button block className="btn-round return" size="md" onClick={eventCancel}>
                    Annuler
                </Button>
            </div>
        </>
    )
}

export default IframeWave;

IframeWave.defaultProps = {
    userPhoneNumber: PropTypes.string,
    eventCancel: PropTypes.func,
};

IframeWave.propTypes = {
    userPhoneNumber: PropTypes.string.isRequired,
    eventCancel: PropTypes.func.isRequired,
}
