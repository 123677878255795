import {Button, Container} from "reactstrap";
import FooterLight from "../../components/FooterLight";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {ROUTE_LOGIN, ROUTE_PRIVATE_HOME} from "../../services/routes/Routes";

function Error500() {

    const userReducers = useSelector((state) => state.userReducers);
    const { isAuthenticated } = userReducers;
    const navigate = useNavigate();

    const handlerRedirect = () => {

        if(!isAuthenticated) {
            navigate(ROUTE_LOGIN);
            return;
        }

        navigate(ROUTE_PRIVATE_HOME);
    }

    return (
        <div className="page-error">
            <div className="page-header-page-error">
                <div className="block-page-error">
                    <Container>
                        <div className="logo-container text-center">
                            <img src={require("../../assets/img/Brand/500.png")} alt={"img-500"}/>
                        </div>
                        <div className="text-center pb-5 pt-3">
                            <p>
                                Oups, quelque chose ne va pas du côté de nos serveurs. Nous nous excusons pour le désagrément. Revenez plus tard ou retournez à l'accueil.
                            </p>
                        </div>
                        <Button block className="btn-round btn-korix-black" size="lg" onClick={handlerRedirect} color="primary">
                            Retour
                        </Button>
                    </Container>
                </div>
            </div>
            <FooterLight />
        </div>
    )
}

export default Error500;