import React, {useState, useEffect} from "react";
import {Container} from "reactstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faLock} from "@fortawesome/free-solid-svg-icons";
import OtpInputComponent from "../../components/otp/OtpInputComponent";
import {CODE_PIN_LENGTH} from "../../services/Constant";
import {ROUTE_500, ROUTE_OTP_CONFIRM_CODE_PIN} from "../../services/routes/Routes";

function OtpCodePin() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("Veuillez renseigner un code valide");
    const [visible, setVisible] = React.useState();
    document.documentElement.classList.remove("nav-open");

    useEffect(() => {
        if(!state) {
            navigate(ROUTE_500)
        }

    }, [state])

    const handleOnClick = async (otp) => {
        setError(false);

        if("" === otp || otp.length !== CODE_PIN_LENGTH) {
            setError(true);
            setVisible(true);
            setLoading(false);
            setMessage("Veuillez renseigner un code Pin valide");
            return;
        }

        setLoading(true);
        navigate(ROUTE_OTP_CONFIRM_CODE_PIN, { state: { result: state, otp: otp}});
    }

    return (
        <div className="page-otp">
            <Container>
                <div className="icon-container-page-otp">
                    <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="icon"/>
                </div>
                <div className="envelope-container-page-otp ml-auto mr-auto">
                    <FontAwesomeIcon icon={faLock} className="icon"/>
                </div>
                <div className="container-form-page-otp text-center">
                    <div className="container-text-page-otp">
                        <h3>Créer un code PIN </h3> <br/>
                        <h5>Veuillez créer un code PIN afin de sécuriser votre compte</h5>
                    </div>
                    <OtpInputComponent
                        autoFocus
                        length={CODE_PIN_LENGTH}
                        error={error}
                        loading={loading}
                        visible={visible}
                        message={message}
                        setVisible={setVisible}
                        onValidate={handleOnClick}
                        className="otp-container"
                        inputClassName="otp-input"
                        redirectForgot={null}
                    />
                </div>

            </Container>
        </div>
    );
}

export default OtpCodePin;
