import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import app from "../../services/Firebase";
import PropTypes from "prop-types";
import {
    PAYMENT_STATUS_FAILED_WEB, PAYMENT_STATUS_SUCCESS_WEB,
} from "../../services/Constant";
import axios from "axios";
import { collection, deleteDoc, doc, getDoc, getFirestore, } from "firebase/firestore";
import { CountdownTimer } from "./CountdownTimer";
import {logger} from "../../services/Logger";
const functions = getFunctions(app);
const db = getFirestore(app);

function IframeOrangeMoneyWeb({ amount, eventCancel, onStatusChange, setPaymentOrderId, setPaymentTxtId }) {
    const [isLoaded, setIsLoaded] = useState(true);
    const [paymentURL, setPaymentURL] = useState();
    const [eventListenerInitPayment, setEventListenerInitPayment] = useState(false);
    const [paymentCheckout, setPaymentCheckout] = useState({ token: '', orderid: '', amount: '', paytoken: '', notiftoken: '' });
    const getAccessToken = httpsCallable(functions, 'getAccessToken');
    const [pasyState, setPayState] = useState("");
    const [statusP, setStatusP] = useState("");
    const initialized = useRef(false)
    let intervalId;

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            fetchPaymentURL();
        }
    }, [])

    const milliseconds = 300 * 1000;
    const circumference = 20 * Math.PI;

    const [timer, setTimer] = useState(milliseconds);

    const strokeDashoffset = () =>
        circumference - (timer / milliseconds) * circumference;


    useEffect(() => {

        const interval = setInterval(() => {
            if (timer <= 0) {
                clearInterval(interval);
                setEventListenerInitPayment(false);
                onStatusChange(PAYMENT_STATUS_FAILED_WEB);
                const newPasyState = Math.floor(Math.random() * 100000000).toString();
                setPayState(newPasyState);
            } else {
                const newPasyState = Math.floor(Math.random() * 100000000).toString();
                setPayState(newPasyState);
                setTimer((prevCountdown) => prevCountdown - 10);
            }
        }, 10);

        return () => {
            clearInterval(interval);
        };
    }, [eventListenerInitPayment, timer]);



    useEffect(() => {
        checkStatus()
    }, [pasyState, timer]);


    let timerId;
    useEffect(() => {
        timerId = setTimeout(() => {
            checkStatus();
        }, 1000);

        return () => {
            clearTimeout(timerId);
        };
    }, [pasyState, timer]);

    useEffect(() => {
        const intervalId = setInterval(() => {

            getPaymentStatus();

        }, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, [statusP, eventListenerInitPayment]);

    const deletewebhookNotif = async (notifToken) => {
        const omStatusCollection = collection(db, "orangePayments");
        const userPayment = doc(omStatusCollection, notifToken);
        await deleteDoc(userPayment);
    }

    const checkStatus = async () => {
        if (paymentCheckout.notiftoken !== "") {
            const omStatusCollection = collection(db, "orangePayments");
            const omstatusDocRef = doc(omStatusCollection, paymentCheckout.notiftoken);

            const docSnapshot = await getDoc(omstatusDocRef);
            if (docSnapshot.exists()) {
                const paymentData = docSnapshot.data();
                const paymentStatus = paymentData.status;

                onStatusChange(paymentStatus);
                setPaymentTxtId(paymentData.txnid);
                deletewebhookNotif(paymentCheckout.notiftoken)
                clearTimeout(timerId);
                setTimer(0);
                setEventListenerInitPayment(false);

            }
        }
    }

    const fetchPaymentURL = async () => {
        const result = await getAccessToken();
        const tokenValue = result.data.token;

        try {
            var paymentData = {
                token: tokenValue,
                calculateTotalPrice: amount,
                orderid: Math.floor(Math.random() * 100000000).toString(),
                returnurl: 'https://korix.app',
                cancelurl: 'https://korix.app',
                notifurl: 'https://us-central1-korixprod.cloudfunctions.net/OmWebpaymentListener/webhookEndpoint',
                lang: 'fr',
                reference: 'KORIX',
            }

            const response = await fetch('https://us-central1-korixprod.cloudfunctions.net/getPaymentURL', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer" + tokenValue,
                    "Accept": "application/json",
                },
                body: JSON.stringify(paymentData),
            });

            if (!response.ok) {
                console.error(`//////////////////////// [fetchPaymentURL] - INIT PAYMENT KO  //////////////////////////`);
                console.error(`//////////////////////// [fetchPaymentURL] - La requête a échoué avec un statut ${response.status} //////////////////////////`);
                throw new Error('La requête a échoué avec un statut ' + response.status);
            }

            const data = await response.json();
            const paymentURL = data.data.paymentURL;
            const getPaymentToken = data.data.paymentToken;
            setEventListenerInitPayment(true);
            strokeDashoffset()
            setPaymentURL(paymentURL);
            setPaymentOrderId(paymentData.orderid);
            setPaymentCheckout({ ...paymentCheckout, token: tokenValue, amount: paymentData.calculateTotalPrice, orderid: paymentData.orderid, paytoken: getPaymentToken, notiftoken: data.data.paymentData.notif_token });
        } catch (error) {
            console.error(`//////////////////////// [fetchPaymentURL] - END PAYMENT KO  //////////////////////////`);
            console.error(`//////////////////////// [fetchPaymentURL] - La requête a échoué avec un statut ${error.toString()} //////////////////////////`);
            console.error(error);
        }
    };

    async function getPaymentStatus() {
        if (paymentCheckout.token !== "" && paymentCheckout.orderid !== "") {

            var data = JSON.stringify({
                token: paymentCheckout.token,
                orderId: paymentCheckout.orderid,
                amount: paymentCheckout.amount,
                paymentToken: paymentCheckout.paytoken
            });

            var transactConfig = {
                method: 'post',
                url: ('https://us-central1-korixprod.cloudfunctions.net/getPaymentStatus'),
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer' + paymentCheckout.token,
                    "Accept": "application/json",
                },
                data: data
            };

            try {
                var transactionStatus = await axios(transactConfig);
                const response = transactionStatus.data.data["status"];

                setStatusP(response.status);

                if (response.status === PAYMENT_STATUS_SUCCESS_WEB || response.status === PAYMENT_STATUS_FAILED_WEB) {
                    deletewebhookNotif(paymentCheckout.notiftoken)
                    onStatusChange(response.status);
                    setPaymentTxtId(response.txnid);
                    clearInterval(intervalId);
                }
            } catch (error) {
                console.error(`//////////////////////// [fetchPaymentURL] - END PAYMENT KO  //////////////////////////`);
                console.error(`//////////////////////// [fetchPaymentURL] - La requête a échoué avec un statut ${error.toString()} //////////////////////////`);
                console.error("Error fetching payment status:", error);
            }
        }
    }



    return (
        <div style={{ position: 'relative' }}>
            {
                isLoaded ? (
                    <div className="uil-reload-css-reload-small">
                        <div></div>
                    </div>
                ) : null
            }

            {!isLoaded &&
                <div
                    style={{
                        position: 'fixed',
                        top: -5,
                        left: 0,
                        right: 0,
                        background: 'rgba(0, 0, 0, 0.7)',
                        color: 'white',
                        padding: '10px 0',
                        borderRadius: '10px',
                        textAlign: 'center',
                        marginLeft: '10px',
                        marginRight: '10px',
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Button onClick={eventCancel}
                            style={{
                                position: 'absolute',
                                color: "#fab600",
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                fontSize: '8px',
                                bottom: '10px',
                                right: '-1px',
                            }}
                    >
                        Annuler
                    </Button>

                    <div style={{ marginTop: '8px', textAlign: 'left' }}>
                        <ol>
                            <li style={{ marginBottom: '4px', fontSize: '10px' }}>Entrez votre numéro de téléphone</li>
                            <li style={{ marginBottom: '4px', color: "#fab600", fontSize: '10px' }}>Cliquez sur le bouton "#144#77#" pour obtenir le code</li>
                            <li style={{ marginBottom: '4px', fontSize: '10px' }}>Copiez le code reçu et revenir sur l'application </li>
                            <li style={{ marginBottom: '4px', fontSize: '10px' }}>Inscrivez le code à 6 chiffres</li>
                            <li style={{ marginBottom: '4px', fontSize: '10px' }}>Cliquez sur le bouton confirmer</li>
                            <p style={{ marginLeft: '-10px', color: "#fab600", fontWeight: "bold", fontSize: '12px' }}>Patientez quelques secondes pour la création de votre badge</p>
                        </ol>
                    </div>
                    <div style={{ marginTop: '8px' }}>
                        <CountdownTimer
                            seconds={300}
                            size={40}
                            strokeBgColor="black"
                            strokeColor="lightblue"
                            strokeWidth={3}
                            onTimeUp={""}
                        />
                    </div>

                </div>
            }

            <iframe
                src={paymentURL}
                title={"KORIX"}
                style={{ width: '100%', height: '420px', border: 'none', overflow: 'hidden', margin: 0, padding: 0, zIndex: 2, }}
                onLoad={() => {
                    setIsLoaded(false);
                }}
            />
            {!isLoaded &&
                <Button color="primary" size="lg" style={{ borderRadius: '20px', color: 'black', fontSize: '16px' }} href={`tel:${encodeURIComponent("#144#77#")}`}>
                    <FontAwesomeIcon icon={faPhone} style={{ marginRight: '5px' }} />
                    #144#77#
                </Button>
            }
        </div>
    )
}

export default IframeOrangeMoneyWeb;

IframeOrangeMoneyWeb.defaultProps = {
    amount: PropTypes.string,
    eventCancel: PropTypes.func,
    onStatusChange: PropTypes.func,
    setPaymentOrderId: PropTypes.func,
    setPaymentTxtId: PropTypes.func
};

IframeOrangeMoneyWeb.propTypes = {
    amount: PropTypes.string.isRequired,
    eventCancel: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    setPaymentOrderId: PropTypes.func.isRequired,
    setPaymentTxtId: PropTypes.func.isRequired,
}
