import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, addDoc, orderBy,query} from "firebase/firestore";
import { selectNetwork } from '../../../../services/stores/nft/NftSlice';
import { useSelector } from 'react-redux';
import app from '../../../../services/Firebase';
import NavBar from "../../../../components/NavBar";
import {Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row} from "reactstrap";

const db = getFirestore(app);
const collectionCELO = collection(db, 'address-CELO');

function Address() {

    const [from, setFrom] = useState(0);
    const [to, setTo] = useState("");
    const apikey = useSelector(selectNetwork).API_KEY;
    const owner = useSelector(selectNetwork).OWNER;
    const [errorField, setErrorField] = useState(false);
    const [ isOpenDialog, setIsOpenDialog ] = useState(false)

    useEffect(()=> {
        getLastIndex();
    })

    const getLastIndex = async () => {
        const addressByIndexID = query(collectionCELO, orderBy('indexID', 'desc'))
        const docSnap = await getDocs(addressByIndexID);
        if (0 < docSnap.docs.length) {
            const lastDoc = (docSnap.docs[0].data());
            const lastIndexID = lastDoc.indexID;
            setFrom(lastIndexID);
        }
    }

     // CUSTODIAL ADDRESS GENERATE USING TATUM API
    async function tatumAddress() {
        setErrorField(false);
        if (from !== undefined && to !== "") {
            try {
                const resp = await fetch(
                    `https://api.tatum.io/v3/gas-pump`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': apikey
                        },
                        body: JSON.stringify({
                            chain: 'CELO',
                            owner: owner,
                            from: parseInt(from) + 1,
                            to: parseInt(to)
                        })
                    }
                );

                const addressResponse = await resp.json();
                await addressResponse.forEach((publicKey, i) => {
                    const nextIndexID = parseInt(from) + 1;
                    i += nextIndexID;

                    addDoc(collectionCELO, { publicKey, publicID: "unasigned", isUsed: false, indexID: i, chainID: 'CELO' }).then((setIsOpenDialog(true)));
                });

            } catch (e) {
                setErrorField(true);
            }
        } else {
            setErrorField(true);
        }
    }

    const handleDialogOK = () => {
        setIsOpenDialog(false);
        window.location.reload();
      };

    return(
        <>
            <NavBar />
            <Modal isOpen={isOpenDialog} centered>
                <ModalBody>
                    <p>Les Adresses ont été ajoutées avec succès</p>
                </ModalBody>
                <div className="d-flex justify-content-end p-3">
                    <Button onClick={handleDialogOK}>OK</Button>
                </div>
            </Modal>
            <div className="page-header-white">
                <Container className="ml-auto mr-auto">
                    <Row>
                        <Col className="col-sm-12">
                            <div className="home-badge">
                                <h3 className="home-badge-title">Adresses</h3>
                            </div>
                        </Col>
                    </Row>
                    <div className="trend">
                        <h5>Nombre total d'adresse : {from}</h5>
                    </div>
                    <div className="pt-5">
                        <FormGroup className={errorField ? "has-danger" : ""}>
                            <Label for="from" className="control-label">From</Label>
                            <Input type="text" name="from" id="from" value={from+1} placeholder="From" onChange={(event) => setFrom(event.target.value)}/>
                        </FormGroup>
                        <FormGroup className={errorField ? "has-danger" : ""}>
                            <Label for="to" className="control-label">To</Label>
                            <Input type="text" name="to" id="to" defaultValue={to} placeholder="To" onChange={(event) => setTo(event.target.value)}/>
                        </FormGroup>
                        <Button block className="btn-round" color="primary" onClick={()=> tatumAddress()}>Valider</Button>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default  Address;