
export class User {

    constructor(firstName, lastName, phoneNumber, role, status, codePin, badge, address) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.role = role;
        this.codePin = codePin;
        this.status = status;
        this.badge = badge;
        this.address = address;
        this.createdAt = new Date();
    }

    getFirstName(): string {
        return this.firstName;
    }

    getLastName(): string {
        return this.lastName;
    }

    getEmail(): string {
        return this.email;
    }

    getRole(): string {
        return this.role;
    }

    getPhoneNumber(): string {
        return this.phoneNumber;
    }

    getAddress(): string {
        return this.address;
    }

    getBadge(): string {
        return this.badge;
    }

    getStatus(): string {
        return this.status;
    }

    getCreatedAt(): string {
        return this.createdAt;
    }
}

