import React from 'react';
import { FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

function AddLevelForm({
    isAddLevelModalOpen,
    toggleAddLevelModal,
    newLevelName,
    setNewLevelName,
    newLevelDescription,
    setNewLevelDescription,
    newLevelReferralCount,
    setNewLevelReferralCount,
    newLevelReward,
    setNewLevelReward,
    onAddLevelSubmit,
}) {
    return (
        <Modal isOpen={isAddLevelModalOpen} toggle={toggleAddLevelModal}>
            <ModalHeader toggle={toggleAddLevelModal}>Ajouter un autre niveau</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Nom du Niveau</Label>
                    <Input
                        type="text"
                        value={newLevelName}
                        onChange={(e) => setNewLevelName(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Description du Niveau</Label>
                    <Input
                        type="text"
                        value={newLevelDescription}
                        onChange={(e) => setNewLevelDescription(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Nombre de Références Requises</Label>
                    <Input
                        type="number"
                        value={newLevelReferralCount}
                        onChange={(e) => setNewLevelReferralCount(parseInt(e.target.value))}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Récompense</Label>
                    <Input
                        type="text"
                        value={newLevelReward}
                        onChange={(e) => setNewLevelReward(e.target.value)}
                    />
                </FormGroup>
                <Button color="secondary" onClick={toggleAddLevelModal}>
                    Annuler
                </Button>
                <Button type="submit" color="primary" onClick={onAddLevelSubmit}>
                    Enregistrer
                </Button>
            </ModalBody>
        </Modal>
    );
}

export default AddLevelForm;