import React, { useState, useRef, useEffect } from 'react';
import {addDoc, collection, getDocs, getFirestore, doc, setDoc, query, where} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor, Plugins } from '@capacitor/core';
import app from '../services/Firebase';
import ModalReelAudience from './ReelAudienceSelect';
import { useSelector } from 'react-redux';
import { faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from './Footer';
import {formatDateDMYFrench} from "../services/Constant";

const { Camera: CapacitorCamera } = Plugins;

const db = getFirestore(app);
const storage = getStorage(app)
const reelsVideoCollection = collection(db, 'reels_videos');
const badgesCollection = collection(db, 'badge-collection');

function AddRealVideoForm({ onAddVideo }) {
  const [description, setDescription] = useState('');
  const [videoUrl, setVideoUrl] = useState(null);
  const videoRef = useRef(null);
  const [openAudienceModal, setOpenAudienceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [badges, setBadges] = useState([]);
  const [badge, setBadge] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const userReducers = useSelector((state) => state.userReducers);
  const { user } = userReducers;
  const firstName = user.firstName;
  const lastName = user.lastName;
  const userPhone = user.phoneNumber;

    useEffect(() => {
        fetchBadges()
    }, []);

    useEffect(() => {
      if(videoUrl && badge.length === 0) {
        setOpenAudienceModal(true)
      }
    }, [videoUrl, badge]);


    const fetchBadges = async () => {
        const currentDate = formatDateDMYFrench(new Date);
        const queryBadges = await query(badgesCollection, where("dateEnd", ">", currentDate));
        const badgesCollectionDocs = await getDocs(queryBadges);

        if (badgesCollectionDocs.docs.length > 0) {
            var collectionBadges = badgesCollectionDocs.docs
                .filter((badge) => badge.data().badgeOwner === userPhone)
                .map((badge) => {
                    return {
                        key: badge.id,
                        name: badge.data().name,
                        collectionName: badge.data().collectionName,
                        amount: badge.data().amount,
                        isListed: badge.data().listed,
                        contractAddress: badge.data().address,
                        imageUrl: badge.data().imageUrl,
                        dateStart: badge.data().dateStart,
                        dateEnd: badge.data().dateEnd,
                        advantage: badge.data().advantages,
                        category: badge.data().category,
                        details: badge.data().details,
                        collectionImg: badge.data().collectionImg,
                        badgeSerial: badge.data().badgeSerial,
                        onLine: badge.data().onLine,
                        liveImageUrl: badge.data().liveImageUrl,
                        badgeOwner: badge.data().badgeOwner,
                        Mint: "N/A",
                    };
                });

            setBadges(collectionBadges)
        }
        setLoading(false);
    }

    const handleImageSelect = (index) => {
        if (selectedImages.includes(index)) {
            setSelectedImages(selectedImages.filter((item) => item !== index));
        } else {
            setSelectedImages([...selectedImages, index]);
        }
    };

    const saveSelectedBadgesToFirestore = async () => {
        try {

            const badgesCollection = collection(db, "badge-collection");
            const querySnapshot = await getDocs(badgesCollection);
            const creatorBadges = [];

            querySnapshot.forEach((doc) => {
                const badgeData = doc.data();
                if (badgeData.isCreator && badgeData.badgeOwner === userPhone) {
                    creatorBadges.push(badgeData);
                }
            });

            const selectedBadgeSerials = selectedImages.map((index) => badges[index].badgeSerial);

            for (const creatorBadge of creatorBadges) {
                if (selectedBadgeSerials.includes(creatorBadge.badgeSerial)) {
                    setBadge({badgeID: creatorBadge.badgeSerial, badgeImage: creatorBadge.imageUrl, name: creatorBadge.name,})           
                }
            }

            setOpenAudienceModal(!openAudienceModal)

        } catch (error) {
            console.error("Erreur lors de l'enregistrement des badges sélectionnés : ", error);
        }
    };

    const handleSaveSelectedBadges = () => {
        if (selectedImages.length > 0) {
            saveSelectedBadgesToFirestore();
        } else {
            console.warn("Aucun badge sélectionné.");
        }
    };

  let recordingTimer;



  const handleSelectFromGallery = async () => {
    try {
      if (Capacitor.isNativePlatform) {
        const galleryResult = await CapacitorCamera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Uri,
          source: CameraSource.Photos,
        });
  
        // Convertir l'URI local en Blob
        const response = await fetch(galleryResult.webPath);
        const blob = await response.blob();
  
        // Mettre à jour l'URL de la vidéo
        setVideoUrl(URL.createObjectURL(blob));
      } else {
        // Si nous sommes sur le Web, utilisez un champ de type fichier pour la sélection de fichiers
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'video/*';
  
        input.addEventListener('change', async (event) => {
          const file = (event.target.files || [])[0];
  
          if (file) {
            // Convertir le fichier en Blob
            const blob = new Blob([file], { type: 'video/mp4' });
  
            // Mettre à jour l'URL de la vidéo
            setVideoUrl(URL.createObjectURL(blob));
          }
        });
  
        input.click();
      }
    } catch (error) {
      console.error('Erreur lors de la sélection depuis la galerie:', error);
    }
  };


  const handlePublish = async () => {
    if(badge.length === 0) {
      setOpenAudienceModal(true)
    }
    try {
      if (!videoUrl || description === "" || badge.length === 0) {
        console.error('Veuillez sélectionner ou enregistrer une vidéo avant de publier.');
        return;
      }
      setLoading(true);
      const response = await fetch(videoUrl);
      const videoBlob = await response.blob();

      const storageRef = ref(storage, 'reels_videos/' + new Date().toISOString() + '.mp4');
       await uploadBytes(storageRef, videoBlob);

       const downloadUrl = await getDownloadURL(storageRef);

      const newVideo = {
        firstName: firstName,
        lastName: lastName,
        reelDescription: description,
        reelUrl: downloadUrl,
        reelViews: 0,
        reelLikes: 0,
        createdAt: new Date(),
        badges: badge,
      };

      await addDoc(reelsVideoCollection, newVideo);

      setLoading(false);
      setDescription('');
      setVideoUrl(null);
    } catch (error) {
      console.error('Erreur lors de la publication de la vidéo:', error);
    }
  };

  useEffect(() => {
    return () => {
      // Nettoyez l'enregistrement simulé s'il est en cours
      clearInterval(recordingTimer);
    };
  }, []);

  return (
    <div>
      {loading ? (
                <div className='uil-reload-class'>
                <div className="uil-reload-css-reload-small">
                    <div></div>
                </div>
                </div>
            ) : null
      }
      {videoUrl ? (
        <div>
          <video ref={videoRef} controls width="100%">
            <source src={videoUrl} type="video/mp4" />
            Votre navigateur ne prend pas en charge la lecture de vidéos.
          </video>
        </div>
      ) : (
        <>
        <div className='container-add-reel-form '>
          <button className="btn-round btn-korix-black" onClick={handleSelectFromGallery}>Sélectionner depuis la galerie</button>
        </div>
        <div className='container-camera-icon'>
          <FontAwesomeIcon onClick={handleSelectFromGallery} icon={faVideoCamera} className="camera-icon" />
        </div>
        </>
      )}

      {videoUrl && (
        <div className="container-reel-detail-form">
          <textarea
            placeholder="Ajouter une description..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className='textarea'
          />
          <button className='send-button ' onClick={handlePublish}>Publier</button>
        </div>
      )}

    <ModalReelAudience 
       isOpen={openAudienceModal} 
       toggle={() => setOpenAudienceModal(!openAudienceModal)} 
       badges={badges}
       handleImageSelect={handleImageSelect}
       handleSaveSelectedBadges={handleSaveSelectedBadges}
       selectedImages={selectedImages}
    />
    <Footer />
    </div>
  );
}

export default AddRealVideoForm;
