import NavBar from "../../../../components/NavBar";
import React, {useEffect, useState} from "react";
import {getFirestore, collection, getDocs, updateDoc, doc, query, where} from "firebase/firestore";
import app from "../../../../services/Firebase";
import {
    Button, Container,
    Table, Row, Col, UncontrolledTooltip, Label, Input, FormGroup,
} from "reactstrap";
import ModalNewUser from "./ModalNewUser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faTrashCan} from '@fortawesome/free-solid-svg-icons'
import {ROLES_ADMIN, ROLES_AGENT, ROLES_OWNER} from "../../../../services/Constant";
import ModalEditUser from "./ModalEditUser";
const db = getFirestore(app);
const userCollection = collection(db, 'users');


function Users() {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setNewUser] = useState(false);
    const [userSelected, setUserSelected] = useState({});
    const [role, setRole] = useState('');
    const roles = [
        ROLES_AGENT,
        ROLES_ADMIN,
        ROLES_OWNER,
    ]

    useEffect(() => {
        fetchAgent(role);
    }, [user, role]);

    const  fetchAgent = async (role) => {
        setLoading(true);
        const collection = '' === role ?
            userCollection :
            await query(userCollection, where("role", "==", role))

        const userDocs = await getDocs(collection);
        if(userDocs.docs.length > 0) {
            var collectionsAgent = [];
            userDocs.docs.map((user, index) => {
                collectionsAgent[index] = {
                    address: user.data().address,
                    firstName: user.data().firstName,
                    lastName: user.data().lastName,
                    phone: user.data().phoneNumber,
                    badge: user.data().badge,
                    role: user.data().role,
                    status: user.data().status,
                };

                return collectionsAgent;
            });

            setUsers(collectionsAgent);
        }
        setLoading(false);
    }

    const handlerChangeUSerStatus = async (userKey, index) => {
        var status = !users[index].status;
        users[index].status = status;
        setUsers(prevState => ([...prevState]));
        const getUser = doc(db, 'users', userKey);
        await updateDoc(getUser, {
            status: status
        });
    }

    const handlerEditUserInformation = async (user, index) => {
        setUserSelected(user);
        setIsOpenEdit(true);
    }

    return (
        <>
            <NavBar />
            <div className="page-header-white">
                <Container className="ml-auto mr-auto">
                    <Row>
                        <Col className="col-lg-3">
                            <div className="pb-5">
                                <Button block className="btn-round" color="default" onClick={() => setIsOpen(true)}>Nouvelle agent</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10} className="ml-auto mr-auto">
                            <FormGroup>
                                <Label for="badge" className="control-label">Filtre</Label>
                                <Input type="select" name="select" id="badge" onChange={(event) => setRole(event.target.value)}>
                                    <option value={""}>Afficher tout les roles</option>
                                    {
                                        roles.map((element, index) => <option key={index} value={element}>{element}</option>)
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-lg-12">
                            {
                                loading ?
                                    <div className="uil-reload-css-reload-small color">
                                        <div></div>
                                    </div> :
                                    users.length === 0 ?
                                        <div className="text-center">
                                            <span>Aucun agent disponible</span>
                                        </div>
                                        :
                                        <Table className="align-items-center table-data" responsive>
                                            <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Nom</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Role</th>
                                                <th scope="col">Badge</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                users.map((user, index) => {
                                                    return <tr key={index}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-2">{user.firstName + " " + user.lastName}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-2">{user.phone}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-2">{user.role}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-2">{user.badge}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <Row className="">
                                                                <Col xs={6}>
                                                                    <span className="mr-2"><FontAwesomeIcon icon={faEdit} id="top" className="icon-fa" onClick={() => handlerEditUserInformation(user, index)}/></span>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <span className="mr-2"><FontAwesomeIcon icon={faTrashCan} id="top" className={user.status ? "icon-fa trash" : "icon-fa active"} onClick={() => handlerChangeUSerStatus(user.phone, index)}/></span>
                                                                    <UncontrolledTooltip placement="top" target="top" delay={0}>
                                                                        {
                                                                            user.status ? "Compte Activé" : "Compte Désactivé"
                                                                        }
                                                                    </UncontrolledTooltip>
                                                                </Col>

                                                            </Row>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </Table>

                            }
                        </Col>
                    </Row>
                </Container>
            </div>
            <ModalNewUser isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} setNewUser={setNewUser}/>
            {
                isOpenEdit &&
                <ModalEditUser isOpen={isOpenEdit} toggle={() => setIsOpenEdit(!isOpenEdit)} userSelected={userSelected} setNewUser={setNewUser}/>
            }
        </>
    )

}

export default Users;