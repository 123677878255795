import {Container} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useNavigate} from "react-router-dom";


function TermsAndCondition () {

    const navigate = useNavigate();

    return (
        <div className="page-about-us">
            <Container>
                <div className="container-header-page-about-us">
                    <div className="icon-container-page-about-us">
                        <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="icon"/>
                    </div>
                    <div className="logo-container-page-about-us text-center">
                        <img src={require("../assets/img/Brand/ACCESS_LOGO_WHITE_nobg.png")} alt={"logo-korix"} width={"75%"}/>
                    </div>
                </div>

                <div className="card-page-about-us ml-auto mr-auto text-center">
                    <h3>Conditions générales d'utilisation</h3>
                    <div className="card-body-page-about-us">
                        <h5 className="font-weight-bold">Définitions</h5>
                        <p>On désignera par la suite :</p> <br/>
                        <ul>
                            <li>« <strong>Site</strong> » ou « <strong>Service</strong> » : l’application web https ://acxes.app  et l'ensemble de ses pages</li>
                            <li>« <strong>Editeur</strong> » : La société KORIX personne morale responsable de l'édition et du contenu du Site.</li>
                            <li>« <strong>Utilisateur</strong> » : l'internaute visitant et utilisant les Services du Site.</li>
                            <li>«<strong>Identifiant</strong> , mot de passe et code PIN » : l’ensemble des informations nécessaires à l’identification d’un Utilisateur sur le Site. Le mot de passe et le code PIN sont confidentiels.</li>
                            <li></li>
                        </ul>
                        <p>Les présentes Conditions Générales d’Utilisation (ci-après les « CGU ») sont proposées par l'Editeur du Site. L'Utilisateur du Site est invité à lire attentivement ces CGU, à les imprimer et/ou à les sauvegarder sur un support durable. L'Utilisateur reconnaît avoir pris connaissance des CGU et les accepte intégralement et sans réserve.</p>
                    </div>
                    <div className="card-body-page-about-us">
                        <h6 className="font-weight-bold">Article 1 - Application des CGU</h6>
                        <div className="card-body-page-about-us">
                           <p> Les présentes CGU ont pour objet de définir les conditions d'accès au Site par les Utilisateurs. L'Éditeur se réserve le droit de modifier à tout moment les CGU en publiant une nouvelle version de ces dernières sur le Site. Les CGU applicables à l'Utilisateur sont celles en vigueur au jour de son acceptation.
                                <br/>
                            L'acquisition d'un produit ou d'un service, ou la création d'un espace membre, ou de manière plus générale la navigation sur le Site suppose l'acceptation, par l'Utilisateur, de l'intégralité des présentes CGU, qui reconnaît du même fait en avoir pris pleinement connaissance.
                               <br/>
                            Toute connexion au Site est subordonnée à l’acceptation et au respect des présentes CGU.
                               <br/>
                            Cette acceptation pourra consister par exemple, pour l'Utilisateur, à cocher la case correspondant à la phrase d'acceptation des présentes CGU, ayant par exemple la mention « je reconnais avoir lu et accepté l'ensemble des conditions générales d’utilisation ». Le fait de cocher cette case sera réputé avoir la même valeur qu'une signature manuscrite de la part de l'Utilisateur.
                               <br/>
                            L'Utilisateur reconnaît la valeur de preuve des systèmes d'enregistrement automatique de l'Editeur du présent Site et, sauf pour lui d'apporter une preuve contraire, il renonce à les contester en cas de litige.
                               <br/>
                            L'acceptation des présentes CGU suppose de la part des Utilisateurs qu'ils jouissent de la capacité juridique nécessaire pour cela. Si l'Utilisateur est mineur ou ne dispose pas de cette capacité juridique, il déclare avoir l'autorisation d'un tuteur, d'un curateur ou de son représentant légal.
                               <br/>
                            L'Éditeur met à la disposition du Client, sur son Site, une charte de confidentialité spécifiant l’ensemble des informations afférentes à l’utilisation des données à caractère personnel du Client collectées par l'Editeur et aux droits dont le Client dispose vis-à-vis de ces données personnelles. La politique de confidentialité des données fait partie des CGU. L'acceptation des présentes CGU implique par conséquent l'acceptation de la politique de confidentialité des données.
                           </p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Article 2 - Mentions légales, objet du Site, protection des données à caractère personnel</h6>
                        <div className="card-body-page-about-us">
                            <p>
                                Le Site est édité par la société KORIX. Les informations légales concernant l'hébergeur et l'Editeur du Site, notamment les coordonnées et les éventuelles informations de capital et d'immatriculation, sont fournies dans les mentions légales du Site.
                                <br/>
                                L'objet du Site est déterminé comme « Application web permettant l’achat de badges numériques par (NFT) et la consommation de contenu digital ».
                                <br/>
                                Les informations demandées à l’inscription du Site sont nécessaires et obligatoires pour la création du compte Utilisateur. En particulier le nom, le prénom, le numéro de téléphone et/ou l’adresse électronique, pourront être utilisés en dehors du site par le site et ses tiers mandataires, pour la commercialisation, l’administration, la gestion et l’animation des Services et activités connexes.
                                <br/>
                                Le Site assure à l’Utilisateur une collecte et un traitement d’informations personnelles dans le respect de la vie privée conformément à la loi n° 2013-015 du 21 mai 2013 portant protection des données à caractère personnel en République du Mali.
                                <br/>
                                En vertu de l’article 12 et suivant de la loi susvisée, l’Utilisateur dispose d’un droit d’accès, de rectification, de suppression et d’opposition à la collecte ou au traitement de ses données à caractère personnelles.
                                <br/>
                                L’Utilisateur exerce ce droit par mail à <a href="mailto:support@korix.africa">support@korix.africa</a>

                            </p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Article 3 - Espace membre</h6>
                        <div className="card-body-page-about-us">
                            <p>
                                L'Utilisateur inscrit au Site (membre) peut y accéder en se connectant grâce à ses identifiants (numéro de téléphone défini lors de son inscription et mot de passe) ou éventuellement en utilisant des systèmes tels que des boutons de connexion tiers de réseaux sociaux. L'utilisateur est entièrement responsable de la protection du mot de passe qu’il a choisi. Il est encouragé à utiliser des mots de passe complexes. En cas d’oubli du mot de passe, l'Utilisateur peut en générer un nouveau. Ce mot de passe constitue la garantie de la confidentialité des informations contenues dans sa rubrique « mon compte » et l'Utilisateur s'interdit donc de le transmettre ou de le communiquer à un tiers. À défaut, l'Editeur du Site ne pourra être tenu pour responsable des accès non autorisés au compte d'un Utilisateur.
                                <br/>
                                La création d’un espace personnel est un préalable indispensable à toute commande ou contribution de l'Utilisateur sur le présent Site. À cette fin, l'Utilisateur sera invité à fournir un certain nombre d’informations personnelles. Il s’engage à fournir des informations exactes.
                                <br/>
                                La collecte des données a pour objet la création d’un « compte membre ». Si les données contenues dans la rubrique compte membre venaient à disparaître à la suite d’une panne technique ou d’un cas de force majeure, la responsabilité du Site et de son Editeur ne pourrait être engagée, ces informations n’ayant aucune valeur probante mais uniquement un caractère informatif. Les pages relatives aux comptes membres sont librement imprimables par le titulaire du compte en question mais ne constituent nullement une preuve. Elles n’ont qu’un caractère informatif destiné à assurer une gestion efficace du service ou des contributions par l'Utilisateur.
                                <br/>
                                Chaque Utilisateur est libre de fermer son compte et ses données sur le Site. Pour ceci, il doit adresser un e-mail à la Société KORIX indiquant qu’il souhaite supprimer son compte. Aucune récupération de ses données ne sera alors possible.
                                <br/>
                                L'Éditeur se réserve le droit exclusif de supprimer le compte de tout Utilisateur qui aurait contrevenu aux présentes CGU (notamment, mais sans que cet exemple n’ait un quelconque caractère exhaustif, lorsque l'Utilisateur aura fourni sciemment des informations erronées, lors de son inscription et de la constitution de son espace personnel) ou encore tout compte inactif depuis au moins une année. Ladite suppression ne sera pas susceptible de constituer un dommage pour l'Utilisateur exclu qui ne pourra prétendre à aucune indemnité de ce fait. Cette exclusion n’est pas exclusive de la possibilité, pour l'Editeur, d’entreprendre des poursuites d’ordre judiciaire à l’encontre de l'Utilisateur, lorsque les faits l’auront justifié.

                            </p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Article 4 - Accès et disponibilité du Site</h6>
                        <div className="card-body-page-about-us">
                            <p>
                                L'Éditeur déploie ses meilleurs efforts pour rendre le Site accessible en permanence, sous réserve des opérations de maintenance du Site ou des serveurs sur lesquels il est hébergé. En cas d’impossibilité d’accès au Site, en raison de problèmes techniques ou de toutes natures, l'Utilisateur ne pourra se prévaloir d'un dommage et ne pourra prétendre à aucune indemnité.
                                <br/>
                                L'Éditeur du Site n’est tenu que par une obligation de moyens ; sa responsabilité ne pourra être engagée pour un dommage résultant de l'utilisation du réseau Internet tel que perte de données, intrusion, virus, rupture du service, ou autres.
                                <br/>
                                L’accès au Site et aux Services peut à tout moment faire l’objet d’une interruption, d’une suspension, ou d’une modification sans préavis pour maintenance ou pour tout autre cas. L’Utilisateur ne pourra réclamer aucune indemnisation suite à l’interruption, la suspension ou la modification du Site.
                                <br/>
                                L'Utilisateur admet expressément utiliser le Site à ses propres risques et sous sa responsabilité exclusive.
                                <br/>
                                Le Site fournit à l'Utilisateur des informations à titre indicatif, avec des imperfections, erreurs, omissions, inexactitudes et autres ambivalences susceptibles d'exister. En tout état de cause, La Société KORIX ne pourra en aucun cas être tenue responsable :
                            </p>
                            <ul>
                                <li>De tout dommage direct ou indirect, notamment en ce qui concerne les pertes de profits, le manque à gagner, les pertes de clientèle, de données pouvant entre autres résulter de l'utilisation du Site, ou au contraire de l'impossibilité de son utilisation ;</li>
                                <li>D’un dysfonctionnement, d'une indisponibilité d'accès, d'une mauvaise utilisation, d'une mauvaise configuration de l'ordinateur de l'Utilisateur, ou encore de l'emploi d'un navigateur peu usité par l'Utilisateur.</li>
                            </ul>

                            <p>L’Utilisateur peut contacter le Site par messagerie électronique à l’adresse : <a href="mailto:support@korix.africa">support@korix.africa</a></p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Article 5 - Liens hypertextes</h6>
                        <div className="card-body-page-about-us">
                            <p>
                                Le Site peut inclure des liens hypertextes vers d’autres sites.
                                <br/>
                                L'Utilisateur reconnaît par conséquent que l'Editeur ne pourra être tenu responsable de tous dommages ou pertes avérés ou allégués, consécutifs à ou en relation avec l'utilisation ou avec le fait d'avoir pris connaissance des contenus, publicités, produits ou services disponibles sur ces sites ou sources externes. De même, la responsabilité de l'Editeur du présent Site ne saurait être engagée si la visite, par l’Utilisateur, de l’un de ces sites, lui causait un préjudice.
                                <br/>
                                Si, en dépit des efforts de l'Editeur, un des liens hypertextes présents sur le Site pointait vers un site ou une source internet dont le contenu était ou paraissait non conforme aux exigences de la loi Malienne à un Utilisateur, celui-ci s’engage à prendre immédiatement contact avec le directeur de la publication du Site, dont les coordonnées figurent dans les mentions légales du Site, afin de lui communiquer l'adresse des pages du site tiers en cause.

                            </p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Article 6 - Cookies</h6>
                        <div className="card-body-page-about-us">
                            <p>
                                Un « Cookie » peut permettre l'identification de l'Utilisateur du Site, la personnalisation de sa consultation du Site et l'accélération de l’affichage du Site grâce à l'enregistrement d'un fichier de données sur son ordinateur. Le Site est susceptible d'utiliser des « Cookies » principalement pour 1) obtenir des statistiques de navigation afin d'améliorer l'expérience de l'Utilisateur, et 2) permettre l'accès à un compte de membre et à du contenu qui n'est pas accessible sans connexion.
                                <br/>
                                L'Utilisateur reconnaît être informé de cette pratique et autorise l'Editeur du Site à y recourir. L'Editeur s'engage à ne jamais communiquer le contenu de ces « Cookies » à des tierces personnes, sauf en cas de réquisition légale.
                                <br/>
                                L'Utilisateur peut refuser l'enregistrement de « Cookies » ou configurer son navigateur pour être prévenu préalablement à l'acception des « Cookies ». Pour ce faire, l'Utilisateur procédera au paramétrage de son navigateur :
                                <br/>
                                L’Utilisateur peut contacter le Site par messagerie électronique à l’adresse : <a href="mailto:support@korix.africa">support@korix.africa</a>

                            </p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Article 7 - Droits de propriété intellectuelle</h6>
                        <div className="card-body-page-about-us">
                            <p>
                                Tous les éléments du présent Site appartiennent à l'Editeur ou à un tiers mandataire, ou sont utilisés par l'Editeur sur le Site avec l'autorisation de leur propriétaire.
                                <br/>
                                Toute représentation, reproduction ou adaptation des logos, contenus textuels, pictographiques ou vidéos, sans que cette énumération ne soit limitative, est rigoureusement interdite et s’apparente à de la contrefaçon.
                                <br/>
                                Tout Utilisateur qui se rendrait coupable de contrefaçon serait susceptible de voir son accès au site supprimé sans préavis ni indemnité et sans que cette exclusion ne puisse lui être constitutive d’un dommage, sans réserve d’éventuelles poursuites judiciaires ultérieures à son encontre, à l’initiative de l’Editeur du présent Site ou de son mandataire.
                                <br/>
                                Les marques et logos contenus dans le Site sont susceptibles d'être déposés par la Société KORIX, ou éventuellement par un de ses partenaires. Toute personne procédant à leurs représentations, reproductions, imbrications, diffusions et rediffusions encourt les sanctions prévues par la réglementation en vigueur.

                            </p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Article 8 - Responsabilité</h6>
                        <div className="card-body-page-about-us">
                            <p>
                                L'Éditeur n’est pas responsable des publications des Utilisateurs, de leur contenu ainsi que de leur véracité. L'Éditeur ne peut en aucun cas être tenu responsable de tout dommage susceptible d'intervenir sur le système informatique de l’Utilisateur et/ou de la perte de données résultant de l'utilisation du Site par l’Utilisateur.
                                <br/>
                                L'Éditeur s'engage à constamment mettre à jour le contenu du Site et à fournir aux Utilisateurs des informations justes, claires, précises et réactualisées. Le Site est en principe accessible en permanence, sauf pendant les opérations techniques de maintenance et de mise à jour du contenu. L'Éditeur ne saurait être tenu responsable de dommages résultant de l’indisponibilité du Site ou de parties de celui-ci.
                                <br/>
                                La responsabilité de l'Editeur du Site ne peut être engagée en raison d'une indisponibilité technique de la connexion, qu'elle soit due notamment à un cas de force majeure, à une maintenance, à une mise à jour, à une modification du Site, à une intervention de l'hébergeur, à une grève interne ou externe, à une panne de réseau, ou encore à une coupure d'alimentation électrique.
                                <br/>
                                L'Éditeur ne saurait être tenu responsable du non-fonctionnement, d'une impossibilité d'accès ou de dysfonctionnements du Site imputables à un équipement non adapté, à une mauvaise configuration ou utilisation de l'ordinateur de l'Utilisateur, à des dysfonctionnements des services du fournisseur d'accès des Utilisateurs, ou à ceux du réseau internet.

                            </p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Article 9 - Contribution des Utilisateurs au contenu du Site</h6>
                        <div className="card-body-page-about-us">
                            <p>
                                Les Utilisateurs se voient offrir la faculté de contribuer au contenu du Site. Les Utilisateurs sont informés que l'Editeur du Site, représenté le cas échéant par les modérateurs, peut choisir de publier le contenu en question sur les newsletters de ce Site et sur les sites de tous ses partenaires, à charge pour l'Editeur de citer le pseudonyme de l'auteur de la contribution.
                                <br/>
                                L’auteur renonce donc à ses droits sur le contenu des contributions, au profit de l'Editeur du Site, pour toute diffusion ou utilisation, même commerciale, sur le support internet, ceci, bien évidemment, toujours dans le respect de la paternité de l’auteur.

                            </p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Article 10 - Notifications et réclamations</h6>
                        <div className="card-body-page-about-us">
                            <p>
                                Toute notification ou avis concernant les présentes CGU, les mentions légales ou la charte de données personnelles doit être faite par écrit et envoyées par courrier recommandé ou certifié, ou par mail à l'adresse indiquée dans les mentions légales du Site, en précisant les coordonnées, nom et prénom du notifiant, ainsi que l'objet de l’avis.
                                <br/>
                                Toute réclamation liée à l'utilisation du Site, des Services, des pages du Site sur des réseaux sociaux éventuels ou aux CGU, aux mentions légales ou à la charte de données personnelles doit être déposée dans les 365 jours suivant le jour d’origine du problème source de réclamation, et ce indépendamment de toute loi ou règle de droit contraire. Dans le cas où une telle réclamation n’aurait pas été déposée dans les 365 jours suivants, une telle réclamation sera à jamais inapplicable en justice.
                                <br/>
                                Il peut être possible que se trouvent, dans l'ensemble du Site internet et des Services proposés, et dans une mesure restreinte, des inexactitudes ou des erreurs, ou des informations qui soient en désaccord avec les CGU, les mentions légales ou la charte de données personnelles. En outre, il est possible que des modifications non autorisées soient faites par des tiers sur le Site ou sur des Services annexes (réseaux sociaux…).
                                <br/>
                                Dans une telle situation, l'Utilisateur a la possibilité de contacter l'Editeur du Site par courrier postal ou par mail aux adresses indiquées dans les mentions légales du Site, avec si possible une description de l’erreur et l'emplacement (URL), ainsi que des informations suffisantes permettant de le contacter.

                            </p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Article 11 - Indépendance des clauses</h6>
                        <div className="card-body-page-about-us">
                            <p>
                                Si une disposition des CGU est jugée illégale, nulle ou pour toute autre raison inapplicable, alors cette disposition sera réputée divisible des CGU et n'affectera pas la validité et l'applicabilité des dispositions restantes.
                                <br/>
                                Les CGU remplacent tout accords antérieurs ou contemporains écrits ou oraux. Elles ne sont pas cessibles, transférables ou sous-licenciables par l'Utilisateur lui-même.
                                <br/>
                                Une version imprimée des CGU et de tous les avis donnés sous forme électronique pourra être demandée dans des procédures judiciaires ou administratives en rapport avec les CGU. Les parties conviennent que toute la correspondance relative à ces CGU doit être rédigée dans la langue française.

                            </p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Article 12 - Loi applicable</h6>
                        <div className="card-body-page-about-us">
                            <p>
                                Les présentes CGU sont régies par et soumises au droit Malien.
                                <br/>
                                Sauf dispositions d’ordre public, tous litiges qui pourraient survenir dans le cadre de l’exécution des présentes CGU pourront avant toute action judiciaire être soumis à l’appréciation de l'Editeur du Site en vue d’un règlement amiable.
                                <br/>
                                Il est expressément rappelé que les demandes de règlement amiable ne suspendent pas les délais ouverts pour intenter les actions judiciaires.
                                <br/>
                                Sauf disposition contraire, d’ordre public, toute action judiciaire relative à l’exécution des présentes CGU devra être soumise à la compétence du Tribunal de Commerce de Bamako.

                            </p>
                        </div>
                    </div>

                    <br/><br/>

                    <p>Tous droits réservés © - 19 octobre 2023</p>
                </div>
            </Container>
        </div>
    )
}

export default TermsAndCondition;