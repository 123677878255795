import {AUTH_USER, SAVE_USER, LOG_OUT_USER} from "../types/UserTypes";

export const saveUserAuh = (user) => (dispatch, getState) => {
    console.log(user)
    localStorage.setItem("user", JSON.stringify(user));

    try {
        dispatch({
            type: SAVE_USER,
            payload: {
                user: user,
                message: `auth user information is ${JSON.stringify(user)}`,
            },
        });
    } catch (error) {
        console.log("Error", error);
    }
};

export const authUser = (isAuthenticated) => (dispatch, getState) => {
    localStorage.setItem("isAuthenticated", isAuthenticated);

    try {
        dispatch({
            type: AUTH_USER,
            payload: {
                isAuthenticated: isAuthenticated,
                message: `User authenticate is ${isAuthenticated ? "successful" : "fail"} `,
            },
        });
    } catch (error) {
        console.log("Error", error);
    }
};

export const logOutUser = () => (dispatch, getState) => {
    localStorage.removeItem('user');
    localStorage.removeItem('isAuthenticated');

    try {
        dispatch({
            type: LOG_OUT_USER,
            payload: {
                isAuthenticated: false,
                user: {
                    address: "",
                    badge: "",
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    role: "",
                    status: true,
                    asCodePin: false
                },
                message: `User logout successfully`,
            },
        });
    } catch (error) {
        console.log("Error", error);
    }
};

export const setUserAsCodePin = () => (dispatch, getState) => {
    const jsonUser = localStorage.getItem("user");
    const user = JSON.parse(jsonUser);
    user.asCodePin = true;
    localStorage.setItem("user", JSON.stringify(user));

    try {
        dispatch({
            type: AUTH_USER,
            payload: {
                user: user,
                message: `User change code pin`,
            },
        });
    } catch (error) {
        console.log("Error", error);
    }
};