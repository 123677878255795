import {
    faAdd, faArrowDown, faArrowLeft,
    faArrowUp,
    faHeart,
    faPlayCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup} from 'reactstrap';
import { doc, updateDoc, collection, addDoc, serverTimestamp, getFirestore, getDocs, query, where, getDoc } from 'firebase/firestore';
import app from '../services/Firebase';
import { useSelector } from 'react-redux';
import ReelCommentToast from './ReelCommentToast';
import { useNavigate } from 'react-router-dom';
import {ROUTE_PRIVATE_ADD_REEL} from '../services/routes/Routes';
import {convertPxToVh, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_OWNER} from '../services/Constant';
import ModalAskBadge from './ModalAskBadge';

const db = getFirestore(app);
const reelsVideoCollection = collection(db, 'reels_videos');
const reelsLikesCollection = collection(db, 'reels_likes');
const reelsCommentsCollection = collection(db, 'reels_comments');
const badgesCollectionRef = collection(db, 'badge-collection');

const RealVideoCard = ({ video, isVisible, handlePrevClick, handleNextClick, canShowReel }) => {
    const { reelVideoId, reelUrl, reelLikes, reelComments, badges } = video;
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;
    const userPhone = user.phoneNumber;
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [likesCount, setLikesCount] = useState(reelLikes);
    const [hasLiked, setHasLiked] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [comments, setComments] = useState(reelComments);
    const [showCommentsToast, setShowCommentsToast] = useState(false);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [badgeData, setBadgeData] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (videoRef.current) {
            if (!isVisible) {
                videoRef.current.pause();
                setIsPlaying(false);
            } else {
                videoRef.current.play();
                setIsPlaying(true);
            }
        }
    }, [isVisible]);

    useEffect(() => {
        const checkUserLiked = async () => {
            if (user) {
                const likedQuery = query(reelsLikesCollection, where('reelVideoId', '==', reelVideoId), where('userId', '==', userPhone));
                const likedSnapshot = await getDocs(likedQuery);

                if (!likedSnapshot.empty) {
                    setHasLiked(true);
                }
            }
        };

        checkUserLiked();
    }, [user, userPhone, reelVideoId]);


    useEffect(() => {
        const fetchBadgeForBuy = async () => {
            setLoading(true);
            const badgeDocRef = doc(badgesCollectionRef, badges.badgeID);
            const badgeData = await getDoc(badgeDocRef);
            const badgeResult = badgeData.data();
            setBadgeData(badgeResult);
            setLoading(false);
        }
        if (isOpen) {
            fetchBadgeForBuy();
        }
    }, [isOpen]);

    useEffect(() => {
        let timer;

        const handleTimeUpdate = () => {
            const videoElement = videoRef.current;

            if (videoElement && videoElement.currentTime >= 3) {
                setIsOpen(true);
                clearInterval(timer);
                videoElement.pause();
                setIsPlaying(false);
            }
        };

        if (!canShowReel(video)) {
            const videoElement = videoRef.current;

            if (videoElement) {
                videoElement.addEventListener('timeupdate', handleTimeUpdate);
            }

            return () => {
                if (videoElement) {
                    videoElement.removeEventListener('timeupdate', handleTimeUpdate);
                }
                clearInterval(timer);
            };
        }
    }, [canShowReel, videoRef, setIsOpen]);

    const handleLikeClick = async () => {
        if (!hasLiked) {
            setLikesCount((prevCount) => {
                const newCount = prevCount + 1;

                const videoDocRef = doc(reelsVideoCollection, reelVideoId);
                updateDoc(videoDocRef, { reelLikes: newCount });

                console.log('Like ajouté avec succès!');
                return newCount;
            });

            setHasLiked(true);

            const likeData = {
                reelVideoId: reelVideoId,
                userId: userPhone,
            };

            await addDoc(reelsLikesCollection, likeData);

            console.log('Like ajouté avec succès!');
        } else {
            console.log('L\'utilisateur a déjà aimé cette vidéo.');
        }
    };

    const handleCommentClick = async () => {
        const commentData = {
            reelVideoId: reelVideoId,
            userId: userPhone,
            reelComment: commentText,
            commentLikes: 0,
            createdAt: serverTimestamp(),
        };

        await addDoc(reelsCommentsCollection, commentData);

        //const updatedComments = [...comments, { ...commentData, createdAt: new Date() }];

        //const videoDocRef = doc(reelsVideoCollection, reelVideoId);
        //await updateDoc(videoDocRef, { reelComments: updatedComments });

        setComments([...comments, commentData]);
        setCommentText('');

        console.log('Commentaire ajouté avec succès!');
    };

    const handleShowComments = async () => {
        setShowCommentsToast(true);

        const commentsQuery = query(reelsCommentsCollection, where('reelVideoId', '==', reelVideoId));
        const commentsSnapshot = await getDocs(commentsQuery);
        const fetchedComments = commentsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
        }));

        setComments(fetchedComments);
    };

    const handleCloseCommentsToast = () => {
        setShowCommentsToast(false);
    };

    const handleAddComment = (newCommentText) => {
        setCommentText(newCommentText);
    };

    const handleVideoClick = () => {
        console.log("ererzrerzerezre")
        if (canShowReel(video)) {
            const videoElement = videoRef.current;
            if (videoElement && isPlaying) {
                videoElement.pause();
                setIsPlaying(false);
            }
        } else {
            setIsOpen(true)
        }
    };

    const handleVideoPlay = () => {
        console.log("8596745263633")
        const videoElement = videoRef.current;
        if (videoElement) {
            if (videoElement.paused) {
                videoElement.play();
                setIsPlaying(true);
            } else {
                videoElement.pause();
                setIsPlaying(false);
            }
        }
    };

    const handleTimeUpdate = () => {
        const videoElement = videoRef.current;

        if (videoElement) {
            setCurrentTime(videoElement.currentTime);
            setDuration(videoElement.duration);
        }
    };

    const handleProgressClick = (e) => {
        const videoElement = videoRef.current;

        if (videoElement) {
            const progressRect = e.currentTarget.getBoundingClientRect();
            const clickX = e.clientX - progressRect.left;
            const percent = clickX / progressRect.width;
            const newTime = percent * videoElement.duration;

            videoElement.currentTime = newTime;
            setCurrentTime(newTime);
        }
    };


    return (
        <>
            {showCommentsToast && (
                <ReelCommentToast
                    comments={comments}
                    onClose={handleCloseCommentsToast}
                    isVisible={showCommentsToast}
                    onAddComment={handleAddComment}
                    onSendComment={handleCommentClick}

                />
            )}
            <div className="realVideo-card" style={{height: `${window.innerHeight}px`}} aria-hidden={!isVisible}>
                <div className={`video-container ${reelUrl ? 'reel' : ''}`} >
                    <div className="video-c">
                        {canShowReel ? (
                            <video className="video-js" ref={videoRef} autoPlay={isVisible} style={{ width: `${window.innerWidth}px`, height: `${window.innerHeight}px`, position: 'absolute', objectFit: 'cover', touchAction: "manipulation"}} onTimeUpdate={handleTimeUpdate}
                                   loop=""
                                   tabindex="-1"
                                   webkit-playsinline=""
                                   playsinline=""
                                   x-webkit-airplay="allow"
                                   controlslist="nodownload"
                                   onClick={handleVideoClick}
                            >
                                <source src={reelUrl} type="video/mp4" />
                                Votre navigateur ne prend pas en charge la lecture de vidéos.
                            </video>

                        ) : (
                            null
                        )}
                    </div>


                    {canShowReel || (
                        <div className="buy-badge-message">
                            {loading ? (
                                <div className="uil-reload-css-reload-small">
                                    <div></div>
                                </div>
                            ) : (
                                "Achetez le badge X pour visionner ses vidéos"
                            )}
                        </div>
                    )}
                    <div className="container-action">
                        {!isPlaying &&
                            <div className="reel-video-play">
                                <Button color="link" className="reel-play-button">
                                    <FontAwesomeIcon onClick={handleVideoPlay} icon={faPlayCircle} />
                                </Button>
                            </div>
                        }

                        <div className="go-back">
                            <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="icon-video"/>
                        </div>

                        <ButtonGroup className="reel-button-group">
                            <img src={badges.badgeImage} alt="logo-korix" className="badge-img" />

                            <Button onClick={handleLikeClick} color="link" className="reel-like-button">
                                <FontAwesomeIcon icon={faHeart} className={`outline-icon ${hasLiked ? 'liked' : ''}`} />
                                <p> {reelLikes}</p>
                            </Button>
                            {
                                [ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_OWNER].includes(user.role) && (
                                    <Button onClick={() => navigate(ROUTE_PRIVATE_ADD_REEL)} color="link" className="reel-share-button">
                                        <FontAwesomeIcon icon={faAdd} className="full-icon" />
                                    </Button>
                                )
                            }
                        </ButtonGroup>

                        <div className="reel-description">
                            <p>{badges.name} </p>
                        </div>

                        <div className="progress-container">
                            <div className="progress-container-child">
                                <progress
                                    value={currentTime}
                                    max={duration}
                                    onClick={handleProgressClick}
                                    className="reel-video-progress"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalAskBadge isOpen={isOpen} loading={loading} badge={badgeData} toggle={() => setIsOpen(!isOpen)} />
        </>
    );
};

export default RealVideoCard;
