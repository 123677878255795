import PropTypes from "prop-types";
import {Button, Card, Col, FormGroup, Input, Label, Modal, ModalBody, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";


function ModalPriviledUsersList({isOpenUsersList, toggle, privilegedUsersList, setPrivilegedUsersList}) {

    // handel remove USER Option
    const handelRemoveThisUser = (index) => {
        const list = [...privilegedUsersList];
        list.splice(index, 1);
        setPrivilegedUsersList(list);
    }

    // handel inputing value of name and user number Option
    const handelAddPrivilegedList = (e) => {
        const list = privilegedUsersList.concat({ name: "", number: ""  });
        setPrivilegedUsersList(list);
    }

    const handlerChangeField = (e, index) => {
        const { name, value } = e.target;
        const list = [...privilegedUsersList];
        list[index][name] = value;
        setPrivilegedUsersList(list);
    }

    return (
        <>
        <Modal className="modal-badge" funk={true} isOpen={isOpenUsersList} toggle={toggle} size="xl">
            <ModalBody>
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle}/>
                </div>
                <div className="modal-badge-button">
                    <Card className="modal-badge-card ml-auto mr-auto">
                        <Button className="modal-badge-button-title detail text-uppercase btn-round" size="lg">
                            <div className="text">VEDETTES</div>
                        </Button>
                        <div className="modal-badge-box">
                            <span className="modal-list-text" >Vous pouvez ajouter autant d'utilisateurs que necessaire</span>
                            <div className="pt-3">
                                {
                                    privilegedUsersList.map((number, index) => {
                                        return (
                                            <div key={index}>
                                                {
                                                    0 !== index && (
                                                        <div className="modal-badge-collection-close">
                                                            <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={(e) => handelRemoveThisUser(index)}/>
                                                        </div>
                                                    )
                                                }
                                                <Row>
                                                    <Col className="col-md-6">
                                                        <FormGroup>
                                                            <Label for="name" className="modal-list-text">Nom</Label>
                                                            <Input type="text" name="name" id="name" placeholder="Nom" onChange={(e) => handlerChangeField(e, index)}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="col-md-6">
                                                        <FormGroup>
                                                            <Label for="number" className="modal-list-text">Numéro</Label>
                                                            <Input type="text" name="number" id="number" placeholder="Numéro" onChange={(e) => handlerChangeField(e, index)}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <Button className="btn-round" color="primary" onClick={handelAddPrivilegedList}><FontAwesomeIcon icon={faPlus}/> Nouvel utilisateur</Button>
                        </div>
                        <div className="right-side">
                            <Button className="btn-round" color="primary" onClick={toggle}>Valider</Button> {" "}
                            <Button className="btn-round" color="default" onClick={toggle}>Annuler</Button>
                        </div>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
        <div className='row'>
        {privilegedUsersList.map((row, index) =>
            row.number !== "" &&
            <div className='col-sm-2 ProOpt' key={index}>
                <div>{row.name}</div>
                <div>{row.number}</div>
            </div>
        )}
    </div>
     </>
    )
}

export default ModalPriviledUsersList;

ModalPriviledUsersList.defaultProps = {
    isOpenUsersList: PropTypes.bool,
    toggle: PropTypes.func,
    privilegedUsersList: PropTypes.array,
    setPrivilegedUsersList: PropTypes.func,
};

ModalPriviledUsersList.propTypes = {
    isOpenUsersList: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    privilegedUsersList: PropTypes.array.isRequired,
    setPrivilegedUsersList: PropTypes.func.isRequired,
};