import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import {
  collection,
  getFirestore,
  setDoc,
  query,
  orderBy,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';

import app from '../../../services/Firebase';
import AddRewardLevelForm from './AddRewardLevelForm';
import AddLevelForm from './AddLevelForm';
import EditLevelForm from './EditLevelForm';
import ProgramCard from './ProgramCard';

const db = getFirestore(app);

function ReferralsProgram() {
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [newProgramName, setNewProgramName] = useState('');
  const [rewardLevels, setRewardLevels] = useState([]);
  const [newRewardLevels, setNewRewardLevels] = useState([]);
  const [newRewardName, setNewRewardName] = useState('');
  const [newRewardDescription, setNewRewardDescription] = useState('');
  const [newRewardReferralCount, setNewRewardReferralCount] = useState(0);
  const [newReward, setNewReward] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLevelModalOpen, setIsLevelModalOpen] = useState(false);
  const [editLevelIndex, setEditLevelIndex] = useState(-1);

  const [isAddLevelModalOpen, setIsAddLevelModalOpen] = useState(false);
  const [newLevelName, setNewLevelName] = useState('');
  const [newLevelDescription, setNewLevelDescription] = useState('');
  const [newLevelReferralCount, setNewLevelReferralCount] = useState(0);
  const [newLevelReward, setNewLevelReward] = useState('');
  const [selectedProgramToAddLevel, setSelectedProgramToAddLevel] = useState(null);

  useEffect(() => {
    loadPrograms();
  }, []);

  async function loadPrograms() {
    const q = query(collection(db, 'referralPrograms'), orderBy('programName'));
    const querySnapshot = await getDocs(q);
    const programData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    const allRewardLevels = programData.flatMap((program) => program.rewardLevels);

    setPrograms(programData);
    setRewardLevels(allRewardLevels);
  }

  async function handleCreateProgram() {
    if (!newProgramName || newRewardLevels.length === 0) {
      return;
    }

    const programRef = doc(collection(db, 'referralPrograms'));

    const newProgram = {
      programName: newProgramName,
      rewardLevels: newRewardLevels,
    };

    await setDoc(programRef, newProgram);

    setNewProgramName('');
    setNewRewardLevels([]);

    loadPrograms();
  }

  async function handleDeleteProgram(programId) {
    await deleteDoc(doc(db, 'referralPrograms', programId));
    loadPrograms();
  }

  async function handleDeleteLevel(programId, levelIndex) {
    if (!programId || levelIndex === undefined) {
      return;
    }

    const programRef = doc(db, 'referralPrograms', programId);

    const programSnapshot = await getDoc(programRef);

    if (!programSnapshot.exists()) {
      return;
    }

    const programData = programSnapshot.data();
    const updatedProgramData = {
      ...programData,
      rewardLevels: [...programData.rewardLevels],
    };

    updatedProgramData.rewardLevels.splice(levelIndex, 1);

    await updateDoc(programRef, updatedProgramData);

    loadPrograms();
  }

  async function handleAddLevel(level) {
    setNewRewardLevels([...newRewardLevels, level]);
  }

  async function handleAddLevelSubmit() {
    const newLevel = {
      name: newLevelName,
      description: newLevelDescription,
      referralCountRequired: newLevelReferralCount,
      reward: newLevelReward,
    };

    if (selectedProgramToAddLevel) {
      const updatedProgram = { ...selectedProgramToAddLevel };
      updatedProgram.rewardLevels.push(newLevel);

      const programRef = doc(db, 'referralPrograms', selectedProgramToAddLevel.id);
      updateDoc(programRef, updatedProgram)
        .then(() => {
          loadPrograms();
          toggleAddLevelModal();
          setNewLevelName('');
          setNewLevelDescription('');
          setNewLevelReferralCount(0);
          setNewLevelReward('');
          setSelectedProgramToAddLevel(null);
        })
        .catch((error) => {
          console.error('Erreur lors de l\'ajout du niveau :', error);
        });
    }
  }

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  function toggleLevelModal() {
    setIsLevelModalOpen(!isLevelModalOpen);
  }

  function toggleAddLevelModal() {
    setIsAddLevelModalOpen(!isAddLevelModalOpen);
  }

  function handleAddLevelClick(program) {
    setSelectedProgramToAddLevel(program);
    toggleAddLevelModal();
  }

  async function handleEditProgram(programId, newProgramData) {
    await updateDoc(doc(db, 'referralPrograms', programId), newProgramData);
    loadPrograms();
  }

  function handleEditLevel(levelIndex) {
    if (levelIndex >= 0) {
      setEditLevelIndex(levelIndex);
      toggleLevelModal();
      setSelectedProgram(programs[levelIndex]);
    }
  }

  function handleCancelEditLevel() {
    setEditLevelIndex(-1);
    toggleLevelModal();
  }

  async function handleSaveEditLevel(updatedLevel) {
    if (selectedProgram && editLevelIndex >= 0) {
      const updatedRewardLevels = [...selectedProgram.rewardLevels];
      updatedRewardLevels[editLevelIndex] = updatedLevel;

      const programRef = doc(db, 'referralPrograms', selectedProgram.id);
      await updateDoc(programRef, { rewardLevels: updatedRewardLevels });

      loadPrograms();
      handleCancelEditLevel();
    }
  }

  function handleEditLevelChange(updatedLevel) {
    const updatedRewardLevels = [...rewardLevels];
    updatedRewardLevels[editLevelIndex] = updatedLevel;
    setRewardLevels(updatedRewardLevels);
  }

  return (
    <Container>
      <Row>
        <Col>
          <h2>Programme de référence</h2>
          <Form>
            <FormGroup>
              <Label>Nom du Programme</Label>
              <Input
                type="text"
                value={newProgramName}
                onChange={(e) => setNewProgramName(e.target.value)}
              />
            </FormGroup>
            <AddRewardLevelForm
              newRewardName={newRewardName}
              setNewRewardName={setNewRewardName}
              newRewardDescription={newRewardDescription}
              setNewRewardDescription={setNewRewardDescription}
              newRewardReferralCount={newRewardReferralCount}
              setNewRewardReferralCount={setNewRewardReferralCount}
              newReward={newReward}
              setNewReward={setNewReward}
              onAddLevel={handleAddLevel}
            />
            {newRewardLevels.length > 0 && (
              <Button onClick={handleCreateProgram} color="success">
                Créer Programme avec Niveaux
              </Button>
            )}
          </Form>
          {newProgramName && <h3> {newProgramName} </h3>}
          <ul>
            {newRewardLevels.map((level, index) => (
              <li key={index}>
                {level.name} - {level.description} - {level.referralCountRequired} références requises - Récompense : {level.reward}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        {programs.map((program) => (
          <ProgramCard
            key={program.id}
            program={program}
            onAddLevelClick={() => handleAddLevelClick(program)}
            onEditLevelClick={(index) => handleEditLevel(index)}
            onDeleteLevel={(index) => handleDeleteLevel(program.id, index)}
          />
        ))}
      </Row>
      <AddLevelForm
        isAddLevelModalOpen={isAddLevelModalOpen}
        toggleAddLevelModal={toggleAddLevelModal}
        newLevelName={newLevelName}
        setNewLevelName={setNewLevelName}
        newLevelDescription={newLevelDescription}
        setNewLevelDescription={setNewLevelDescription}
        newLevelReferralCount={newLevelReferralCount}
        setNewLevelReferralCount={setNewLevelReferralCount}
        newLevelReward={newLevelReward}
        setNewLevelReward={setNewLevelReward}
        onAddLevelSubmit={handleAddLevelSubmit}
      />
      <EditLevelForm
        isLevelModalOpen={isLevelModalOpen}
        toggleLevelModal={toggleLevelModal}
        rewardLevels={rewardLevels}
        editLevelIndex={editLevelIndex}
        onEditLevelChange={handleEditLevelChange}
        onCancelEditLevel={handleCancelEditLevel}
        onSaveEditLevel={handleSaveEditLevel}
      />
    </Container>
  )
}

export default ReferralsProgram;