
export class Badge {

    constructor(collection, publicID, publicKey, payment, badgeSerial, referralID, referrerID, paymentOrderId, paymentTxiId, userName, buyer) {
        this.collection = collection;
        this.date = new Date().toString();
        this.publicID = publicID;
        this.publicKey = publicKey;
        this.payment = payment;
        this.tokenId = new Date().getTime().toString();
        this.badgeSerial = badgeSerial;
        this.referralID = referralID;
        this.referrerID = referrerID;
        this.paymentOrderId = paymentOrderId;
        this.paymentTxiId = paymentTxiId;
        this.userName = userName;
        this.buyer = buyer;
    }

    getBadgeSerial(): string {
        return this.badgeSerial;
    }

    getPublicID(): string {
        return this.publicID;
    }

    getReferralID(): string {
        return this.referralID;
    }

    getReferrerID(): string {
        return this.referrerID;
    }

    getPaymentTxiId(): string {
        return this.paymentTxiId;
    }

    getBadge() :Object {
        return {
            date: this.date,
            collection: this.collection,
            publicID: this.publicID,
            publicKey: this.publicKey,
            payment: this.payment,
            tokenID: this.tokenId,
            badgeSerial:this.badgeSerial,
            referralID: this.referralID,
            referrerID: this.referrerID,
            paymentOrderId: this.paymentOrderId,
            paymentTxiId: this.paymentTxiId,
            userName: this.userName,
            buyer: this.buyer,
        }
    }
}

