import { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, addDoc} from "firebase/firestore";
import app from "../../../../services/Firebase";
import * as React from "react";
import PropTypes from "prop-types";
import {Alert, Button, Card, FormGroup, Input, Label, Modal, ModalBody} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import { getFunctions, httpsCallable } from "firebase/functions";

const db = getFirestore(app);
const functions = getFunctions(app);
const badgeCollection = collection(db, 'badge-collection');


function ModalNewUserBadge({isOpen, toggle}) {
    const [badges, setBadges] = useState([]);
    const [number, setNumber] = useState('');
    const [paymentTxId, setPaymentTxId] = useState('');
    const [paymentProvder, setPaymentProvder] = useState('');
    const [badge, setBadge] = useState('');
    const [badgeId, setBadgeId] = useState('');
    const [visible,setVisible] = React.useState();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Tous les champs sont obligatoires");
    const [errorField, setErrorField] = useState(false);


    useEffect( () => {
        setErrorField(false);
        const fetchBadges = async () => {
            const badgesDocs = await getDocs(badgeCollection);
            if(badgesDocs.docs.length > 0) {
                var collectionsBadge = [];
                badgesDocs.docs.map((badge, index) => {
                    collectionsBadge[index] = {
                        address: badge.data().address,
                        name: badge.data().name,
                        key: badge.id
                    };
                });
                setBadges(collectionsBadge);
            }
        }

        fetchBadges();
    }, []);

    const handleChangeBadge = (event) => {
        setBadge(badges[event.target.value].name);
        setBadgeId(badges[event.target.value].key);
    }
  
    const handleOnValidate = async() => {
        if("" === paymentProvder || "" === number || "" === paymentTxId) {
            setErrorField(true);
            return;
        }

        try {
            const processUserBadge = httpsCallable(functions, 'processBadgeCreation');
             await processUserBadge({
                badgeId: badgeId,
                userId: number,
                paymentTxId: paymentTxId,
                payment: paymentProvder,
            }).then((toggle()));

            setNumber("");
            setBadgeId("");

        } catch (e) {
            console.error("Erreur lors de l'appel à la fonction:", e);
            setErrorMessage(e.message);
            setError(true);
            
        }
    }

    return (
        <Modal className="modal-badge" funk={true} isOpen={isOpen} toggle={toggle} size="xl">
            <ModalBody>
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle}/>
                </div>
                <div className="modal-badge-button">
                    <Card className="modal-badge-card ml-auto mr-auto">
                        <Button className="modal-badge-button-title detail text-uppercase btn-round" size="lg">
                            <div className="text">Badge</div>
                        </Button>
                        {
                            error && (
                                <Alert color="danger" isOpen={visible} toggle={() => setVisible(false)}>
                                    {errorMessage}
                                </Alert>
                            )
                        }
                        <div className="modal-badge-box">
                            <FormGroup className={errorField ? "has-danger" : ""}>
                                <Label for="badge" className="control-label">Badge</Label>
                                <Input type="select" name="select" id="badge" onChange={handleChangeBadge}>
                                    <option>Sélectionner badge</option>
                                    {
                                        badges.map((element, index) => <option key={index} value={index}>{element.name}</option>)
                                    }
                                </Input>
                            </FormGroup>

                            <FormGroup className={errorField ? "has-danger" : ""}>
                                <Label for="name" className="control-label">Utilisateur</Label>
                                <Input type="text" name="name" id="name" placeholder="Numéro de l'utilisateur" onChange={(event) => setNumber(event.target.value)}/>
                            </FormGroup>

                            <FormGroup className={errorField ? "has-danger" : ""}>
                                <Label for="name" className="control-label">Référence de paiement</Label>
                                <Input type="text" name="name" id="name" placeholder="Paiement TxID" onChange={(event) => setPaymentTxId(event.target.value)}/>
                            </FormGroup>

                            <FormGroup className={errorField ? "has-danger" : ""}>
                                <Label for="name" className="control-label">Moyen de paiement</Label>
                                <Input type="text" name="name" id="name" placeholder="Orange Money" onChange={(event) => setPaymentProvder(event.target.value)}/>
                            </FormGroup>

                        
                        </div>
                        <div className="right-side">
                            <Button className="btn-round" color="primary" onClick={handleOnValidate}>Valider</Button> {" "}
                            <Button className="btn-round" color="default" onClick={toggle}>Annuler</Button>
                        </div>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalNewUserBadge;

ModalNewUserBadge.defaultProps = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
};

ModalNewUserBadge.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};
