import {Button} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import {STEP_COUNTRY, STEP_INIT} from "../../services/Constant";
import {emptyCart} from "../../services/stores/actions/CartActions";
import {useDispatch} from "react-redux";

function Init({eventClose, eventNext}) {

    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch(emptyCart());
        eventClose();
    }

    return (
        <>
            <div className="text-center">
                <h3>Tout achat de badge est final et non remboursable</h3>
            </div>
            <div className="container-validate">
                <Button block className="btn-round btn-korix-orange" size="md" onClick={() => eventNext(STEP_INIT, STEP_COUNTRY, null)}>
                    suivant
                </Button>
                <Button block className="btn-round btn-korix-black" size="md" onClick={handleCancel}>
                    Annuler
                </Button>
                <Button block className="btn-round return" size="md" onClick={eventClose} >
                    Quitter
                </Button>
            </div>
        </>
    )
}

export default Init;

Init.defaultProps = {
    eventClose: PropTypes.func,
    eventNext: PropTypes.func
};

Init.propTypes = {
    eventClose: PropTypes.func.isRequired,
    eventNext: PropTypes.func.isRequired,
}