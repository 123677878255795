import React, {useRef, useState} from "react";
import ModalBadgeDetail from "./ModalBadgeDetail";

const BadgeImage = ({loading, badge, liveState, onEvent, className, conserveContainer}) => {
    const [isOpenBadgeDetail, setIsOpenBadgeDetail] = useState(false);
    const imageUrlToDisplay = badge && (true === liveState ? badge.liveImageUrl : badge.imageUrl);
    const intervalClick = useRef();

    const onMouseDown = () => {
        intervalClick.current = setTimeout(() => {
            setIsOpenBadgeDetail(true);
        }, 400);
    }

    const onMouseUp = (event: MouseEvent) => {
        clearTimeout(intervalClick.current);
    }

    if (loading || !badge) {
        return (
            <div className="badge-placeholder">
                <div className={className ? `${className} image-container` : "image-container"} />
                <div className="image-container-live-offline" />
                <span className="image-container-title"></span>
            </div>
        );
    }

    return (
        <div className="badge-content text-center">
            <div className={ !conserveContainer ? className ? `image-container ${className}` : "image-container" : "no-container" }>
                <img
                    src={imageUrlToDisplay}
                    alt={badge.key}
                    className="img-responsive"
                    onClick={onEvent ? () => onEvent(badge) : null}
                    onTouchStart={onMouseDown}
                    onTouchEnd={onMouseUp}
                />
            </div>
            <ModalBadgeDetail isOpen={isOpenBadgeDetail} toggle={() => setIsOpenBadgeDetail(!isOpenBadgeDetail)} badge={badge}/>
        </div>
    );
}

export default BadgeImage;