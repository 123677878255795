import React from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import {ROUTE_PRIVATE_CART, routes} from "../services/routes/Routes";

function Footer() {
    const location = useLocation();
    const navigate = useNavigate();

    const handlerChange = (route) => {
        document.documentElement.classList.remove("nav-open");
        var element = document.getElementById("button-nav-close");
        if(element !== null) {
            element.classList.remove("toggled");
            navigate(route);
        } else {
            navigate(route);
        }
        
    }

    return (
        <nav className="footer-page">
            {
                routes.map(route => {
                    if (route.footer) {
                        return <div className={
                            (route.path === location.pathname) ||
                            (location.pathname === ROUTE_PRIVATE_CART && route.key === "marketplace")
                                ? "bloc-icon active" : "bloc-icon"
                        } onClick={() => handlerChange(route.path)}>
                            <img src=
                                     {
                                         (route.path === location.pathname) ||
                                         (location.pathname === ROUTE_PRIVATE_CART && route.key === "marketplace")
                                             ? require(`../assets/img/${route.imgActive}`) : require(`../assets/img/${route.img}`)
                                     }
                                     alt=""/>
                            <div>
                                <span className={route.key}>{route.name}</span>
                            </div>
                        </div>
                    }
                })
            }
        </nav>
    );
}

export default Footer;
