import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import {Button, Col, Container, Row} from "reactstrap";
import {
    ROUTE_OTP,
    ROUTE_PRIVATE_BADGE,
    ROUTE_PRIVATE_HOME,
    ROUTE_PRIVATE_MARKETPLACE,
    ROUTE_PRIVATE_QRCODE,
    ROUTE_PRIVATE_WALLET
} from "../../services/routes/Routes";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowDown, faArrowRight, faArrowUp, faChevronRight, faCirclePlus} from '@fortawesome/free-solid-svg-icons'
import AddToScreenToast from "../../components/Toast";
import {collection, doc, getDocs, getFirestore, query, where} from "firebase/firestore";
import app from "../../services/Firebase";
import {useSelector} from "react-redux";
import LiveComponent from "../../components/LiveComponent";
import BadgeImage from "../../components/BadgeImage";
import SearchComponent from "../../components/SearchComponent";
import ModalAskCodePin from "../../components/ModalAskCodePin";
import {faq} from "../../services/Constant";
import BadgePartnerComponent from "../../components/BadgePartnerComponent";
import {getUserBadges} from "../../services/queries/Badge";
const db = getFirestore(app);
const userCollection = collection(db, 'users');
const partnersCollection = collection(db, 'partners-collection');

function Home() {

    const navigate = useNavigate();
    const userReducers = useSelector((state) => state.userReducers);
    const codePinReducers = useSelector((state) => state.codePinReducers);
    const { user } = userReducers;
    const { lastTimeStampCodePinGenerate } = codePinReducers;
    const [usersBadges, setUsersBadges] = useState([]);
    const [partners, setPartners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingPartner, setLoadingPartner] = useState(true);
    const [show, setShow] = useState(1);
    const [isOpenCodePin, setIsOpenCodePin] = useState(false);
    const [badgeSelected, setBadgeSelected] = useState({});

    useEffect(() => {
        document.body.style.overscrollBehaviorY = 'auto';
    }, [])

    useEffect(() => {
        fetchUserBadges();
        fetchPartners();
    }, []);

    const  fetchUserBadges = async () => {
        setUsersBadges(await getUserBadges(user.phoneNumber));
        setLoading(false);
    }

    const  fetchPartners = async () => {
        const q = query(partnersCollection, where('listed', '==', true));
        const partnersDocs = await getDocs(q);
        if(partnersDocs.docs.length > 0) {
            var collectionsPartners = [];
            partnersDocs.docs.map((partner, index) => {
                collectionsPartners[index] = {
                    key: partner.id,
                    name: partner.data().collectionName,
                    category: partner.data().category,
                    imageUrl: partner.data().imageUrl,
                    details: partner.data().collectionDetails,
                    badgeCount: 0
                };

                return collectionsPartners;
            });

            setPartners(collectionsPartners);
        }
        setLoadingPartner(false);
    }

    const handlerShowPartnerInformation = async (partner) => {
        return navigate(ROUTE_PRIVATE_BADGE, {state: partner});
    }

    const redirectToBadgeQrCode = (badge) => {
        const state = {
            whereIComeFrom: ROUTE_PRIVATE_HOME,
            whereIGo: ROUTE_PRIVATE_QRCODE,
            key: badge.key,
            img: badge.imageUrl,
            name: badge.name,
            address: badge.address,
            data: {
                tokenId: badge.tokenID,
                userTel: user.phoneNumber,
                contractAddress: badge.address,
                userName: badge.userName,
            }
        };

        if(!user.asCodePin) {
            setBadgeSelected(state);
            setIsOpenCodePin(!isOpenCodePin);
            return;
        }

        const currentTimestamp = Date.now();
        const lastTimeStampCodePinGeneratePlus30sec = lastTimeStampCodePinGenerate + 30000;
        if (null === lastTimeStampCodePinGenerate || lastTimeStampCodePinGeneratePlus30sec < currentTimestamp) {
            navigate(ROUTE_OTP, { state: state});
        } else {
            navigate(ROUTE_PRIVATE_QRCODE, { state: state});
        }
    }

    return (
        <>
            <NavBar />
            <AddToScreenToast className={"home"}/>
            <div className="page-home">
                <Container>
                    <LiveComponent />
                    <div className="badge-container-page-home">
                        <div className="badge-container-information">
                            <h4>Mes badges</h4>
                            <FontAwesomeIcon icon={faChevronRight} className="icon" onClick={() => navigate(ROUTE_PRIVATE_WALLET)}/>
                        </div>
                        <span>Explorez les badges que vous avez achetés...</span>
                        <div className="badge-container-detail-page-home">
                            {
                                usersBadges.length > 0 ?
                                    <div className="badge-container-detail-badge-page-home">
                                        {
                                            usersBadges.map((badge, index) => {
                                                return (
                                                    <BadgeImage
                                                        badge={badge}
                                                        loading={loading}
                                                        onEvent={redirectToBadgeQrCode}
                                                        className={"badge-wallet"}
                                                    />
                                                )
                                            })
                                        }
                                    </div> :
                                    <div className="badge-container-empty-page-home"><span>Vous n'avez pas de badge</span></div>
                            }
                        </div>
                    </div>
                    <div className="badge-container-page-home partner">
                        <div className="badge-container-information">
                            <h4>Découvrir</h4>
                            <FontAwesomeIcon icon={faChevronRight} className="icon"/>
                        </div>
                        <span>Explorez les badges en ventes...</span>
                        <div className="badge-container-detail-page-home">
                            <div className="badge-container-detail-badge-page-home">
                                {
                                    partners.length > 0 ?
                                        partners.map((partner, index) => {
                                            return (
                                                <BadgePartnerComponent
                                                    key={index}
                                                    badge={partner}
                                                    onEvent={handlerShowPartnerInformation}
                                                    className={null}
                                                />
                                            )
                                        }) :
                                        [...Array(2)].map(index => {
                                            return (
                                                <BadgeImage
                                                    key={index}
                                                    badge={null}
                                                    liveState={false}
                                                    showLiveIcon={false}
                                                    loading={true}
                                                    onEvent={null}
                                                    className={null}
                                                />
                                            )
                                        })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="faq-container-page-home">
                        <div className="faq-container-title-page-home">
                            <span>Faq</span>
                        </div>
                        <div className="faq-container-body-page-home">
                            {
                                Object.keys(faq).map(index => {
                                    return (
                                        <div key={index}>
                                            <Row className="pt-3">
                                                <Col xs={10}>
                                                    <h6>{index} - {faq[index].title}</h6>
                                                </Col>
                                                <Col xs={2}>
                                                    <FontAwesomeIcon icon={show === index ? faArrowUp : faArrowDown} className="icon-fa" onClick={show === index ? () => setShow(null) : () => setShow(index)} style={{cursor: "pointer"}}/>
                                                </Col>
                                            </Row>
                                            <div className={show === index ? "show" : "hide"}>
                                                <p>{faq[index].description}</p>
                                                {
                                                    faq[index].list && (
                                                        <ul>
                                                            {
                                                                faq[index].list.map((element, i) => {
                                                                    return <li key={i}>{element}</li>
                                                                })
                                                            }
                                                        </ul>
                                                    )
                                                }
                                                {
                                                    faq[index].otherInformation && (
                                                        <p>{faq[index].otherInformation}</p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <ModalAskCodePin isOpen={isOpenCodePin} toggle={() => setIsOpenCodePin(!isOpenCodePin)} route={ROUTE_PRIVATE_QRCODE} state={badgeSelected} modal={false}/>
                </Container>
            </div>
            <Footer/>
        </>

    )
}

export default Home;