import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import {
    collection,
    getFirestore,
    getDocs,
    doc,
    updateDoc,
    getDoc, setDoc,
} from 'firebase/firestore';
import app from '../../services/Firebase';
import { useSelector } from 'react-redux';
import "../../userprofile.css";
import {
    ROUTE_500,
    ROUTE_OTP_CODE_PIN,
    ROUTE_PRIVATE_REFERRAL_DETAILS, ROUTE_PRIVATE_USER_PROFILE
} from '../../services/routes/Routes';
import {useLocation, useNavigate} from 'react-router-dom';

const db = getFirestore(app);
const usersCollection = collection(db, 'users');

const UserProfile = () => {
    const navigate  = useNavigate();
    const [users, setUser] = useState(null);
    const {state} = useLocation();
    const [referralCode, setReferralCode] = useState('');
    const [referralCount, setReferralCount] = useState(0);
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;

    useEffect(() => {
        if(!state) {
            navigate(ROUTE_500)
        }

    }, [state])

    useEffect(() => {
        fetchUser()
    }, []);

    const fetchUser = async () => {
        setUser(user);
        const userDocRef = doc(usersCollection, user.phoneNumber);

        const userDocSnapshot = await getDoc(userDocRef)
        const userData = userDocSnapshot.data();

        setUser(userData);
        setReferralCode(userData.referralCode || '');
        setReferralCount(userData.referralCount || 0);
    }

    const createReferralCode = () => {
        if (user) {
            const referralCode = generateReferralCode(6);
            const userDocRef = doc(usersCollection, user.phoneNumber);
            updateDoc(userDocRef, { referralCode: referralCode })
                .then(async () => {
                    await setDoc(doc(db, "referralid_collection", referralCode), { referralID: referralCode, userID: user.phoneNumber});
                    setReferralCode(referralCode);
                    console.log('Code de référence créé avec succès.',referralCode);
                })
                .catch((error) => {
                    console.error('Erreur lors de la création du code de référence :', error);
                });
        }
    };

    function generateReferralCode(length) {
        let result = '';
        const characters = '0123456789';
      
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters[randomIndex];
        }
      
        return result;
      };

    return (
        <div className="userprofile">
            <div className="overlap-wrapper">
                <div className="overlap">
                    <img className="image"
                     alt="Image" 
                     src={require("../../assets/img/image-8.png")} 
                     />
                    <div className="back-global" />
                    <div className="back-user-data" />
                    <div className="text-wrapper">Mon code de référent</div>
                    <div className="user-data">
                        <div className="overlap-group">
                            <div className="nom-prenom">
                                <div className="div">{user.firstName + " "} {user.lastName}</div>
                            </div>
                            <div className="button">
                                <p className="p">{user.phoneNumber}</p>
                            </div>
                        </div>
                        <div className="button-pin">
                            <div className="overlap-group-2">
                                <div className="text-wrapper-3">Modifier mon code pin</div>
                                <div className="icon-wrapper">
                                    <img
                                        className="icon"
                                        alt="Icon"
                                        src={require("../../assets/img/icon-arrow-black.png")}
                                        onClick={() => navigate(ROUTE_OTP_CODE_PIN, {state: {whereIGo: ROUTE_PRIVATE_USER_PROFILE, whereIComeFrom: state.whereIComeFrom,}})}
                                    />
                                </div>
                            </div>
                        </div>
                        <button className="div-wrapper">

                            {referralCode ? (
                                <div className="overlap-2">
                                    <img
                                        className="vuesax-linear-copy"
                                        alt="Vuesax linear copy"
                                        src={require("../../assets/img/icon-add.png")}
                                        onClick={() => {navigator.clipboard.writeText(referralCode)}}
                                    />
                                    <div className="text-wrapper-5">{referralCode}</div>
                                </div>
                            ) : (
                                <button onClick={createReferralCode}>Créer un code de référence</button>
                            )}
                        </button>
                        <div className="ref-qual">
                            <div className="overlap-3">
                                <div className="text-wrapper-6">Mes points références: {referralCount}</div>
                                <div className="text-wrapper-7">Niveau</div>
                                <img
                                    className="vuesax-linear-shield"
                                    alt="Vuesax linear shield"
                                    src={require("../../assets/img/shield-cross.png")}
                                />
                                <p className="r-f-rez">
                                    <span className="span">Référez* </span>
                                    <span className="text-wrapper-8">100 utilisateurs</span>
                                    <span className="span"> pour passer au statut confirmé et </span>
                                    <span className="text-wrapper-8">gagner une récompense !</span>
                                </p>
                                <div className="img-wrapper">
                                    <img
                                        className="img"
                                        alt="Icon"
                                        src={require("../../assets/img/icon-arrow-left.png")}
                                        onClick={() => navigate(ROUTE_PRIVATE_REFERRAL_DETAILS, {state: state})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="text-wrapper-9">*Pour être comptabilisé une référence doit acheter un badge Korix</p>
                    <div className="text-wrapper-10" onClick={() => navigate(ROUTE_PRIVATE_REFERRAL_DETAILS, {state: state})}>Plus de détails</div>
                    <div className="profile-pic">
                        <div className="group">
                            <img
                                className="vuesax-linear-user"
                                alt="Vuesax linear user"
                                src={require("../../assets/img/userr.png")}
                            />
                        </div>
                    </div>
                    <div className="group-2">
                        <div className="arrow-2" onClick={() => navigate(state.whereIComeFrom)}>
                            <img
                                className="icon-2"
                                alt="Icon"
                                src={require("../../assets/img/icon-arrow.png")}
                            />
                        </div>
                        <img 
                        className="LOGO-ACCESS-WHITE" 
                        alt="Logo ACCESS WHITE" 
                        src={require("../../assets/img/Brand/ACCESS_LOGO_WHITE.png")}
                        />
                    </div>
                    <button className="button-2">
                        <div className="overlap-4">
                            <div className="text-wrapper-11">SE DECONNECTER</div>
                        </div>
                    </button>
                    <div className="text-wrapper-12">Points échangés: 0</div>
                    <div className="text-wrapper-13">Date: 15/10/2023</div>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;