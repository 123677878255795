import {Alert, Button, Col, FormGroup, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {CODE_PIN_LENGTH, STEP_SMS_CODE} from "../../services/Constant";
import PropTypes from "prop-types";
import InputComponent from "../input/InputComponent";

function CodePinValidateTransaction ({operatorImg, sms, eventCancel, eventNext,setLoading}) {
    const [code, setCode] = useState("");
    const [error, setError] = useState(false);
    const [visible, setVisible] = React.useState();



    const handlePaste = (event) => {
        event.preventDefault();

        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedText = clipboardData.getData('text');

        if (pastedText.length === CODE_PIN_LENGTH) {
            const inputElements = document.querySelectorAll('.form-control[type="number"]');
            const pastedDigits = pastedText.split('');

            inputElements.forEach((input, index) => {
                if (index < CODE_PIN_LENGTH) {
                    input.value = pastedDigits[index];
                    setCode({ target: input }, index);
                }
            });
        }
    };

    const handleOnClick = () => {
        setError(false);

        if("" === code || code.length !== CODE_PIN_LENGTH) {
            setError(true);
            setVisible(true);
            return;
        }

  
        eventNext(STEP_SMS_CODE, null, code);
        setLoading(true);
    }

    return (
        <>
            <div className={"image-container_payment-border"}>
                <img src={operatorImg.flag} alt="image sélectionnée"/>
            </div>
            {
                error && (
                    <Alert color="danger" isOpen={visible} toggle={() => setVisible(!visible)}>
                        Le code envoyé n'est pas correcte
                    </Alert>
                )
            }
            <div className="modal-badge-body-div ml-auto mr-auto text-center">
                <h5>1- Obtenez un code de confirmation en cliquant sur :</h5>
                <Button color="primary" size="lg"
                        style={{ borderRadius: '20px', color: 'black', fontSize: '20px' }}
                        href={`tel:${encodeURIComponent(sms)}`}
                >
                    <FontAwesomeIcon icon={faPhone} style={{ marginRight: '5px' }} />
                    {sms}
                </Button>
            </div>
            <div>
                <div className="container-form-page-otp text-center">
                    <div className="container-text-page-otp">
                        <h5>2- Entrer le code reçu par sms :</h5>
                    </div>
                    <InputComponent
                        type="number"
                        name="code"
                        placeholder="Entrer le Code à 4 chiffres"
                        className={"container-input-field-page-register"}
                        onChange={(value) => setCode(value)}
                        disabled={false}
                        required={true}
                        setFieldError={setError}
                        defaultValue={code}
                    />
                </div>
            </div>
            <div className="container-validate">
                <Button block className="btn-round btn-korix-orange" size="md" onClick={handleOnClick}>
                    Confirmer
                </Button>
                <Button block className="btn-round return" size="md" onClick={eventCancel}>
                    retour
                </Button>
            </div>
        </>
    )
}

export default CodePinValidateTransaction;

CodePinValidateTransaction.defaultProps = {
    operatorImg: PropTypes.string,
    sms: PropTypes.string,
    eventCancel: PropTypes.func,
    eventNext: PropTypes.func,
    setLoading:PropTypes.func,
};

CodePinValidateTransaction.propTypes = {
    operatorImg: PropTypes.string.isRequired,
    sms: PropTypes.string.isRequired,
    eventCancel: PropTypes.func.isRequired,
    eventNext: PropTypes.func.isRequired,
    setLoading:PropTypes.func.isRequired,
}
