import React from "react";
import {Modal, ModalBody, Button} from "reactstrap";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {ROUTE_PRIVATE_MARKETPLACE} from "../services/routes/Routes";
import BadgeImage from "./BadgeImage";

function ModalCheckIsOffline({isOfflineOpen, toggleIsOffline, loadingIsOffline, badge}) {

    const navigate = useNavigate();

    return (
        <Modal className="modal-badge" funk={true} isOpen={isOfflineOpen} toggle={toggleIsOffline} size="xl">
            <ModalBody>
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggleIsOffline}/>
                </div>
                <div className="modal-badge-button text-center">
                    <BadgeImage
                        badge={badge}
                        liveState={false}
                        showLiveIcon={false}
                        loading={loadingIsOffline}
                        onEvent={null}
                        className={"big"}
                        conserveContainer={true}
                    />
                    <div className="pt-3">
                        <p>{null !== badge ? badge.name : ""} n'est pas en live</p>
                    </div>
                    <div className="container-validate">
                        <Button block className="btn-round btn-korix-black" size="md" onClick={toggleIsOffline}>
                            Fermer
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalCheckIsOffline;

ModalCheckIsOffline.defaultProps = {
    isOfflineOpen: PropTypes.bool,
    toggleIsOffline: PropTypes.func,
    loadingIsOffline: PropTypes.bool,
    badge: PropTypes.object,
};

ModalCheckIsOffline.propTypes = {
    isOfflineOpen: PropTypes.bool.isRequired,
    toggleIsOffline: PropTypes.func.isRequired,
    loadingIsOffline: PropTypes.bool.isRequired,
};