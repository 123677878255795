import React, {useEffect, useState} from "react";
import {Container} from "reactstrap";
import {ROUTE_500, ROUTE_PRIVATE_HOME, ROUTE_REGISTRATION,} from "../services/routes/Routes";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {authUser, saveUserAuh} from "../services/stores/actions/UserActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import OtpInputComponent from "../components/otp/OtpInputComponent";
import {CODE_FIREBASE_LENGTH, ROLES_USER} from "../services/Constant";
import {User} from "../services/models/Users";
import {getUser, saveUser} from "../services/queries/Users";
import {getAuth, signOut} from "firebase/auth";
import app from "../services/Firebase";
const auth = getAuth(app);

function OtpFirebase() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {state} = useLocation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("Veuillez renseigner un code valide");
    const [visible, setVisible] = React.useState();
    document.documentElement.classList.remove("nav-open");

    useEffect(() => {
        if(!state) {
            navigate(ROUTE_500)
        }

    }, [state])

    const handleOnClick = async (otp) => {
        setError(false);
        if("" === otp || otp.length !== CODE_FIREBASE_LENGTH) {
            setError(true);
            setVisible(true);

            return;
        }

        setLoading(true);
        window.confirmationResult.confirm(otp).then( async (result) => {
            if(result) {
                try {
                    var response;
                    var user;
                    if(state.login) {
                        response = await getUser(state.phoneNumber);
                        const asCodePin = response.codePin === null || response.codePin === undefined || response.codePin === "";
                        user = new User(
                            response.firstName,
                            response.lastName,
                            response.phoneNumber,
                            response.role,
                            response.status, !asCodePin, response.badge, response.address
                        )
                    } else {
                        user = new User(
                            state.firstName,
                            state.lastName,
                            state.phoneNumber,
                            ROLES_USER,
                            true, false, null, null);

                        response = await saveUser(user);
                    }

                    if(response) {
                        dispatch(authUser(true));
                        dispatch(saveUserAuh(user));
                        await signOut(auth);
                        return navigate(ROUTE_PRIVATE_HOME);
                    } else {
                        if(state.login) {
                            dispatch(authUser(true));
                            return navigate(ROUTE_REGISTRATION);
                        } else {
                            setError(true);
                            setVisible(true);
                            setMessage("Le code envoyé et celui fournit sont différents");
                            setLoading(false);
                        }
                    }

                } catch(err) {
                    setError(true);
                    setMessage(err.messages);
                    setLoading(false);
                }
           } else {
                setLoading(false);
                setError(true);
                setMessage(result);
            }
        }).catch((err) => {
            setError(true);
            setMessage(err.message);
            setLoading(false);
        });
    }

    return (
        <div className="page-otp">
            <Container>
                <div className="icon-container-page-otp">
                    <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="icon"/>
                </div>
                <div className="envelope-container-page-otp max ml-auto mr-auto">
                    <FontAwesomeIcon icon={faEnvelope} className="icon"/>
                </div>
                <div className="container-form-page-otp text-center">
                    <div className="container-text-page-otp">
                        <h3>Un code unique vous a été envoyé par sms </h3> <br/>
                        <h5>Veuillez saisir le code unique qui a été envoyé sur le numéro { state ? state.phoneNumber : null }</h5>
                    </div>
                    <OtpInputComponent
                        autoFocus
                        length={CODE_FIREBASE_LENGTH}
                        error={error}
                        loading={loading}
                        visible={visible}
                        message={message}
                        setVisible={setVisible}
                        onValidate={handleOnClick}
                        className="otp-container max"
                        inputClassName="otp-input-firebase"
                        redirectForgot={null}
                    />
                </div>
            </Container>
        </div>
    );
}

export default OtpFirebase;
