import {Alert, Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {getFunctions, httpsCallable} from "firebase/functions";
import app from "../../services/Firebase";
import PropTypes from "prop-types";
import {
    ORANGE, PAYMENT_STATUS_FAILED, PAYMENT_STATUS_SUCCESS, formatPrice,
} from "../../services/Constant";
import axios from "axios";
import InputComponent from "../input/InputComponent";
import { CountdownTimer } from "./CountdownTimer";
const functions = getFunctions(app);

function IframeOrangeMoney ({countryCode, amount, eventCancel, onStatusChange,setLoading}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [phone, setPhone] = useState('');
    const [token, setToken] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [visible, setVisible] = React.useState(false);
    const [paymentCode, setPaymentCod] = useState(null);
    const [paymentTimesout, setPaymentTimesout] = useState(false)

    const AskPaymentAuth = httpsCallable(functions, 'askPaymentOmAuth');

    const totalAmountToPayed = (amount) => {
        const fees = (parseInt(amount)) / 100;
        return fees + parseInt(amount);
    }

    function generateReferralCode(length) {
        let result = '';
        const characters = '0123456789';
      
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters[randomIndex];
        }
      
        return result;
      };

      const referralCode = generateReferralCode(6).toString();

    const askPaymentOmPush = async () => {
        const result = await AskPaymentAuth();
        const tokenValue = result.data;
        setToken(tokenValue)
        const montantSansDecimales = Math.floor(amount);
        const id = "KRX" + referralCode;
        setTransactionId(id);


        try {
            var dataa = JSON.stringify({
                token:tokenValue,
                transactionId: id,
                msisdn: phone,
                montant: montantSansDecimales,
                motif: "test gcp korix",
            });
            console.log(dataa)
            const response = await fetch('https://us-central1-korixprod.cloudfunctions.net/askPaymentOmPush', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": tokenValue,
                    "Accept": "application/json",
                },
                body: dataa,
            });

            if (!response.ok) {
                throw new Error('La requête a échoué avec un statut ' + response.status);
            }

            const data = await response.json();
            console.log(data)
            setPaymentCod(data.code)
              
        } catch (error) {
            console.error(error);
        }
    };

    const [stop, setStop] = useState(false);
    let intervalId;
    
    const handleCheckStatus = async () => {
        const requestBody = {
            token: token,
            transactionId: transactionId
          };
        
          try {
            const response = await axios.post(
              'https://us-central1-korixprod.cloudfunctions.net/askPaymentOmGetStatus',
              requestBody
            );
        
            const result = response.data;

            const state = result.data.state;
            

            if(state === PAYMENT_STATUS_SUCCESS || state === PAYMENT_STATUS_FAILED ){
                onStatusChange(state)
                clearInterval(intervalId);
            }else if(paymentTimesout === true){
                onStatusChange(PAYMENT_STATUS_FAILED);
                setStop(true)
                clearInterval(intervalId);
            }
            
          } catch (error) {
            console.error(error);
          }

    }
     intervalId = setInterval(async () => {
         if(token !== "" && transactionId !== "") {
            handleCheckStatus();
        }
      }, 15000);

    const handleOnClick = () => {
        setError(false);

        if("" === phone || phone.length !== 8) {
            setError(true);
            setVisible(true);
            return;
        }

        askPaymentOmPush();
        setIsLoaded(true)
    }

    const handleTimeUp = () => {
        setPaymentTimesout(true)
      };
      

    return (
        <>
            {
                error && (
                    <Alert color="danger" isOpen={visible} toggle={() => setVisible(!visible)}>
                        Veuillez renseigner un numéro de téléphone valide
                    </Alert>
                )
            }
            <div className={"image-container_payment-border"}>
                <img src={require("../../assets/img/om-icon.png")} alt={"mali-flag"}/>
            </div>

            <div className="container-phone-page-register pt-5">
                <InputComponent
                    type="tel"
                    name="phone"
                    placeholder="Numéro de téléphone"
                    className={"container-input-field-page-register"}
                    onChange={(value) => setPhone(value)}
                    disabled={false}
                    required={true}
                    code={
                        <div className="container-input-field-phone-modal">
                            <div className="container-code-icon-modal">
                                <span>{countryCode}</span>
                            </div>
                        </div>
                    }
                    defaultValue={phone}
                />
            </div>
            <div className={"image-container-total-border"}>
                <span className={"price"}>Total à payer : {formatPrice(amount)} <small>FCFA</small></span>
            </div>
            <div className="container-validate">
                {
                    isLoaded ? (
                        <div>
                            <CountdownTimer
                                seconds={60}
                                size={80}
                                strokeBgColor="black"
                                strokeColor="lightblue"
                                strokeWidth={3}
                                onTimeUp={handleTimeUp}
                            />
                        </div>
                    ) :
                        <Button block className="btn-round btn-korix-orange" size="md" onClick={handleOnClick}>
                            Payer
                        </Button>
                }
                <Button block className="btn-round return" size="md" onClick={eventCancel}>
                    retour
                </Button>
            </div>
        </>
    )
}

export default IframeOrangeMoney;

IframeOrangeMoney.defaultProps = {
    amount: PropTypes.string,
    eventCancel: PropTypes.func,
    onStatusChange: PropTypes.func
};

IframeOrangeMoney.propTypes = {
    amount: PropTypes.string.isRequired,
    eventCancel: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
}
