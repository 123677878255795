
// Creat Payment Intent
export const createPaymentIntent = async (userId, amount, setPaymentIntentId, setPaymentIntentToken, hub2) => {

    try {

        const response = await fetch('https://us-central1-korixprod.cloudfunctions.net/hub2createPaymentIntent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId,
                amount,
                hub2,
            }),
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log('Payment Intent created successfully:', responseData);
            setPaymentIntentId(responseData.id);
            setPaymentIntentToken(responseData.token);
        } else {
            console.error('Error creating Payment Intent:', responseData);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

//Lauch payment
export const processPayment = async (onFailedRedirectionUrl, onSuccessRedirectionUrl, paymentIntentToken, country, operator, numberInput, code, hub2, paymentIntentId) => {

    const response = await fetch('https://us-central1-korixprod.cloudfunctions.net/hub2ProcessPayment', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'merchantId': hub2.HUB2_merchantId,
            'environment': hub2.HUB2_environment
        },
        body: JSON.stringify({
            onFailedRedirectionUrl,
            onSuccessRedirectionUrl,
            paymentIntentToken,
            country,
            operator,
            numberInput,
            code,
            hub2,
            paymentIntentId,
        }),
    });

    const responseData = await response.json();

    if (response.ok) {
        console.log('Payment processed', responseData);
    } else {
        console.log('Error processing payment');
    }
}

export const verifyOtp = async (paymentIntentId, paymentIntentToken, code, onStatusChange) => {
    try {
        const response = await fetch('https://us-central1-korixprod.cloudfunctions.net/hub2VerifyOtp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                paymentIntentId,
                paymentIntentToken,
                code,
            }),
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log('Verification successful:', responseData);

        } else {
            console.error('Error verifying OTP:', responseData);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}
