
const initialState = {
  items: JSON.parse(localStorage.getItem('cartItems')) || [],
  total: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const existingItem = state.items.find(item => item.name + item.badgeSerial === action.payload.name + action.payload.badgeSerial);
      if (existingItem) {
        return state;
      } else {
      const updatedItems = [...state.items, action.payload];
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return {
        ...state,
        items: updatedItems,
        total: state.total + action.payload.amount,
      };
    }
    case 'REMOVE_FROM_CART':
      const filteredItems = state.items.filter(item => item.key !== action.payload);
      localStorage.setItem('cartItems', JSON.stringify(filteredItems));
      return {
        ...state,
        items: filteredItems,
        total: state.total - action.payload.amount,
      };
    case 'EMPTY_CART':
      localStorage.setItem('cartItems', JSON.stringify([]));
      return {
        ...state,
        items: [],
        total: 0,
      };
    default:
      return state;
  }
};

export default cartReducer;
