import React from "react";
import {Modal, ModalBody} from "reactstrap";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {listCountryCode} from "../services/Constant";

export function ModalCountryCode({isOpen, toggle, setCountryCode}) {

    const handlerSetCountryCode = (code) => {
        setCountryCode(code);
        toggle();
    }

    return (
        <Modal className="modal-country" funk={true} isOpen={isOpen} toggle={toggle}>
            <ModalBody>
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle}/>
                </div>

                <div className="modal-data">
                    {
                        listCountryCode.map(code => {
                            return <div onClick={() => handlerSetCountryCode(code)}>
                                <h4>{code}</h4>
                                <hr/>
                            </div>
                        })

                    }
                </div>
            </ModalBody>
        </Modal>
    )
}

ModalCountryCode.defaultProps = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    setCountryCode: PropTypes.func,
};

ModalCountryCode.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    setCountryCode: PropTypes.func.isRequired,
};