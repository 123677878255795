import { Navigate } from 'react-router-dom';
import {ROUTE_404, ROUTE_LOGIN} from "./Routes";

import PropTypes from "prop-types";
import {useSelector} from "react-redux";

function Private({ children, roles }) {
    const userReducers = useSelector((state) => state.userReducers);
    const { isAuthenticated, user } = userReducers;
    const userHasRequiredRole = !!(user.role && roles.includes(user.role))

    if (!isAuthenticated) {
        return <Navigate to={ROUTE_LOGIN}/>
    }

    if (isAuthenticated && !userHasRequiredRole) {
        return <Navigate to={ROUTE_404}/>
    }

    return children;
}


export default Private;
Private.defaultProps = {
    children: "",
};

Private.propTypes = {
    children: PropTypes.object,
};