import BadgeImage from "./BadgeImage";
import React, { useState, useEffect } from "react";
import ModalAskBadge from "./ModalAskBadge";
import ModalCheckIsOffline from "./ModalCheckIsOffline";
import { useNavigate } from "react-router-dom";
import { ROUTE_PRIVATE_PLAY_STREAM } from "../services/routes/Routes";
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import app from "../services/Firebase";
import { useDispatch, useSelector } from "react-redux";
import { setPlayerBadgeImg, setPlayerRoomID, setPlayerStreamID } from "../services/stores/actions/PlayRoomidAction";
import {formatDateDMYFrench} from "../services/Constant";
const db = getFirestore(app);
const badgesCollectionRef = collection(db, 'badge-collection');

function LiveComponent() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [isOfflineOpen, setIsOfflineOpen] = useState(false);
    const [badgeSelected, setBadgeSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [lives, setLives] = useState([]);
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;

    useEffect(() => {
        fetchBadges()
    }, []);


    const fetchBadges = async () => {
        const currentDate = formatDateDMYFrench(new Date);
        const q = query(badgesCollectionRef, where('isCreator', '==', true), where("dateEnd", ">", currentDate));
        const badgesCollectionDocs = await getDocs(q);
        if (badgesCollectionDocs.docs.length > 0) {
            var collectionBadges = [];
            badgesCollectionDocs.docs
                .map((badge, index) => {
                    if (!badge.data().isBadgePrivate || hasMatchingUserPrivelege(badge)) {
                        collectionBadges[index] = {
                            key: badge.id,
                            name: badge.data().name,
                            collectionName: badge.data().collectionName,
                            amount: badge.data().amount,
                            isListed: badge.data().listed,
                            contractAddress: badge.data().address,
                            imageUrl: badge.data().imageUrl,
                            dateStart: badge.data().dateStart,
                            dateEnd: badge.data().dateEnd,
                            advantage: badge.data().advantages,
                            category: badge.data().category,
                            details: badge.data().details,
                            collectionImg: badge.data().collectionImg,
                            advantages: badge.data().advantages,
                            badgeSerial: badge.data().badgeSerial,
                            discount: badge.data().referralDiscount,
                            ownerEmail: badge.data().ownerEmail,
                            isreferrable: badge.data().isreferrable,
                            onLine: badge.data().onLine,
                            liveImageUrl: badge.data().liveImageUrl,
                            Mint: "N/A",
                        }
                    } else {
                        collectionBadges[index] = null;
                    }

                    return collectionBadges;
                });

            collectionBadges = collectionBadges.filter(badge => badge !== null);

            setLives(collectionBadges)
        }
        setLoading(false);
    }

    function hasMatchingUserPrivelege(badge) {
        const badgeData = badge.data();

        if (badgeData.privilegedUsersList) {

            for (const key in badgeData.privilegedUsersList) {
                const privilegedUser = badgeData.privilegedUsersList[key];

                if (privilegedUser.number === user.phoneNumber) {
                    return true;
                }
            }
        }
        return false;
    }

    const fetchRoomID = async (selectedBadgeId) => {
        try {
            const streamDocRef = doc(db, "stream-collection", selectedBadgeId);
            const docSnapshot = await getDoc(streamDocRef);

            if (docSnapshot.exists()) {
                return docSnapshot.data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération du roomID :", error);
            return null;
        }
    };

    const handlerSelectBadge = async (badge) => {
        setBadgeSelected(badge);
        const selectedBadgeId = badge.badgeSerial;

        const data = await fetchRoomID(selectedBadgeId);

        const isOnline = badge.onLine;
        if (isOnline) {
            const roomID = data.roomID;
            const streamID = data.streamID;

            if (roomID) {
                dispatch(setPlayerRoomID(roomID));
                dispatch(setPlayerStreamID(streamID));
                dispatch(setPlayerBadgeImg(badge.imageUrl));
                navigate(ROUTE_PRIVATE_PLAY_STREAM);
            } else {
                setIsOfflineOpen(true);
            }
        } else {
            setIsOfflineOpen(true);
        }
    }

    return (
        <div className="component-live">
            {
                lives.length > 0 ?
                    <>
                        <h4>Streaming</h4>
                        <div className="block-component-live">
                            {
                                lives.map((badge, index) => {
                                    return (
                                        <BadgeImage
                                            key={index}
                                            badge={badge}
                                            liveState={badge.onLine}
                                            showLiveIcon={true}
                                            loading={loading}
                                            onEvent={() => handlerSelectBadge(badge)}
                                            className={null}
                                        />
                                    )
                                })
                            }
                        </div>
                        <ModalAskBadge isOpen={isOpen} loading={loading} badge={badgeSelected} toggle={() => setIsOpen(!isOpen)} />
                        <ModalCheckIsOffline isOfflineOpen={isOfflineOpen} loadingIsOffline={loading} badge={badgeSelected} toggleIsOffline={() => setIsOfflineOpen(!isOfflineOpen)} />
                    </> : null
            }
        </div>
    )
}

export default LiveComponent;