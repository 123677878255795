import React, {useState, useEffect} from "react";
import {Button, Container} from "reactstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import OtpInputComponent from "../../components/otp/OtpInputComponent";
import {CODE_FIREBASE_LENGTH} from "../../services/Constant";
import {
    createUserWithEmailAndPassword,
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    signOut
} from "firebase/auth";
import app from "../../services/Firebase";
import {ROUTE_500, ROUTE_OTP_CODE_PIN} from "../../services/routes/Routes";
import {useSelector} from "react-redux";
import {getFunctions, httpsCallable} from "firebase/functions";
const functions = getFunctions(app);
const auth = getAuth(app);

function OtpPrivateFirebase() {

    const navigate = useNavigate();
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;
    const {state} = useLocation();
    const [loading, setLoading] = useState(false);
    const [loadingOtpSend, setLoadingOtpSend] = useState(false);
    const [error, setError] = useState(false);
    const [result, setResult] = useState(false);
    const [message, setMessage] = useState("Veuillez renseigner un code valide");
    const [visible, setVisible] = React.useState();
    document.documentElement.classList.remove("nav-open");

    useEffect(() => {
        if(!state) {
            navigate(ROUTE_500)
        }

    }, [state])
    const handleOnClickSendOtp = async () => {
        setLoadingOtpSend(true);
        let verify = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
            }
        }, auth);

        signInWithPhoneNumber(auth, user.phoneNumber, verify).then((result) => {
            setResult(result);
            setLoadingOtpSend(false);
        }).catch((err) => {
            setResult(null);
            setError(true);
            setMessage(err.message);
            setLoadingOtpSend(false);
        });
    }

    const handleOnClick = async (otp) => {
        setError(false);
        if("" === otp || otp.length !== CODE_FIREBASE_LENGTH) {
            setError(true);
            setVisible(true);

            return;
        }

        if(!result) {
            setError(true);
            setVisible(true);
            return;
        }

        setLoading(true);
        result.confirm(otp).then( async (result) => {
            try {
                if(state.reset) {
                    const deleteUser = httpsCallable(functions, 'deleteUser');
                    const responseDelete = await deleteUser({
                        userUuid: user.uuid
                    });

                    if(responseDelete.data.success) {
                        let errorMessage = null;

                        try {
                            await createUserWithEmailAndPassword(auth, state.email, state.pwd);
                            await signOut(auth);

                            return navigate(state.whereIGo, {state: {reset: true, resetError: false, whereIComeFrom: state.whereIComeFrom, state: state}});
                        } catch (e) {
                            console.log(error.code)
                            if (error.code === "auth/weak-password") {
                                errorMessage = " Le mot de passe est trop faible. il doit contenir au minimum 6 caracères, Une majuscule, Un chiffre, et un caractère spécial.";
                            }

                            return navigate(state.whereIGo, {state: {reset: true, resetError: true, whereIComeFrom: state.whereIComeFrom, errorMessage: errorMessage}});
                        }
                    } else {
                        return navigate(state.whereIGo, {state: {reset: true, resetError: true, whereIComeFrom: state.whereIComeFrom, errorMessage: "Une erreur s'est produite. Veuillez réessayer ultérieurement."}});
                    }
                } else {
                    await signOut(auth);
                    navigate(ROUTE_OTP_CODE_PIN, {state: state});
                }

            } catch(err) {
                setError(true);
                setVisible(true);
                setMessage(err.messages);
                setLoading(false);
            }
        }).catch((err) => {
            setError(true);
            setVisible(true);
            setMessage(err.messages);
            setLoading(false);
        })
    }

    return (
        <div className="page-otp">
            <Container>
                <div className="icon-container-page-otp">
                    <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="icon"/>
                </div>
                <div className="container-form-page-otp pt-5 text-center">
                    <div className="container-text-page-otp">
                        <h3>Appuyez sur 'Recevoir le code unique' et entrez le code unique que vous avez reçu par SMS.</h3>
                    </div>
                    <div className="container-form-page-otp">
                        <div className="container-validate-page-otp pt-3">
                            {
                                loadingOtpSend ?
                                    <div className="uil-reload-css-reload-small">
                                        <div></div>
                                    </div> :
                                    <Button block className="btn-round btn-korix-black" onClick={handleOnClickSendOtp}>Recevoir le code unique</Button>
                            }
                        </div>
                    </div>
                    <OtpInputComponent
                        autoFocus
                        length={CODE_FIREBASE_LENGTH}
                        error={error}
                        loading={loading}
                        visible={visible}
                        message={message}
                        setVisible={setVisible}
                        onValidate={handleOnClick}
                        className="otp-container max"
                        inputClassName="otp-input-firebase"
                        redirectForgot={null}
                        information={null}
                    />
                </div>
            </Container>
            <div id="recaptcha-container"></div>
        </div>
    );
}

export default OtpPrivateFirebase;
