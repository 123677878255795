import React, { useEffect, useState } from "react";
import "../../ReferralDetail.css";
import { ROUTE_PRIVATE_USER_PROFILE } from "../../services/routes/Routes";
import {useLocation, useNavigate} from "react-router-dom";
import {
  collection,
  getFirestore,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from 'firebase/firestore';
import app from "../../services/Firebase";
import { useSelector } from "react-redux";


const db = getFirestore(app);
const usersCollection = collection(db, 'users');

const ReferralDetails = () => {
  const navigate = useNavigate();
  const [referralCode, setReferralCode] = useState('');
  const [referralCount, setReferralCount] = useState(0);
  const userReducers = useSelector((state) => state.userReducers);
  const { user } = userReducers;
  const {state} = useLocation();

  useEffect(() => {
    fetchUser()
  }, []);

  const fetchUser = async () => {
    const userDocRef = doc(usersCollection, user.phoneNumber);

    const userDocSnapshot = await getDoc(userDocRef)
    const userData = userDocSnapshot.data();

    setReferralCode(userData.referralCode || '');
    setReferralCount(userData.referralCount || 0);

  }

  const createReferralCode = () => {
    if (user) {
      const newReferralCode = `${user.phoneNumber}-${user.phoneNumber.substring(0, 6)}`;

      const userDocRef = doc(usersCollection, user.phoneNumber);

      updateDoc(userDocRef, { referralCode: newReferralCode })
        .then(() => {
          setReferralCode(newReferralCode);
          console.log('Code de référence créé avec succès.');
        })
        .catch((error) => {
          console.error('Erreur lors de la création du code de référence :', error);
        });
    }
  };

  return (
    <div className="refferaldetails">
      <div className="overlap-wrapper">
        <div className="overlap">
          <img
            className="image"
            alt="Image"
            src={require("../../assets/img/image-8.png")} 
          />
          <div className="back-global" />
          <div className="back-user-data" />
          <div className="text-wrapper">Mon code de référent</div>
          <div className="user-data">
            <div className="overlap-group">
              <div className="nom-prenom">
                <div className="div">{user.firstName + " "} {user.lastName}</div>
              </div>
              <div className="button">
                <p className="p">{user.phoneNumber}</p>
              </div>
            </div>
            <button className="overlap-group-wrapper">
              <div className="overlap-group-2">
                <img
                  className="vuesax-linear-copy"
                  alt="Vuesax linear copy"
                  src={require("../../assets/img/icon-add.png")}
                />
                <div className="text-wrapper-2"
                     onClick={() => {navigator.clipboard.writeText(referralCode)}}
                >{referralCode}</div>
              </div>
            </button>
            <div className="ref-qual">
              <div className="overlap-2">
                <p className="partage-ton-code-r-f">
                  Partage ton code Référent aXes à tes amis ou à ta communauté. En les aidant à obtenir un badge Korix moins cher, et gagne jusqu'à
                </p>
                <div className="navbar">
                  <p className="text text-center">
                      <span className="span">
                        Grâce au programme de référence <br/>
                      </span>
                      <span className="text-wrapper-3">
                        #KorixPartage <br/>
                      </span>
                      <span className="span">
                        fais profiter tes amis d'un <br/>
                      </span>

                      <span className="text-wrapper-3">rabais de 20%</span> {" "}
                      <span className="span">
                        sur le badge Korix.
                      </span>

                  </p>
                </div>
                <div className="group">
                  <div className="div-wrapper">
                    <div className="text-wrapper-4">20.000 Points</div>
                  </div>
                </div>
                <div className="group-2">
                  <div className="overlap-3">
                    <div className="text-wrapper-5">Confirmé</div>
                  </div>
                  <div className="text-wrapper-6">100 pts</div>
                  <div className="overlap-4">
                    <div className="text-wrapper-7">1.000 pts</div>
                    <div className="group-3">
                      <div className="overlap-group-3">
                        <div className="text-wrapper-8">Silver</div>
                      </div>
                    </div>
                  </div>
                  <div className="overlap-5">
                    <div className="text-wrapper-9">10.000 pts</div>
                    <div className="group-3">
                      <div className="overlap-6">
                        <div className="text-wrapper-8">Gold</div>
                      </div>
                    </div>
                  </div>
                  <div className="overlap-7">
                    <div className="text-wrapper-10">20.000 pts</div>
                    <div className="group-3">
                      <div className="overlap-8">
                        <div className="text-wrapper-11">Diamond</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-4">
                  <div className="earn-process">
                    <img
                      className="vuesax-linear-people"
                      alt="Vuesax linear people"
                      src={require("../../assets/img/people.png")}
                    />
                    <p className="text-wrapper-12">Aide-les à acheter un badge Korix</p>
                    <div className="flexcontainer">
                      <p className="span-wrapper">
                        <span className="text-wrapper-13">
                          Donne des
                          <br />
                        </span>
                      </p>
                      <p className="span-wrapper">
                        <span className="text-wrapper-13">rabais</span>
                      </p>
                    </div>
                    <div className="flexcontainer-2">
                      <p className="span-wrapper">
                        <span className="text-wrapper-13">
                          Gagne des
                          <br />
                        </span>
                      </p>
                      <p className="span-wrapper">
                        <span className="text-wrapper-13">Points</span>
                      </p>
                    </div>
                    <p className="text-wrapper-14">Envoie ton code à tes amis</p>
                  </div>
                  <div className="group-5">
                    <div className="flexcontainer-wrapper">
                      <div className="flexcontainer-3">
                        <p className="text-2">
                          <span className="text-wrapper-15">
                            Les points de références sont échangeables par niveau déverrouillé
                            <br />
                          </span>
                        </p>
                        <p className="text-2">
                          <span className="text-wrapper-15">
                            <br />
                          </span>
                        </p>
                        <p className="text-2">
                          <span className="text-wrapper-16">1 pts = 250 fcfa</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-wrapper-17">Niveaux à déverrouiller</div>
                  <div className="text-wrapper-18">Mes points références: {referralCount}</div>
                  <div className="status">
                    <img
                      className="vuesax-linear-shield"
                      alt="Vuesax linear shield"
                      src={require("../../assets/img/shield-cross.png")}
                    />
                    <div className="text-wrapper-19">Niveau</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-6">
            <img
              className="img"
              alt="Vuesax linear shield"
              src={require("../../assets/img/shield-tick0.png")}
            />
            <img
              className="shield-tick"
              alt="Shield tick"
              src={require("../../assets/img/shield-tick.png")}
            />
            <img
              className="shield-tick-2"
              alt="Shield tick"
              src={require("../../assets/img/shield-tick1.png")}
            />
            <img
              className="shield-tick-3"
              alt="Shield tick"
              src={require("../../assets/img/shield-tick2.png")}
            />
          </div>
          <div className="profile-pic">
            <div className="vuesax-linear-user-wrapper">
              <img
                className="vuesax-linear-user"
                alt="Vuesax linear user"
                src={require("../../assets/img/userr.png")}
              />
            </div>
          </div>
          <div className="group-7" onClick={() => navigate(ROUTE_PRIVATE_USER_PROFILE, {state: state})}>
            <div className="arrow">
              <img
                className="icon"
                alt="Icon"
                src={require("../../assets/img/icon-arrow.png")}
              />
            </div>
            <img
              className="LOGO-ACCESS-WHITE"
              alt="Logo ACCESS WHITE"
              src={require("../../assets/img/Brand/ACCESS_LOGO_WHITE.png")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralDetails;