import {Alert, Button} from "reactstrap";
import React, {useState} from "react";
import PropTypes from "prop-types";
import InputComponent from "../input/InputComponent";
import {
    COUNTRY_CODE_SN,
    formatPrice,
    STEP_HUB2_PAYMENT,
    STEP_PHONE,
    STEP_SMS_CODE, STEP_WAVE, WAVE
} from "../../services/Constant";

function NumberComponent({operatorInformation, countrySelected, amount, eventBack, eventNext, setLoading}) {
    const [phone, setPhone] = useState('');
    const [error, setError] = useState(false);
    const [visible, setVisible] = React.useState(false);
    const [fieldError, setFieldError] = useState(false);

    const totalAmountToPayed = (amount, thisFees) => {
        const fees = (parseInt(amount) * parseInt(thisFees)) / 100;
        return fees + parseInt(amount);
    }

    const handlerNextStep = () => {
        setError(false);
        if("" === phone || fieldError) {
            setError(true);
            setVisible(true);
            return;
        }

        const phoneNumber = `${countrySelected.countryCode}${phone}`;
        if(operatorInformation.operator === WAVE) {
            eventNext(STEP_PHONE, STEP_WAVE, phoneNumber);
        } else {
            if(countrySelected.country === COUNTRY_CODE_SN) {
                eventNext(STEP_PHONE, null, phoneNumber);
                setLoading(true);
            } else {
                eventNext(STEP_PHONE, STEP_SMS_CODE, phoneNumber);
            }
        }
    }

    return (
        <>
            {
                error && (
                    <Alert color="danger" isOpen={visible} toggle={() => setVisible(!visible)}>
                        Veuillez renseigner un numéro de téléphone valide
                    </Alert>
                )
            }
            <div className={"image-container_payment-border"}>
                <img src={operatorInformation.flag} alt="image sélectionnée"/>
            </div>

            <div className="container-phone-page-register pt-5">
                <InputComponent
                    type="tel"
                    name="phone"
                    placeholder="Numéro de téléphone"
                    className={"container-input-field-page-register"}
                    onChange={(value) => setPhone(value)}
                    disabled={false}
                    required={true}
                    setFieldError={setFieldError}
                    code={
                        <div className="container-input-field-phone-modal">
                            <div className="container-code-icon-modal">
                                <span>{countrySelected.countryCode}</span>
                            </div>
                        </div>
                    }
                    defaultValue={phone}
                />
            </div>

            <div className={"image-container-total-border"}>
                <span className={"price"}>Total à payer : {formatPrice(totalAmountToPayed(amount, operatorInformation.fees))} <small>FCFA</small></span>
            </div>

            <div className="container-validate">
                <Button block className="btn-round btn-korix-orange" size="md" onClick={handlerNextStep}>
                    Payer
                </Button>
                <Button block className="btn-round return" size="md" onClick={() => eventBack(STEP_HUB2_PAYMENT)}>
                    retour
                </Button>
            </div>
        </>
    )
}

export default NumberComponent;

NumberComponent.defaultProps = {
    countryCode: PropTypes.string,
    amount: PropTypes.string,
    operatorInformation: PropTypes.object,
    eventBack: PropTypes.func,
    eventNext: PropTypes.func
};

NumberComponent.propTypes = {
    countryCode: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    operatorInformation: PropTypes.object.isRequired,
    eventBack: PropTypes.func.isRequired,
    eventNext: PropTypes.func.isRequired,
}