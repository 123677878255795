import React, {useState} from "react";
import {
    Button,
    Container,
    Row,
    Col,
    Alert,
    Form
} from "reactstrap";
import Switch from 'react-bootstrap-switch';
import {Link, useNavigate} from "react-router-dom";
import {ROUTE_LOGIN, ROUTE_OTP_FIREBASE, ROUTE_PRIVATE_HOME, ROUTE_TERM_AND_CONDITION} from "../services/routes/Routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {getAuth, RecaptchaVerifier, signInWithPhoneNumber, signOut} from "firebase/auth";
import app from "../services/Firebase";
import InputComponent from "../components/input/InputComponent";
import {ModalCountryCode} from "../components/ModalCountryCode";
import {useDispatch, useSelector} from "react-redux";
import {User} from "../services/models/Users";
import {ROLES_USER} from "../services/Constant";
import {saveUser} from "../services/queries/Users";
import {saveUserAuh} from "../services/stores/actions/UserActions";
const auth = getAuth(app);

function Registration() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [countryCode, setCountryCode] = useState('+223');
    const [phone, setPhone] = useState('');
    const [accept, setAccept] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [fieldError, setFieldError] = useState(false);
    const [message, setMessage] = useState("");
    const [visible,setVisible] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    document.documentElement.classList.remove("nav-open");

    const handlerSubmit = async () => {
        setError(false);
        setVisible(false);
        if('' === firstName || '' === lastName || '' === phone || fieldError) {
            setError(true);
            setVisible(true);
            setMessage("Tous les champs sont obligatoires");
            return;
        }

        if(!accept) {
            setError(true);
            setVisible(true);
            setMessage("Veuillez accepter les termes et conditions");
            return;
        }

        setFieldError(false);
        setLoading(true);
        const phoneNumber = `${countryCode}${phone}`;

        if(user.isAuthenticated) {
            const userModel = new User(
                firstName,
                lastName,
                phoneNumber,
                ROLES_USER,
                true, false, null, null);

            const response = await saveUser(userModel);
            if(response) {
                dispatch(saveUserAuh(user));
                await signOut(auth);
                return navigate(ROUTE_PRIVATE_HOME);
            } else {
                setLoading(false);
                setError(true);
                setVisible(true);
            }
        } else {
            try {
                let recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                    }
                }, auth);

                window.confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
                navigate(ROUTE_OTP_FIREBASE, { state: {
                        firstName: firstName,
                        lastName: lastName,
                        phoneNumber: phoneNumber
                    }});
            } catch (error) {
                setLoading(false);
                setError(true);
                setVisible(true);
            }
        }
    }

    return (
        <div className="page-register">
            <div className="page-header-page-register">
                <Container>
                    <Form>
                        <div className="container-field-page-register ml-auto mr-auto text-center">
                            <div className="container-header-page-register">
                                <div className="icon-container-page-register">
                                    <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(ROUTE_LOGIN)} className="icon"/>
                                </div>
                            </div>
                            <div className="logo-container-page-register text-center pb-5">
                                <img src={require("../assets/img/Brand/ACCESS_LOGO_WHITE_nobg.png")} alt={"logo-korix"}/>
                            </div>
                            <h5>Créer un compte</h5>
                            {
                                error && (
                                    <Alert color="danger" isOpen={visible} toggle={() => setVisible(false)}>
                                        {message}
                                    </Alert>
                                )
                            }
                            <Form>
                                <InputComponent
                                    type="text"
                                    defaultValue={lastName}
                                    name="lastName"
                                    placeholder="Nom"
                                    className={"container-input-field-page-register"}
                                    onChange={(value) => setLastName(value)}
                                    disabled={false}
                                    required={true}
                                    setFieldError={setFieldError}
                                />

                                <InputComponent
                                    type="text"
                                    defaultValue={firstName}
                                    name="firstName"
                                    placeholder="Prénom"
                                    className={"container-input-field-page-register"}
                                    onChange={(value) => setFirstName(value)}
                                    disabled={false}
                                    required={true}
                                    setFieldError={setFieldError}
                                />

                                <div className="container-phone-page-register">
                                    <InputComponent
                                        type="tel"
                                        defaultValue={phone}
                                        name="phone"
                                        placeholder="Numéro de téléphone"
                                        className={"container-input-field-page-register"}
                                        onChange={(value) => setPhone(value)}
                                        disabled={false}
                                        required={true}
                                        setFieldError={setFieldError}
                                        code={
                                            <div className="container-code-icon-page-login" onClick={() => setIsOpen(!isOpen)}><span>{countryCode}</span></div>
                                        }
                                    />
                                </div>

                                <Row className="term-condition-page-register">
                                    <Col xs={3}>
                                        <Switch
                                            defaultValue={accept}
                                            offColor="primary"
                                            offText={<i className="nc-icon nc-simple-remove" />}
                                            onColor="primary"
                                            onText={<i className="nc-icon nc-check-2" />}
                                            onChange={() => setAccept(!accept)}
                                        />
                                    </Col>
                                    <Col xs={9}>
                                        <small> je reconnais avoir lu et accepté l'ensemble des <Link to={ROUTE_TERM_AND_CONDITION} >conditions générales d’utilisation</Link></small>
                                    </Col>
                                </Row>
                                <div className="container-validate-page-register">
                                    {
                                        loading ?
                                            <div className="uil-reload-css-reload-small">
                                                <div></div>
                                            </div> :
                                            <Button block className="btn-round btn-korix-black" onClick={handlerSubmit}>S'inscrire</Button>
                                    }
                                </div>
                            </Form>
                            <div className="container-signup-page-register text-center">
                                <div>
                                    <span className="text">Vous avez déjà un compte ?</span>
                                </div>
                                <div className="pt-3">
                                    <Link className="signup" to={ROUTE_LOGIN}>Se connecter</Link>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Container>
            </div>
            <ModalCountryCode
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                setCountryCode={setCountryCode}
            />
            <div id="recaptcha-container"></div>
        </div>
    );
}

export default Registration;
