import React from "react";
import {ROUTE_PRIVATE_CART} from "../services/routes/Routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

function CartComponent({className}) {
    const navigate = useNavigate();
    const cartItems = useSelector((state) => state.cart.items);

    return (
        <div className="cart-page">
            <a className={className ? `button-cart ${className}` : "button-cart"} onClick={() => navigate(ROUTE_PRIVATE_CART)}>
                <div className="div-icon">
                    <strong>{cartItems.length}</strong> <FontAwesomeIcon icon={faCartPlus}/>
                </div>
                <div>
                    <span className="text-uppercase">Mon panier</span>
                </div>
            </a>
        </div>
    );
}

export default CartComponent;

CartComponent.defaultProps = {
    className: PropTypes.string
};