import './App.css';
import React, {useEffect} from 'react';
import {Routes, Route, Navigate, useNavigate} from "react-router-dom";
import {ROUTE_PRIVATE_WALLET, ROUTE_WELCOME, routes} from "services/routes/Routes";
import Private from "services/routes/Private";
import ModalBlockWeb from "./components/ModalBlockWeb";
import {saveBadgesToCache} from "./services/stores/IndexDB";
import * as Sentry from "@sentry/react";

function App() {
    const navigate = useNavigate();
    useEffect( () => {
        if(navigator.onLine) {

           saveBadgesToCache().then((response) => {
               if(response) {
                   navigate(ROUTE_PRIVATE_WALLET)
               }
           } );
        }
    }, []);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.child);
            }

            if (route.path) {
                if(route.private) {
                    return <Route
                        exact
                        path={route.path}
                        element={
                            <Private roles={route.roles}>
                                {route.component}
                            </Private>
                        }
                        key={route.key}
                    />;
                }

                return <Route exact path={route.path} element={route.component} key={route.key} />;
            }

            return null;
        });

    return (
        <>
            <ModalBlockWeb />
            <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to={ROUTE_WELCOME} />} />
            </Routes>
        </>
    );
}

export default Sentry.withProfiler(App);
