import React, {useState, useEffect} from "react";
import {Modal, ModalBody} from "reactstrap";
import {detectMobileDevices} from "../services/Constant";

function ModalBlockWeb() {
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        if(!detectMobileDevices()) {
            setIsDesktop(true);
        }
    }, [])

    return (
        <Modal className="modal-badge dark" funk={true} isOpen={isDesktop} size="xl">
            <ModalBody className="dark">
                <div className={"modal-badge-button text-center"}>
                    <img src={require("../assets/img/Brand/ACCESS_LOGO_WHITE_nobg.png")} alt={"logo-korix"} width={"30%"}/>
                    <div className="pt-5">
                        <h3>Vous souhaite la bienvenue</h3> <br/>
                        <p>Notre site a été spécialement conçu pour les écrans mobiles, offrant une navigation fluide, des fonctionnalités tactiles intuitives et une interface adaptée à votre appareil. En utilisant un smartphone ou une tablette, vous découvrirez notre contenu de la manière la plus conviviale qui soit.</p>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalBlockWeb;
