import {CODE_PIN_LAST_GENERATE} from "../types/CodePinTypes";

export const setGenerateLastCodePin = () => (dispatch, getState) => {
    const timestamp = Date.now();

    try {
        dispatch({
            type: CODE_PIN_LAST_GENERATE,
            payload: {
                lastTimeStampCodePinGenerate: timestamp,
                message: `User codepin is ok `,
            },
        });
    } catch (error) {
        console.log("Error", error);
    }
};