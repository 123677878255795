import app from "../Firebase";
import {
    addDoc,
    collection,
    doc, getDoc,
    getDocs,
    getFirestore,
    increment,
    query,
    setDoc,
    updateDoc,
    where
} from "firebase/firestore";
import {Badge} from "../models/Badge";
import {User} from "../models/Users";
import {formatDateDMYFrench} from "../Constant";

const db = getFirestore(app);
const userCollection = collection(db, 'users');
const referredCountCollection = collection(db, 'referred_count');
const badgeMintCollection = collection(db, 'badges-mint');
const badgeCollection = collection(db, 'badge-collection');


export async function saveBadge (badge: Badge)  {
    const userDocRef = doc(userCollection, badge.getPublicID());
    const collectionUserBadge = collection(userDocRef, 'user-badge');
    const mintDocID = doc(collectionUserBadge, badge.getBadgeSerial());
    await setDoc(mintDocID, badge.getBadge())
}

export async function saveBadgeMint (badge: Badge)  {
    const badgeDocID = doc(badgeMintCollection, badge.getBadgeSerial() + badge.getPaymentTxiId());
    await setDoc(badgeDocID, badge.getBadge())
}

export async function saveReferralCount (badge: Badge) {
    const userDocRef = doc(userCollection, badge.getReferrerID());
    await updateDoc(userDocRef, { referralCount: increment(1) });
    const referredDocData = {referralID: badge.getReferralID(), referredID: badge.getPublicID(), referrerUid: badge.getReferrerID(), date: new Date()};
    await addDoc(referredCountCollection, referredDocData);
}

export async function giveBadgeForANewUser(badgeSerial: String, user: User, userPublicKey: String) {
    const badgeCollectionRef = doc(badgeCollection, badgeSerial);
    const badgesCollectionDocs = await getDoc(badgeCollectionRef);
    const badge = badgesCollectionDocs.data();

    const referralID = badge.referralID ? badge.referralID : null;
    const referrerID = badge.referrerID ? badge.referrerID : null;
    const userName = user.lastName + " " + user.firstName;
    const badgeModel = new Badge(
        'Badges', user.getPhoneNumber(), userPublicKey, null, badge.badgeSerial,
        referralID,  referrerID, null, null, userName, null
    );

    return await Promise.all([
        await saveBadge(badgeModel),
        await saveBadgeMint(badgeModel)
    ]);
}


export async function getUserBadgesTransaction(phoneNumber: string) {
    const userDocRef = doc(userCollection, phoneNumber);
    const collectionUserBadge = collection(userDocRef, 'user-badge')
    const badgesCollectionDocs = await getDocs(collectionUserBadge);
    if(badgesCollectionDocs.docs.length > 0 ) {
        var collectionBadges = [];
        badgesCollectionDocs.docs.map((badge, index)  => {
            collectionBadges[badge.id] = badge.data();
            collectionBadges[badge.id].key = badge.id;

            return collectionBadges;
        });

        const collectionBadgeKeys = Object.keys(collectionBadges);
        for (let i = 0; i < collectionBadgeKeys.length; i += 10) {
            const tenBadgeSerialInArray = collectionBadgeKeys.slice(i, i + 10);

            const q = await query(badgeCollection, where('badgeSerial', "in", tenBadgeSerialInArray));
            const data = await getDocs(q);
            if(data.docs.length > 0) {
                data.docs.map((badge) => {
                    collectionBadges[badge.id] = {
                        ...collectionBadges[badge.id],
                        ...badge.data()
                    };

                    return collectionBadges;
                })
            }
        }

        return Object.values(collectionBadges);
    }

    return [];
}

export async function getUserBadges(phoneNumber: string) {
    const currentDate = formatDateDMYFrench(new Date);
    const userDocRef = doc(userCollection, phoneNumber);
    const collectionUserBadge = collection(userDocRef, 'user-badge')
    const badgesCollectionDocs = await getDocs(collectionUserBadge);
    if(badgesCollectionDocs.docs.length > 0 ) {
        var collectionBadges = [];
        badgesCollectionDocs.docs.map((badge, index)  => {
            collectionBadges[badge.id] = badge.data();
            collectionBadges[badge.id].key = badge.id;

            return collectionBadges;
        });

        var badges = [];
        const collectionBadgeKeys = Object.keys(collectionBadges);
        for (let i = 0; i < collectionBadgeKeys.length; i += 10) {
            const tenBadgeSerialInArray = collectionBadgeKeys.slice(i, i + 10);

            const q = await query(badgeCollection, where('badgeSerial', "in", tenBadgeSerialInArray), where("dateEnd", ">", currentDate));
            const data = await getDocs(q);
            if(data.docs.length > 0) {
                data.docs.map((badge) => {
                    if(collectionBadges[badge.id]) {
                        badges[badge.id] = {
                            ...collectionBadges[badge.id],
                            ...badge.data()
                        };
                    }

                    return badges;
                })
            }
        }

        return Object.values(badges);
    }

    return [];
}

export async function getUserBadgeIds(phoneNumber: string) {
    const userDocRef = doc(userCollection, phoneNumber);
    const collectionUserBadge = collection(userDocRef, 'user-badge')
    const badgesCollectionDocs = await getDocs(collectionUserBadge);
    if(badgesCollectionDocs.docs.length > 0 ) {
        var badgeIds = [];
        badgesCollectionDocs.docs.map((badge, index) => {
            badgeIds.push(badge.id);

            return badgeIds;
        })

        return badgeIds;
    }

    return [];
}