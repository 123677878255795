import React, { useState, useEffect, useRef } from 'react';
import { ZegoExpressEngine } from 'zego-express-engine-webrtc';
import { Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, UncontrolledDropdown } from 'reactstrap';
import { FaCamera, FaEye, FaHeart, FaPaperPlane, FaPlay } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDollarToSlot, faClose, faHeart, faLaugh, faSmile, faThumbsDown, faThumbsUp, faWarning } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import app from '../../services/Firebase';
import axios from 'axios';
import { collection, doc, getDocs, getFirestore, updateDoc } from 'firebase/firestore';
import { ROUTE_PRIVATE_HOME } from '../../services/routes/Routes';
import { useNavigate } from 'react-router-dom';
import LiveStreamRecorder from '../../components/LiveStreamRecorder';

const db = getFirestore(app);

const LiveStream = () => {
  const navigate = useNavigate();
  const [isStreaming, setIsStreaming] = useState(false);
  const [likes, setLikes] = useState("0");
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [userCount, setUserCount] = useState('');
  const roomid = useSelector((state) => state.streamReducer);
  const { roomID, streamID, badgeImg } = roomid;
  const [token, setToken] = useState('');
  const userReducers = useSelector((state) => state.userReducers);
  const { user } = userReducers;
  const userPhone = user.phoneNumber;
  const userUID = user.uuid;
  const firstName = user.firstName;
  const lastName = user.lastName;
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [zg, setZg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [localStream, setLocalStream] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [cameraSource, setCameraSource] = useState('environment');
  const commentsContainerRef = useRef(null);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const liveStreamElementRef = useRef(null);
  let touchStartY = 0;
  let touchEndY = 0;

  const appID = 1936111096;
  const server = 'wss://webliveroom1936111096-api-bak.coolzcloud.com/ws';

  const payloadObject = {
    room_id: roomID,
    privilege: {
      1: 1,   // loginRoom: 1 pass, 0 not pass
      2: 0    // publishStream: 1 pass, 0 not pass
    },
    stream_id_list: []
  };

  useEffect(() => {
    handleGenerateToken();
  }, []);

  useEffect(() => {
    if (!appID || !/^[1-9]\d*$/.test(appID)) {
      console.error('Invalid appID:', appID);
      return;
    }

    if (roomID && streamID) {
      const newZg = new ZegoExpressEngine(appID, server);
      setZg(newZg);
    }
  }, [roomID, streamID, appID, server]);

  useEffect(() => {
    if (zg) {
      zg.on('roomStreamUpdate', (roomID, updateType, streamList, extendedData) => {
        if (updateType === 'ADD') {
          const newStream = streamList[0];
        }
      });

      zg.on('roomUserUpdate', (roomID, updateType, userList) => {
        console.log(`roomUserUpdate: room ${roomID}, user ${updateType === 'ADD' ? 'added' : 'left'}`, JSON.stringify(userList));
      });

      zg.on('roomStateUpdate', (roomID, state, errorCode, extendedData) => {
        console.log("++++++++++roomID", roomID, "++++++++++extendedData", extendedData);
      });

      zg.on("roomOnlineUserCountUpdate", (roomID, count) => {
        setUserCount(count);
        console.log("++++++++++rCOUNT++++++++++extendedData", count);
      });

    }
  }, [zg]);

  useEffect(() => {
    if (zg && isStreaming) {
      zg.on("IMRecvBarrageMessage", (roomID, chatData) => {
        const messageContent = chatData[0].message;

        if (messageContent.startsWith("#LIKE#")) {
          // C'est un like
          const likeCount = 1  + parseInt(likes);
          setLikes(likeCount.toString());
        } else if (messageContent.startsWith("#EMOJI#")) {
          // It's an emoji
          const emoji = messageContent.replace("#EMOJI#", "");
          setSelectedEmoji(emoji);
        }  else {
          // It's a comment
          const updatedComments = [...comments, messageContent];
          setComments(updatedComments);

          if (scrollPosition < updatedComments.length - 5) {
            setScrollPosition(updatedComments.length - 5);
          }

        }
      });
    }

  }, [likes, comments,zg, roomID, streamID, appID, server]);

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
  }, [comments]);
  

  const toggleCameraSource = () => {
    setCameraSource(prevSource => {
      const newSource = prevSource === 'user' ? 'environment' : 'user';
      return newSource;
    });
  };

  const handleGenerateToken = async () => {
    const zegoFunctionUrl = 'https://us-central1-korixdev.cloudfunctions.net/zegoTokenGeneration';

    const requestData = {
      appId: 1936111096,
      userId: "host" + userUID,
      secret: "a43c58c4a5d244025315c2adfe88e5fe",
      effectiveTimeInSeconds: 3600,
      payload: JSON.stringify(payloadObject)
    };

    try {
      const response = await axios.post(zegoFunctionUrl, requestData);
      setToken(response.data.token);
    } catch (error) {
      console.error('Erreur lors de l\'appel à la fonction Firebase :', error);
    }
  };


  const exitLiveStream = async () => {
    try {
      const badgesCollection = collection(db, "badge-collection");
      const querySnapshot = await getDocs(badgesCollection);
      const creatorBadges = [];

      querySnapshot.forEach((doc) => {
        const badgeData = doc.data();
        if (badgeData.isCreator && badgeData.badgeOwner === userPhone) {
          creatorBadges.push(badgeData);
        }
      });

      for (const creatorBadge of creatorBadges) {
        const badgeRef = doc(badgesCollection, creatorBadge.badgeSerial);
        await updateDoc(badgeRef, {
          onLine: false,
        });
      }

      if (zg) {
        zg.stopPublishingStream(streamID);
        zg.logoutRoom(roomID);
      }

      navigate(ROUTE_PRIVATE_HOME);
      window.location.reload()
    } catch (error) {
      console.error("Erreur lors de l'enregistrement des badges sélectionnés : ", error);
    }
  };

  const connectToRoom = async () => {
    const userID = "host" + userUID;
    const userName = "hostXn" + Math.floor(Math.random() * 100000000).toString();
    try {
      if (zg && roomID && token) {
        await zg.loginRoom(roomID, token, { userID, userName }, { userUpdate: true });
      } else {
        console.error('Erreur lors de la connexion à la salle : Les paramètres ne sont pas définis correctement.');
      }
    } catch (error) {
      console.error('Erreur lors de la connexion à la salle :', error);
    }
  };

  const startLiveStream = async () => {
    await connectToRoom();
    setIsLoaded(false);
    try {
      if (zg) {
        const option = {
          camera: {
            facingMode: cameraSource,
            audioBitrate: 48,
            videoQuality: 3,
            width: 720,
            height: 1280 ,
            frameRate: 30,
            bitrate: 2000,
            AEC: true,
            //ANS: true,
            //AGC: true,
          }

        }
        const localStream = await zg.createStream(option);

        if (localStream) {
          const result = await zg.startPublishingStream(streamID, localStream);
          if (result) {
            setIsStreaming(true);
            setLocalStream(localStream);
            setIsLoaded(true);

            const liveVideo = document.getElementById('liveVideo');
            if (liveVideo) {
              liveVideo.srcObject = localStream;

              liveStreamElementRef.current = liveVideo;
            } else {
              console.error("Élément vidéo introuvable.");
            }
          } else {
            console.error('Erreur lors du démarrage de la diffusion en direct');
          }
        } else {
          console.error('Erreur lors de la création du flux local');
        }
      } else {
        console.error('Erreur lors du démarrage du streaming en direct : ZegoExpressEngine non initialisé.');
      }
    } catch (error) {
      console.error('Erreur lors du démarrage du streaming en direct :', error);
    }
  };

  const handleLike = async () => {
    const integer = parseInt(likes, 10);
    const updatedLikes = integer + 1;
    const likeString = `#LIKE#${updatedLikes}`;
    setLikes(parseInt(likeString.replace("#LIKE#", ""), 10));

    try {
      if (zg) {
        await zg.sendBarrageMessage(roomID, likeString);
      } else {
        console.error("Erreur lors de l'envoi du message de like : ZegoExpressEngine non initialisé.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du message de like : ", error);
    }
  };

  const handleAddComment = async () => {
    const updatedComments = [...comments, firstName + lastName + ":" + newComment];
    setComments(updatedComments);
    setNewComment('');

    if (scrollPosition < updatedComments.length - 5) {
      setScrollPosition(updatedComments.length - 5);
    }

    try {
      await zg.sendBarrageMessage(roomID,  firstName + lastName + ":" + newComment);
    } catch (error) {
      console.log("Erreur lors de l'envoi du commentaire :", error);
    }
  };

  const handleEmojiClick = async  (emoji) => {
    setSelectedEmoji(emoji);
    const message = `#EMOJI#${emoji}`;
    await zg.sendBarrageMessage(roomID, message);
  };
  

  const scrollUp = () => {
    if (scrollPosition < comments.length - 5) {
      setScrollPosition(scrollPosition + 1);
    }
  };

  const scrollDown = () => {
    if (scrollPosition > 0) {
      setScrollPosition(scrollPosition - 1);
    }
  };

  const handleTouchStart = (e) => {
    touchStartY = e.changedTouches[0].clientY;
  };

  const handleTouchEnd = (e) => {
    touchEndY = e.changedTouches[0].clientY;
    if (touchStartY - touchEndY > 40) {
      scrollUp();
    } else if (touchEndY - touchStartY > 40) {
      scrollDown();
    }
  };

  return (
    <>
    <div className="component-liveStream" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      <div className={`video-container ${isStreaming ? 'streaming' : ''}`}>
        <video id="liveVideo" muted autoPlay playsInline></video>
        {isStreaming && localStream !== null ? (
          <div>

            <img src={require("../../assets/img/Brand/ACXES_LOGO_b_nobg.png")} alt="logo-korix" className="logo" />

            <div className="badge-button">
              <Button className="button">
                <div className="badge-content">
                  <img src={badgeImg} alt="logo-korix" className="badge-img" />
                  <div className="text-lines">
                    <p>{firstName} {lastName}</p>
                    <p>{likes} j'aime</p>
                  </div>
                </div>
              </Button>
            </div>
            <div className="user-count">
              <Button className="button">
                <FaEye /> {userCount}
              </Button>
            </div>
            <div className="camera-button">
              <Button className="button" onClick={toggleCameraSource}>
                <FaCamera />
              </Button>
            </div>
            {liveStreamElementRef.current && (
              <LiveStreamRecorder liveStreamElement={liveStreamElementRef.current} />
            )}
            <div className="comments" ref={commentsContainerRef}>
              {comments.slice(scrollPosition, scrollPosition + 5).map((comment, index) => (
                <div key={index} className="comment-box">
                  {comment}
                </div>
              ))}
            </div>
            <div className="emoji-container">
              <FontAwesomeIcon
                icon={faSmile}
                className={`fa-smile ${selectedEmoji === 'smile' ? 'selected selected-emoji-animation' : ''}`}
                onClick={() => handleEmojiClick('smile')}
              />
              <FontAwesomeIcon
                icon={faHeart}
                className={`fa-heart ${selectedEmoji === 'heart' ? 'selected selected-emoji-animation' : ''}`}
                onClick={() => handleEmojiClick('heart')}
              />
              <FontAwesomeIcon
                icon={faLaugh}
                className={`fa-laugh ${selectedEmoji === 'laugh' ? 'selected selected-emoji-animation' : ''}`}
                onClick={() => handleEmojiClick('laugh')}
              />
              <FontAwesomeIcon
                icon={faThumbsUp}
                className={`fa-thumbs-up ${selectedEmoji === 'thumbs-up' ? 'selected selected-emoji-animation' : ''}`}
                onClick={() => handleEmojiClick('thumbs-up')}
              />
              <FontAwesomeIcon
                icon={faThumbsDown}
                className={`fa-thumbs-down ${selectedEmoji === 'thumbs-down' ? 'selected selected-emoji-animation' : ''}`}
                onClick={() => handleEmojiClick('thumbs-down')}
              />
            </div>
            <div className="new-comment">
              <Input
                type="textarea"
                placeholder="Ajouter un commentaire"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
              <span className="send-button" onClick={handleAddComment}>
                <FaPaperPlane size="16px" className="fa-paper-plane" />
              </span>
            </div>
            <div >
              <ButtonGroup className="live-button-group">
                <UncontrolledDropdown direction="left" className="dollar-dropdown">
                  <DropdownToggle caret color="primary" className="dropdown-toggle">
                    <FontAwesomeIcon icon={faCircleDollarToSlot} />
                  </DropdownToggle>
                  <DropdownMenu className="live-dropdown-menu">
                    <DropdownItem>10 000 Fcfa</DropdownItem>
                    <DropdownItem>5 000 Fcfa</DropdownItem>
                    <DropdownItem>1000 Fcfa</DropdownItem>
                    <DropdownItem>250 Fcfa</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <Button onClick={handleLike} color="link" className="like-button">
                  <FaHeart color="red" size="32px" /> {likes}
                </Button>
              </ButtonGroup>
            </div>
          </div>
        ) : (
          <div className="reload-spinner">
            <div></div>
          </div>
        )}
      </div>

      <div className="go-live-button">
        {!isStreaming ? (
          <Button onClick={startLiveStream} color="green" className="button not-streaming">
            Go live <FaPlay />
          </Button>
        ) : (
          <div className="close-icon">
            <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={() => setIsOpenDialog(true)} />
          </div>
        )}
      </div>
      <Modal className="modal-badge dark" funk={true} isOpen={isOpenDialog} toggle={() => setIsOpenDialog(false)} size="xl">
        <ModalBody className="dark">
          <div className="modal-badge-close">
            <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={() => setIsOpenDialog(false)} />
          </div>
          <div className="modal-badge-button text-center">
            <div align="center" className="pt-0 pb-0">
              <FontAwesomeIcon icon={faWarning} className="icon-fa" color="white" size={"6x"} />
            </div>
            <br />
            <h5>Voulez-vous vraiment fermer le live </h5>
            <div className="container-validate">
              <Button block className="btn-round btn-korix-orange" size="md" onClick={exitLiveStream}>
                Oui
              </Button>
              <Button block className="btn-round btn-korix-orange" size="md" onClick={() => setIsOpenDialog(false)}>
                Non
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
    </>
  );
}

export default LiveStream;
