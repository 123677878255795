import {Container} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
function LegalNotice () {

    const navigate = useNavigate();

    return (
        <div className="page-about-us">
            <Container>
                <div className="container-header-page-about-us">
                    <div className="icon-container-page-about-us">
                        <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="icon"/>
                    </div>
                    <div className="logo-container-page-about-us text-center">
                        <img src={require("../assets/img/Brand/ACCESS_LOGO_WHITE_nobg.png")} alt={"logo-korix"} width={"75%"}/>
                    </div>
                </div>

                <div className="card-page-about-us ml-auto mr-auto text-center">
                    <h3>Mentions légales</h3>
                    <div className="card-body-page-about-us">
                        <p>
                            Les site web <Link to={"https://korix.africa"} target={"_blank"}>https://korix.africa</Link> et <Link to={"https://acxes.app"} target={"_blank"}>https://acxes.app</Link> sont la propriété de la La société KORIX, Société à Responsabilité Limitée au capital de 1 000 000 FCFA, dont le siège social est sis à Sotuba ACI, Bamako, immatriculée au Registre de Commerce et du Crédit Mobilier de Bamako sous le numéro MA.BKO.2022. M.11259, Numéro d’Identification Fiscal (NIF) : 081133268
                        </p>
                        <br/>
                        <h6>Responsable Informatique : Tidiane Wane</h6>
                        <p>Email : <a href="mailto:support@korix.africa">support@korix.africa</a></p>
                    </div>
                    <div className="card-body-page-about-us">
                        <div className="card-body-page-about-us">
                            <p>Les site web <Link to={"https://korix.africa"} target={"_blank"}>https://korix.africa</Link> et <Link to={"https://acxes.app"} target={"_blank"}>https://acxes.app</Link> sont régi sous la loi N° 2013-015 du 21 Mai 2013 portant : Protection des Données a Caractère Personnel en République du Mali.</p>
                            <p>Les visiteurs ayant accès aux sites, doivent s’assurer du respect des lois localement applicables.</p> <br/><br/>
                            <p>Les mentions légales pouvant être modifiées à tout moment et sans préavis, nous vous engageons à les consulter régulièrement.</p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Protection des données</h6>
                        <div className="card-body-page-about-us">
                            <p>
                                Korix vous souhaite la bienvenue sur nos pages Internet et vous remercie de l’intérêt que vous portez à nos solutions. La protection de vos données personnelles revêt pour nous la plus haute importance. Dans la présente Déclaration de confidentialité, nous vous informons sur la collecte de vos données personnelles, leur utilisation, la finalité et la base juridique de cette démarche et les droits et recours qui en découlent pour vous.
                            </p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Collecte et traitement des données personnelles</h6>
                        <div className="card-body-page-about-us">
                            <p>A. Lorsque vous consultez nos pages Internet, nous enregistrons certaines informations sur le navigateur et le système d’exploitation que vous utilisez, la date et l’heure de votre visite, le statut d’accès (par ex. si vous avez pu consulter une page Internet ou si vous avez reçu un message d’erreur), l’utilisation des fonctions de la page Internet, les mots-clés que vous avez éventuellement saisis, la fréquence avec laquelle vous consultez les différentes pages Internet, la désignation des fichiers consultés, le volume de données transmis, la page Internet depuis laquelle vous accédez à nos pages Internet et la page Internet que vous consultez depuis nos pages Internet, soit en cliquant sur les liens figurant sur nos pages Internet, soit en entrant directement un domaine dans le champ de saisie du même onglet (ou de la même fenêtre) du navigateur dans lequel vous avez ouvert nos pages Internet. Par mesure de sécurité, notamment pour prévenir et détecter d’éventuelles attaques sur nos pages Internet ou tentatives de fraude, nous enregistrons aussi, pendant une durée de sept jours, votre adresse IP et le nom de votre fournisseur d’accès Internet.</p>
                            <br/><br/>
                            <p>B. Nous n’enregistrerons d’autres données personnelles que si vous nous les avez communiquées, par ex. dans le cadre d’une inscription, d’un formulaire de prise de contact, d’un sondage, d’un jeu-concours ou pour l’exécution d’un contrat et dans tous ces cas, uniquement si nous y sommes autorisés sur la base de votre consentement ou des prescriptions légales en vigueur (vous trouverez de plus amples informations sur ce point dans la section « Bases juridiques du traitement »).</p>
                            <br/><br/>
                            <p>C. Vous n’êtes tenu ni par la loi, ni par contrat, de mettre vos données personnelles à disposition. Il se peut cependant que certaines fonctions de nos pages Internet dépendent de la mise à disposition des données personnelles. Dans tous les cas, si vous ne mettez pas vos données personnelles à disposition, il se peut que certaines fonctions ne soient pas disponibles ou qu’elles ne le soient que partiellement.</p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Finalité de l’utilisation</h6>
                        <div className="card-body-page-about-us">
                            <ul>
                                <li>Nous utilisons les données collectées lors de la consultation de nos pages Internet pour accroître le confort d’utilisation de celles-ci et protéger nos systèmes informatiques des attaques et autres agissements illicites.</li>
                                <li>Dans la mesure où vous nous communiquez d’autres données personnelles dans le cadre par ex. d’une inscription, d’un formulaire de prise de contact, d’un sondage, d’un jeu-concours ou de l’exécution d’un contrat, nous utiliserons celles-ci à des fins de gestion des clients et – si nécessaire, de règlement et de facturation d’éventuelles transactions commerciales dans les proportions requises pour chaque opération.</li>
                            </ul>

                            <p>L’Utilisateur peut contacter le Site par messagerie électronique à l’adresse : <a href="mailto:support@korix.africa">support@korix.africa</a></p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Sécurité</h6>
                        <div className="card-body-page-about-us">
                            <p>Nous appliquons des mesures de sécurité techniques et organisationnelles visant à protéger les données personnelles que nous gérons contre toute manipulation, perte, destruction ou utilisation par des personnes non autorisées. Nos mesures de sécurité font l’objet d’améliorations permanentes et tiennent compte de l’évolution technologique.</p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Newsletter</h6>
                        <div className="card-body-page-about-us">
                            <p>Si vous vous abonnez à une newsletter proposée sur nos pages Internet, les données collectées lors de votre inscription à la newsletter ne seront utilisées que pour son envoi si vous n’acceptez pas expressément une autre utilisation. Vous pourrez à tout moment résilier votre abonnement grâce à la fonction de désabonnement prévue dans la newsletter.</p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Cookies</h6>
                        <div className="card-body-page-about-us">
                            <p>Vous trouverez dans nos mentions sur les cookies des informations sur les cookies que nous utilisons et leurs fonctions.</p>
                        </div>
                        <br/><br/>
                        <h6 className="font-weight-bold">Fonctions et utilisation de cookies</h6>
                        <div className="card-body-page-about-us">
                            <p>Les cookies sont de petits fichiers enregistrés par un site Internet que vous consultez sur votre PC, votre ordinateur portable ou votre appareil mobile. Ils nous permettent notamment de détecter si un lien a déjà été établi entre votre appareil et notre site Internet, de prendre en compte votre langue préférée ou d’autres réglages, de vous offrir certaines fonctionnalités ou de reconnaître vos centres d’intérêt en fonction de votre utilisation. Les cookies peuvent aussi contenir des données à caractère personnel.</p>
                            <br/><br/>
                            <p>Le site peut inclure des liens vers d’autres sites ou d’autres sources Internet. Dans la mesure où Korix ne peut contrôler ces sites et ces sources externes, Korix ne peut être tenu pour responsable de la mise à disposition de ces sites et sources externes, et décline ainsi toute responsabilité quant aux contenus, publicités, produits, services ou tout autre matériel disponible sur ou à partir de ces sites ou sources externes.</p>
                            <br/><br/>
                            <p>Ces liens sont proposés aux utilisateurs des sites de Korix en tant que service. La décision d’activer les liens vous appartient exclusivement.</p>
                            <br/><br/>
                            <p>Nous vous rappelons que les sites non-affiliés sont soumis à leurs propres conditions d’utilisation.</p>
                        </div>
                        <br/><br/>
                    </div>
                    <p>Korix</p>
                    <p><Link to={"https://korix.africa"} target={"_blank"}>https://korix.africa</Link></p>
                    <p>support@korix.africa</p>
                    <p>Tél : +223 90501050</p>
                    <p>Sotuba ACI, Bamako Mali</p>
                </div>
            </Container>
        </div>
    )
}

export default LegalNotice;