import * as React from "react";
import { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, limit, orderBy, query, startAfter } from "firebase/firestore";
//import styles from "../../../../Styles/SignIn.module.css";
import app from "../../../../services/Firebase";
import PropTypes from "prop-types";
import { Container, Row, Col, Button, Table } from 'reactstrap';
import { selectAdminData } from "../../../../services/stores/nft/NftSlice";
import { useSelector } from "react-redux";
import { ROLES_ADMIN, ROLES_OWNER } from "../../../../services/Constant";
import NavBar from "../../../../components/NavBar";
import MintedTableFilter from "./MintedDataFilter";

const db = getFirestore(app);
const nftMintCollection = collection(db, 'badges-mint');
const badgeCollection = collection(db, 'badge-collection');

function MintedDataTable({ badgeCount  }) {

    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(10);
    const itemsPerPage = 10;
    const [mintedList, setMintedList] = useState([])
    const [filterstartDate, setFilterStartDate] = useState(null);
    const [filterendDate, setFilterEndDate] = useState(null);
    const [nameFilter, setNameFilter] = useState('');
    const [addressFilter, setAddressFilter] = useState('');
    const [filterActiveBadge, setFilterActiveBadge] = useState(false);
    const [filterClosedBadge, setFilterClosedBadge] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [hasMoreData, setHasMoreData] = useState(true);
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [lastDocument, setLastDocument] = useState(null);
 

    useEffect(() => {
        setLoading(true);
        getAllBadge();
      }, []);

    const getAllBadge = async (startAfterDoc) => {
        const badgeCollectionDocs = await getDocs(badgeCollection);
        var badgesCollection = [];
        badgeCollectionDocs.docs.map((result) => {
            badgesCollection[result.id] = result.data();
        })

        try {
            let queryRef = query(nftMintCollection, orderBy('date'), limit(itemsPerPage + 1));
            if (startAfterDoc) {
                queryRef = startAfter(queryRef, startAfterDoc);
            }

            const querySnapshot = await getDocs(queryRef);
            var result = [];
            querySnapshot.docs.map((doc) => {
                result.push(
                    {
                        ...doc.data(),
                        ...badgesCollection[doc.data().badgeSerial]
                    }
                )
            })

            if (result.length > 0) {
                setLastDocument(querySnapshot.docs[result.length - 1]);
            }

            setMintedList(result.slice(0, itemsPerPage));
            setTotalDocuments(result.length); 
            setLoading(false);

            setHasMoreData(result.length > itemsPerPage);
            
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        const filterBadges = (donnee) => {
            const currentDate = new Date();
      
            // Filter by ongoing Badge
            if (filterActiveBadge && new Date(donnee.dateEnd) < currentDate) {
              return false;
            }
      
            // Filter by closed Badge
            if (filterClosedBadge && new Date(donnee.dateEnd) > currentDate) {
              return false;
            }
      
            // Filter by date
            if (filterstartDate && filterendDate) {
              const date = new Date(donnee.date);
              if (date < filterstartDate || date > filterendDate) {
                return false;
              }
            }
      
            // Filter by name
            if (nameFilter && !donnee.name.toLowerCase().includes(nameFilter.toLowerCase())) {
              return false;
            }
      
            // Filter by address
            if (addressFilter && !donnee.publicID.toLowerCase().includes(addressFilter.toLowerCase())) {
              return false;
            }
      
            return true;
          };
      
          const filteredData = mintedList.filter((donnee) => {
            if (user.role === ROLES_ADMIN) {
              return filterBadges(donnee);
            } else if (user.role === ROLES_OWNER) {
              return donnee.address === user.address && filterBadges(donnee);
            }
            return false;
          });


        setFilteredData(filteredData);
        setStartIndex(0);
        setEndIndex(itemsPerPage);
    }, [
        mintedList,
        filterstartDate,
        filterendDate,
        nameFilter,
        addressFilter,
        filterActiveBadge,
        filterClosedBadge,
        user.address,
    ]);

    const displayedData = filteredData.slice(startIndex, endIndex);

    const handleOngoingFilter = () => {
        setFilterActiveBadge(true);
        setFilterClosedBadge(false);
        setStartIndex(0);
        setEndIndex(itemsPerPage);
    };

    const handleClosedFilter = () => {
        setFilterClosedBadge(true);
        setFilterActiveBadge(false);
        setStartIndex(0);
        setEndIndex(itemsPerPage);
    };

    const handleDateFilter = () => {
        const startDateInput = document.getElementById('start-date-input').value;
        const endDateInput = document.getElementById('end-date-input').value;
        setFilterStartDate(startDateInput ? new Date(startDateInput) : null);
        setFilterEndDate(endDateInput ? new Date(endDateInput) : null);
        setStartIndex(0);
        setEndIndex(itemsPerPage);
    };
    const handleNameFilter = () => {
        const nameInput = document.getElementById('name-input').value;
        setNameFilter(nameInput);
        setStartIndex(0);
        setEndIndex(itemsPerPage);
    };
    const handleAddressFilter = () => {
        const addressInput = document.getElementById('address-input').value;
        setAddressFilter(addressInput);
        setStartIndex(0);
        setEndIndex(itemsPerPage);
    };

    const handlePreviousPage = () => {
        if (startIndex >= itemsPerPage) {
            const newStartIndex = startIndex - itemsPerPage;
            const newEndIndex = endIndex - itemsPerPage;
            setStartIndex(newStartIndex);
            setEndIndex(newEndIndex);
        }
      };
      
      const handleNextPage = () => {
        if (hasMoreData) {
            setStartIndex(endIndex); 
            setEndIndex(endIndex + itemsPerPage);
        }
      };

    const disableNextButton = endIndex >= filteredData.length || !hasMoreData;
    const disablePreviousButton = startIndex === 0;


    const formatDate = (dateString) => {
        if (!dateString) {
          return "Date invalide";
        }
      
        const dateObj = new Date(dateString);
        if (isNaN(dateObj.getTime())) {
          return "Date invalide";
        }
      
        return dateObj.toISOString().replace('T', ' ').substring(0, 19);
      };


    return (
        <>
            <NavBar />
            <Container className="ml-auto mr-auto">
                {loading ? (
                    <div>
                        <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th>Badge</th>
                                    <th>Adresse</th>
                                    <th>Date</th>
                                    <th>Paiement</th>
                                    <th>Montant</th>
                                    <th>Collection</th>
                                </tr>
                            </thead>
                        </Table>
                        <div className="uil-reload-css-reload-small color">
                            <div></div>
                        </div>
                    </div>
                ) : user.role === ROLES_ADMIN ? (
                    <div >
                        <MintedTableFilter
                        endIndex={endIndex} setEndIndex={setEndIndex}
                        startIndex={startIndex} setStartIndex={setStartIndex}
                        mintedList={mintedList} setMintedList={setMintedList}
                        handleDateFilter={handleDateFilter}
                        handleNameFilter={handleNameFilter}
                        handleAddressFilter={handleAddressFilter}
                        handleOngoingFilter={handleOngoingFilter}
                        handleClosedFilter={handleClosedFilter}
                        filterActiveBadge={filterActiveBadge}
                        setFilterActiveBadge={setFilterActiveBadge}
                        filterClosedBadge={filterClosedBadge}
                        setFilterClosedBadge={setFilterClosedBadge}
                        nameFilter={nameFilter}
                        setNameFilter={setNameFilter}
                        filterstartDate={filterstartDate}
                        setFilterStartDate={setFilterStartDate}
                        filterendDate={filterendDate}
                        setFilterEndDate={setFilterEndDate}
                        addressFilter={addressFilter}
                        setAddressFilter={setAddressFilter}
                        displayedData={displayedData} />

                        <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th>Badge</th>
                                    <th>public ID</th>
                                    <th>Date</th>
                                    <th>Paiement</th>
                                    <th>Montant</th>
                                    <th>Collection</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedData.length === 0 ? (
                                    <div>
                                        <div>Aucun badge acheté</div>
                                    </div>
                                ) : (
                                    displayedData.map((donnee) => (
                                        <tr key={donnee.id}>
                                            <td data-th='Nom'>{donnee.name}</td>
                                            <td data-th='Adresse'>{donnee.publicID}</td>
                                            <td data-th='Date'>{formatDate(donnee.date)}</td>
                                            <td data-th='Paiement'>{donnee.payment}</td>
                                            <td data-th='Montant'>{donnee.amount}</td>
                                            <td data-th='Collection'>{donnee.collection}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                disabled={disablePreviousButton}
                                onClick={() => {
                                    setStartIndex(startIndex - 10);
                                    setEndIndex(endIndex - 10);
                                }}
                                className="mb-2 mr-2"
                            >
                                Précédent
                            </Button>
                            <Button
                                disabled={disableNextButton}
                                onClick={() => {
                                    setStartIndex(startIndex + 10);
                                    setEndIndex(endIndex + 10);
                                }}
                                className="mb-2 ml-2"
                            >
                                Suivant
                            </Button>
                        </div>
                    </div>
                ) : user.role === ROLES_OWNER && (
                    <div className="container">
                        <MintedTableFilter
                        endIndex={endIndex} setEndIndex={setEndIndex}
                        startIndex={startIndex} setStartIndex={setStartIndex}
                        mintedList={mintedList} setMintedList={setMintedList}
                        handleDateFilter={handleDateFilter}
                        handleNameFilter={handleNameFilter}
                        handleAddressFilter={handleAddressFilter}
                        handleOngoingFilter={handleOngoingFilter}
                        handleClosedFilter={handleClosedFilter}
                        filterActiveBadge={filterActiveBadge}
                        setFilterActiveBadge={setFilterActiveBadge}
                        filterClosedBadge={filterClosedBadge}
                        setFilterClosedBadge={setFilterClosedBadge}
                        nameFilter={nameFilter}
                        setNameFilter={setNameFilter}
                        filterstartDate={filterstartDate}
                        setFilterStartDate={setFilterStartDate}
                        filterendDate={filterendDate}
                        setFilterEndDate={setFilterEndDate}
                        addressFilter={addressFilter}
                        setAddressFilter={setAddressFilter}
                        displayedData={displayedData} />
                        <Table className="align-items-center" responsive>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Adresse</th>
                                    <th>Date</th>
                                    <th>Paiement</th>
                                    <th>Montant</th>
                                    <th>Collection</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedData.length === 0 ? (
                                    <div>
                                        <div>Aucun badge </div>
                                    </div>
                                ) : (
                                    displayedData.map((donnee) => (
                                        <tr key={donnee.id}>
                                            <td data-th='Nom'>{donnee.name}</td>
                                            <td data-th='Adresse'>{donnee.publicID}</td>
                                            <td data-th='Date'>{formatDate(donnee.date)}</td>
                                            <td data-th='Paiement'>{donnee.payment}</td>
                                            <td data-th='Montant'>{donnee.amount}</td>
                                            <td data-th='Collection'>{donnee.collection}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                                <div style={{ textAlign: 'center' }}>
                                    <Button
                                        disabled={disablePreviousButton} onClick={handlePreviousPage}
                                        className="mb-2 mr-2"
                                    >
                                        Précédent
                                    </Button>
                                    <Button
                                        disabled={disableNextButton} onClick={handleNextPage}
                                        className="mb-2 ml-2"
                                    >
                                        Suivant
                                    </Button>
                                </div>
                    </div>
                )}
            </Container>
        </>
    )
}

export default MintedDataTable;

MintedDataTable.defaultProps = {
    badgeCount: PropTypes.array,
    adminType: PropTypes.any,
};

MintedDataTable.propTypes = {
    adminType: PropTypes.any.isRequired,
}