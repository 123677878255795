import { configureStore } from '@reduxjs/toolkit';
import UserReducers from "./reducers/UserReducers";
import cartReducer from './reducers/CartReducer';
import CodePinReducers from './reducers/CodePinReducers';
import SliceReducers from "./nft/NftSlice";
import StreamReducer from './reducers/StreamReducer';
import PlayerRoomReducer from './reducers/PlayerRoomReducer';

const store = configureStore({
    reducer: {
        NFT: SliceReducers,
        userReducers: UserReducers,
        codePinReducers: CodePinReducers,
        cart: cartReducer,
        streamReducer:StreamReducer,
        playerRoomReducer:PlayerRoomReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;