export const setPlayerRoomID = (roomID) => ({
  type: 'SET_Player_ROOM_ID',
  payload: roomID,
});

export const setPlayerStreamID = (streamID) => ({
  type: 'SET_Player_STREAM_ID',
  payload: streamID,
});

export const setPlayerBadgeImg = (badgeImg) => ({
  type: 'SET_BADGE_IMG',
  payload: badgeImg,
});
