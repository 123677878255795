import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "../audience.css";
import { useNavigate } from "react-router-dom";
import { ROUTE_PRIVATE_LIVE_STREAM } from "../services/routes/Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import {
    addDoc,
    collection,
    doc,
    getDocs,
    getFirestore,
    query,
    setDoc,
    updateDoc,
    where,
    writeBatch
} from "firebase/firestore";
import app from "../services/Firebase";
import { useSelector, useDispatch } from "react-redux";
import { setBadgeImg, setRoomID, setStreamID } from "../services/stores/actions/StreamActions";
import {formatDateDMYFrench} from "../services/Constant";

const db = getFirestore(app);
const badgesCollection = collection(db, 'badge-collection');
const streamCollectionRef = collection(db, "stream-collection");


const ModalAudience = ({ isOpen, toggle }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [badges, setBadges] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;
    const userPhone = user.phoneNumber;

    useEffect(() => {
        fetchBadges()
    }, []);


    const fetchBadges = async () => {
        const currentDate = formatDateDMYFrench(new Date);
        const queryBadges = await query(badgesCollection, where("dateEnd", ">", currentDate));
        const badgesCollectionDocs = await getDocs(queryBadges);

        if (badgesCollectionDocs.docs.length > 0) {
            var collectionBadges = badgesCollectionDocs.docs
                .filter((badge) => badge.data().badgeOwner === userPhone)
                .map((badge) => {
                    return {
                        key: badge.id,
                        name: badge.data().name,
                        collectionName: badge.data().collectionName,
                        amount: badge.data().amount,
                        isListed: badge.data().listed,
                        contractAddress: badge.data().address,
                        imageUrl: badge.data().imageUrl,
                        dateStart: badge.data().dateStart,
                        dateEnd: badge.data().dateEnd,
                        advantage: badge.data().advantages,
                        category: badge.data().category,
                        details: badge.data().details,
                        collectionImg: badge.data().collectionImg,
                        badgeSerial: badge.data().badgeSerial,
                        onLine: badge.data().onLine,
                        liveImageUrl: badge.data().liveImageUrl,
                        badgeOwner: badge.data().badgeOwner,
                        Mint: "N/A",
                    };
                });

            setBadges(collectionBadges)
        }
        setLoading(false);
    }

    const handleImageSelect = (index) => {
        if (selectedImages.includes(index)) {
            setSelectedImages(selectedImages.filter((item) => item !== index));
        } else {
            setSelectedImages([...selectedImages, index]);
        }
    };

    const saveSelectedBadgesToFirestore = async () => {
        try {

            const roomID = "Rm" + Math.floor(Math.random() * 100000000).toString();
            const streamID = "St" + Math.floor(Math.random() * 100000000).toString();
            const badgeImage = [];

            // fetch creator's badges
            const badgesCollection = collection(db, "badge-collection");
            const querySnapshot = await getDocs(badgesCollection);
            const creatorBadges = [];

            querySnapshot.forEach((doc) => {
                const badgeData = doc.data();
                if (badgeData.isCreator && badgeData.badgeOwner === userPhone) {
                    creatorBadges.push(badgeData);
                }
            });

            const selectedBadgeSerials = selectedImages.map((index) => badges[index].badgeSerial);

            // Update "onLine" fields for creators's badges
            for (const creatorBadge of creatorBadges) {
                if (selectedBadgeSerials.includes(creatorBadge.badgeSerial)) {
                    const badgeRef = doc(badgesCollection, creatorBadge.badgeSerial);
                    await updateDoc(badgeRef, { onLine: true });
                    badgeImage.push(creatorBadge.imageUrl);

                    // Save roomID and streamID for the selected badge
                    const streamDocRef = doc(streamCollectionRef, creatorBadge.badgeSerial);
                    await setDoc(streamDocRef, {
                        roomID: roomID,
                        streamID: streamID,
                        badges: [{ name: creatorBadge.name, badgeID: creatorBadge.badgeSerial }],
                    });
                }
            }

            console.log("Mises à jour réussies.");
            dispatch(setRoomID(roomID));
            dispatch(setStreamID(streamID));
            dispatch(setBadgeImg(badgeImage));

            navigate(ROUTE_PRIVATE_LIVE_STREAM);

        } catch (error) {
            console.error("Erreur lors de l'enregistrement des badges sélectionnés : ", error);
        }
    };

    const handleSaveSelectedBadges = () => {
        if (selectedImages.length > 0) {
            saveSelectedBadgesToFirestore();
        } else {
            console.warn("Aucun badge sélectionné.");
        }
    };



    return (
        <Modal className="modal-badge" isOpen={isOpen} toggle={toggle} size="xl">
            <ModalBody className="modal-body">
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle} />
                </div>
                <div className="badge-content text-center">
                    <div className="d-flex flex-wrap justify-content-center">
                        {badges.map((image, index) => (
                            <div key={index} className="image-checkbox-container">
                                <div className="image-container">
                                    <img className="img-responsive" src={image.imageUrl} alt={image.alt} />
                                </div>
                                <label className="checkbox-label">
                                    <input
                                        type="checkbox"
                                        className="image-checkbox"
                                        checked={selectedImages.includes(index)}
                                        onChange={() => handleImageSelect(index)}
                                    />
                                </label>
                            </div>
                        ))}
                    </div>

                    <div className="pt-3">
                        <p>Veuillez choisir une audience à travers les badges présent ici</p>
                    </div>
                    <div className="container-validate">
                        <Button block className="btn-round btn-korix-orange" size="md" onClick={() => { handleSaveSelectedBadges() }}>
                            Démarrer le live
                        </Button>
                        <Button block className="btn-round btn-korix-black" onClick={toggle}>
                            Annuler
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalAudience;