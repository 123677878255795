import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faWarning} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal, ModalBody} from "reactstrap";
import React, {useEffect, useState} from "react";
import {
    paymentProcessData,
    STEP_HUB2_PAYMENT,
    STEP_COUNTRY,
    STEP_ML_PAYMENT,
    STEP_SMS_CODE,
    ORANGE,
    STEP_PHONE,
    STEP_WAVE,
    PAYMENT_STATUS_SUCCESS,
    PAYMENT_STATUS_FAILED,
    PAYMENT_STATUS_SUCCESS_WEB,
    PAYMENT_STATUS_FAILED_WEB,
    PROVIDER_HUB2,
    STEP_INIT,
} from "../services/Constant";
import ChooseCountries from "./payment/ChooseCountries";
import IframeOrangeMoney from "./payment/IframeOrangeMoney";
import ChooseModePayment from "./payment/ChooseModePayment";
import NumberComponent from "./payment/NumberComponent";
import CodePinValidateTransaction from "./payment/CodePinValidateTransaction";
import {createPaymentIntent, processPayment, verifyOtp} from "./Hub2Functions";
import PropTypes from "prop-types";
import IframeWave from "./payment/IframeWave";
import {collection, deleteDoc, doc, getDoc, getFirestore, onSnapshot} from "firebase/firestore";
import app from "../services/Firebase";
import IframeOrangeMoneyWeb from "./payment/IframeOrangeMoneyWeb";
import {ROUTE_PRIVATE_WALLET} from "../services/routes/Routes";
import {useNavigate} from "react-router-dom";
import Init from "./payment/Init";
const db = getFirestore(app);
const usersCollection = collection(db, 'users');
const switchPaymentCollection = collection(db, 'switch_payment_orange_money');

function ModalPayment({userPhoneNumber, isOpen, toggle, amount, onOnSuccess, setOperatorValue, onSuccessPersist, onFailPersist, onConnexionLose, setPaymentOrderId, setPaymentTxtId,setLoading}) {
    const [step, setStep] = React.useState(STEP_INIT);
    const [countrySelected, setCountrySelected] = React.useState(null);
    const [operatorSelected, setOperatorSelected] = React.useState(null);
    const [paymentIntentId, setPaymentIntentId] = useState('');
    const [paymentIntentToken, setPaymentIntentToken] = useState('');
    const [cancelPayment, setCancelPayment] = useState(false);
    const [showOrangePaymentIframe, setShowOrangePaymentIframe] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState('');
    const prevStatusRef = React.useRef(paymentStatus);
    const {onSuccessRedirectionUrl, onFailedRedirectionUrl, countries, paymentAvailable, codePayment, apiInformation} = paymentProcessData;
    const navigate = useNavigate();

    useEffect(() => {
        if (prevStatusRef.current !== paymentStatus) {
            prevStatusRef.current = paymentStatus;
            if (paymentStatus === PAYMENT_STATUS_SUCCESS || paymentStatus === PAYMENT_STATUS_SUCCESS_WEB) {
                console.error("************************ [useEffect] - paymentStatus : Paiement successful  ************************");

                onOnSuccess();
                onClose();
                deletewebhookNotif();
            }

            if (paymentStatus === PAYMENT_STATUS_FAILED || paymentStatus === PAYMENT_STATUS_FAILED_WEB) {
                console.error("************************ [useEffect] - Paiement échoué  ************************");
                deletewebhookNotif();
                onClose();
            }
        }
    }, [paymentStatus])

    useEffect(() => {
        getShowOrangePaymentIframe();
    }, [showOrangePaymentIframe])

    const deletewebhookNotif = async() => {
        const userDoc = doc(usersCollection, userPhoneNumber);
        const userPayment = collection(userDoc, "payments");
        const userPayDoc = doc(userPayment, userPhoneNumber)
        if(userPayDoc) {
            await deleteDoc(userPayDoc);
        }
    }

    const getShowOrangePaymentIframe = async () => {
        const switchPayment = doc(switchPaymentCollection, "orange_payment_mode");
        const switchPaymentInfo = await getDoc(switchPayment);
        const mode = switchPaymentInfo.data().mode;
        setShowOrangePaymentIframe(mode === "WEB");
    }

    const webhookListener = () => {
        const userDoc = doc(usersCollection, userPhoneNumber);
        const userPayment = collection(userDoc, "payments");
        const unsubscribe = onSnapshot(userPayment, snapshot => {
            snapshot.docChanges().forEach(change => {
                if (change.type === 'added' || change.type === 'modified') {
                    const response = change.doc.data().data.payments[0]["status"];
                    setPaymentTxtId(change.doc.data().data.payments[0]["id"]);
                    setPaymentOrderId(change.doc.data().data.purchaseReference);

                    let status;
                    if(response === "successful") {
                        setLoading(false)
                        status = PAYMENT_STATUS_SUCCESS;
                        setPaymentStatus(status);
                    }else if(response === PAYMENT_STATUS_FAILED){
                        setLoading(false)
                        status = PAYMENT_STATUS_FAILED;
                        setPaymentStatus(status);
                    }
                  
                
                } else if(change.type === 'removed') {
                    console.error("************************ [webhookListener] - Doc removed ************************");
                    unsubscribe()
                }
            });
        });

    };

    webhookListener();

    const setPrevStep = (nextStep) => {
        setStep(nextStep);
    }

    const setNextStep = async (currentStep, nextStep, elementSelected) => {
        if(currentStep === STEP_COUNTRY) {
            setCountrySelected(elementSelected);
            setOperatorValue(ORANGE);
        }

        if(currentStep === STEP_HUB2_PAYMENT) {
                await createPaymentIntent(
                    userPhoneNumber,
                    amount,
                    setPaymentIntentId,
                    setPaymentIntentToken,
                    apiInformation[countrySelected.country]
                );

            setOperatorSelected(elementSelected);
            setOperatorValue(elementSelected.operator + " " + PROVIDER_HUB2);
        }

        if(currentStep === STEP_PHONE) {
            await processPayment(
                onFailedRedirectionUrl,
                onSuccessRedirectionUrl,
                paymentIntentToken,
                countrySelected.country,
                operatorSelected.operator,
                elementSelected,
                "",
                apiInformation[countrySelected.country],
                paymentIntentId
            );
        }

        if(currentStep === STEP_SMS_CODE) {
            await verifyOtp(
                paymentIntentId,
                paymentIntentToken,
                elementSelected,
                setPaymentStatus
            );
        }

        if(!nextStep) {
            onClose();
            return;
        }

        setStep(nextStep);
    }

    const onClose = () => {
        setCancelPayment(false);
        setStep(STEP_INIT);
        setCountrySelected(null);
        setOperatorSelected(null);
        setPaymentIntentId('');
        setPaymentIntentToken('');
        toggle();
        if(onSuccessPersist) {
            navigate(ROUTE_PRIVATE_WALLET);
        }

        if(navigator.onLine && onConnexionLose) {
            window.location.reload();
        }
    }

    const onCancel = async () => {
        await deletewebhookNotif();
        onClose();
    }

    const showStepContent = () => {
        switch (step) {
            case STEP_INIT:
                return <Init
                    eventNext={setNextStep}
                    eventClose={onClose}
                />
            case STEP_COUNTRY:
                return <ChooseCountries
                    countries={countries}
                    eventNext={setNextStep}
                    eventClose={onClose}
                />
            case STEP_ML_PAYMENT:
                if(showOrangePaymentIframe) {
                    return <IframeOrangeMoneyWeb
                        amount={amount}
                        onStatusChange={setPaymentStatus}
                        eventCancel={() => setCancelPayment(true)}
                        setPaymentOrderId={setPaymentOrderId}
                        setPaymentTxtId={setPaymentTxtId}
                    />
                } else {
                    return <IframeOrangeMoney
                        amount={amount}
                        countryCode={countrySelected.countryCode}
                        onStatusChange={setPaymentStatus}
                        eventCancel={() => setCancelPayment(true)}
                    />
                }
            case STEP_HUB2_PAYMENT:
                return <ChooseModePayment
                    paymentsAvailable={paymentAvailable[countrySelected.country]}
                    eventNext={setNextStep}
                    eventBack={setPrevStep}
                />
            case STEP_PHONE:
                return <NumberComponent
                    amount={amount}
                    countrySelected={countrySelected}
                    operatorInformation={operatorSelected}
                    eventNext={setNextStep}
                    eventBack={setPrevStep}
                    setLoading={setLoading}
                />
            case STEP_WAVE:
                return <IframeWave
                    userPhoneNumber={userPhoneNumber}
                    eventCancel={() => setCancelPayment(true)}
                />
            case STEP_SMS_CODE:
                return <CodePinValidateTransaction
                    operatorImg={operatorSelected}
                    sms={codePayment[countrySelected.country]}
                    eventNext={setNextStep}
                    eventCancel={() => setCancelPayment(true)}
                    setLoading={setLoading}
                />
            default:
                return <ChooseCountries
                    eventNext={setNextStep}
                    eventClose={onClose}
                />
        }
    }

    const handlerPaymentFailStatusContent = () => {
        return (
            <>
                <h5>Une erreur est survenue lors du payment, veuillez réessayer</h5>
                <div align="center" className="pt-5 pb-5">
                    <FontAwesomeIcon icon={faWarning} className="icon-fa" color="red" size={"10x"} />
                </div>
                <div className="container-validate">
                    <Button block className="btn-round btn-korix-orange" size="md" onClick={()=> {onClose(); window.location.reload()}}>
                        Ok
                    </Button>
                </div>
            </>
        )
    }

    const handlerPaymentStatusContent = (onSuccessPersist, onFailPersist, onConnexionLose) => {
        if(onConnexionLose) {
            return (
                <>
                    <h5>Votre paiement a été confirmé avec succès mais la connexion a été perdue. Veuillez vous reconnecter</h5>
                    <div align="center" className="pt-5 pb-5">
                        <FontAwesomeIcon icon={faCheck} className="icon-fa" color="orange" size={"10x"} />
                    </div>
                    <div className="container-validate">
                        <Button block className="btn-round btn-korix-orange" size="md" onClick={onClose}>
                            Ok
                        </Button>
                    </div>
                </>
            )
        }

        if(onSuccessPersist) {
            return (
                <>
                    <h5>Votre Badge a été créé avec succès. Rendez-vous dans la section "Mes Badges" pour le consulter. <br/>
                        Nous vous remercions pour votre confiance.</h5>
                    <div align="center" className="pt-5 pb-5">
                        <FontAwesomeIcon icon={faCheck} className="icon-fa" color="green" size={"10x"} />
                    </div>
                    <div className="container-validate">
                        <Button block className="btn-round btn-korix-orange" size="md" onClick={onClose}>
                            Ok
                        </Button>
                    </div>
                </>
            )
        }

        if(onFailPersist) {
            return (
                <>
                    <h5>Désolé pour le problème actuelle. Contactez notre support au 90501050 pour une assistance rapide</h5>
                    <div align="center" className="pt-5 pb-5">
                        <FontAwesomeIcon icon={faWarning} className="icon-fa" color="red" size={"10x"} />
                    </div>
                    <div className="container-validate">
                        <Button block className="btn-round btn-korix-orange" size="md" onClick={onClose}>
                            Ok
                        </Button>
                    </div>
                </>
            )
        }
    }

    const handlerErrorCancelContent = () => {
        return (
            <>
                <h5>Souhaitez-vous annuler votre paiement ?</h5>
                <div align="center" className="pt-5 pb-5">
                    <FontAwesomeIcon icon={faWarning} className="icon-fa" color="red" size={"10x"} />
                </div>
                <div className="container-validate">
                    <Button block className="btn-round btn-korix-orange" size="md" onClick={onCancel}>
                        oui
                    </Button>
                    <Button block className="btn-round return" size="md" onClick={() => setCancelPayment(false)}>
                        non
                    </Button>
                </div>
            </>
        )
    }

    return(
        <Modal className="modal-badge dark" funk={true} isOpen={isOpen} size="xl">
            <ModalBody className="dark">
                {
                    (step === STEP_COUNTRY || step === STEP_HUB2_PAYMENT) && (
                        <div className="modal-badge-close">
                            <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={onClose} />
                        </div>
                    )
                }
                <div className="modal-badge-button text-center">
                    {
                        cancelPayment ?
                            handlerErrorCancelContent() :
                            (paymentStatus === PAYMENT_STATUS_FAILED || paymentStatus === PAYMENT_STATUS_FAILED_WEB) ?
                                handlerPaymentFailStatusContent() :
                                (paymentStatus === PAYMENT_STATUS_SUCCESS || paymentStatus === PAYMENT_STATUS_SUCCESS_WEB) ?
                                    handlerPaymentStatusContent(onSuccessPersist, onFailPersist) :
                                    showStepContent()
                    }
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalPayment;

ModalPayment.defaultProps = {
    userPhoneNumber: PropTypes.string,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    amount: PropTypes.string,
    setOperatorValue: PropTypes.func,
    onOnSuccess: PropTypes.func,
    setPaymentTxtId: PropTypes.func,
    setPaymentOrderId: PropTypes.func,
    onSuccessPersist: PropTypes.bool,
    onFailPersist: PropTypes.bool,
    onConnexionLose: PropTypes.bool,
    setLoading:PropTypes.func,
};

ModalPayment.propTypes = {
    userPhoneNumber: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    amount: PropTypes.string.isRequired,
    toggle: PropTypes.func.isRequired,
    onOnSuccess: PropTypes.func.isRequired,
    onSuccessPersist: PropTypes.bool.isRequired,
    onFailPersist: PropTypes.bool.isRequired,
    onConnexionLose: PropTypes.bool.isRequired,
    setOperatorValue: PropTypes.func.isRequired,
    setPaymentTxtId: PropTypes.func.isRequired,
    setPaymentOrderId: PropTypes.func.isRequired,
    setLoading:PropTypes.func.isRequired,
}