import {Button, Container} from "reactstrap";
import {useNavigate} from "react-router-dom";
import FooterLight from "../../components/FooterLight";


function Error404() {

    const navigate = useNavigate();

    return (
        <div className="page-error">
            <div className="page-header-page-error">
                <div className="block-page-error">
                    <Container>
                        <div className="logo-container text-center">
                            <img src={require("../../assets/img/Brand/404.png")} alt={"img-404"}/>
                        </div>
                        <div className="text-center pb-5 pt-3">
                            <p>
                                Hmm... Cette page est introuvable. Essayez de retourner à la page d'accueil pour trouver ce que vous cherchez.
                            </p>
                        </div>
                        <Button block className="btn-round btn-korix-black" size="lg" onClick={() => navigate(-1)} color="primary">
                            Retour
                        </Button>
                    </Container>
                </div>
            </div>
            <FooterLight />
        </div>
    )
}

export default Error404;