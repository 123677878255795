import PropTypes from "prop-types";
import React from "react";
import BadgeImage from "./BadgeImage";
import {faInfoCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Row} from "reactstrap";
import {formatPrice} from "../services/Constant";

const BadgeSell = ({index, loading, loadingSelect, indexSelect, badge, onEventImage, onEvent, onMouseDown, onMouseUp, className, sell}) => {

    return <div className="content-badge-sell">
                <div className="content-header-badge-sell">
                    <span>{badge.name}</span>
                    <div className="content-header-image-badge-sell">
                        <BadgeImage
                            loading={loading}
                            badge={badge}
                            className={className}
                            onEvent={onEventImage}
                            conserveContainer={false}
                        />
                    </div>
                </div>
                <div className="content-footer-badge-sell">
                    <Row>
                        <Col xs={8}>
                            <span className="price">{formatPrice(badge.amount)} <small>FCFA</small></span>
                        </Col>
                        <Col xs={4}>
                            {
                                loadingSelect && index === indexSelect ?
                                    <div className="uil-reload-css-reload-xxs">
                                        <div></div>
                                    </div>
                                    :
                                sell ?
                                    <FontAwesomeIcon icon={faInfoCircle} onClick={() => onEvent(badge, index)} className="icon-sell"/>:
                                    <FontAwesomeIcon icon={faTrash} onClick={() => onEvent(badge, index)} className="icon-trash"/>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
}

export default BadgeSell;


BadgeSell.defaultProps = {
    index: PropTypes.number,
    loading: PropTypes.bool,
    badge: PropTypes.object,
    onEventImage: PropTypes.func,
    indexSelect: PropTypes.any,
    loadingSelect: PropTypes.bool,
    onEvent: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMouseDown: PropTypes.func,
    className: PropTypes.string,
    sell: PropTypes.bool,
};

BadgeSell.propTypes = {
    index: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    badge: PropTypes.object.isRequired,
    onEvent: PropTypes.func.isRequired,
    onMouseUp: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    sell: PropTypes.bool.isRequired,
}