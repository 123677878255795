import React, {useEffect, useRef, useState} from "react";
import {Row, Col, Container} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import Qrcode from "qrcode";
import moment from "moment";
import {collection, getFirestore, query, where, getDocs} from "firebase/firestore";
import app from "../services/Firebase";
import CryptoJS from "crypto-js";
import {useLocation, useNavigate} from "react-router-dom";
import BlockComponent from "./BlockComponent";
import {formatDate} from "../services/Constant";
import {ROUTE_500} from "../services/routes/Routes";
const db = getFirestore(app);
const eventsCollection = collection(db, 'events');

function QrCodeBadge() {

    const navigate = useNavigate();
    const {state} = useLocation();
    const { key, img, name, data, whereIComeFrom } = state;
    const [qr, setQr] = useState("");
    const [loading, setLoading] = useState(true);
    const [eventList, setEventList] = useState([]);
    const [eventSelected, setEventSelected] = useState(null);
    const [isManyEvent, setIsManyEvent] = useState(false);
    const qrRef = useRef();

    useEffect(() => {
        if(!state) {
            navigate(ROUTE_500)
        }

    }, [state])

    useEffect(() => {
        fetchEvent(key);
    }, [key]);

    useEffect(() => {
        if(null !== eventSelected && eventSelected.eventId !== "") {
            generateQrCode();
        }
    }, [eventSelected]);

    const fetchEvent = async (key) => {
        const date = moment().format("YYYY-MM-DD");
        const queryEvents = query(eventsCollection, where("badgeId", "==", key), where("endDate", ">=", date));
        const eventSnap = await getDocs(queryEvents);
        if(false === eventSnap.empty) {
            let data = [];

            eventSnap.docs.map((event, index) =>  {
                if(eventSnap.docs.length > 1) {
                    setIsManyEvent(true);
                    data[index] = {
                        id: event.id,
                        name: event.data().name,
                        type: event.data().type,
                        badge: event.data().badge,
                        place: event.data().place,
                        startDate: new Date(event.data().startDate.seconds*1000 + event.data().startDate.nanoseconds/100000),
                        endDate: event.data().endDate
                    };
                } else {
                    setEventSelected(
                        {
                            id: event.id,
                            name: event.data().name,
                            type: event.data().type,
                            badge: event.data().badge,
                            place: event.data().place,
                            startDate: new Date(event.data().startDate.seconds*1000 + event.data().startDate.nanoseconds/100000),
                            endDate: event.data().endDate,
                        }
                    )
                }

                return data;
            })

            setLoading(false);
            setEventList(data);
        } else {
            setLoading(false);
        }
    }
console.log(eventSelected)
    function encryptData(data, secretKey) {
        return CryptoJS.AES.encrypt(
            JSON.stringify(data),
            secretKey
        ).toString();
    }

    const generateQrCode = () => {
        const secretKey = process.env.REACT_APP_QR_KEY;
        const qrInformation = data;
        qrInformation.eventId = eventSelected.id;
        qrInformation.expireDate = eventSelected.endDate;
        qrInformation.name = eventSelected.name;

        const encryptedData = encryptData(qrInformation, secretKey);
        Qrcode.toDataURL(encryptedData, (err, qr) => {
            if(err) {
                console.log('Generate Qrcode fail');
                return;
            }

            setQr(qr);
        });
    }

    const handlerSelectEvent = (event) => {
        setEventSelected(event);
    }

    return (
        <div className="page-qrcode">
            <Container>
                <div className="container-header-page-qrcode">
                    <div className="icon-container-page-qrcode">
                        {
                            null !== eventSelected && isManyEvent ?
                                <FontAwesomeIcon icon={faArrowLeft} onClick={() => setEventSelected(null)} className="icon"/> :
                                <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(whereIComeFrom)} className="icon"/>
                        }
                    </div>
                </div>
                <div className="logo-container-page-qrcode">
                    <img src={img} alt={name}/>
                </div>
                <div className="container-body-page-qrcode">
                    {
                        loading ?
                            <div className="container-loading-body-page-qrcode">
                                <div className="uil-reload-css-reload-small">
                                    <div></div>
                                </div>
                            </div> :
                            <>
                                {
                                    eventSelected ? (
                                        <div className="container-qrcode-body-page-qrcode">
                                            {(qr && <img ref={qrRef} alt={name} src={qr}/>)}
                                            <a href={qr} download="book.png">Download </a>
                                            <div className="container-qrcode-information-page-qrcode">
                                                <span>Valide jusqu'au {formatDate(eventSelected.endDate)}</span>
                                            </div>
                                        </div>
                                    ) :
                                        (
                                            <>
                                                {
                                                   eventList.length === 0 && (
                                                        <div className="text-center">
                                                            <h4>Aucun événement en cours</h4>
                                                            <p>Restez à l'écoute pour des événements exclusifs</p>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    isManyEvent && (
                                                        <>
                                                            <h4>Les évènements</h4>
                                                            <div className="container-body-block-page-qrcode">
                                                                <Row>
                                                                    {
                                                                        eventList.map((event, index) => {
                                                                            return (
                                                                                <Col key={index} xs={12}>
                                                                                    <BlockComponent
                                                                                        badge={null}
                                                                                        loading={false}
                                                                                        onEvent={handlerSelectEvent}
                                                                                        information={
                                                                                            {
                                                                                                id: event.id,
                                                                                                artistName: event.badge,
                                                                                                endDate: event.endDate,
                                                                                                startDate: event.startDate,
                                                                                                place: event.place,
                                                                                                type: event.type,
                                                                                                name: event.name
                                                                                            }
                                                                                        }
                                                                                    />
                                                                                </Col>
                                                                            )
                                                                        })
                                                                    }
                                                                </Row>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        )
                                }
                            </>

                    }
                </div>
            </Container>
        </div>
    )
}

export default QrCodeBadge;