import { useEffect, useState } from 'react';
import { getFirestore, collection, doc, setDoc, addDoc, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import NavBar from "../../../../components/NavBar";
import app from '../../../../services/Firebase';
import {Alert, Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import ModalNewAdvantage from "./ModalNewAdvantage";
import ModalBadgeContract from "./ModalBadgeContract";
import Switch from 'react-bootstrap-switch';
import {categories} from "../../../../services/Constant";
import ModalPriviledUsersList from './ModalPrivilegedUsers';
const db = getFirestore(app);
const storage = getStorage(app)
const badgeCollection = collection(db, 'badge-collection');
const partnersCollection = collection(db, 'partners-collection');

function Projects() {

    const [isOpen, setIsOpen] = useState(false);
    const [badgeName, setBadgeName] = useState("");
    const [badgeAdvantage, setBadgeAdvantage] = useState([{ actor: "", advantage: "" }]);
    const [startAt, setStartAt] = useState("");
    const [endAt, setEndAt] = useState("");
    const [toDeploy, setToDeploy] = useState(false);
    const [img, setImg] = useState("");
    const [badgeCategory, setBadgeCategory] = useState("Musique");
    const [imgFile, setImgFile] = useState("");
    const [badgePrice, setBadgePrice] = useState("");
    const [badgeDetails, setBadgeDetails] = useState("");
    const [badgeAddress, setBadgeAddress] = useState("");
    const [badgeOwner, setBadgeOwner] = useState("");
    const [errorField, setErrorField] = useState(false);
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [isSponsorised, setIsSponsorised] = useState(false);
    const [sponsorName, setSponsorName] = useState('');
    const [sponsorDetails, setSponsorDetails] = useState('');
    const [totalOffer, setTotalOffer] = useState('');
    const [discount, setDiscount] = useState('');
    const [partners, setPartners] = useState([]);
    const [collectionImg, setColectionImg] = useState("");
    const [collectionName, setCollectionName] = useState("")
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [isOpenUsersList, setIsOpenUsersList] = useState(false);
    const [badgeSerial, setBadgeSerial] = useState("")
    const [liveimg, setLiveImg] = useState("");
    const [liveImgFile, setLiveImgFile] = useState("");
    const [totalSupply, setTotalSupply] = useState('');
    const [referralDiscount, setReferralDiscount] = useState('');
    const [isReferrable, setIsReferrable] = useState(false);
    const [isCreator, setIsCreator] = useState(false);
    const [emailOwner, setEmailOwner] = useState("");
    const [isBadgePrivate, setIsBadgePrivate] = useState(false);
    const [privilegedUsersList, setPrivilegedUsersList] = useState([{ name: "", number: "" }]);
    const handleIsSponsorisedChange = () => {
        setIsSponsorised(!isSponsorised);
    };
    const handleIsReferrable = () => {
        setIsReferrable(!isReferrable);
    };

    const handleIsCreator = () => {
        setIsCreator(!isCreator);
    };
    
    const handleIsPrivate = () => {
        setIsBadgePrivate(!isBadgePrivate);
    };

    // FETCH PARTNERS COLLECTIONS BADGES
    useEffect(() => {
        const fetchBadges = async () => {
            const partnersDocs = await getDocs(partnersCollection);
            if (partnersDocs.docs.length > 0) {
                var collectionsPartners = [];
                partnersDocs.docs.map((badge, index) => {
                    collectionsPartners[index] = {
                        address: badge.data().contractAddress,
                        collectionName: badge.data().collectionName,
                        category: badge.data().category,
                        ownerNumber: badge.data().ownerNumber,
                        collectionImg: badge.data().imageUrl,
                    };
                });
                setPartners(collectionsPartners);
            }
        }

        fetchBadges();
    }, []);

    const handleChangePartner = (event) => {
        setCollectionName(partners[event.target.value].collectionName);
        setBadgeAddress(partners[event.target.value].address);
        setBadgeCategory(partners[event.target.value].category);
        //setBadgeOwner(partners[event.target.value].ownerNumber);
        setColectionImg(partners[event.target.value].collectionImg)
    }

    const onImageChange = (e) => {
        const [file] = e.target.files;
        if (file.size < 50000000) {
            setImg(URL.createObjectURL(file));
            setImgFile(e.target.files[0]);
        } else {
            setImg("");
            setImgFile("");
            setAlert(true);
            setMessage("file size larger than 50MB");
        }
    };

    const onLiveImageChange = (e) => {
        const [file] = e.target.files;
        if (file.size < 50000000) {
            setLiveImg(URL.createObjectURL(file));
            setLiveImgFile(e.target.files[0]);
        } else {
            setLiveImg("");
            setLiveImgFile("");
            setAlert(true);
            setMessage("file size larger than 50MB");
        }
    };

    function uploadImageAndGetURL(imageFile) {
        return new Promise((resolve, reject) => {
            const uploadTask = ref(storage, `badges/${imageFile.name}`);
            uploadBytes(uploadTask, imageFile)
                .then((image) => {
                    getDownloadURL(image.ref)
                        .then((url) => {
                            resolve(url);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    

    const handlerValidateProject = () => {
        if (badgeSerial === "" || badgeAddress === "" || badgeName === "" || startAt === "" || endAt === "" || badgeDetails === "" || badgePrice === "" || badgeOwner === "") {
            setAlert(true);
            setErrorField(true);
            setMessage("Veuillez remplir tous les champs marqués par '*'");
            return;
        }

        const imgUploadPromise = uploadImageAndGetURL(imgFile);
        const liveImgUploadPromise = uploadImageAndGetURL(liveImgFile);
        const badgeCollectionDocID = doc(badgeCollection, badgeSerial);
        Promise.all([imgUploadPromise, liveImgUploadPromise])
        .then(([imgUrl, liveImgUrl]) => {
                    setDoc(badgeCollectionDocID, {
                        name: badgeName,
                        category: badgeCategory,
                        amount: badgePrice,
                        address: badgeAddress,
                        details: badgeDetails,
                        advantages: { ...badgeAdvantage },
                        dateStart: startAt,
                        dateEnd: endAt,
                        badgeOwner: badgeOwner,
                        emailOwner: emailOwner,
                        listed: true,
                        isSponsorised: isSponsorised,
                        sponsorName: isSponsorised === true ? sponsorName : null,
                        sponsorDetails: isSponsorised === true ? sponsorDetails : null,
                        totalOffer: isSponsorised === true ? totalOffer : null,
                        discount: isSponsorised === true ? discount : null,
                        totalSupply: totalSupply !== "" ? totalSupply : null,
                        imageUrl: imgUrl,
                        liveImageUrl: liveImgUrl,
                        collectionImg: collectionImg,
                        collectionName:collectionName,
                        badgeSerial:badgeSerial,
                        onLine:false,
                        isCreator:isCreator,
                        isreferrable:isReferrable,
                        referralDiscount: isReferrable === true ? referralDiscount : null,
                        isBadgePrivate: isBadgePrivate,
                        privilegedUsersList: isBadgePrivate  === true ? {...privilegedUsersList} : null,
                    })
                    .then(() => {
                        setIsOpenDialog(true);
                    })
                    .catch((error) => {
                        console.log(error.message);
                    });
            })
            .catch((error) => {
                console.log(error.message);
            });
    }

    const handleDialogOK = () => {
        setIsOpenDialog(false);
        window.location.reload();
      };

    return (
        <>
            <NavBar />
            <div className="page-header-white">
                <Container className="ml-auto mr-auto">
                    <Row className="pb-4">
                        <Col className="pt-4" lg="4">
                            <Button block className="btn-round" color="default" onClick={() => { setToDeploy(true) }}>Créer une nouvelle collection</Button>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg="8">
                            <div className="home-badge">
                                <h3 className="home-badge-title">Ajouter des metadata pour un badge</h3>
                            </div>
                        </Col>
                    </Row>
                    <div className="pt-4">
                        {
                            alert && (
                                <Alert color="danger" isOpen={alert} toggle={() => setAlert(false)}>
                                    {message}
                                </Alert>
                            )
                        }
                        <FormGroup className={badgeAddress === "" && errorField ? "has-danger" : ""}>
                            <Label for="nameSelected" className="control-label">Choisir une collection</Label>
                            <Input type="select" name="select" id="nameSelected" onChange={handleChangePartner}>
                                <option>Sélectionner une collection</option>
                                {
                                    partners.map((element, index) => <option key={index} value={index}>{element.collectionName}</option>)
                                }
                            </Input>
                            <hr />
                        </FormGroup>

                        {isSponsorised && (
                            <>
                                <FormGroup className={sponsorName === "" && errorField ? "has-danger" : ""}>
                                    <Label for="sponsorName" className="control-label">Nom du sponsor</Label>
                                    <Input type="text" name="sponsorName" id="sponsorName" value={sponsorName} onChange={(e) => setSponsorName(e.target.value)} />
                                </FormGroup>

                                <FormGroup className={sponsorDetails === "" && errorField ? "has-danger" : ""}>
                                    <Label for="sponsorDetails" className="control-label">Details du sponsor</Label>
                                    <Input type="textarea" name="sponsorDetails" id="sponsorDetails" placeholder="Détail sponsor" value={sponsorDetails} onChange={(e) => setSponsorDetails(e.target.value)} />
                                </FormGroup>

                                <FormGroup className={totalOffer === "" && errorField ? "has-danger" : ""}>
                                    <Label for="totalOffer" className="control-label">Offre Total</Label>
                                    <Input type="number" name="totalOffer" id="totalOffer" placeholder="Offre Total" value={totalOffer} onChange={(e) => setTotalOffer(e.target.value)} />
                                </FormGroup>

                                <FormGroup className={discount === "" && errorField ? "has-danger" : ""}>
                                    <Label for="discount" className="control-label">Pourcentage de réduction</Label>
                                    <Input type="number" name="discount" id="discount" placeholder="50%" value={discount} onChange={(e) => setDiscount(e.target.value)} />
                                </FormGroup>
                            </>
                        )}
                        <hr />
                        {isReferrable && (
                            <FormGroup className={referralDiscount === "" && errorField ? "has-danger" : ""}>
                                <Label for="referralDiscount" className="control-label">Pourcentage de rabais</Label>
                                <Input type="number" name="referralDiscount" id="referralDiscount" placeholder="20%" value={referralDiscount} onChange={(e) => setReferralDiscount(e.target.value)} />
                            </FormGroup>
                        )}
                        
                        <hr />
                        <FormGroup display="flex" alignItems="center">
                            <Label color="black">Créateur</Label>
                            <Switch isChecked={isSponsorised} onChange={handleIsCreator} />
                        </FormGroup>  

                        <FormGroup display="flex" alignItems="center">
                            <Label color="black">Badge sponsorisé</Label>
                            <Switch isChecked={isSponsorised} onChange={handleIsSponsorisedChange} />
                        </FormGroup>

                        <FormGroup display="flex" alignItems="center">
                            <Label color="black">Badge referrable</Label>
                            <Switch isChecked={isReferrable} onChange={handleIsReferrable} />
                        </FormGroup>

                        <FormGroup display="flex" alignItems="center">
                            <Label color="black">Badge privé</Label>
                            <Switch isChecked={isBadgePrivate} onChange={handleIsPrivate} />
                        </FormGroup>

                        {isBadgePrivate && (
                            <Row>
                            <Col className="col-lg-11">
                                <label>Ajouter des utilisateurs vedettes</label>
                            </Col>
                            <Col className="col-lg-1">
                                <Button block className="btn-round" color="primary" onClick={() => setIsOpenUsersList(true)}><FontAwesomeIcon icon={faPlus} /></Button>
                            </Col>
                        </Row>
                        )}
                        <ModalPriviledUsersList isOpenUsersList={isOpenUsersList} toggle={() => setIsOpenUsersList(!isOpenUsersList)} privilegedUsersList={privilegedUsersList} setPrivilegedUsersList={setPrivilegedUsersList} />

                        <FormGroup className={badgeName === "" && errorField ? "has-danger" : ""}>
                            <Label for="name" className="control-label">Nom</Label>
                            <Input type="text" name="name" id="name" placeholder="Nom du badge" onChange={(event) => setBadgeName(event.target.value)} />
                        </FormGroup>

                        <FormGroup className={badgeSerial === "" && errorField ? "has-danger" : ""}>
                            <Label for="name" className="control-label">Numéro de série du badge</Label>
                            <Input type="text" name="name" id="name" placeholder="Série du badge" onChange={(event) => setBadgeSerial(event.target.value)} />
                        </FormGroup>
                        <FormGroup >
                                    <Label for="totalSupply" className="control-label">Quantité Total de Badge</Label>
                                    <Input type="number" name="totalSupply" id="totalSupply" placeholder="Quantité Total Fixe" value={totalSupply} onChange={(e) => setTotalSupply(e.target.value)} />
                         </FormGroup>
                        <FormGroup className={badgePrice === "" && errorField ? "has-danger" : ""}>
                            <Label for="name" className="control-label">Prix</Label>
                            <Input type="text" name="name" id="name" placeholder="Prix" onChange={(event) => setBadgePrice(event.target.value)} />
                        </FormGroup>
                        <FormGroup className={badgeOwner === "" && errorField ? "has-danger" : ""}>
                            <Label for="name" className="control-label">Numéro du Créateur</Label>
                            <Input type="phone" name="name" id="name" placeholder="Numéro du Créateur" value={badgeOwner} onChange={(event) => setBadgeOwner(event.target.value)} />
                        </FormGroup>
                        <FormGroup className={emailOwner === "" && errorField ? "has-danger" : ""}>
                            <Label for="emailOwner" className="control-label">Email du partenaire</Label>
                            <Input type="email" name="name" id="emailOwner" placeholder="Numéro du Créateur" value={emailOwner} onChange={(event) => setEmailOwner(event.target.value)} />
                        </FormGroup>
                        <FormGroup className={badgeCategory === "" && errorField ? "has-danger" : ""}>
                            <Label for="category" className="control-label">Catégories</Label>
                            <Input type="select" name="select" id="category" onChange={(category) => setBadgeCategory(category.target.value)}>
                                {
                                    categories.map((element, index) => <option key={index} value={element}>{element}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup className={startAt === "" && errorField ? "has-danger" : ""}>
                            <label
                                className="form-control-label"
                                htmlFor="example-date-input"
                            >
                                Date de début
                            </label>
                            <Input
                                defaultValue={new Date().getFullYear() + "-11-23"}
                                onChange={(date) => setStartAt(date.target.value)}
                                id="example-date-input"
                                type="date"
                            />
                        </FormGroup>
                        <FormGroup className={endAt === "" && errorField ? "has-danger" : ""}>
                            <label
                                className="form-control-label"
                                htmlFor="example-date-input"
                            >
                                Date de fin
                            </label>
                            <Input
                                defaultValue={new Date().getFullYear() + "-11-23"}
                                onChange={(date) => setEndAt(date.target.value)}
                                id="example-date-input"
                                type="date"
                            />
                        </FormGroup>
                        <FormGroup className={badgeDetails === "" && errorField ? "has-danger" : ""}>
                            <Label for="name" className="control-label">Détail badge</Label>
                            <Input type="textarea" name="name" id="name" placeholder="Détail badge" onChange={(event) => setBadgeDetails(event.target.value)} />
                        </FormGroup>
                        <Row className="pb-3">
                            <Col lg="9">
                                <FormGroup className={badgeAddress === "" && errorField ? "has-danger" : ""}>
                                    <Label for="name" className="control-label">Adresse badge</Label>
                                    <Input type="textarea" name="name" id="name" placeholder="Adresse badge" value={badgeAddress} onChange={(event) => { setBadgeAddress(event.target.value) }} />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col className="col-lg-11">
                                <label>Ajouter des avantages</label>
                            </Col>
                            <Col className="col-lg-1">
                                <Button block className="btn-round" color="primary" onClick={() => setIsOpen(true)}><FontAwesomeIcon icon={faPlus} /></Button>
                            </Col>
                        </Row>
                        <ModalNewAdvantage isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} badgeAdvantage={badgeAdvantage} setBadgeAdvantage={setBadgeAdvantage} />
                        <div className="pb-3">
                            <label className="lable">
                                Image du Badge<i> *</i>
                            </label>
                            <div className="custom-file">
                                <input
                                    className="custom-file-input"
                                    id="media"
                                    lang="en"
                                    type="file"
                                    onChange={(e) => onImageChange(e)}
                                />
                                <label className="custom-file-label" htmlFor="customFileLang">
                                    Choisir l'image du badge
                                </label>
                            </div>
                            {img === "" ? <span className="icon"></span>
                                : <img src={img} alt="" style={{ padding: '10px', width: '80px', height: 'auto' }} />
                            }
                        </div>
                        <div className="pb-3">
                            <label className="lable">
                                Image Live<i> *</i>
                            </label>
                            <div className="custom-file">
                                <input
                                    className="custom-file-input"
                                    id="media"
                                    lang="en"
                                    type="file"
                                    onChange={(e) => onLiveImageChange(e)}
                                />
                                <label className="custom-file-label" htmlFor="customFileLang">
                                    Choisir l'image live du badge
                                </label>
                            </div>
                            {liveimg === "" ? <span className="icon"></span>
                                : <img src={liveimg} alt="" style={{ padding: '10px', width: '80px', height: 'auto' }} />
                            }
                        </div>

                        <Button block className="btn-round" color="primary" onClick={handlerValidateProject}>Valider</Button> {" "}
                    </div>
                </Container>
            </div>
            <ModalBadgeContract isOpen={toDeploy} toggle={() => setToDeploy(!toDeploy)} />
            <Modal isOpen={isOpenDialog} centered>
                <ModalBody>
                    <p>La badge {badgeName} a été ajouté avec succès</p>
                </ModalBody>
                <div className="d-flex justify-content-end p-3">
                    <Button onClick={handleDialogOK}>OK</Button>
                </div>
            </Modal>
        </>
    )
}

export default Projects;