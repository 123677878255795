import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Container, Row, Col, Alert} from 'reactstrap';
import { removeFromCart } from '../../services/stores/actions/CartActions';
import NavBar from '../../components/NavBar';
import {conectAccount, requestAccount, selectAccont, selectPhoneNumber} from "../../services/stores/nft/NftSlice";
import {formatPrice} from '../../services/Constant';
import BadgeSell from "../../components/BadgeSell";
import Footer from "../../components/Footer";
import * as Sentry from "@sentry/browser";
import {
    ROUTE_OTP,
    ROUTE_PRIVATE_CART,
    ROUTE_PRIVATE_MARKETPLACE,
} from "../../services/routes/Routes";
import {useLocation, useNavigate} from "react-router-dom";
import ModalPayment from "../../components/ModalPayment";
import ModalAskCodePin from "../../components/ModalAskCodePin";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowLeft, faArrowUp} from '@fortawesome/free-solid-svg-icons';
import {collection, doc, getFirestore, setDoc} from "firebase/firestore";
import app from "../../services/Firebase";
import {Badge} from "../../services/models/Badge";
import {getUserBadgeIds, saveBadge, saveBadgeMint, saveReferralCount} from "../../services/queries/Badge";
import {addBadgesToCache} from "../../services/stores/IndexDB";
import InputComponent from "../../components/input/InputComponent";
import {getUser} from "../../services/queries/Users";
import {ModalCountryCode} from "../../components/ModalCountryCode";
const db = getFirestore(app);
const persistDataFailPaymentSuccessCollection = collection(db, 'persist_data_fail_payment_success');

const CartPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cartItems = useSelector((state) => state.cart.items);
    const [loading, setLoading] = React.useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const userReducers = useSelector((state) => state.userReducers);
    const userTel = useSelector(selectPhoneNumber);
    const userPublicKey = useSelector(selectAccont);
    const {state} = useLocation();
    const { user } = userReducers;
    const [onSuccessPersist, setOnSuccessPersist] = useState(false);
    const [onFailPersist, setOnFailPersist] = useState(false);
    const [onConnexionLose, setOnConnexionLose] = useState(false);
    const [operatorValue, setOperatorValue] = useState('');
    const [paymentTxtId, setPaymentTxtId] = useState('');
    const [paymentOrderId, setPaymentOrderId] = useState('');
    const codePinReducers = useSelector((state) => state.codePinReducers);
    const { lastTimeStampCodePinGenerate } = codePinReducers;
    const [isOpenCodePin, setIsOpenCodePin] = useState(false);
    const [byToAnother, setByToAnother] = useState(false);
    const [countryCode, setCountryCode] = useState('+223');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState("");
    const [fieldError, setFieldError] = useState(false);
    const [visible,setVisible] = React.useState(false);
    const [error, setError] = useState(false);
    const [isOpenCountryCode, setIsOpenCountryCode] = React.useState(false);
    const [anotherUser, setAnotherUser] = React.useState(null);

    useEffect(() => {
        if(state && state.showModal) {
            setIsOpen(true);
            window.history.replaceState({}, document.title)
        }
    }, [state])

    useEffect(() => {
        document.documentElement.classList.remove("nav-open");
        var element = document.getElementById("button-nav-close");
        element.classList.remove("toggled");

        dispatch(conectAccount());
        requestAccount()
    }, [dispatch, userTel])

    const onOnSuccess = async () => {
        await buyAllBadges();
    };

    const handleRemoveFromCart = (badge) => {
        dispatch(removeFromCart(badge.key));
    };

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        cartItems.forEach(item => {
            const price = parseFloat(item.amount);
            totalPrice += price;
        });
        return totalPrice.toFixed(2);
    };

    const handelBuyBadge = (badge) => {
        return new Promise(async (resolve) => {
            try {
                console.error(`************ [handelBuyBadge] : promise process badge ${JSON.stringify(badge)} *********************`);

                const referralID = badge.referralID ? badge.referralID : null;
                const referrerID = badge.referrerID ? badge.referrerID : null;

                let phoneNumber = user.phoneNumber;
                let userName = user.lastName + " " + user.firstName;
                let buyer = null;
                if(null !== anotherUser) {
                    phoneNumber = anotherUser.phoneNumber;
                    userName = anotherUser.firstName + " " + anotherUser.lastName;
                    buyer = user.phoneNumber;
                }

                const badgeModel = new Badge(
                    'Badges', phoneNumber, userPublicKey, operatorValue, badge.badgeSerial,
                    referralID,  referrerID, paymentOrderId, paymentTxtId, userName, buyer,
                );
                await saveBadge(badgeModel);
                await saveBadgeMint(badgeModel);

                if(badge.isReferralUsed){
                    await saveReferralCount(badgeModel);
                }

                dispatch(removeFromCart(badge.key));
                resolve (true);
            } catch (error) {
                resolve(false);
                console.error("/////////////////////////// [handelBuyBadge] : Catch exception ///////////////////////////////");
                Sentry.addBreadcrumb({
                    category: "[handelBuyBadge]",
                    message: error.toString(),
                    level: "warning",
                });
                throw new Error(error.toString());
            }
        });
    }

    const buyAllBadges = async () => {
        console.error("************ [buyAllBadges] : Begin mint process *********************");
        try {
            setLoading(true);
            let tryToPersist = true;
            let count = 0;

            const badgeObjects = cartItems.filter((badge) => typeof badge === 'object');
            if(!navigator.onLine) {
                try {
                    console.error("+++++++++++++++++++ [buyAllBadges] : END mint process connexion lose +++++++++++++++++++");
                    setIsOpen(true);
                    setOnConnexionLose(true);
                    setLoading(false);
                    badgeObjects.map((badge) => {
                        dispatch(removeFromCart(badge.key));
                    });
                    await addBadgesToCache(badgeObjects, userPublicKey, operatorValue, user.phoneNumber, paymentOrderId, paymentTxtId);
                    return;
                } catch (error) {
                    setLoading(false);
                    setIsOpen(true);
                    console.error("/////////////////////////// [buyAllBadges] : catch exceptions connexion lose  ///////////////////////////////");
                    Sentry.addBreadcrumb({
                        category: "buyAllBadges",
                        message: error.toString(),
                        level: "error",
                    });
                    throw new Error(error.toString());
                }
            }

            let collectionPromiseBuyBadge = [];
            let badgesInformation = [];
            badgeObjects.map((badge) => {
                badgesInformation.push(
                    {
                        badgeSerial: badge.badgeSerial,
                        referralID: badge.referralID ? badge.referralID : null
                    });
                collectionPromiseBuyBadge.push(handelBuyBadge(badge));

                return collectionPromiseBuyBadge;
            });

            do {
                console.error("************ [buyAllBadges] : Begin mint process do/while *********************");
                const responsesPromise = await Promise.all(collectionPromiseBuyBadge);

                let response = true;
                for (let i = 0; i < responsesPromise.length; i++) {
                    if (responsesPromise[i] === false) {
                        response = false;
                        break;
                    }
                }

                if(response) {
                    console.error("************ [buyAllBadges] : END mint process persist OK *********************");

                    setIsOpen(true);
                    setLoading(false);
                    setOnSuccessPersist(true);
                    tryToPersist = false;
                } else {
                    console.error(`+++++++++++++++++++ [buyAllBadges] : PENDING mint ${count} tentative to persist KO +++++++++++++++++++`);
                    Sentry.addBreadcrumb({
                        category: "[buyAllBadges]",
                        message: `PENDING mint ${count} tentative to persist KO +`,
                        level: "warning",
                    });
                    count++;
                }

                if(count === 3) {
                    console.error(`/////////////////////////// [buyAllBadges] : END mint ${count} tentative to persist KO ///////////////////////////`);
                    const persistDataFailPaymentSuccessId = doc(persistDataFailPaymentSuccessCollection, paymentTxtId);
                    await setDoc(persistDataFailPaymentSuccessId, {
                        date: new Date().toString(),
                        collectionBadgeInformation: badgesInformation,
                        payment: operatorValue,
                        paymentOrderId: paymentOrderId,
                        paymentTxiId: paymentTxtId,
                        publicID: user.phoneNumber,
                    });
                    tryToPersist = false;
                    setIsOpen(true);
                    setOnFailPersist(true);
                    setLoading(false);

                    Sentry.addBreadcrumb({
                        category: "[buyAllBadges]",
                        message: `END mint ${count} tentative to persist KO - badgesInformationPersist: ${JSON.stringify(badgesInformation)}`,
                        level: "warning",
                    });
                    console.error(`END mint ${count} tentative to persist KO - badgesInformationPersist: ${JSON.stringify(badgesInformation)}`)
                }
            } while (tryToPersist);
        } catch (error) {
            setLoading(false);
            setIsOpen(true);
            console.error("/////////////////////////// catch exception buyAllBadges ///////////////////////////////");
            Sentry.addBreadcrumb({
                category: "buyAllBadges",
                message: error.toString(),
                level: "error",
            });
            throw new Error(error.toString());
        }
    }

    const handlerPayed = async () => {
        if(cartItems.length > 0) {
            if(true === byToAnother) {
                if("" === phone  || true === fieldError) {
                    setVisible(true);
                    setError(true);
                    setMessage("Merci de renseigner le numéro du tiers.");
                    return;
                }

                const phoneNumber = `${countryCode}${phone}`;
                const user = await getUser(phoneNumber);
                if(!user) {
                    setVisible(true);
                    setError(true);
                    setMessage("Acxes ne reconnaît pas ce numéro de téléphone.");
                    return;
                }

                const anotherUserBadgeId = await getUserBadgeIds(phoneNumber);
                if (anotherUserBadgeId.length > 0) {
                    let asBadges = "";
                    cartItems.forEach(item => {
                        if(anotherUserBadgeId.includes(item.badgeSerial)) {
                            asBadges += item.name + ", ";
                        }
                    });

                    if(asBadges !== "") {
                        setVisible(true);
                        setError(true);
                        setMessage(`Le ${phone} possède déja ${asBadges}. Nous vous prions de bien vouloir les retirer du panier afin de finaliser l'achat s'il vous plaît`);
                        return false;
                    }
                }

                setAnotherUser(user);
                setVisible(false);
                setError(false);
            } else {
                const anotherUserBadgeId = await getUserBadgeIds(user.phoneNumber);
                if (anotherUserBadgeId.length > 0) {
                    let asBadges = "";
                    cartItems.forEach(item => {
                        if(anotherUserBadgeId.includes(item.badgeSerial)) {
                            asBadges += item.name + ", ";
                        }
                    });

                    if(asBadges !== "") {
                        setVisible(true);
                        setError(true);
                        setMessage(`Vous possèdez déja ${asBadges}. Nous vous prions de bien vouloir les retirer du panier afin de finaliser l'achat s'il vous plaît`);
                        return false;
                    }
                }
            }

            const state = {
                whereIComeFrom: ROUTE_PRIVATE_CART,
                whereIGo: ROUTE_PRIVATE_CART,
                showModal: true,
            };

            if(!user.asCodePin) {
                setIsOpenCodePin(!isOpenCodePin);
                return;
            }

            const currentTimestamp = Date.now();
            const lastTimeStampCodePinGeneratePlus30sec = lastTimeStampCodePinGenerate + 30000;
            if (null === lastTimeStampCodePinGenerate || lastTimeStampCodePinGeneratePlus30sec < currentTimestamp) {
                navigate(ROUTE_OTP, { state: state});
            } else {
                setIsOpen(!isOpen);
            }
        }
    }

    const handlerNotCodePin = () => {
        setIsOpenCodePin(!isOpenCodePin);
        setIsOpen(!isOpen)
    }

    return (
        <>
            <NavBar />
            <ModalPayment
                userPhoneNumber={user.phoneNumber}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                amount={calculateTotalPrice()}
                onOnSuccess={onOnSuccess}
                onSuccessPersist={onSuccessPersist}
                onFailPersist={onFailPersist}
                onConnexionLose={onConnexionLose}
                setOperatorValue={setOperatorValue}
                setPaymentOrderId={setPaymentOrderId}
                setPaymentTxtId={setPaymentTxtId}
                setLoading={setLoading}
            />
            <div className="shopping-cart-page">
                <Container>
                    {
                        loading && (
                            <>
                                <div className="modal-loading text-center">
                                    <div className="modal-loading-content">
                                        <div className="uil-reload-css-reload-small">
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <span className="modal-loading-span">Veuillez patienter s'il vous plaît. <br/> Votre badge est en cour de creation...</span>
                                </div>
                            </>
                        )
                    }
                    <div className="block-shopping-cart-page pb-3">
                        <div className="icon-container-cart-page">
                            <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(ROUTE_PRIVATE_MARKETPLACE)} className="icon" />
                        </div>
                        <h4>Mon panier</h4>
                        <div className="block-shopping-badge-cart-page">
                            <Row className="pt-3">
                                {
                                    cartItems.map((badge, index) => {
                                        return (
                                            <Col xs={6} className="pt-3">
                                                <BadgeSell
                                                    index={index}
                                                    loading={false}
                                                    badge={badge}
                                                    onEventImage={null}
                                                    loadingSelect={null}
                                                    indexSelect={null}
                                                    onEvent={handleRemoveFromCart}
                                                    className={"sell"}
                                                    sell={false}
                                                />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>

                            <div className="block-shopping-total-cart-page">
                                <Row>
                                    <Col xs={4} className="block-shopping-total-label-cart-page">
                                        <span>Total</span>
                                    </Col>
                                    <Col xs={8} className="block-shopping-total-price-cart-page">
                                        <span className="price">{formatPrice(parseInt(calculateTotalPrice()))} <small>FCFA</small></span>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    {
                        error && (
                            <Alert color="danger" isOpen={visible} toggle={() => setVisible(!visible)}>
                                {message}
                            </Alert>
                        )
                    }
                    {
                        <div className="payment-another-cart-page">
                            <div className="payment-another-container-information-cart-page">
                                <h4>Payer pour un tiers</h4>
                                <FontAwesomeIcon icon={byToAnother ? faArrowUp : faArrowDown} className="icon" onClick={() => setByToAnother(!byToAnother)}/>
                            </div>
                            {
                                byToAnother && (
                                    <>
                                        <div className="container-phone-page-login">
                                            <InputComponent
                                                type="tel"
                                                name="phone"
                                                placeholder="Numéro de téléphone du tiers"
                                                className={"container-input-field-page-login"}
                                                onChange={(value) => setPhone(value)}
                                                label={""}
                                                disabled={false}
                                                defaultValue={phone}
                                                required={true}
                                                setFieldError={setFieldError}
                                                code={
                                                    <div className="container-code-icon-page-login" onClick={() => setIsOpenCountryCode(!isOpenCountryCode)}><span>{countryCode}</span></div>
                                                }
                                            />
                                        </div>
                                        <p>Pour effectuer le paiement pour un tiers, veuillez fournir le numéro de téléphone de la personne, puis cliquer sur le bouton 'Payer'</p>
                                    </>
                                )
                            }
                        </div>
                    }
                    <div className="container-validate-cart-page pt-3">
                        <Button block className="btn-round btn-korix-black" size="md" onClick={handlerPayed}>
                            Payer
                        </Button>
                    </div>
                </Container>
            </div>
            <ModalAskCodePin isOpen={isOpenCodePin} toggle={() => setIsOpenCodePin(!isOpenCodePin)} state={{
                whereIComeFrom: ROUTE_PRIVATE_CART,
                whereIGo: ROUTE_PRIVATE_CART,
            }} modal={true} event={handlerNotCodePin}/>
            <ModalCountryCode
                isOpen={isOpenCountryCode}
                toggle={() => setIsOpenCountryCode(!isOpenCountryCode)}
                setCountryCode={setCountryCode}
            />
            <Footer/>
        </>
    )
};

export default CartPage;
