import React, { useState, useEffect } from 'react';
import bcrypt from 'bcryptjs';
import { doc, collection, getFirestore, getDoc } from 'firebase/firestore';
import app from '../../services/Firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useDispatch, useSelector} from 'react-redux';
import {setGenerateLastCodePin} from "../../services/stores/actions/CodePinActions";
import OtpInputComponent from "../../components/otp/OtpInputComponent";
import {CODE_PIN_LENGTH} from "../../services/Constant";
import {useLocation, useNavigate, useNavigationType} from "react-router-dom";
import {Container} from "reactstrap";
import {faArrowLeft, faLock} from "@fortawesome/free-solid-svg-icons";
import {ROUTE_500, ROUTE_OTP_PRIVATE_FIREBASE} from "../../services/routes/Routes";

const db = getFirestore(app);
const userCollection = collection(db, 'users');

function Otp() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navType: NavigationType = useNavigationType();
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;
    const {state} = useLocation();
    const [error, setError] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        if(!state) {
            navigate(ROUTE_500)
        } else if(navType === "POP") {
            navigate(state.whereIComeFrom);
        }

    }, [state])

    const handleOnClick = async (otp) => {
        setError(false);

        if("" === otp || otp.length !== CODE_PIN_LENGTH) {
            setError(true);
            setVisible(true);
            setLoading(false);
            setMessage("Veuillez renseigner un code Pin valide");
            return;
        }

        setLoading(true);
        const userDocRef = doc(userCollection, user.phoneNumber);
        const userDocSnapshot = await getDoc(userDocRef);
        const userData = userDocSnapshot.data();
        const storedHashedPassword = userData.codePin;

        bcrypt.compare(otp, storedHashedPassword, (err, result) => {
            if (result) {
                dispatch(setGenerateLastCodePin());
                navigate(state.whereIGo, { state: state});
            } else {
                setError(true);
                setVisible(true);
                setLoading(false);
                setMessage("Votre code Pin est incorrect, si vous l'avez oublier veillez le réinisialiser");
            }
        });
    }

    const handlerGoBack = () => {
        document.documentElement.classList.remove("nav-open");

        navigate(state.whereIComeFrom)
    }

    return (
        <div className="page-otp">
            <Container>
                <div className="icon-container-page-otp">
                    <FontAwesomeIcon icon={faArrowLeft} onClick={handlerGoBack} className="icon"/>
                </div>
                <div className="envelope-container-page-otp ml-auto mr-auto">
                    <FontAwesomeIcon icon={faLock} className="icon"/>
                </div>
                <div className="container-form-page-otp text-center">
                    <div className="container-text-page-otp">
                        <h3>Entrer votre code Pin </h3> <br/>
                        <h5>Veuillez saisir votre code pin pour effectuer cette action</h5>
                    </div>
                    <OtpInputComponent
                        autoFocus
                        length={CODE_PIN_LENGTH}
                        error={error}
                        loading={loading}
                        visible={visible}
                        message={message}
                        setVisible={setVisible}
                        onValidate={handleOnClick}
                        className="otp-container"
                        inputClassName="otp-input"
                        redirectForgot={ROUTE_OTP_PRIVATE_FIREBASE}
                        information={state}
                    />
                </div>
            </Container>
        </div>
    );
}

export default Otp;
