import { QrReader } from 'react-qr-reader';
import React from 'react';
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal, ModalBody} from "reactstrap";
import CryptoJS from "crypto-js";

function ScanQrcode({popupToggle, setPopupToggle, qrCodeSuccessCallback}) {

    /*const startScan = () => {
        const html5QrCode = new Html5Qrcode(qrcodeRegionId);
        var config = createConfig(
            fps,
            qrbox,
            disableFlip,
            aspectRatio,
        );

        if (!qrCodeSuccessCallback) {
            console.warn( "qrCodeSuccessCallback is required callback.");
            return;
        }
        setHtmlQrCode(html5QrCode);
        html5QrCode.start({facingMode: "environment"}, config, handleDecodedText).then((e) =>{console.log(e)});
    };

    const createConfig = (fps, qrbox, disableFlip, aspectRatio) => {
        var config = {};
        config.rememberLastUsedCamera = true;

        config.supportedScanTypes = [Html5QrcodeScanType.SCAN_TYPE_CAMERA];

        if (fps) {
            config.fps = fps;
        }

        if (qrbox) {
            config.qrbox = qrbox;
        }

        if (aspectRatio) {
            config.aspectRatio = aspectRatio;
        }

        if (disableFlip !== undefined) {
            config.disableFlip = disableFlip;
        }
        return config;
    }*/

    const onResult = (decodedText) => {
        if (undefined === decodedText || null === decodedText) {
            return null;
        }

        const secretKey = process.env.REACT_APP_QR_KEY;

        try {
            const decryptedData = decryptData(decodedText.text, secretKey);

            qrCodeSuccessCallback(decryptedData);
        } catch (error) {
            console.error("Erreur lors du déchiffrement du QR code :", error);
        }
    };

    const decryptData = (encryptedData, secretKey) => {
        return CryptoJS.AES.decrypt(
            encryptedData,
            secretKey
        ).toString(CryptoJS.enc.Utf8);
    };

    const handleError = (err) => {
        setPopupToggle(false);
        console.error(err);
    };

    const modalClose = () => {
        setPopupToggle(false);
    }

    const modalOpen = () => {
        setPopupToggle(true);
    }

    return (
        <>
            <img
                className="img-scan-qrcode"
                src={require("../assets/img/icons8-qrcode-64.png")}
                alt="scanning qrcode"
                width={40}
                onClick={modalOpen}
            />
            <Modal className="modal-badge dark" funk={true} isOpen={popupToggle} toggle={modalClose} size="xl">
                <ModalBody className="dark">
                    <div className="modal-badge-close">
                        <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={modalClose}/>
                    </div>
                    <div className="modal-badge-button text-center">
                        <h3 className="title-uppercase font-weight-bold">Scanner le qrCode du badge</h3>
                        <div className="pb-5 pt-3">
                            <QrReader
                                delay={300}
                                onError={handleError}
                                onStop={modalClose}
                                //onScan={(result) => { handleDecodedText(result) }}
                                onResult={onResult}
                                style={{ width: '100%' }}
                                constraints={ {facingMode: 'environment'} }
                            />
                        </div>
                        <div className="container-validate">
                            <Button className="btn-round btn-korix-orange" onClick={modalClose}>
                                FERMER
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}
export default ScanQrcode;


ScanQrcode.defaultProps = {
    popupToggle: PropTypes.bool,
    setPopupToggle: PropTypes.func,
    qrCodeSuccessCallback: PropTypes.func,
};

ScanQrcode.propTypes = {
    popupToggle: PropTypes.bool.isRequired,
    setPopupToggle: PropTypes.func.isRequired,
    qrCodeSuccessCallback: PropTypes.func.isRequired,
};