import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {doc, getDoc, getFirestore, collection, getDocs, query, where} from "firebase/firestore";
import app from "../../services/Firebase";
import { checkSponsorisedBadge } from "../../services/stores/nft/NftSlice";
import BadgeImage from "../../components/BadgeImage";
import {ROUTE_500, ROUTE_PRIVATE_BADGE_DETAIL} from "../../services/routes/Routes";
import { useSelector } from "react-redux";
import {formatDateDMYFrench} from "../../services/Constant";
const db = getFirestore(app);
const partnersCollection = collection(db, 'partners-collection');
const badgesCollection = collection(db, 'badge-collection');

function Badge() {

    const navigate = useNavigate();
    const {state} = useLocation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [badges, setBadges] = useState([]);
    const userReducers = useSelector((state) => state.userReducers); 
    const { user } = userReducers;

    useEffect(() => {
        if(!state) {
            navigate(ROUTE_500)
        }

    }, [state])

    useEffect(() => {
        fetchInformation();
    }, [])

    const fetchInformation = async () => {
        const partnerDocRef = await doc(partnersCollection, state.key);
        const partnerInfo = await getDoc(partnerDocRef);
        if(partnerInfo.data()) {
            const address = partnerInfo.data()["contractAddress"];
            setData(partnerInfo.data());
            fetchBadges(address);
        }
    }

    const fetchBadges = async (address) => {
        const currentDate = formatDateDMYFrench(new Date);
        const queryBadges = await query(badgesCollection, where("address", "==", address), where("dateEnd", ">", currentDate));

        const badgesDocs = await getDocs(queryBadges);
        if(badgesDocs.docs.length > 0) {
            var collectionsBadges = [];
            badgesDocs.docs
            .map((badge, index) => {
                if (!badge.data().isBadgePrivate || hasMatchingUserPrivelege(badge)) {
                collectionsBadges[index] = {
                    key: badge.id,
                    imageUrl: badge.data().imageUrl,
                    advantages: badge.data().advantages,
                    details: badge.data().details,
                    name: badge.data().name,
                    amount: badge.data().amount,
                };

            } else {
                collectionsBadges[index] = null; 
            }

                return collectionsBadges;
            });

            collectionsBadges = collectionsBadges.filter(badge => badge !== null);   

            setBadges(collectionsBadges);
        }

        setLoading(false);
    }

    function hasMatchingUserPrivelege(badge) {
        const badgeData = badge.data();

         if (badgeData.privilegedUsersList) {

        for (const key in badgeData.privilegedUsersList) {
            const privilegedUser = badgeData.privilegedUsersList[key];

            if (privilegedUser.number === user.phoneNumber) {
                return true;
            }
        }
    }
        return false;
    }

    const handlerShowBadgeInformation = async (badge) => {
        checkSponsorisedBadge(badge.key)
        return navigate(ROUTE_PRIVATE_BADGE_DETAIL, {state: badge});
    }

    return (
        <div className="badge-page">
            <Container>
                <div className="container-header-badge-page">
                    <div className="icon-container-badge-page">
                        <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="icon"/>
                    </div>
                </div>

                <div className="img-container-badge-page">
                    <img src={state.imageUrl} alt={state.key}/>
                </div>

                <div className="card-page-badge-page text-center">
                    <div className="pb-3">
                        <h3 className="title-uppercase font-weight-bold">{data["collectionName"]}</h3>
                    </div>
                    <p>{data["collectionDetails"]}</p>
                    <div className="card-page-badge-page-collection-li">
                        {
                            data["collectionDetailsList"] && (
                                <ul>
                                    {
                                        data["collectionDetailsList"].map((element, index) => {
                                            return <li key={index}>{element}</li>
                                        })
                                    }
                                </ul>
                            )
                        }
                    </div>
                    <div className="partner-container-badge-page">
                        <div className="title-partner-container-badge-page">
                            <span><strong>Badges en vente</strong></span>
                        </div>
                        <div className="card-body-page-badge-page">
                            <Row>
                                {
                                    badges.map((badge, index) => {
                                        return (
                                            <Col xs={4} key={index}>
                                                <BadgeImage
                                                    badge={badge}
                                                    liveState={false}
                                                    showLiveIcon={false}
                                                    loading={loading}
                                                    onEvent={handlerShowBadgeInformation}
                                                    className={null}
                                                />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Badge;