import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

const LiveStreamRecorder = ({ liveStreamElement }) => {
    const mediaRecorderRef = useRef(null);
    const recordedChunks = useRef([]);
    const [isRecording, setIsRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [recordedTime, setRecordedTime] = useState(0);
    const [timerInterval, setTimerInterval] = useState(null);
    const [pauseStartTime, setPauseStartTime] = useState(null);

    useEffect(() => {
        if (liveStreamElement) {
            mediaRecorderRef.current = new MediaRecorder(liveStreamElement.srcObject);

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    recordedChunks.current.push(event.data);
                }
            };

            mediaRecorderRef.current.onstop = () => {
                const blob = new Blob(recordedChunks.current, { type: 'video/webm' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style = 'display: none';
                a.href = url;
                a.download = 'recorded-video.webm';
                a.click();
                window.URL.revokeObjectURL(url);
            };
        }

        return () => {
            mediaRecorderRef.current && mediaRecorderRef.current.stop();
        };
    }, [liveStreamElement]);

    const startRecording = () => {
        if (mediaRecorderRef.current && !isRecording) {
            if (isPaused) {
              mediaRecorderRef.current.resume();
              setIsPaused(false);
            } else {
              mediaRecorderRef.current.start();
            }
            setIsRecording(true);

                const startTime = Date.now() - (recordedTime * 1000);
                const interval = setInterval(() => {
                    const currentTime = Date.now();
                    const elapsed = Math.floor((currentTime - startTime) / 1000);
                    setRecordedTime(elapsed);
                }, 1000);

                setTimerInterval(interval);
            }

    };

    const pauseRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.pause();
            setIsRecording(false);
            setIsPaused(true);
            setPauseStartTime(Date.now());

            clearInterval(timerInterval);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            setIsPaused(false);
            setRecordedTime(0); 

            clearInterval(timerInterval);
        }
    };

    return (
        <div className='record-button' >
            {isRecording ? (
                <>
                    <Button className='button' onClick={pauseRecording}>
                        <FontAwesomeIcon className='pause-button' icon={faPause} />
                    </Button>
                    <Button className="button" onClick={stopRecording}>
                        <FontAwesomeIcon className='stop-button' icon={faStop} />
                    </Button>
                    <div className="timer">{recordedTime} s</div>
                </>
            ) : (
                <Button className="button" onClick={startRecording}>
                    <FontAwesomeIcon className='play-button' icon={faPlay} />
                </Button>
            )}
        </div>
    );
};

export default LiveStreamRecorder;
