import {Alert, Button, Col, Row} from "reactstrap";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {
    COUNTRY_CODE_ML,
    STEP_HUB2_PAYMENT,
    STEP_COUNTRY,
    STEP_ML_PAYMENT,
    COUNTRY_CODE_CI
} from "../../services/Constant";

function ChooseCountries({countries, eventClose, eventNext}) {

    const [country, setCountry] = useState(COUNTRY_CODE_ML);
    const [error, setError] = useState(false);
    const [visible, setVisible] = React.useState();

    const handlerNextStep = () => {
        setError(false);
        if("" === country) {
            setError(true);
            setVisible(true);
            return;
        }

        if(country === COUNTRY_CODE_ML) {
            eventNext(STEP_COUNTRY, STEP_ML_PAYMENT, countries[country]);
        } else {
            eventNext(STEP_COUNTRY, STEP_HUB2_PAYMENT, countries[country]);
        }
    }

    return (
        <>
            {
                error && (
                    <Alert color="danger" isOpen={visible} toggle={() => setVisible(!visible)}>
                        Veuillez sélectionner un pays
                    </Alert>
                )
            }
            <div>
                <p>Choisissez votre pays</p>
                <Row className={"modal-container-drapeau"}>
                    {
                        Object.keys(countries).map(key => {
                            return <Col key={key} className={COUNTRY_CODE_ML === key || COUNTRY_CODE_CI === key ? "col-md-4 border-right" : "col-md-4"}>
                                <div className={country === key ? "modal-container-image active" : "modal-container-image"}>
                                    <div className={"image-container-border"}>
                                        <img src={countries[key].flag} alt={"mali-flag"} onClick={() => setCountry(key)} />
                                    </div>
                                </div>
                            </Col>
                        })
                    }
                </Row>
            </div>
            <div className="container-validate">
                <Button block className="btn-round btn-korix-orange" size="md" onClick={handlerNextStep}>
                    suivant
                </Button>
                <Button block className="btn-round return" size="md" onClick={eventClose} >
                    retour
                </Button>
            </div>
        </>
    )
}

export default ChooseCountries;

ChooseCountries.defaultProps = {
    countries: PropTypes.object,
    eventClose: PropTypes.func,
    eventNext: PropTypes.func
};

ChooseCountries.propTypes = {
    countries: PropTypes.object.isRequired,
    eventClose: PropTypes.func.isRequired,
    eventNext: PropTypes.func.isRequired,
}