import React from "react";
import {ROLES_ADMIN, ROLES_AGENT, ROLES_OWNER, ROLES_USER, ROLES_CREATOR, ROLES_CREATOR_OWNER} from "../Constant";
import Registration from "../../layouts/Registration";
import Login from "../../layouts/Login";
import TermAndCondition from "../../layouts/TermAndCondition";
import AboutUs from "../../layouts/AboutUs";
import Welcome from "../../layouts/Welcome";
import Home from "../../layouts/private/Home";
import Error500 from "../../layouts/error/Error500";
import Error404 from "../../layouts/error/Error404";
import Marketplace from "../../layouts/private/Marketplace";
import Wallet from "../../layouts/private/Wallet";
import Badge from "../../layouts/private/Badge";
import BadgeDetail from "../../layouts/private/BadgeDetail";
import Dashboard from "../../layouts/private/admin/dashboard/Dashboard";
import Projects from "../../layouts/private/admin/project/Projects";
import Events from "../../layouts/private/admin/events/Events";
import Users from "../../layouts/private/admin/agents/Users";
import Address from "../../layouts/private/admin/address/Address";
import CartPage from "../../layouts/private/Cart";
import Otp from "../../layouts/private/Otp";
import QrCodeBadge from "../../components/QrCodeBadge";
import OtpFirebase from "../../layouts/OtpFirebase";
import OtpCodePin from "../../layouts/private/OtpCodePin";
import OtpConfirmCodePin from "../../layouts/private/OtpConfirmCodePin";
import OtpPrivateFirebase from "../../layouts/private/OtpPrivateFirebase";
import LiveStream from "../../layouts/private/LiveStream";
import PlayStream from "../../layouts/private/PlayStream";
import UserProfile from "../../layouts/private/UserProfile";
import ReferralDetails from "../../layouts/private/ReferralDetails";
import ReferralsProgram from "../../layouts/private/admin/ReferralsPrograms";
import LegalNotice from "../../layouts/LegalNotice";
import AddRealVideoForm from "../../components/AddRealVideoForm";
import RealVideosPage from "../../layouts/private/RealVideos";


export const ROUTE_WELCOME = "/welcome";
export const ROUTE_REGISTRATION = "/registration";
export const ROUTE_OTP_FIREBASE = "/security-otp";
export const ROUTE_LOGIN = "/login";
export const ROUTE_TERM_AND_CONDITION = "/term-and-condition";
export const ROUTE_ABOUT_US = "/about-us";
export const ROUTE_LEGAL_NOTICE = "/legal-notice";


// private routes
export const ROUTE_OTP_PRIVATE_FIREBASE = "/private/auth-security-otp";
export const ROUTE_OTP = "/private/security-code-pin";
export const ROUTE_OTP_CODE_PIN = "/private/create-code-pin";
export const ROUTE_OTP_CONFIRM_CODE_PIN = "/private/confirm-code-pin";
export const ROUTE_PRIVATE_HOME = "/private/home";
export const ROUTE_PRIVATE_WALLET = "/private/wallet";
export const ROUTE_PRIVATE_MARKETPLACE = "/private/marketplace";
export const ROUTE_PRIVATE_BADGE = "/private/badge";
export const ROUTE_PRIVATE_BADGE_DETAIL = "/private/badge-detail";
export const ROUTE_PRIVATE_QRCODE = "/private/qr-code";
export const ROUTE_PRIVATE_ADMIN_DASHBOARD = "/private/admin/dashboard";
export const ROUTE_PRIVATE_ADMIN_PROJECTS = "/private/admin/project";
export const ROUTE_PRIVATE_ADMIN_EVENTS = "/private/admin/events";
export const ROUTE_PRIVATE_ADMIN_AGENTS = "/private/admin/agents";
export const ROUTE_PRIVATE_ADMIN_ADDRESS = "/private/admin/address";
export const ROUTE_PRIVATE_CART = "/private/cart";
export const ROUTE_PRIVATE_LIVE_STREAM = "/private/LiveStream"; 
export const ROUTE_PRIVATE_PLAY_STREAM = "/private/PlayStream";
export const ROUTE_PRIVATE_ADMIN_REFERRALS = "/private/admin/ReferralsPrograms";
export const ROUTE_PRIVATE_USER_PROFILE = "/private/UserProfile";
export const ROUTE_PRIVATE_REFERRAL_DETAILS= "/private/ReferralDetails";
export const ROUTE_PRIVATE_ADD_REEL = "/private/AddRealVideoForm"
export const ROUTE_PRIVATE_REEL = "/private/RealVideos"

// error routes
export const ROUTE_500 = "/error";
export const ROUTE_404 = "/not-found";


export const routes = [
    // routes public
    {
        key: "registration",
        name: "Inscription",
        path: ROUTE_REGISTRATION,
        component: <Registration />,
        private: false,
        roles: null,
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "login",
        name: "Se connecter",
        path: ROUTE_LOGIN,
        component: <Login />,
        private: false,
        roles: null,
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "term&condition",
        name: "Termes et conditions",
        path: ROUTE_TERM_AND_CONDITION,
        component: <TermAndCondition />,
        private: false,
        roles: null,
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "about-us",
        name: "A propos de nous",
        path: ROUTE_ABOUT_US,
        component: <AboutUs />,
        private: false,
        roles: null,
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "legal notice",
        name: "Les Mentions légales",
        path: ROUTE_LEGAL_NOTICE,
        component: <LegalNotice />,
        private: false,
        roles: null,
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "welcome",
        name: "Bienvenue",
        path: ROUTE_WELCOME,
        component: <Welcome />,
        private: false,
        collapse: false,
        roles: null,
        show: false,
        footer: false
    },
    {
        key: "404",
        name: "404",
        path: ROUTE_404,
        component: <Error404 />,
        private: false,
        collapse: false,
        roles: null,
        show: false,
        footer: false
    },
    {
        key: "500",
        name: "500",
        path: ROUTE_500,
        component: <Error500 />,
        private: false,
        collapse: false,
        roles: null,
        show: false,
        footer: false
    },
    {
        key: "otpFirebase",
        name: "otpFirebase",
        path: ROUTE_OTP_FIREBASE,
        component: <OtpFirebase />,
        private: false,
        roles: null,
        collapse: false,
        show: false,
        footer: false
    },

    // routes privées
    {
        key: "createCodePin",
        name: "createCodePin",
        path: ROUTE_OTP_PRIVATE_FIREBASE,
        component: <OtpPrivateFirebase />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_ADMIN, ROLES_AGENT],
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "createCodePin",
        name: "createCodePin",
        path: ROUTE_OTP_CODE_PIN,
        component: <OtpCodePin />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_ADMIN, ROLES_AGENT],
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "confirmCodePin",
        name: "confirmCodePin",
        path: ROUTE_OTP_CONFIRM_CODE_PIN,
        component: <OtpConfirmCodePin />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_ADMIN, ROLES_AGENT],
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "otp",
        name: "Otp",
        path: ROUTE_OTP,
        component: <Otp />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_ADMIN, ROLES_AGENT],
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "qrcode",
        name: "qrcode",
        path: ROUTE_PRIVATE_QRCODE,
        component: <QrCodeBadge />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_ADMIN, ROLES_AGENT],
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "home",
        name: "Accueil",
        path: ROUTE_PRIVATE_HOME,
        component: <Home />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER,  ROLES_ADMIN, ROLES_AGENT],
        collapse: false,
        img: "home-2.svg",
        imgActive: "home-2-active.svg",
        show: true,
        footer: true
    },
    {
        key: "boutique",
        name: "Boutique",
        path: ROUTE_PRIVATE_MARKETPLACE,
        component: <Marketplace />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_ADMIN, ROLES_AGENT],
        img: "bag-2.svg",
        imgActive: "bag-2-active.svg",
        collapse: false,
        show: true,
        footer: true
    },
    {
        key: "wallet",
        name: "Mes badges",
        path: ROUTE_PRIVATE_WALLET,
        component: <Wallet />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_ADMIN, ROLES_AGENT],
        collapse: false,
        img: "strongbox.svg",
        imgActive: "strongbox-active.svg",
        show: true,
        footer: true
    },
    {
        key: "badge",
        name: "badge",
        path: ROUTE_PRIVATE_BADGE,
        component: <Badge />,
        private: true,
        collapse: false,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_ADMIN, ROLES_AGENT],
        show: false,
        footer: false
    },
    {
        key: "badge-detail",
        name: "badgeDetail",
        path: ROUTE_PRIVATE_BADGE_DETAIL,
        component: <BadgeDetail />,
        private: true,
        collapse: false,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_ADMIN, ROLES_AGENT],
        show: false,
        footer: false
    },
    {
        key: "cart",
        name: "Panier",
        path: ROUTE_PRIVATE_CART,
        component: <CartPage />,
        private: true,
        collapse: false,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_ADMIN, ROLES_AGENT],
        show: false,
        footer: false
    },
    {
        key: "dashboard",
        name: "Dashboard",
        path: ROUTE_PRIVATE_ADMIN_DASHBOARD,
        component: <Dashboard />,
        private: true,
        roles: [ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_ADMIN],
        collapse: true,
        show: true,
        footer: false,
        child: [
            {
                key: "dashboard",
                name: "Dashboard",
                path: ROUTE_PRIVATE_ADMIN_DASHBOARD,
                component: <Dashboard />,
                private: true,
                roles: [ROLES_ADMIN, ROLES_OWNER, ROLES_CREATOR_OWNER],
                collapse: false,
                show: true,
                footer: false
            },
            {
                key: "address",
                name: "Adresse",
                path: ROUTE_PRIVATE_ADMIN_ADDRESS,
                component: <Address />,
                private: true,
                roles: [ROLES_ADMIN],
                collapse: false,
                show: true,
                footer: false
            },
            {
                key: "projects",
                name: "Projets",
                path: ROUTE_PRIVATE_ADMIN_PROJECTS,
                component: <Projects />,
                private: true,
                roles: [ROLES_ADMIN],
                collapse: false,
                show: true,
                footer: false
            },
            {
                key: "events",
                name: "Evènements",
                path: ROUTE_PRIVATE_ADMIN_EVENTS,
                component: <Events />,
                private: true,
                roles: [ROLES_ADMIN],
                collapse: false,
                show: true,
                footer: false
            },
            {
                key: "users",
                name: "Users",
                path: ROUTE_PRIVATE_ADMIN_AGENTS,
                component: <Users />,
                private: true,
                roles: [ROLES_ADMIN],
                collapse: false,
                show: true,
                footer: false
            },
        ]
    },
    {
        key: "liveStream",
        name: "LiveStream",
        path: ROUTE_PRIVATE_LIVE_STREAM,
        component: <LiveStream />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_ADMIN, ROLES_CREATOR],
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "playStream",
        name: "PlayStream",
        path: ROUTE_PRIVATE_PLAY_STREAM,
        component: <PlayStream />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_ADMIN, ROLES_CREATOR],
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "userProfile",
        name: "UserProfile",
        path: ROUTE_PRIVATE_USER_PROFILE,
        component: <UserProfile />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_ADMIN, ROLES_AGENT, ROLES_CREATOR],
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "referrals",
        name: "Referrals",
        path: ROUTE_PRIVATE_ADMIN_REFERRALS,
        component: <ReferralsProgram />,
        private: true,
        roles: [ROLES_ADMIN],
        collapse: false,
        show: true,
        footer: false
    },
    {
        key: "referraldetails",
        name: "Referraletails",
        path: ROUTE_PRIVATE_REFERRAL_DETAILS,
        component: <ReferralDetails />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_ADMIN, ROLES_AGENT, ROLES_CREATOR],
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "addReel",
        name: "AddReel",
        path: ROUTE_PRIVATE_ADD_REEL,
        component: <AddRealVideoForm />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_ADMIN, ROLES_CREATOR],
        collapse: false,
        show: false,
        footer: false
    },
    {
        key: "reel",
        name: "Reel",
        path: ROUTE_PRIVATE_REEL,
        component: <RealVideosPage />,
        private: true,
        roles: [ROLES_USER, ROLES_OWNER, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_ADMIN, ROLES_AGENT],
        img: "reel-tv.svg",
        imgActive: "reel-tv-active.svg",
        collapse: false,
        show: true,
        footer: true
    },
];
