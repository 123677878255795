import { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, addDoc} from "firebase/firestore";
import app from "../../../../services/Firebase";
import * as React from "react";
import PropTypes from "prop-types";
import {Alert, Button, Card, FormGroup, Input, Label, Modal, ModalBody} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {categories} from "../../../../services/Constant";
const db = getFirestore(app);
const badgeCollection = collection(db, 'badge-collection');
const eventsCollection = collection(db, 'events');

function ModalNewEvent({isOpen, toggle}) {
    const [badges, setBadges] = useState([]);
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [place, setPlace] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [badge, setBadge] = useState('');
    const [badgeId, setBadgeId] = useState('');
    const [address, setAddress] = useState("");
    const [visible,setVisible] = React.useState();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Tous les champs sont obligatoires");
    const [errorField, setErrorField] = useState(false);

    useEffect( () => {
        setErrorField(false);
        const fetchBadges = async () => {
            const badgesDocs = await getDocs(badgeCollection);
            if(badgesDocs.docs.length > 0) {
                var collectionsBadge = [];
                badgesDocs.docs.map((badge, index) => {
                    collectionsBadge[index] = {
                        address: badge.data().address,
                        name: badge.data().name,
                        key: badge.id
                    };
                });
                setBadges(collectionsBadge);
            }
        }

        fetchBadges();
    }, []);

    const handleChangeBadge = (event) => {
        setBadge(badges[event.target.value].name);
        setBadgeId(badges[event.target.value].key);
        setAddress(badges[event.target.value].address);
    }

    const handleOnValidate = () => {
        setErrorField(false);
        if("" === address || "" === name || "" === type || "" === startDate || "" === endDate || "" === place) {
            setErrorField(true);
            return;
        }

        const event = {
            address: address,
            badge: badge,
            badgeId: badgeId,
            name: name,
            type: type,
            place: place,
            startDate: new Date(startDate),
            endDate: endDate,
        }

        try {
            addDoc(eventsCollection, event).then((toggle()));
        } catch (e) {
            console.log(e)
            setErrorMessage(e.message);
            setError(true);
        }
    }

    return (
        <Modal className="modal-badge" funk={true} isOpen={isOpen} toggle={toggle} size="xl">
            <ModalBody>
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle}/>
                </div>
                <div className="modal-badge-button">
                    <Card className="modal-badge-card ml-auto mr-auto">
                        <Button className="modal-badge-button-title detail text-uppercase btn-round" size="lg">
                            <div className="text">Evènement</div>
                        </Button>
                        {
                            error && (
                                <Alert color="danger" isOpen={visible} toggle={() => setVisible(false)}>
                                    {errorMessage}
                                </Alert>
                            )
                        }
                        <div className="modal-badge-box">
                            <FormGroup className={errorField ? "has-danger" : ""}>
                                <Label for="badge" className="control-label">Badge</Label>
                                <Input type="select" name="select" id="badge" onChange={handleChangeBadge}>
                                    <option>Sélectionner badge</option>
                                    {
                                        badges.map((element, index) => <option key={index} value={index}>{element.name}</option>)
                                    }
                                </Input>
                            </FormGroup>

                            <FormGroup className={errorField ? "has-danger" : ""}>
                                <Label for="name" className="control-label">Nom</Label>
                                <Input type="text" name="name" id="name" placeholder="Nom de l'évènement" onChange={(event) => setName(event.target.value)}/>
                            </FormGroup>

                            <FormGroup className={errorField ? "has-danger" : ""}>
                                <Label for="place" className="control-label">Lieu de l'évènement</Label>
                                <Input type="text" name="place" id="place" placeholder="Lieu de l'évènement" onChange={(event) => setPlace(event.target.value)}/>
                            </FormGroup>

                            <FormGroup className={errorField ? "has-danger" : ""}>
                                <Label for="badge" className="control-label">Badge</Label>
                                <Input type="select" name="select" id="badge" onChange={(type) => setType(type.target.value)}>
                                    <option>Sélectionner type</option>
                                    {
                                        categories.map((element, index) => <option key={index} value={element}>{element}</option>)
                                    }
                                </Input>
                            </FormGroup>
                            <FormGroup className={errorField ? "has-danger" : ""}>
                                <label
                                    className="form-control-label"
                                    htmlFor="example-date-input"
                                >
                                    Date de début
                                </label>
                                <Input
                                    defaultValue={new Date().getFullYear() + "-11-23"}
                                    id="example-date-input"
                                    onChange={(date) => setStartDate(date.target.value)}
                                    type="datetime-local"
                                />
                            </FormGroup>
                            <FormGroup className={errorField ? "has-danger" : ""}>
                                <label
                                    className="form-control-label"
                                    htmlFor="example-date-input"
                                >
                                    Date de fin
                                </label>
                                <Input
                                    defaultValue={new Date().getFullYear() + "-11-23"}
                                    onChange={(date) => setEndDate(date.target.value)}
                                    id="example-date-input"
                                    type="date"
                                />
                            </FormGroup>
                        </div>
                        <div className="right-side">
                            <Button className="btn-round" color="primary" onClick={handleOnValidate}>Valider</Button> {" "}
                            <Button className="btn-round" color="default" onClick={toggle}>Annuler</Button>
                        </div>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalNewEvent;

ModalNewEvent.defaultProps = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
};

ModalNewEvent.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};
