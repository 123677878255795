import React, { useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

function ProgramCard({ program, onAddLevelClick, onEditLevelClick, onDeleteLevel }) {
    const [deleteConfirmationIndex, setDeleteConfirmationIndex] = useState(null);

    const handleDeleteClick = (index) => {
        setDeleteConfirmationIndex(index);
    };

    const handleDeleteConfirmation = () => {
        if (deleteConfirmationIndex !== null) {
            onDeleteLevel(deleteConfirmationIndex);
            setDeleteConfirmationIndex(null);
        }
    };

    return (
        <Col>
            <h3>{program.programName}</h3>
            <Button onClick={() => onAddLevelClick(program)} color="primary">
                Ajouter un Niveau
            </Button>
            <Table>
                <thead>
                    <tr>
                        <th>Niveau</th>
                        <th>Description</th>
                        <th>Références Requises</th>
                        <th>Récompense</th>
                    </tr>
                </thead>
                <tbody>
                    {program.rewardLevels.map((level, index) => (
                        <tr key={index}>
                            <td>{level.name}</td>
                            <td>{level.description}</td>
                            <td>{level.referralCountRequired}</td>
                            <td>{level.reward}</td>
                            <FontAwesomeIcon
                                icon={faEdit}
                                color="green"
                                onClick={() => onEditLevelClick(index)}
                                className="edit-icon"
                            />
                            <FontAwesomeIcon
                                icon={faTrash}
                                color="red"
                                onClick={() => handleDeleteClick(index)}
                                className="delete-icon"
                            />
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal isOpen={deleteConfirmationIndex !== null} toggle={() => setDeleteConfirmationIndex(null)}>
                <ModalHeader toggle={() => setDeleteConfirmationIndex(null)}>Suppression</ModalHeader>
                <ModalBody>
                    Êtes-vous sûr de vouloir supprimer ce niveau de récompense ?
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setDeleteConfirmationIndex(null)}>Annuler</Button>
                    <Button color="danger" onClick={handleDeleteConfirmation}>Supprimer</Button>
                </ModalFooter>
            </Modal>

        </Col>
    );
}

export default ProgramCard;