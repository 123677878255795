import React from "react";
import {Modal, ModalBody, Button} from "reactstrap";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faWarning} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {ROUTE_OTP_CODE_PIN} from "../services/routes/Routes";

function ModalAskCodePin({isOpen, toggle, route, state, modal, event}) {

    const navigate = useNavigate();

    return (
        <Modal className="modal-badge" funk={true} isOpen={isOpen} toggle={toggle} size="xl">
            <ModalBody>
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle}/>
                </div>
                <div className="text-center">
                    <div className={"modal-badge-text"}>
                        <FontAwesomeIcon icon={faWarning} className="icon-fa"/>
                        <p>Vos badges ne sont pas sécurisé.</p>
                        <p>Vous voulez protéger l'accès avec un code pin ?</p>
                    </div>
                    <div className="container-validate">
                        <Button block className="btn-round btn-korix-orange" size="md" onClick={() => navigate(ROUTE_OTP_CODE_PIN, {state: state})}>
                            Créer un code PIN
                        </Button>
                        <Button block className="btn-round btn-korix-black small" size="md" onClick={modal ? event : () => navigate(route, {state: state})}>
                            Continuer sans code PIN
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalAskCodePin;

ModalAskCodePin.defaultProps = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    route: PropTypes.string,
    state: PropTypes.object,
    modal: PropTypes.bool,
    event: PropTypes.func
};

ModalAskCodePin.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};