import React from 'react';

const SignalBars = ({ signalLevel, networkQuality }) => {

    const barClasses = Array(5).fill('black');

    if (signalLevel >= 1) {
        barClasses[0] = 'red';
    }
    if (signalLevel >= 2) {
        barClasses[0] = 'yellow';
        barClasses[1] = 'yellow';
    }
    if (signalLevel === 3) {
        barClasses[0] = 'yellow';
        barClasses[1] = 'yellow';
        barClasses[2] = 'yellow'; 
    }
    if (signalLevel === 4) {
        barClasses[0] = 'green';
        barClasses[1] = 'green';
        barClasses[2] = 'green'; 
        barClasses[3] = 'green';
    }


    return (
        <div className="signal-bars-container">
            <div className="signal-bars">
                {barClasses.map((barClass, index) => (
                    <div key={index} className={`bar ${barClass}`} />
                ))}
            </div>
        </div>
    );
};

export default SignalBars;
