import React, { useEffect, useState } from 'react';
import RealVideoCard from '../../components/RealVideoCard';
import app from '../../services/Firebase';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { animated } from 'react-spring';
import {convertPxToVh, ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_OWNER} from '../../services/Constant';
import {ROUTE_PRIVATE_ADD_REEL, ROUTE_WELCOME} from '../../services/routes/Routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAdd, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { getUserBadgeIds } from '../../services/queries/Badge';

const db = getFirestore(app);
const reelsVideoCollection = collection(db, 'reels_videos');

function RealVideosPage() {
    const [videos, setVideos] = useState([]);
    const [visibleIndex, setVisibleIndex] = useState(0);
    const userReducers = useSelector((state) => state.userReducers);
    const navigate = useNavigate();
    const { user } = userReducers;
    const [userBadgesIds, setUserBadgesIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [translateValue, setTranslateValue] = useState(0)

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

// the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientY)
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientY)
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        if(isLeftSwipe) {
            handleNextClick();
        } else {
            handlePrevClick();
        }
    }

    const containerStyle = {
        top: `${translateValue}px`,
    };

    useEffect(() => {
        document.body.style.overscrollBehaviorY = 'contain';
    }, [])

    useEffect(() => {
        const unsubscribe = onSnapshot(reelsVideoCollection, (snapshot) => {
            setLoading(true);
            const videosData = snapshot.docs.map((doc) => ({ reelVideoId: doc.id, ...doc.data() }));
            setVideos(videosData);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        fetchUserBadgesIds();
    }, []);

    const fetchUserBadgesIds = async () => {
        setUserBadgesIds(await getUserBadgeIds(user.phoneNumber));
    }

    function hasMatchingBadge(userBadgesIds, video) {
        setLoading(true);
        if (!video.badges || Object.keys(video.badges).length === 0) {
            setLoading(false);
            return false;
        }
    
        const videoBadgeIds = Object.values(video.badges);
        setLoading(false);
        return videoBadgeIds.some((badgeId) => userBadgesIds.includes(badgeId));
    }


    const handlePrevClick = () => {
        const value = translateValue + window.innerHeight;
        if(translateValue !== 0) {
            setTranslateValue(value);
            setVisibleIndex((prevIndex) => prevIndex - 1);
        }
    };

    const handleNextClick = () => {
        if (visibleIndex < videos.length - 1) {
            setTranslateValue(translateValue - window.innerHeight);
            setVisibleIndex((prevIndex) => prevIndex + 1);
        }
    };

    return (
        <>
            <div className="video-parent-container">
                {loading ? (
                    <div className='uil-reload-class'>
                        <div className="uil-reload-css-reload-small">
                            <div></div>
                        </div>
                    </div>
                ) : (
                    <div className="video-child-container" style={{height: `${window.innerHeight}px`}} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} >
                        {videos.length === 0 ? (
                            <>
                                <div className='reelempy'>
                                    {
                                        [ROLES_CREATOR, ROLES_CREATOR_OWNER, ROLES_OWNER].includes(user.role) && (
                                            <Button onClick={() => navigate(ROUTE_PRIVATE_ADD_REEL)} color="link" className="reel-add-button">
                                                <FontAwesomeIcon icon={faAdd} className="outline-icon" />
                                            </Button>
                                        )
                                    }

                                </div>
                                <div className='reelempytext'>
                                    <p className="empty-text"> Il n'y a pas de vidéo </p>
                                </div>
                            </>
                        ) : (
                            <animated.div className="carousel-items" style={containerStyle}>
                                {videos.map((video, index) => {
                                    return <RealVideoCard
                                        key={video.reelVideoId}
                                        video={video} isVisible={index === visibleIndex}
                                        handleNextClick={handleNextClick}
                                        handlePrevClick={handlePrevClick}
                                        canShowReel={() => hasMatchingBadge(userBadgesIds, video)}
                                    />
                                })}
                            </animated.div>
                        )}
                    </div>
                )}

            </div>
        </>
    );
}

export default RealVideosPage;
