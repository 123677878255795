import PropTypes from "prop-types";
import React from "react";
import BadgeImage from "./BadgeImage";
import {Col, Row} from "reactstrap";
import {formatDate, formatHour, formatPrice} from "../services/Constant";

const BlockComponent = ({badge, loading, onEvent, information, className}) => {

    const handleClick = () => {
        onEvent(information);
    }

    return <div className="content-block-component" onClick={onEvent ? handleClick : null}>
            {
                null !== badge ?
                    <div className="content-header-block-component">
                        <div className="content-header-image-block-component">
                            <BadgeImage
                                loading={loading}
                                badge={badge}
                                className={className}
                                onEvent={onEvent}
                            />
                        </div>
                        <div className="content-price-image-block-component">
                            <span className="price-block-component">{formatPrice(information.amount)} <small>FCFA</small></span>
                        </div>
                    </div> : <div className="content-header-block-component"/>
            }
            <div className="content-footer-block-component">
                <Row>
                    <Col xs={12} className="content-footer-information-block-component">
                        <span className="title-block-component"><strong>{formatDate(information.date)}</strong></span><br/><br/>
                        <strong className="artistName-block-component">{information.artistName}</strong> <br/>
                        {
                            information.amount && (
                                <>
                                    <div className="id-orange-block-component">
                                        {
                                            information.referralID && (
                                                <>
                                                    <span>Ref id : {information.referralID}</span><br/>
                                                </>
                                            )
                                        }
                                        <span>Token id : {information.tokenID}</span><br/>
                                        <span>Paiement id : {information.paymentTxiId}</span>
                                        {
                                            information.buyer && (
                                                <>
                                                    <br/>
                                                    <span>L'acheteur : {information.buyer}</span>
                                                </>
                                            )
                                        }
                                    </div>
                                </>
                            )
                        }
                        {
                            information.name && (
                                <>
                                    <span className="name-block-component">{information.name}</span>
                                    {
                                        information.type && (
                                            <><span className="type-block-component"> ({information.type})</span></>
                                        )
                                    }
                                    <br/>
                                    <br/>
                                    {
                                        information.place && (
                                            <h6 className="type-block-component"><strong>{information.place} à {formatHour(information.startDate)}</strong></h6>
                                        )
                                    }
                                </>
                            )
                        }
                    </Col>
                </Row>
                {
                    information.imageCollection && (
                        <div className="logo-container-block-component">
                            <img src={information.imageCollection} alt={information.imageCollection} width={"10%"}/>
                        </div>
                    )
                }
            </div>
        </div>
}

export default BlockComponent;


BlockComponent.defaultProps = {
    loading: PropTypes.bool,
    badge: PropTypes.object,
    onEvent: PropTypes.func,
    information: PropTypes.object,
    className: PropTypes.string,
};

BlockComponent.propTypes = {
    badge: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
}