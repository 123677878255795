export function MainNet () {
    return {
        "name": "mainNet",
        "list": [
            {
                "id": "1",
                "chainDecimalId": "1",
                "Name": "Etherium",
                "image": "Images/unitIcon/Etherium.png",
                "simbol": "ETH",
                "params": [{
                    "chainName": "Ethereum Mainnet",
                    "chainId": "0x1",
                    "rpcUrls": [
                        "https://mainnet.infura.io/v3/"
                    ],
                    "blockExplorerUrls": [
                        "https://etherscan.io"
                    ]
                }]
            },
            {
                "id": "2",
                "chainDecimalId": "137",
                "Name": "Polygon",
                "image": "Images/unitIcon/Polygon.png",
                "simbol": "MATIC",
                "params": [{
                    "chainName": "Polygon Mainnet",
                    "chainId": "0x89",
                    "rpcUrls": [
                        "https://polygon-rpc.com"
                    ],
                    "blockExplorerUrls": [
                        "https://polygonscan.com"
                    ]
                }]
            },
            {
                "id": "3",
                "chainDecimalId": "42220",
                "Name": "Celo",
                "image": "Images/unitIcon/Celo.svg",
                "simbol": "CELO",
                "params": [{
                    "chainName": "Celo Mainnet",
                    "chainId": "0xA4EC",
                    "rpcUrls": [
                        "https://forno.celo.org"
                    ],
                    "blockExplorerUrls": [
                        "https://explorer.celo.org"
                    ]
                }]
            }
        ],
        "marketplace":[
            {
                "name" : "ETH",
                "address": "...",
                "marketFee":"0.025"
            },
            {
                "name" : "MATIC",
                "address":"...",
                "marketFee":"0.025"
            },
            {
                "name" : "CELO",
                "address":"...",
                "marketFee":"0.025"
            }
        ],
        "API_KEY": process.env.REACT_APP_API_KEY,
        "MINTER": process.env.REACT_APP_MINTER,
        "OWNER": process.env.REACT_APP_OWNER,
        "SIGNATURE_ID": process.env.REACT_APP_SIGNATURE_ID,
        "OM_AUTH": process.env.REACT_APP_OM_AUTH,
        "OM_MERCHANT_KEY": process.env.REACT_APP_OM_MERCHANT_KEY,

        "HUB2_apiKey": process.env.REACT_APP_HUB2_apiKey,
        "HUB2_environment": process.env.REACT_APP_HUB2_environment,
        "HUB2_merchantId": process.env.REACT_APP_HUB2_merchantId
    }
}