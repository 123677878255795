import React, {useState} from "react";
import {Button, Container, Alert, Form} from "reactstrap";
import {ROUTE_OTP_FIREBASE,
    ROUTE_REGISTRATION,
    ROUTE_WELCOME
} from "../services/routes/Routes";
import {Link, useNavigate} from "react-router-dom";
import {getAuth, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import app from "../services/Firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import FooterLight from "../components/FooterLight";
import InputComponent from "../components/input/InputComponent";
import AddToScreenToast from "../components/Toast";
import {ModalCountryCode} from "../components/ModalCountryCode";


const auth = getAuth(app);

function Login() {
    const navigate = useNavigate();
    const [countryCode, setCountryCode] = useState('+223');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [fieldError, setFieldError] = useState(false);
    const [visible,setVisible] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);

    document.documentElement.classList.remove("nav-open");
    const handlerGenerateUniqueCode = async () => {
        setError(false);
        setVisible(false);
        if('' === phone || '' === countryCode || fieldError) {
            setLoading(false);
            setError(true);
            setVisible(true);
            setMessage("Veuillez renseigner un numéro de téléphone valide");
            return;
        }

        setFieldError(false);
        setLoading(true);
        const phoneNumber = `${countryCode}${phone}`;
        let recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
            }
        }, auth);

        window.confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
        navigate(ROUTE_OTP_FIREBASE, { state: {
                login: true,
                phoneNumber: phoneNumber,
            }});
    }

    return (
        <div className="page-login">
            <AddToScreenToast/>
            <div className="page-header-page-login">
                <Container>
                    <Form>
                        <div className="container-field-page-login ml-auto mr-auto">
                            <div className="container-header-page-login">
                                <div className="icon-container-page-login">
                                    <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(ROUTE_WELCOME)} className="icon"/>
                                </div>
                            </div>
                            <div className="logo-container-page-login text-center  pb-5">
                                <img src={require("../assets/img/Brand/ACCESS_LOGO_WHITE_nobg.png")} alt={"logo-korix"}/>
                            </div>
                            {
                                error && (
                                    <Alert color="danger" isOpen={visible} toggle={() => setVisible(!visible)}>
                                        {message}
                                    </Alert>
                                )
                            }
                            <div className="container-phone-page-login">
                                <InputComponent
                                    type="tel"
                                    name="phone"
                                    placeholder="Numéro de téléphone"
                                    className={"container-input-field-page-login"}
                                    onChange={(value) => setPhone(value)}
                                    label={"Numéro de téléphone"}
                                    disabled={false}
                                    defaultValue={phone}
                                    required={true}
                                    setFieldError={setFieldError}
                                    code={
                                        <div className="container-code-icon-page-login" onClick={() => setIsOpen(!isOpen)}><span>{countryCode}</span></div>
                                    }
                                />
                            </div>
                        </div>

                        <div className="container-validate-page-login">
                            {
                                loading ?
                                    <div className="uil-reload-css-reload-small">
                                        <div></div>
                                    </div> :
                                    <Button block className="btn-round btn-korix-black" size="md" onClick={handlerGenerateUniqueCode}>
                                        Se connecter
                                    </Button>
                            }
                        </div>
                        <div className="container-signup-page-login text-center">
                            <div>
                                <span className="text">Vous n'avez pas de compte ?</span>
                            </div>
                            <div className="pt-3">
                                <Link className="signup" to={ROUTE_REGISTRATION}>Créer un compte</Link>
                            </div>
                        </div>
                    </Form>
                    <FooterLight />
                </Container>
            </div>
            <ModalCountryCode
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                setCountryCode={setCountryCode}
            />
            <div id="recaptcha-container"></div>
        </div>
    );
}

export default Login;
