import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Modal, ModalBody, Button, Input } from 'reactstrap';

function ModalReferralCode({ isOpen, toggleModal, onReferralCodeSubmit, onNoCode, validateReferralCode }) {
    const [referralCode, setReferralCode] = useState('');
    const [isValidReferral, setIsValidReferral] = useState(true);

    const handleReferralCodeChange = (e) => {
        setReferralCode(e.target.value);
        setIsValidReferral(true);
    };

    const handleSubmit = async () => {
        const referralIDDoc = await validateReferralCode(referralCode);

        if (referralIDDoc || referralCode === "") {
            onReferralCodeSubmit(referralCode);
            setReferralCode('');
        } else if(!referralIDDoc && referralCode !== ""){
            setIsValidReferral(false);
        }
    };

    return (
        <Modal className="modal-badge dark" isOpen={isOpen} toggle={toggleModal}>
            <ModalBody className="dark">
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggleModal} />
                </div>
                <div className="modal-badge-button text-center">
                    <h5 class="primary-text-color">
                        Avez-vous un code de référence ?
                    </h5>
                   
                    <Input
                        type="text"
                        placeholder="Veuillez renseigner le code de référence"
                        value={referralCode}
                        onChange={handleReferralCodeChange}
                    />
                    {!isValidReferral && <p style={{ color: 'red' }}>Le code de référence est invalide.</p>}
                    <div className="container-validate">
                        <Button className="btn-round btn-korix-orange" color="primary" onClick={handleSubmit}>
                            Ajouter au panier
                        </Button>
                        <Button block className="btn-round return" size="md" onClick={onNoCode}>
                            Je n'ai pas de code
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ModalReferralCode;
