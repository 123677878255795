const initialState = {
    roomID: null,
    streamID: null,
    badgeImg:null,
  };
  
  const streamReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_ROOM_ID':
        return {
          ...state,
          roomID: action.payload,
        };

      case 'SET_BADGE_IMG':
        return {
          ...state,
          badgeImg: action.payload,
        };  
        
      case 'SET_STREAM_ID':
        return {
          ...state,
          streamID: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default streamReducer;
  