import React, {memo, useState, useCallback, CSSProperties} from 'react';
import SingleInput from './SingleInput';
import {Alert, Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDeleteLeft} from "@fortawesome/free-solid-svg-icons";
import {CODE_FIREBASE_LENGTH, CODE_PIN_LENGTH} from "../../services/Constant";
import {useNavigate} from "react-router-dom";

export interface OTPInputProps {
    length: number;
    onValidate: (code: string) => any;
    setVisible: (visible: boolean) => any;

    autoFocus?: boolean;
    disabled?: boolean;
    loading: boolean;
    error: boolean;
    visible: boolean;

    style?: CSSProperties;
    className?: string;
    message?: string;

    inputStyle?: CSSProperties;
    inputClassName?: string;
    redirectForgot?: string;
    information?: any;
}

export function OTPInputComponent(props: OTPInputProps) {
    const {
        length,
        autoFocus,
        disabled,
        onValidate,
        loading,
        error,
        visible,
        setVisible,
        message,
        inputClassName,
        inputStyle,
        redirectForgot,
        information,
        ...rest
    } = props;

    const navigate = useNavigate();
    const [activeInput, setActiveInput] = useState(0);
    const [otpValues, setOTPValues] = useState(Array(length).fill(''));
    const [otp, setOtp] = useState("");

    // Helper to return OTP from inputs
    const handleOtpChange = useCallback(
        (otp: string[]) => {
            const otpValue = otp.join('');
            setOtp(otpValue);
        },
        [setOtp],
    );

    // Helper to return OTP from inputs
    const handleOtpChangeInput = useCallback(
        (otp: string) => {
            if(otp.length > CODE_FIREBASE_LENGTH) {
                return;
            }

            setOtp(otp);
        },
        [setOtp],
    );

    // Helper to return value with the right type: 'text' or 'number'
    const getRightValue = useCallback(
        (str: string) => {
            let changedValue = str;

            if (!changedValue) {
                return changedValue;
            }

            return Number(changedValue) >= 0 ? changedValue : '';
        },
        [],
    );

    // Change OTP value at focussing input
    const changeCodeAtFocus = useCallback(
        (str: string) => {

            const updatedOTPValues = [...otpValues];
            updatedOTPValues[activeInput] = str[0] || '';
            setOTPValues(updatedOTPValues);
            handleOtpChange(updatedOTPValues);
        },
        [activeInput, handleOtpChange, otpValues],
    );

    // Focus `inputIndex` input
    const focusInput = useCallback(
        (inputIndex: number) => {
            const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
            setActiveInput(selectedIndex);
        },
        [length],
    );

    const focusPrevInput = useCallback(() => {
        focusInput(activeInput - 1);
    }, [activeInput, focusInput]);

    const focusNextInput = useCallback(() => {
        focusInput(activeInput + 1);
    }, [activeInput, focusInput]);

    // Handle onChange value for each input
    const handleOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = getRightValue(e.currentTarget.value);

            if (!val) {
                e.preventDefault();
                return;
            }
            changeCodeAtFocus(val);
            focusNextInput();
        },
        [changeCodeAtFocus, focusNextInput, getRightValue],
    );

    const handlerOnClick = useCallback(
        (press: string, e: React.ChangeEvent<HTMLInputElement>) => {
            const val = getRightValue(press);
            if (!val) {
                e.preventDefault();
                return;
            }
            changeCodeAtFocus(val);
            focusNextInput();
        },
        [changeCodeAtFocus, focusNextInput, getRightValue],
    );

    const handlerOnDelete = useCallback(
        () => {

            if (otpValues[activeInput]) {
                changeCodeAtFocus('');
            } else {
                focusPrevInput();
            }
        },
        [changeCodeAtFocus, focusPrevInput, otpValues, activeInput],
    );

    // Handle onKeyDown input
    const handleOnKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const pressedKey = e.key;

            switch (pressedKey) {
                case 'Backspace':
                case 'Delete': {
                    if (otpValues[activeInput]) {
                        focusPrevInput();
                        changeCodeAtFocus('');
                    } else {
                        focusPrevInput();
                    }
                    break;
                }
                case 'ArrowLeft': {
                    e.preventDefault();
                    focusPrevInput();
                    break;
                }
                case 'ArrowRight': {
                    e.preventDefault();
                    focusNextInput();
                    break;
                }
                default: {
                    // Ignore all special keys if it is not numeric or alphabet characters
                    if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
                        e.preventDefault();
                    }

                    break;
                }
            }
        },
        [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues],
    );

    const handleOnPaste = useCallback(
        (e: React.ClipboardEvent<HTMLInputElement>) => {
            e.preventDefault();
            const pastedData = e.clipboardData
                .getData('text/plain')
                .trim()
                .slice(0, length - activeInput)
                .split('');
            if (pastedData) {
                let nextFocusIndex = 0;
                const updatedOTPValues = [...otpValues];
                updatedOTPValues.forEach((val, index) => {
                    if (index >= activeInput) {
                        const changedValue = getRightValue(pastedData.shift() || val);
                        if (changedValue) {
                            updatedOTPValues[index] = changedValue;
                            nextFocusIndex = index;
                        }
                    }
                });
                setOTPValues(updatedOTPValues);
                const otpValue = updatedOTPValues.join('');
                setOtp(otpValue);
                setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
            }
        },
        [activeInput, getRightValue, length, otpValues]
    );

    const onBlur = useCallback(() => {
        setActiveInput(-1);
    }, []);

    const handleOnFocus = useCallback(
        (index: number) => () => {
            focusInput(index);
        },
        [focusInput],
    );

    return (
        <>
            <div {...rest}>
                {
                    length === CODE_FIREBASE_LENGTH ?
                        <SingleInput
                            key={`SingleInput-0`}
                            type={'number'}
                            focus={true}
                            value={otp}
                            autoFocus={autoFocus}
                            onChange={(e) => handleOtpChangeInput(e.target.value)}
                            style={inputStyle}
                            className={inputClassName}
                            disabled={disabled}
                            maxLength={length}
                        />:
                    Array(length)
                    .fill('')
                    .map((_, index) => (
                        <SingleInput
                            key={`SingleInput-${index}`}
                            type={'number'}
                            focus={activeInput === index}
                            value={otpValues && otpValues[index]}
                            autoFocus={autoFocus}
                            onChange={handleOnChange}
                            style={inputStyle}
                            onPaste={handleOnPaste}
                            onKeyDown={handleOnKeyDown}
                            className={inputClassName}
                            disabled={disabled}
                            maxLength={length}
                            onFocus={handleOnFocus(index)}
                            onBlur={onBlur}
                        />
                    ))}
            </div>
            {
                error && (
                    <Alert color="danger" isOpen={visible} toggle={() => setVisible(!setVisible)}>
                        {message}
                    </Alert>
                )
            }
            <Row className="container-validate-page-otp">
                <Col xs={7} className="div-span">
                    {
                        null !== redirectForgot &&
                        <span onClick={() => navigate(redirectForgot, {state: information})}>Code Pin oublié</span>
                    }
                </Col>
                <Col xs={5}>
                    {
                        loading ?
                            <div className="uil-reload-css-reload-small">
                                <div></div>
                            </div> :
                            <Button block className="btn-round btn-korix-black" size="xs" onClick={() => onValidate(otp)}>Valider</Button>
                    }

                </Col>
            </Row>
            {/*
                length === CODE_PIN_LENGTH && (
                    <div className="phone-container">
                        <div className="keyboard">
                            <div className="number">
                                <span data-number="1" onClick={()=> handlerOnClick("1")}><i>1</i></span>
                                <span data-number="2" onClick={()=> handlerOnClick("2")}><i>2</i></span>
                                <span data-number="3" onClick={()=> handlerOnClick("3")}><i>3</i></span>
                                <span data-number="4" onClick={()=> handlerOnClick("4")}><i>4</i></span>
                                <span data-number="5" onClick={()=> handlerOnClick("5")}><i>5</i></span>
                                <span data-number="6" onClick={()=> handlerOnClick("6")}><i>6</i></span>
                                <span data-number="7" onClick={()=> handlerOnClick("7")}><i>7</i></span>
                                <span data-number="8" onClick={()=> handlerOnClick("8")}><i>8</i></span>
                                <span data-number="9" onClick={()=> handlerOnClick("9")}><i>9</i></span>
                            </div>
                            <div className="number aling-right">
                                <span className="call-button"></span>
                                <span data-number="0" onClick={()=> handlerOnClick("0")}><i>0</i></span>
                                <span><FontAwesomeIcon icon={faDeleteLeft} className="icon" onClick={() => handlerOnDelete(otp)}/></span>
                            </div>
                        </div>
                    </div>
                )
            */}
        </>
    );
}

const OtpInputComponent = memo(OTPInputComponent);
export default OtpInputComponent;