export const setRoomID = (roomID) => ({
  type: 'SET_ROOM_ID',
  payload: roomID,
});


export const setStreamID = (streamID) => ({
  type: 'SET_STREAM_ID',
  payload: streamID,
});

export const setBadgeImg = (badgeImg) => ({
  type: 'SET_BADGE_IMG',
  payload: badgeImg,
});