import { useEffect, useState } from 'react';
import {badgeListget, selectAdminData, adminInfo,} from '../../../../services/stores/nft/NftSlice';
import { useDispatch, useSelector } from 'react-redux';
import MintedBadgeScreen from './MintedBadgesScreen';
import MintedDataTable from './BadgesDataTable';
import NavBar from '../../../../components/NavBar';
import { Button, Col, Container, Row } from 'reactstrap';
import ModalNewUserBadge from './ModalNewUserBadge';


export default function Dashboard() {

  const dispatch = useDispatch();
  const [badgeCount, setBadgeCount] = useState([]);
  const [thisOwner, setThisOwner] = useState("");
  const [adminType, setAdminType] = useState();
  const userReducers = useSelector((state) => state.userReducers);
  const { user } = userReducers;
  const [isOpen, setIsOpen] = useState(false);

 
  useEffect(() => {
      dispatch(adminInfo());
  }, [])


  useEffect(() => {
    dispatch(adminInfo())
    dispatch(badgeListget());
  }, [])


  return (
      <>
          <NavBar />
        <div className="page-header-white">
        <Container className="ml-auto mr-auto">     
            {
              !user ?
                (
                    <div className="colltext" style={{ paddingTop: "10em" }}>
                      <></>
                    </div>
                ) : user &&
                (
                  <div className="container">

                    <Row>
                        <Col className="col-lg-3">
                            <div className="pb-0">
                                <Button block className="btn-round" color="default" onClick={() => setIsOpen(true)}>Nouveau Badge</Button>
                            </div>
                        </Col>
                    </Row>

                     {/* Badge counter section */}
                     <ModalNewUserBadge isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} />

                    {/* Badge counter section */}
                    <section style={{ paddingTop: "5em" }}>
                        <MintedBadgeScreen badgeCount={badgeCount} adminType={adminType} thisOwner={thisOwner} setThisOwner={setThisOwner} />
                    </section>

                    {/* Badge Minted DataTable */}
                    {
                      
                      <section>
                          <MintedDataTable adminType={adminType} />
                      </section>
                    }
                  </div>
                )
             }

          </Container>
        </div>
      </>
   )
}