import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import NavBar from "../../components/NavBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus,} from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/Footer";
import {useSelector} from "react-redux";
import BadgeImage from "../../components/BadgeImage";
import BlockComponent from "../../components/BlockComponent";
import {ROUTE_OTP, ROUTE_PRIVATE_QRCODE, ROUTE_PRIVATE_WALLET} from "../../services/routes/Routes";
import {useNavigate} from "react-router-dom";
import ModalAskCodePin from "../../components/ModalAskCodePin";
import {getUserBadges, getUserBadgesTransaction} from "../../services/queries/Badge";

function Wallet () {

    const navigate = useNavigate();
    const userReducers = useSelector((state) => state.userReducers);
    const codePinReducers = useSelector((state) => state.codePinReducers);
    const { user } = userReducers;
    const { lastTimeStampCodePinGenerate } = codePinReducers;
    const [badges, setBadges] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [badgeSelected, setBadgeSelected] = useState({});
    const [loading, setLoading] = useState(true);
    const [isOpenCodePin, setIsOpenCodePin] = useState(false);

    useEffect(() => {
        document.body.style.overscrollBehaviorY = 'auto';
    }, [])

    useEffect(() => {
        fetchBadges();
    }, []);

    useEffect(() => {
        fetchTransaction();
    }, []);

    const fetchBadges = async () => {
        setBadges(await getUserBadges(user.phoneNumber));
        setLoading(false);
    }

    const fetchTransaction = async () => {
        setTransactions(await getUserBadgesTransaction(user.phoneNumber));
        setLoading(false);
    }

    const redirectToBadgeQrCode = (badge) => {
        const state = {
            whereIComeFrom: ROUTE_PRIVATE_WALLET,
            whereIGo: ROUTE_PRIVATE_QRCODE,
            key: badge.key,
            img: badge.imageUrl,
            name: badge.name,
            address: badge.address,
            data: {
                tokenId: badge.tokenID,
                userTel: user.phoneNumber,
                contractAddress: badge.address,
                userName: badge.userName,
            }
        };

        if(!user.asCodePin){
            setBadgeSelected(state);
            setIsOpenCodePin(!isOpenCodePin);
            return;
        }

        const currentTimestamp = Date.now();
        const lastTimeStampCodePinGeneratePlus30sec = lastTimeStampCodePinGenerate + 120000;
        if (null === lastTimeStampCodePinGenerate || lastTimeStampCodePinGeneratePlus30sec < currentTimestamp) {
            navigate(ROUTE_OTP, { state: state});
        } else {
            navigate(ROUTE_PRIVATE_QRCODE, { state: state});
        }
    }

    return (
        <div className="marketplace-page">
            <NavBar />
            <Container>
                <div className="sell-marketplace-page">
                    <h4>Mes badges</h4>
                    <div className={"sell-badge-marketplace-page"}>
                        {
                            badges.length > 0 ?
                                <Row>
                                    {
                                        badges.map((badge, index) => {
                                            return (
                                                <Col xs={6} key={index}>
                                                    <BadgeImage
                                                        badge={badge}
                                                        liveState={false}
                                                        showLiveIcon={false}
                                                        loading={loading}
                                                        onEvent={redirectToBadgeQrCode}
                                                        className={"wallet"}
                                                    />
                                                </Col>
                                            )
                                        })
                                    }
                                </Row> :
                                <div className="sell-badge-empty-marketplace-page"><span>Vous n'avez pas de badge</span></div>
                        }
                    </div>
                </div>
                <div className="sell-transaction-marketplace-page">
                    <Row>
                        <Col xs={6}>
                            <h4>Mes transactions</h4>
                        </Col>
                        <Col xs={6}>
                            <div className="sell-transaction-marketplace-page-icon">
                                <span>Tout afficher</span><FontAwesomeIcon icon={faPlus} className="icon"/>
                            </div>
                        </Col>
                    </Row>
                    <div className="container-body-block-marketplace-page">
                        {
                            transactions.map((transaction, index) => {
                                return (
                                    <Col key={index} xs={12}>
                                        <BlockComponent
                                            badge={transaction}
                                            loading={loading}
                                            onEvent={null}
                                            information={
                                                {
                                                    id: transaction.key,
                                                    artistName: `Achat - badge ${transaction.name}`,
                                                    date: transaction.date,
                                                    amount: transaction.amount,
                                                    imageCollection: transaction.collectionImg,
                                                    paymentTxiId: transaction.paymentTxiId,
                                                    tokenID: transaction.tokenID,
                                                    referralID: transaction.referralID,
                                                    buyer: transaction.buyer ? transaction.buyer : null
                                                }
                                            }
                                            className={"transaction"}
                                        />
                                    </Col>
                                )
                            })
                        }
                    </div>
                </div>
            </Container>
            <ModalAskCodePin isOpen={isOpenCodePin} toggle={() => setIsOpenCodePin(!isOpenCodePin)} route={ROUTE_PRIVATE_QRCODE} state={badgeSelected} modal={false}/>
            <Footer/>
        </div>
    )

}

export default Wallet;
