import React from 'react';
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faClose, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal, ModalBody} from "reactstrap";
import {codeScanError, formatDate, formatDateHour} from "../services/Constant";

function ShowStatusScanQrcode({isOpen, toggle, codeScanStatus, badgeInformation}) {

    const showStatus = (badgeStatus) => {
        switch (badgeStatus) {
            case codeScanError.scanValid:
                return (
                    <div>
                        <div align="center">
                            <h2>Badge valide</h2> <br/>
                            <h5>{`${badgeInformation.userName} est autorisé à accéder à l'événement:`} <br/><br/> <strong className="text-uppercase">{badgeInformation.name}</strong></h5>
                            <span>Contact du propriétaire: {badgeInformation.userTel}</span>
                        </div>
                        <div align="center" className="pt-4 pb-3">
                            <FontAwesomeIcon icon={faCheckCircle} className="icon-fa" color="green" size={"10x"}/>
                        </div>
                    </div>
                )
            case codeScanError.scanInvalid:
                return (
                    <div>
                        <div align="center">
                            <h2>Votre badge est expiré</h2> <br/>
                            <h5>Ce badge a expiré le : <br/><br/> <strong>{formatDate(badgeInformation.expireDate)}</strong></h5>
                        </div>
                        <div align="center" className="pt-4 pb-3">
                            <FontAwesomeIcon icon={faXmarkCircle} className="icon-fa" color="red" size={"10x"}/>
                        </div>
                    </div>
                )
            case codeScanError.scanIsAlreadyOk:
                return (
                    <div>
                        <div align="center">
                            <h2>Badge déjà scanner</h2> <br/>
                            <h5>Ce badge a déja été scanné le : <br/><br/> <strong>{formatDateHour(badgeInformation.scanAt)}</strong></h5>
                        </div>
                        <div align="center" className="pt-4 pb-3">
                            <FontAwesomeIcon icon={faXmarkCircle} className="icon-fa" color="red" size={"10x"}/>
                        </div>
                    </div>
                )
            case codeScanError.scanUnauthorised:
                return (
                    <div>
                        <div align="center">
                            <h2>Scan non autorisé</h2> <br/>
                            <h5>Vous n'avez pas le droit pas effectuer le scanne du badge.</h5>
                        </div>
                        <div align="center" className="pt-4 pb-3">
                            <FontAwesomeIcon icon={faXmarkCircle} className="icon-fa" color="red" size={"10x"}/>
                        </div>
                    </div>
                )
            default:
                return (
                    <div>
                        <div align="center">
                            <h5>Une erreur c'est produite veuillez scanner le badge de nouveau</h5>
                        </div>
                    </div>
                )
        }
    }

    return (
        <Modal className="modal-badge dark" funk={true} isOpen={isOpen} toggle={toggle} size="xl">
            <ModalBody className="dark">
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle}/>
                </div>
                <div className="modal-badge-button text-center">
                    {showStatus(codeScanStatus)}
                    <div className="container-validate">
                        <Button className="btn-round btn-korix-orange" onClick={toggle}>
                            FERMER
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}
export default ShowStatusScanQrcode;


ShowStatusScanQrcode.defaultProps = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    codeScanStatus: PropTypes.string,
    badgeInformation: PropTypes.object,
};

ShowStatusScanQrcode.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    badgeInformation: PropTypes.object.isRequired,
};