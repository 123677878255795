import {PHONE_USER, AUTH_USER, SAVE_USER, AS_CODE_PIN, LOG_OUT_USER} from '../types/UserTypes';


const isAuthenticated = localStorage.getItem("isAuthenticated");
const user = localStorage.getItem("user");

const INITIAL_STATE = {
    isAuthenticated: isAuthenticated ? isAuthenticated : false,
    phoneNumber: "",
    user: user ? JSON.parse(user) : {
        address: "",
        badge: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        role: "",
        status: true,
        asCodePin: false
    },
    message: ""
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SAVE_USER:
            return {
                ...state,
                ...action.payload,
            };
        case PHONE_USER:
            return {
                ...state,
                ...action.payload,
            };
        case AUTH_USER:
            return {
                ...state,
                ...action.payload,
            };
        case AS_CODE_PIN:
            return {
                ...state,
                ...action.payload,
            };
        case LOG_OUT_USER:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};