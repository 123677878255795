import {Alert, Button, Col, Row} from "reactstrap";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {STEP_HUB2_PAYMENT, STEP_COUNTRY, STEP_PHONE} from "../../services/Constant";

function ChooseModePayment ({paymentsAvailable, eventBack, eventNext}) {

    const [operator, setOperator] = useState('');
    const [error, setError] = useState(false);
    const [visible, setVisible] = React.useState();

    const handlerNextStep = () => {
        setError(false);
        if("" === operator) {
            setError(true);
            setVisible(true);
            return;
        }

        eventNext(STEP_HUB2_PAYMENT, STEP_PHONE, paymentsAvailable[operator]);
    }

    return (
        <>
            {
                error && (
                    <Alert color="danger" isOpen={visible} toggle={() => setVisible(!visible)}>
                        Veuillez un mode de payement
                    </Alert>
                )
            }
            <div>
                <p>Choisissez une moyen de payement</p>
                <Row className={"modal-container-drapeau"}>
                    {
                        Object.keys(paymentsAvailable).map((key, index) => {
                            return (
                                <Col className="col-md-4 border-right" key={key}>
                                    <div className={operator === key ? "modal-container-image active" : "modal-container-image"}>
                                        <div className={"image-container-border white"}>
                                            <img src={paymentsAvailable[key].flag} alt={key}
                                                 onClick={() => setOperator(key)} />
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
            <div className="container-validate">
                <Button block className="btn-round btn-korix-orange" size="md" onClick={handlerNextStep}>
                    suivant
                </Button>
                <Button block className="btn-round return" size="md" onClick={() => eventBack(STEP_COUNTRY)}>
                    retour
                </Button>
            </div>
        </>
    )
}

export default ChooseModePayment;

ChooseModePayment.defaultProps = {
    paymentsAvailable: PropTypes.object,
    eventBack: PropTypes.func,
    eventNext: PropTypes.func,
};

ChooseModePayment.propTypes = {
    paymentsAvailable: PropTypes.object.isRequired,
    eventBack: PropTypes.func.isRequired,
    eventNext: PropTypes.func.isRequired,
}