const REGEX_PASSWORD = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{6,}$/; /// Au moins 6 caractères, au moins une lettre, un chiffre
const REGEX_PHONE = /^\d{8,10}$/;

const validate = (value, rule, length?) => {

    let valid;
    let message = "";
    switch (rule) {
        case 'minLength':
            valid = minLengthValidator(value, length);
            break;
        case 'maxLength':
            valid = maxLengthValidator(value, length);
            break;
        case 'length':
            valid = lengthValidator(value, length);
            break;
        case 'isRequired':
            valid = requiredValidator(value);
            message = "Champs obligatoire";
            break;
        case 'password':
            valid = passwordValidator(value);
            message = "Le format du mots de passe n'est pas correct";
            break;
        case 'tel':
            valid = phoneValidator(value);
            message = "Le format du téléphone n'est pas correct";
            break;
        case 'image':
            valid = imageValidator(value);
            break;
        case "maxImage":
            valid = imageLengthValidator(value, length);
            message = "form.error.maxImage";
            break;
        default:
            valid = false;
    }

    return {
        valid: valid,
        message: message
    }
}

const lengthValidator = (value, length) => {
    return value.length === length;
}

const minLengthValidator = (value, length) => {
    return value.length >= length;
}

const maxLengthValidator = (value, length) => {
    return value.length <= length;
}

const requiredValidator = (value) => {
    return '' === value && '' === value.trim();
}

const passwordValidator = (value) => {
    return REGEX_PASSWORD.test(String(value));
}

const phoneValidator = (value) => {
    return REGEX_PHONE.test(String(value));
}

const imageValidator = (value) => {
    return !("image/jpeg" !== value.type.toLowerCase() && "image/jpg" !== value.type.toLowerCase() && "image/png" !== value.type.toLowerCase() && "image/webp" !== value.type.toLowerCase());
}

const imageLengthValidator = (value, length) => {
    return value.length <= length;
}

export default validate;