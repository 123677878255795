import React, {useCallback, useEffect, useState} from "react";
import SingleInput from "../input/SingleInput";
import {FormGroup, Label} from "reactstrap";
import {faCheck, faClose, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import validate from "../../services/Validate";

export interface InputProps {
    name: string;
    defaultValue: any;
    length?: number;
    onChange: (value: string) => any;

    focus?: boolean;
    type: string;
    disabled?: boolean;
    required?: boolean;
    placeholder: string;

    className?: string;
    code?: any;
    setFieldError?: any;
    label?: string;
    password?: boolean;
    confirmPassword?: string;
}

export function InputComponent(props: InputProps) {
    const {
        name,
        defaultValue,
        length,
        onChange,
        focus,
        type,
        disabled,
        required,
        className,
        code,
        placeholder,
        setFieldError,
        label,
        password,
        confirmPassword
    } = props;

    const [value, setValue] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [typePassword, setTypePassword] = useState(type);
    const [message, setMessage] = useState("");

    useEffect(() => {
        if("" === defaultValue) {
            setValue(defaultValue);
            setSuccess(false);
        }
    }, [defaultValue])

    const handleOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;

            if("" !== value) {
                setError(false);
            }

            onChange(value);
            setValue(value);
        },
        [onChange, setValue],
    );

    const handleBlur = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if("" === value && required) {
                const {valid, message} = validate(value, "isRequired");

                setFieldError(valid);
                if(valid) {
                    setError(true);
                    setMessage(message);
                    return;
                }
            }

            if("" !== value) {
                if("password" === type || "password" === typePassword) {
                    let {valid, message} = validate(value, "password");

                    if(confirmPassword && value !== confirmPassword) {
                        valid = false;
                        message = "Les mots de passe sont différents";
                    }

                    setFieldError(!valid);
                    if(!valid) {
                        setError(true);
                        setMessage(message);
                        return;
                    }
                }

                if("tel" === type) {
                    const {valid, message} = validate(value, "tel");

                    setFieldError(!valid);
                    if(!valid) {
                        setError(true);
                        setMessage(message);
                        return;
                    }
                }
            }

            if("" === value && !required) {
                setSuccess(false);
                setMessage("");
                return;
            }

            setSuccess(true);
            setMessage("");
        },
        [value, setError, setMessage, required, type]
    );

    const handlerOnClickChangeEye = (type) => {
        setTypePassword(type)
    }

    return (
        <div className={error ? `${className} has-danger` : className}>
            {
                label && (
                    <Label for={name}>{label}</Label>
                )
            }
            <FormGroup className={error ? `has-danger` : ""}>
                <SingleInput
                    key={name}
                    type={false === password ? type : typePassword}
                    focus={focus}
                    value={value}
                    onChange={handleOnChange}
                    onBlur={handleBlur}
                    disabled={disabled}
                    required={required}
                    maxLength={length}
                    placeholder={placeholder}
                    autoComplete={type === 'password' ? "off" : "on"}
                />
                {
                    password && (
                        "password" === typePassword ?
                            <FontAwesomeIcon icon={faEyeSlash} className={error ? `icon-eye has-danger` : "icon-eye"} onClick={() => handlerOnClickChangeEye("text")}/> :
                            <FontAwesomeIcon icon={faEye} className={error ? `icon-eye has-danger` : "icon-eye"} onClick={() => handlerOnClickChangeEye("password")}/>
                    )
                }
                {
                    required && error && (
                        <>
                            <FontAwesomeIcon icon={faClose} className={password ? "icon-error password" : "icon-error"}/>
                            {
                                "" !== message && (
                                    <small>{message}</small>
                                )
                            }
                        </>
                    )
                }
                {
                    !error && success && (
                        <FontAwesomeIcon icon={faCheck} className={password ? "icon-green password" : "icon-green"}/>
                    )
                }
            </FormGroup>
            {
                code && (
                    code
                )
            }
        </div>
    )
}

export default InputComponent;