import {CODE_PIN_LAST_GENERATE} from '../types/CodePinTypes';


const INITIAL_STATE = {
    lastTimeStampCodePinGenerate: null,
    message: ""
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CODE_PIN_LAST_GENERATE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};