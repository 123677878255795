import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

export const CountdownTimer = (props) => {
    const milliseconds = props.seconds * 1000;
    const radius = props.size / 2;
    const circumference = props.size * Math.PI;
  
    const [countdown, setCountdown] = useState(milliseconds);
    const [isPlaying, setIsPlaying] = useState(true);
  
    const strokeDashoffset = () =>
      circumference - (countdown / milliseconds) * circumference;
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (countdown <= 0) {
          clearInterval(interval);
          setIsPlaying(false);
          props.onTimeUp();
        } else {
          setCountdown((prevCountdown) => prevCountdown - 10);
        }
      }, 10);
  
      return () => {
        clearInterval(interval);
      };
    }, [countdown]);
  
    const countdownSizeStyles = {
      height: props.size,
      width: props.size,
    };
  
    const textStyles = {
      color:'#fab600',
      fontSize: props.size * 0.3,
    };
    const circleStyles = {
        stroke: '#fab600',
        strokeWidth: props.strokeWidth,
    };
  
    const seconds = Math.max(0, Math.ceil(countdown / 1000)); // Évite les valeurs négatives
  
    return (
      <div>
        {isPlaying ? (
          <div
            style={{
              pointerEvents: 'none',
              opacity: 0.4,
            }}
          ></div>
        ) : (
          <p>Le paiement a été annulé</p>
        )}
        <div
          style={Object.assign({}, styles.countdownContainer, countdownSizeStyles)}
        >
          <p style={textStyles}>{seconds}s</p>
          <svg style={styles.svg}>
            <circle
              cx={radius}
              cy={radius}
              r={radius}
              fill="none"
              stroke={props.strokeBgColor}
              strokeWidth={props.strokeWidth}
            ></circle>
          </svg>
          <svg style={styles.svg}>
            <circle
              strokeDasharray={circumference}
              strokeDashoffset={isPlaying ? strokeDashoffset() : 0}
              r={radius}
              cx={radius}
              cy={radius}
              fill="none"
              strokeLinecap="round"
              stroke={props.strokeColor}
              {...circleStyles}
            ></circle>
          </svg>
        </div>
      </div>
    );
  };
const styles = {
    countdownContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: 'auto',
    },
    svg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        transform: 'rotateY(-180deg) rotateZ(-90deg)',
        overflow: 'visible',
    },
};