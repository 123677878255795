import React, { useState, useEffect } from "react";
import { Button, Toast, ToastBody, ToastHeader } from "reactstrap";
import useAddToHomescreenPrompt from "./AddAppToScreen";
import {detectMobileDevices} from "../services/Constant";

const AddToScreenToast = ({className}) => {
    const [isVisible, setVisibleState] = useState(false);
    const hide = () => setVisibleState(false);

    const [prompt, promptToInstall] = useAddToHomescreenPrompt();

    useEffect(
        () => {
            if (prompt && detectMobileDevices()) {
                setVisibleState(true);
            }
        },
        [prompt]
    );

    return (
        <div className={className ? `component-toast ${className}` : "component-toast"}>
            <Toast isOpen={isVisible} onClose={hide}>
                <ToastHeader toggle={hide}>Ajouter Acxes à l'écran d'accueil</ToastHeader>
                <ToastBody>
                    <Button
                        className="btn-korix-black"
                        onClick={() => {
                            promptToInstall()
                            hide();
                        }}
                    >
                        Cliquer ici pour installer
                    </Button>
                </ToastBody>
            </Toast>
        </div>
    );
};

export default AddToScreenToast;