import React, {useState, useEffect} from "react";
import {Container} from "reactstrap";
import {useLocation, useNavigate, useNavigationType} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faLock} from "@fortawesome/free-solid-svg-icons";
import OtpInputComponent from "../../components/otp/OtpInputComponent";
import {CODE_PIN_LENGTH} from "../../services/Constant";
import {collection, doc, getFirestore, updateDoc} from "firebase/firestore";
import bcrypt from "bcryptjs";
import app from "../../services/Firebase";
import {useDispatch, useSelector} from "react-redux";
import {setGenerateLastCodePin} from "../../services/stores/actions/CodePinActions";
import {setUserAsCodePin} from "../../services/stores/actions/UserActions";
import {ROUTE_500} from "../../services/routes/Routes";
const db = getFirestore(app);
const userCollection = collection(db, 'users');

function OtpConfirmCodePin() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const navType: NavigationType = useNavigationType();
    const userReducers = useSelector((state) => state.userReducers);
    const { user } = userReducers;
    const {state} = useLocation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("Veuillez renseigner un code valide");
    const [visible, setVisible] = React.useState();
    document.documentElement.classList.remove("nav-open");

    useEffect(() => {
        if(!state) {
            navigate(ROUTE_500)
        } else if(navType === "POP") {
            navigate(state.whereIComeFrom);
        }

    }, [state])

    const handleOnClick = async (otp) => {
        setError(false);
        setVisible(false);

        if("" === otp || otp.length !== CODE_PIN_LENGTH) {
            setError(true);
            setVisible(true);
            setLoading(false);
            setMessage("Veuillez renseigner un code Pin valide");
            return;
        }

        if(state.otp !== otp) {
            setError(true);
            setVisible(true);
            setLoading(false);
            setMessage("Les codes pin sont différents");
            return;
        }

        setLoading(true);
        const userDocID = doc(userCollection, user.phoneNumber);
        const hashedPassword = await bcrypt.hash(otp, 10);

        try {
            await updateDoc(userDocID, { codePin: hashedPassword});
            dispatch(setUserAsCodePin());
            dispatch(setGenerateLastCodePin());
            navigate(state.result.whereIGo, { state: state.result});
        } catch (e) {
            setError(true);
            setVisible(true);
            setLoading(false);
            setMessage("Votre code Pin est incorrect, si vous l'avez oublier veillez le réinisialiser");
        }
    }

    return (
        <div className="page-otp">
            <Container>
                <div className="icon-container-page-otp">
                    <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} className="icon"/>
                </div>
                <div className="envelope-container-page-otp ml-auto mr-auto">
                    <FontAwesomeIcon icon={faLock} className="icon"/>
                </div>
                <div className="container-form-page-otp text-center">
                    <div className="container-text-page-otp">
                        <h3>Confirmer le code PIN </h3> <br/>
                        <h5>Veuillez créer votre code PIN à nouveau pour le confirmer</h5>
                    </div>
                    <OtpInputComponent
                        autoFocus
                        length={CODE_PIN_LENGTH}
                        error={error}
                        loading={loading}
                        visible={visible}
                        message={message}
                        setVisible={setVisible}
                        onValidate={handleOnClick}
                        className="otp-container"
                        inputClassName="otp-input"
                        redirectForgot={null}
                    />
                </div>

            </Container>
        </div>
    );
}

export default OtpConfirmCodePin;
