import NavBar from "../../../../components/NavBar";
import React, {useEffect, useState} from "react";
import {getFirestore, collection, getDocs, doc, updateDoc, deleteDoc} from "firebase/firestore";
import app from "../../../../services/Firebase";
import {
    Button, Container,
    Table, Row, Col, UncontrolledTooltip,
} from "reactstrap";
import ModalNewEvent from "./ModalNewEvent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashCan} from "@fortawesome/free-solid-svg-icons";
const db = getFirestore(app);
const eventsCollection = collection(db, 'events');


function Events() {

    const [isOpen, setIsOpen] = useState(false);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchAgent();
    }, []);

    const  fetchAgent = async () => {
        const agentDocs = await getDocs(eventsCollection);
        if(agentDocs.docs.length > 0) {
            var collectionsAgent = [];
            agentDocs.docs.map((agent, index) => {
                collectionsAgent[index] = {
                    key: agent.id,
                    address: agent.data().address,
                    badge: agent.data().badge,
                    name: agent.data().name,
                    type: agent.data().type,
                    startDate: agent.data().startDate,
                    endDate: agent.data().endDate,
                };
            });

            setEvents(collectionsAgent);
        }
        setLoading(false);
    }

    const handlerDelete = async (event, index) => {
        const id = event.key;
        setEvents(prevState => ([...prevState]));
        const eventRef = doc(db, 'events', id);
        await deleteDoc(eventRef);
    }

    return (
        <>
            <NavBar />
            <div className="page-header-white">
                <Container className="ml-auto mr-auto">
                    <Row>
                        <Col className="col-lg-3">
                            <div className="pb-5">
                                <Button block className="btn-round" color="default" onClick={() => setIsOpen(true)}>Nouvelle évènement</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-lg-12">
                            {
                                loading ?
                                    <div className="uil-reload-css-reload-small color">
                                        <div></div>
                                    </div> :
                                    events.length === 0 ?
                                        <div className="text-center">
                                            <span>Aucun évènement disponible</span>
                                        </div>
                                        :
                                        <Table className="align-items-center" responsive>
                                            <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Nom</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Date de début</th>
                                                <th scope="col">Date de fin</th>
                                                <th scope="col">badge</th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                events.map((event, index) => {
                                                    return <tr>
                                                        <th scope="row">
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-2">{event.key}</span>
                                                            </div>
                                                        </th>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-2">{event.name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-2">{event.phone}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-2">{event.badge}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-2">{event.status}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-2">{event.status}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <Row className="">
                                                                <Col xs={10}>
                                                                    <span className="mr-2"><FontAwesomeIcon icon={faTrashCan} id="top" onClick={() => handlerDelete(event, index)}/></span>
                                                                </Col>

                                                            </Row>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </Table>

                            }
                        </Col>
                    </Row>
                </Container>
            </div>
            <ModalNewEvent isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}/>
        </>
    )

}

export default Events;