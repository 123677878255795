
import React, { useEffect, useState } from 'react';
import listFreeCurrency from '../../../../services/constance/feeCurrency.json';
import { useDispatch, useSelector } from 'react-redux';
import {
    addMinter,
    getContractAddress,
    selecAPI,
    selectAlert,
    selectkms,
    selectNetwork,
    selectSmartContract,
    selectTxId,
    setTxId
} from "../../../../services/stores/nft/NftSlice";
import {Alert, Button, Card, Col, CustomInput, FormGroup, Input, Label, Modal, ModalBody, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import app from '../../../../services/Firebase';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import AlertCompo from '../../../../components/Alert';
import { alert as thisAlers, deploySmartContract, selectMetaAccont, metaModalChekWallet, conectMetaAccount, conectMetaWallet } from '../../../../services/stores/nft/NftSlice';
import {categories} from "../../../../services/Constant";

const db = getFirestore(app);
const badgeCollection = collection(db, 'badge-collection');
const partnersCollection = collection(db, 'partners-collection');
const storage = getStorage(app)

const PENDING = "pending";
const REJECTED = "rejected";
const IDLE = "idle";

function ModalBadgeContract ({isOpen, toggle}) {

    const dispatch = useDispatch();
    const BlockChain = useSelector(selectNetwork);
    const smartContract = useSelector(selectSmartContract);
    const [fieldSmartContract, setFieldSmartContract] = useState("");
    const txId = useSelector(selectTxId);
    const kmsStatus = useSelector(selectkms).Status;
    const hasContract = useSelector(selecAPI).contract.hasContract;
    const metaAccont = useSelector(selectMetaAccont);
    const alertProp = useSelector(selectAlert);

    const [deployChain, setDeployChain] = useState("CELO");
    const [deployName, setDeployName] = useState("");
    const [deploySymbol, setDeploySymbol] = useState("");
    const [collectionDetails, setCollectionDetails] = useState("");
    const [collectionDetailsList, setCollectionDetailsList] = useState( [""]);
    const [feeCurrency, setFeeCurrency] = useState("CELO");
    const [fixCashback, setFixCashback] = useState(false);
    const [percentage, setPercentage] = useState(false);
    const [royalti, setRoyalti] = useState(false);
    const [inputTxId, setInputTxId] = useState("");
    const [errorField, setErrorField] = useState(false);
    const [timer, setTimer] = useState(0);
    const [alert, setAlert] = useState(false); 
    const [collectionCategory, setCollectionCategory] = useState("Musique");
    const [collectionImg, setCollectionImg] = useState("");
    const [collectionImgfile, setCollectionImgfile] = useState("");
    const [ownerNumber, setOwnerNumber] = useState('');
    const [metamaskRequired, setMetamaskRequired] = useState(false);
    const [fieldNull, setFieldNull] = useState(false);
    const [deployErrorField, setDeployErrorField] = useState(false);
    const [txIdErrorField, setTxIdErrorField] = useState(false);
    const [counter, setCounter] = useState(0);
    const [intervalName, setintervalName] = useState(0);
    const [ isOpenDialog, setIsOpenDialog ] = useState(false)


      
    const handlerDeployContract = () => {
        if (deployChain === "" || deployName === "" || deploySymbol === "" || (deployChain === "CELO" && feeCurrency === "") || (royalti && (!fixCashback && !percentage))) {
            setFieldNull(true);
            setDeployErrorField(true);
          } else {
            if (!metaAccont) {
              setMetamaskRequired(true);
              dispatch(conectMetaWallet());
              dispatch(metaModalChekWallet());
            } else {
              dispatch(deploySmartContract(deployName, deploySymbol, deployChain, feeCurrency, percentage, fixCashback));
            }
          }
    }


    // for approve Minter to mint on created smartContract
    useEffect(() => {
        if (smartContract != null) {
            dispatch(addMinter(smartContract))
        }
    }, [smartContract])

    // for check and get transaction and contract address
    const checkContract = () => {
        if (deployChain === "" || inputTxId === "") {
            setTxIdErrorField(true)
            dispatch(thisAlers({ massage: "Saissez l'ID de la transaction", type: "danger", show: true }));
        } else {
            dispatch(setTxId(inputTxId))
            dispatch(getContractAddress({ chain: deployChain, txId: inputTxId }))
        }
    }

    // for check and get and contract address interval
    const checkinterval = () => {
        if (hasContract === "pending" || hasContract === null || hasContract === "") {
            dispatch(getContractAddress({ chain: deployChain, txId: txId }))
            setCounter(counter + 1);
        }
    }

    // for call chek api evry minuts 
    useEffect(() => {
        const timerCheckContract = () => {
            if (intervalName) clearInterval(intervalName);
            if ((hasContract === "pending" || hasContract === null || hasContract === "") && txId) {
                var tempTime = 30;
                const interval = setInterval(() => {
                    if (tempTime > 0) {
                        setTimer(tempTime);
                        tempTime--
                    } else {
                        checkinterval();
                        return clearInterval(interval);
                    };
                }, 1000);
                setintervalName(interval);
            }
        }
        if (counter < 10) timerCheckContract();
    }, [counter, txId, hasContract]);

    useEffect(() => {
        (kmsStatus === "loading") && dispatch(thisAlers({ massage: "Confirmer la transaction dans metamask", type: "primary", show: true, time: 3000 }));
        (kmsStatus === "rejected") && dispatch(thisAlers({ massage: "La transaction n'a pas abouti", type: "danger", show: true, time: 3000 }));
    }, [kmsStatus])

    // for show img in form
    const onImageChange = (e) => {
        const [file] = e.target.files;
        if (file.size < 50000000) {
            setCollectionImg(URL.createObjectURL(file));
            setCollectionImgfile(e.target.files[0]);
        } else {
            setCollectionImg("");
            setCollectionImgfile("");
            dispatch(thisAlers({ massage: "file size larger than 50MB", type: "danger", show: true }))
        }
    };

    const handleUploadCollection = () => {
        const uploadTask = ref(storage, `partners/${collectionImgfile.name}`);
         if("" === fieldSmartContract || "" === deployName || "" === collectionCategory || "" === collectionDetails || "" === ownerNumber || "" === deploySymbol) {
            setErrorField(true);
             return;
         }
 
         uploadBytes(uploadTask, collectionImgfile).then((image) => {
             getDownloadURL(image.ref).then((url) => {
                 var metadata = {
                     contractAddress: fieldSmartContract,
                     collectionDetails: collectionDetails,
                     collectionName: deployName,
                     category: collectionCategory,
                     ownerNumber: ownerNumber,
                     deploySymbol: deploySymbol,
                     collectionDetailsList: collectionDetailsList,
                     imageUrl: url,
                     listed: true,
                 }
 
                 const partnersDocId = doc(partnersCollection, deployName);
 
                 setDoc(partnersDocId, metadata).then((e) => {
                    setIsOpenDialog(true);
                 });
             })
         })
 
     }

     const handleDialogOK = () => {
        setIsOpenDialog(false);
        window.location.reload();
      };

    const handlerAddDescriptionList = () => {
        collectionDetailsList.push("");
        setCollectionDetailsList(prevState => ([...prevState]));
    }

    const handlerDeleteDescriptionList = (index) => {
        collectionDetailsList.splice(index, 1);
        setCollectionDetailsList(prevState => ([...prevState]));
    }

    const handlerSetDescriptionElement = (index, text) => {
        collectionDetailsList[index] = text;
        setCollectionDetailsList(prevState => ([...prevState]));
    }


    return (
        <>
        <Modal className="modal-badge" funk={true} isOpen={isOpen} toggle={toggle} size="xl">
            <ModalBody>
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle} />
                </div>
                <div className="modal-badge-button">
                    <Card className="ml-auto mr-auto">
                        <Button className="modal-badge-button-title detail text-uppercase btn-round" size="lg">
                            <div className="text">Deployer</div>
                        </Button>
                        <div className="pt-3">
                            {
                                metamaskRequired &&
                                <Alert color="warning" isOpen={metamaskRequired} toggle={() => setMetamaskRequired(!metamaskRequired)}>
                                    Connectez-vous à Metamask et réessayez
                                </Alert>
                            }
                            {
                                fieldNull && (deployName === "" || deploySymbol === "") &&
                                <Alert color="danger" isOpen={fieldNull} toggle={() => setFieldNull(false)}>
                                    Remplissez les champs nom et symbole
                                </Alert>
                            }
                            {
                                alert && (
                                    <Alert color="danger" isOpen={alert} toggle={() => setAlert(false)}>
                                        Veuillez remplir tous les champs marqués par '*'
                                    </Alert>
                                )
                            }
                        </div>
                        <div className="pt-3">
                            
                        {((hasContract === "pending")) &&
                                    <div className="pending alert alert-primary">
                                        transaction encours ... verifier après {timer} seconde
                                    </div>
                                }
                                {((kmsStatus === IDLE && txId !== "" && hasContract === "")) &&
                                    <div className="pending alert alert-primary">
                                        transaction encours ... verifier après {timer} seconde
                                    </div>
                                }
                                {(hasContract === REJECTED) &&
                                    <div className="pending alert alert-danger">
                                        cette txId n'a aucun smartContact
                                    </div>
                                }
                                {(hasContract === IDLE) &&
                                    <div className="pending alert alert-success showTxId">
                                        votre adresse smartcontarct  est: <a className="d-flex justify-content-center align-items-center" href="#" >{smartContract.substring(0, 10)}...{smartContract.substring(smartContract.length - 10, smartContract.length)}</a>
                                        <span className='icon' onClick={() => navigator.clipboard.writeText(smartContract)}>Copier</span>
                                    </div>
                                }
                                {(txId && kmsStatus === IDLE && inputTxId === "") &&
                                    <div className="pending alert alert-success showTxId">
                                        votre txId est:<a className="d-flex justify-content-center align-items-center" href="#" onClick={(e) => {e.preventDefault(); navigator.clipboard.writeText(txId)}} >{txId.substring(0, 10)}...{txId.substring(txId.length - 10, txId.length)}</a>
                                        <span className='icon' onClick={(e) => {e.preventDefault(); navigator.clipboard.writeText(txId)}}>Copier</span>
                                    </div>
                                }
                            
                        </div>
                        <div className="modal-badge-box">
                            <div className="pt-3">
                                <FormGroup className={deployName === "" && deployErrorField || errorField  ? "has-danger" : ""}>
                                    <Label for="name" className="control-label">Nom</Label>
                                    <Input type="text" name="name" id="name" placeholder="Nom de la collection" value={deployName} onChange={(event) => setDeployName(event.target.value)} />
                                </FormGroup>
                                <FormGroup className={deploySymbol === "" && deployErrorField || errorField  ? "has-danger" : ""}>
                                    <Label for="symbol" className="control-label">Symbole</Label>
                                    <Input type="text" name="symbol" id="symbol" placeholder="Symbole Ex : DMS" value={deploySymbol} onChange={(event) => setDeploySymbol(event.target.value)} />
                                </FormGroup>

                                <FormGroup className={errorField ? "has-danger" : ""}>
                                    <Label for="address" className="control-label">Adresse</Label>
                                    <Input type="text" name="address" id="address" placeholder="Sera remplit automatiquement" value={fieldSmartContract}  onChange={(event) => setFieldSmartContract(event.target.value)} />
                                </FormGroup>
                                <Button className="btn-round" color="primary" onClick={handlerDeployContract}>Deployer le Contrat</Button>
                            </div>
                        </div>
                        <hr />
                        <div>
                            {alertProp.show && <AlertCompo info={alertProp}></AlertCompo>}
                            <FormGroup className={txIdErrorField === true ? "has-danger" : ""}>
                                <Label for="txId" className="control-label">Obtenir l'Address du Contrat par txId</Label>
                                <Input type="text" name="txId" id="txId" placeholder="Obtenir l'Address du Contrat par txId" value={inputTxId} onChange={(event) => setInputTxId(event.target.value)} />
                            </FormGroup>
                            <Button className="btn-round" color="primary" onClick={checkContract}>Obtenir l'Address du Contrat</Button>
                        </div>
                        <hr />
                        <FormGroup className={errorField ? "has-danger" : ""}>
                            <Label for="phoneNumber" className="control-label">Numero</Label>
                            <Input type="phone" name="phoneNumber" id="phoneNumber" placeholder="Numero du propriétaire" value={ownerNumber} onChange={(event) => setOwnerNumber(event.target.value)} />
                        </FormGroup>
                        <FormGroup className={errorField ? "has-danger" : ""}>
                            <Label for="category" className="control-label">Catégories</Label>
                            <Input type="select" name="select" id="category" onChange={(category) => setCollectionCategory(category.target.value)}>
                                
                                {
                                    categories.map((element, index) => <option key={index} value={element}>{element}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup className={errorField ? "has-danger" : ""}>
                            <Label for="name" className="control-label">Détail de la collection</Label>
                            <Input type="textarea" name="namcollectionDetails" id="namcollectionDetails" placeholder="Détail collection" value={collectionDetails} onChange={(event) => setCollectionDetails(event.target.value)} />
                        </FormGroup>
                        <div>
                            {
                                collectionDetailsList.map((element, index) => {
                                    return <Row>
                                        <Col xs={10}>
                                            <FormGroup key={index}>
                                                <Label for="name" className="control-label">Li collection description {index + 1}</Label>
                                                <Input
                                                    type="textarea"
                                                    name="collectionDetailsList"
                                                    id="collectionDetailsList"
                                                    placeholder="Li collection description"
                                                    value={element}
                                                    onChange={(event) => handlerSetDescriptionElement(index, event.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={2}>
                                            {
                                                collectionDetailsList.length > 1 && (
                                                    <FontAwesomeIcon icon={faClose} onClick={() => handlerDeleteDescriptionList(index)}/>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                })
                            }
                            <Button block className="btn-round" color="primary" onClick={handlerAddDescriptionList}><FontAwesomeIcon icon={faPlus} /></Button>
                        </div>
                        <div className="pb-3">
                            <label className="lable">
                                Image<i> *</i>
                            </label>
                            <div className="custom-file">
                                <input
                                    className="custom-file-input"
                                    id="media"
                                    lang="en"
                                    type="file"
                                    accept="image/*,video/*,audio/*,webgl/*,.glb,.gltf"
                                    onChange={(e) => onImageChange(e)}
                                />
                                <label className="custom-file-label" htmlFor="customFileLang">
                                    Choisir une image pour la collection
                                </label>
                                {collectionImg === "" ? <span className="icon"></span>
                                    : <img src={collectionImg} alt="" style={{ padding: '8px', width: '80px', height: 'auto' }} />
                                }
                            </div>
                        </div>
                        <Row>
                            <Col className="right-side pt-3" lg="9">
                                <Button className="btn-round" color="primary" onClick={handleUploadCollection}>Ajouter le projet</Button>
                            </Col>
                            <Col className="right-side pt-3" lg="3">
                                <Button className="btn-round" color="default" onClick={toggle}>Annuler</Button>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
            <Modal isOpen={isOpenDialog} toggle={toggle} centered>
                <ModalBody>
                    <p>La collection {deployName} a été ajouté avec succès</p>
                </ModalBody>
                <div className="d-flex justify-content-end p-3">
                    <Button onClick={handleDialogOK}>OK</Button>
                </div>
            </Modal>
        </>
    )

}

export default ModalBadgeContract;

ModalBadgeContract.defaultProps = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
};

ModalBadgeContract.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};