import { useEffect, useState } from 'react';
import {getFirestore, collection, getDocs, doc, updateDoc} from "firebase/firestore";
import app from "../../../../services/Firebase";
import * as React from "react";
import PropTypes from "prop-types";
import {ModalDetailBadge} from "../../../../components/ModalDetailBadge";
import {
    Button,
    Card, Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody, Row
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faPlus} from "@fortawesome/free-solid-svg-icons";
import {ROLES_ADMIN, ROLES_AGENT, ROLES_OWNER} from "../../../../services/Constant";
const db = getFirestore(app);
const badgeCollection = collection(db, 'badge-collection');

function ModalNewUser({isOpen, toggle, userSelected, setNewUser}) {
    console.log(userSelected)
    const [loading, setLoading] = useState(false);
    const [badges, setBadges] = useState([]);
    const [address, setAddress] = useState(userSelected.address ? userSelected.address : [""]);
    const [errorField, setErrorField] = useState(false);
    const [role, setRole] = useState('');
    const roles = [
        ROLES_AGENT,
        ROLES_ADMIN,
        ROLES_OWNER,
    ]

    useEffect( () => {
        const fetchBadges = async () => {
            const badgesDocs = await getDocs(badgeCollection);
            if(badgesDocs.docs.length > 0) {
                var collectionsBadge = [];
                badgesDocs.docs.map((badge, index) => {
                    collectionsBadge[index] = {
                        address: badge.data().address,
                        name: badge.data().name
                    };

                    return collectionsBadge;
                });
                setBadges(collectionsBadge);
            }
        }

        fetchBadges();
    }, []);

    const handleOnValidate = () => {
        setLoading(true);
        if(address.length === 0 || "" === role) {
            setErrorField(true);
            return;
        }

        try {
            var agent = {
                address: address,
                role: role,
            }

            updateDoc(doc(db, "users", userSelected.phone), agent).then(ee => {
                setLoading(false);
                toggle();
            });
            setNewUser(true);
        } catch (e) {
            setLoading(false);
        }
    }

    const handlerAddDescriptionList = () => {
        address.push("");
        setAddress(prevState => ([...prevState]));
    }

    const handlerDeleteDescriptionList = (index) => {
        address.splice(index, 1);
        setAddress(prevState => ([...prevState]));
    }

    const handleChangeBadge = (index, event) => {
        address[index] = badges[event.target.value].address;
        setAddress(prevState => ([...prevState]));
    }

    return (
        <Modal className="modal-badge" funk={true} isOpen={isOpen} toggle={toggle} size="xl">
            <ModalBody>
                <div className="modal-badge-close">
                    <FontAwesomeIcon icon={faClose} className="icon-fa" onClick={toggle}/>
                </div>
                <div className="modal-badge-button">
                    <Card className="ml-auto mr-auto">
                        <div className="text-center pb-3">
                            <h4>Modification</h4>
                        </div>
                        <div className="modal-badge-box">
                            <FormGroup className={errorField ? "has-danger" : ""}>
                                <Label for="badge" className="control-label">Role de l'utilisateur</Label>
                                <Input type="select" name="select" id="badge" onChange={(event) => setRole(event.target.value)}>
                                    <option>Sélectionner le role de l'utilisateur</option>
                                    {
                                        roles.map((element, index) => <option key={index} value={element}>{element}</option>)
                                    }
                                </Input>
                            </FormGroup>
                            {
                                address.map((addressElement, index) => {
                                    return <Row>
                                        <Col xs={10}>
                                            <FormGroup className={errorField ? "has-danger" : ""}>
                                                <Label for="badge" className="control-label">Badge</Label>
                                                <Input type="select" name="select" id="badge" onChange={(event) => handleChangeBadge(index, event)}>
                                                    <option>Sélectionner un badge</option>
                                                    {
                                                        badges.map((element, index) => <option key={index} value={index}>{element.name}</option>)
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={2}>
                                            {
                                                address.length > 1 && (
                                                    <FontAwesomeIcon icon={faClose} onClick={() => handlerDeleteDescriptionList(index)}/>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                })
                            }
                            <Button block className="btn-round" color="primary" onClick={handlerAddDescriptionList}><FontAwesomeIcon icon={faPlus} /></Button>
                        </div>
                        <div className="right-side pt-3">
                            <Button className="btn-round" color="primary" onClick={loading ? null : handleOnValidate}>
                                {
                                    loading ?
                                        <div className="uil-reload-css-reload-small">
                                            <div></div>
                                        </div> :
                                        "Valider"
                                }
                            </Button> {" "}
                            <Button className="btn-round" color="default" onClick={toggle}>Annuler</Button>
                        </div>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalNewUser;

ModalDetailBadge.defaultProps = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    userSelected: PropTypes.object,
    setNewUser: PropTypes.func,
};

ModalDetailBadge.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    userSelected: PropTypes.object.isRequired,
    setNewUser: PropTypes.func.isRequired,
};
