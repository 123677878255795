import * as React from "react";
import { useEffect } from 'react';
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { Row, Col, Card, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import app from "../../../../services/Firebase";
import PropTypes from "prop-types";
import { useState } from "react";
const db = getFirestore(app);
const badgeCollection = collection(db, 'badge-collection');
const nftMintCollection = collection(db, 'badges-mint');

function MintedTableFilter({
    endIndex, setEndIndex,
    startIndex, setStartIndex,
    mintedList, setMintedList,
    handleDateFilter, handleNameFilter, handleAddressFilter,
    handleClosedFilter,handleOngoingFilter,
    nameFilter, setNameFilter,
    filterstartDate, setFilterStartDate,
    filterendDate, setFilterEndDate,
    filterActiveBadge, setFilterActiveBadge,
    filterClosedBadge, setFilterClosedBadge,
    addressFilter, setAddressFilter,}) {

        const [isOpenDate, setIsOpenDate] = useState(false);
        const [isOpenName, setIsOpenName] = useState(false);
        const [isOpenNumber, setIsOpenNumber] = useState(false);

        const onCloseDate = () => {
            setIsOpenDate(false);
          };

          const onOpenDate = () => {
            setIsOpenDate(true);
          };

          const onCloseName = () => {
            setIsOpenName(false);
          };

          const onOpenName = () => {
            setIsOpenName(true);
          };

          const onCloseNumber = () => {
            setIsOpenNumber(false);
          };

          const onOpenNumber = () => {
            setIsOpenNumber(true);
          };


    useEffect(() => {
        const getAllBadge = async () => {
            const badgeCollectionDocs = await getDocs(badgeCollection);
            var badgesCollection = [];
            badgeCollectionDocs.docs.map((result) => {
                badgesCollection[result.id] = result.data();
            })

            const getNftMintList = await getDocs(nftMintCollection);
            var result = [];
            getNftMintList.docs.map((doc) => {
                result.push(
                    {
                        ...doc.data(),
                        ...badgesCollection[doc.data().badgeSerial]
                    }
                )
            })
            //const result = (getNftMintList.docs.map(r => r.data()))

            setMintedList(result)

            return result;
        }
        getAllBadge();
    }, [setMintedList]);
    
    return (
        <div>
            <div className="marketplace-title">
                <h3>Badges total achetés</h3>
            </div>
            <Row>
        <Col xs="12" md="12">
          <Card style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className="d-flex flex-wrap justify-content-center align-items-center">
              {!filterActiveBadge && (
                <Button size="md" color="primary" className="mb-2 mr-2" onClick={() => { handleOngoingFilter()} }>
                  Badges Encours
                </Button>
              )}
              {!filterClosedBadge && (
                <Button size="md" color="primary" className="mb-2 mr-2" onClick={() => { handleClosedFilter()} }>
                  Badges Expirés
                </Button>
              )}
              {!filterstartDate && (
                <Button size="md" color="primary" className="mb-2 mr-2" onClick={onOpenDate}>
                  Filtrer par date
                </Button>
              )}
              {!nameFilter && (
                <Button size="md" color="primary" className="mb-2 mr-2" onClick={onOpenName}>
                  Filtrer par badge
                </Button>
              )}
              {!addressFilter && (
                <Button size="md" color="primary" className="mb-2 mr-2" onClick={onOpenNumber}>
                  Filtrer par Public ID
                </Button>
              )}
            </div>
          </Card>
        </Col>
        <Col xs="12" md="6">
          <Card style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className="d-flex flex-wrap justify-content-center align-items-center">
              {filterActiveBadge && (
                <Button size="md" className="mb-2 mr-2" color="warning" onClick={() => {
                  setFilterActiveBadge('');
                  setStartIndex(0);
                  setEndIndex(10);
                }}>
                  Badges Encours
                </Button>
              )}
              {filterClosedBadge && (
                <Button size="md" onClick={() => {
                  setFilterClosedBadge('');
                  setStartIndex(0);
                  setEndIndex(10);
                }}>
                  Badges Expirés
                </Button>
              )}
              {filterstartDate && filterendDate && (
                <Button size="md" color="warning" className="mb-2 mr-2" onClick={() => {
                  setFilterStartDate(null);
                  setFilterEndDate(null);
                  setStartIndex(0);
                  setEndIndex(10);
                }}>
                  {`${filterstartDate.toLocaleDateString()} - ${filterendDate.toLocaleDateString()}`}
                </Button>
              )}
              {nameFilter && (
                <Button size="md" color="warning" className="mb-2 mr-2" onClick={() => {
                  setNameFilter('');
                  setStartIndex(0);
                  setEndIndex(10);
                }}>
                  {nameFilter}
                </Button>
              )}
              {addressFilter && (
                <Button size="md" color="warning" onClick={() => {
                  setAddressFilter('');
                  setStartIndex(0);
                  setEndIndex(10);
                }}>
                  {addressFilter}
                </Button>
              )}
            </div>
          </Card>
        </Col>
      </Row>

            <Modal isCentered isOpen={isOpenDate} toggle={onCloseDate}>
                <ModalHeader>Filtre par Date</ModalHeader>
                <ModalBody>
                    <Input id="start-date-input" textColor="black" placeholder="Date début" type="date" />
                    <Input id="end-date-input" textColor="black" placeholder="Date fin" type="date" />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={onCloseDate}>
                        Annuler
                    </Button>
                    <Button color="orange" onClick={() => { onCloseDate(); handleDateFilter(); }}>
                        Appliquer
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isCentered isOpen={isOpenName} toggle={onCloseName}>
                <ModalHeader>Filtre par Nom</ModalHeader>
                <ModalBody>
                    <Input id="name-input" textColor="black" placeholder="Rechercher un nom" />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={onCloseName}>
                        Annuler
                    </Button>
                    <Button color="orange" onClick={() => { onCloseName(); handleNameFilter(); }}>
                        Appliquer
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isCentered isOpen={isOpenNumber} toggle={onCloseNumber}>
                <ModalHeader>Filtre par Numéro</ModalHeader>
                <ModalBody>
                    <Input id="address-input" textColor="black" placeholder="Rechercher un numéro" />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={onCloseNumber}>
                        Annuler
                    </Button>
                    <Button color="orange" onClick={() => { onCloseNumber(); handleAddressFilter(); }}>
                        Appliquer
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default MintedTableFilter;

MintedTableFilter.defaultProps = {
    mintedList: PropTypes.array,
    setMintedList: PropTypes.array,
    startIndex: PropTypes.any,
    setStartIndex: PropTypes.any,
    setEndIndex: PropTypes.any,
    endIndex: PropTypes.any,
    handleDateFilter: PropTypes.func,
    handleNameFilter: PropTypes.func,
    handleAddressFilter: PropTypes.func,
    handleClosedFilter: PropTypes.func,
    handleOngoingFilter: PropTypes.func,
    setNameFilter: PropTypes.any,
    nameFilter: PropTypes.any,
    filterstartDate: PropTypes.number,
    setFilterStartDate: PropTypes.number,
    filterendDate: PropTypes.any,
    setFilterEndDate: PropTypes.any,
    filterActiveBadge: PropTypes.any, 
    filterClosedBadge: PropTypes.any,
    setFilterActiveBadge: PropTypes.any, 
    setFilterClosedBadge: PropTypes.any,
    addressFilter: PropTypes.string,
    setAaddressFilter: PropTypes.string,
};